<template>
  <div class="modal" id="modalBg">
    <div class="menus_bg">
      <div class="logo">
        <img src="@/assets/images/logo_without_rd.png" alt="logo" width="170">
      </div>
      <div class="menus">
        <div class="list_my_info">
          <h2>{{lang[$store.state.lang].MODAL_TXT_MENU_TITLE}}<!--판독관리--></h2>
          <ul>
            <li :class="[tab == list.value ? 'selected' : '']" v-for="(list,idx) in menus" :key="'menus-'+idx+'-'+list.text">{{list.text}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="modal_box">
        <h3 class="title">{{lang[$store.state.lang].MODAL_TITLE_PRINT_MULTIPLE}}<!--판독소견서 다운로드--></h3>
        <p class="footer_txt print_info_txt">{{lang[$store.state.lang].MODAL_TXT_GUIDE5}}<!--"내정보 → 개인정보 변경" 에서 서명파일을 등록 해주세요.--></p>
        <div class="util_wrap">
          <div class="util">
            <div class='date_filter'>
              <div ref="calendarBtn" class='date_calender' id="modalCalendarBtn">
                <CalendarModal type="modal" @closeCalendar="closeCalendar" @pickOnlyStartDate="pickOnlyStartDate" @pick_date="setDate" @complete="$store.state.modalCalendar=false" top='41' left='40' v-if="$store.state.modalCalendar" :from="from" :to="to"/>
                <div class="icon" @click="$store.state.modalCalendar = true">
                  <img id="modalCalendarIcon" src="https://panvi.kr/images/mn_calendar-7903b8f5f22f9339a053..png" alt="calender" class="calender_normal">
                  <img id="modalCalendarIcon" src="https://panvi.kr/images/mn_calendar_on-032bcc8d12bd309554f1..png" alt="calender" class="calender_hover">
                </div>
                <div class="date_text" id="listCalendarInput" @click="controlModalCalendar">
                  <span style="text-align:end">{{from ? from.replaceAll('-','.') : ''}}</span>
                  <span class="bar_horiz"></span>
                  <span>{{to ? to.replaceAll('-','.') : ''}}</span>
                </div>
              </div>
              <div class='feature_filter'>
                <div class='feature'>
                  <select v-model="examType">
                    <option selected disabled value="examType">{{lang[$store.state.lang].FILTER_TXT_TYPE}}<!--영상종류--></option>
                    <option value="all">{{lang[$store.state.lang].FILTER_TXT_ALLTYPE}}<!--전체--></option>
                    <option value="PX">PANORAMA</option>
                    <option value="CT">CBCT</option>
                  </select>
                </div>
                <div class="keyword">
                  <input type="text" v-model="keyword" @keydown="_enterToSearch" :placeholder="lang[$store.state.lang].FILTER_TXT_ENTER_KEYWORD"> <!--환자명 또는 차트번호를 입력하세요.-->
                </div>
              </div>
            </div>
          </div>
          <div class="util_btn_wrap">
            <button @click="search" class="search_btn">
              <img src="@/assets/images/search_white.png" alt="search_icon">
              {{lang[$store.state.lang].FILTER_BTN_SEARCH}}<!--검색하기-->
            </button>
          </div>
        </div>
        <!-- 목록 -->
        <div class="exam_box" @scroll="scrollExamBox" ref="scrollExamBox">
          <div class="loading_bg" v-if="!examsAreLoaded" ref="loadingBar" :style="{top: spinnerTop+'px'}">
            <div class="spinner"></div>
          </div>
          <table class="exam_table" ref="modalExamTable">
            <thead>
              <tr>
                <th><div class="check_wrap"><input type="checkbox" :checked="all" @click="allCheck" value="all" id="exam_check_all"><label for="exam_check_all"></label></div></th>
                <th>{{lang[$store.state.lang].MODAL_THEAD_TYPE}}<!--종류--></th>
                <th>{{lang[$store.state.lang].MODAL_THEAD_CHART_ID}}<!--진료번호--></th>
                <th>{{lang[$store.state.lang].MODAL_THEAD_NAME}}<!--이름--></th>
                <th>{{lang[$store.state.lang].MODAL_THEAD_DATE}}<!--촬영일시--></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(exam,idx) in exams" :key="'exams-'+idx+'-'+'chart_id-'+exam.chart_id">
                <td>
                  <div class="check_wrap"><input :checked="checkedExam.includes(exam.seq) || all" :value="exam.seq" @click="check" type="checkbox" :id="`exam_check_`+exam.seq"><label :for="`exam_check_`+exam.seq"></label></div>
                </td>
                <td>{{exam.type}}</td>
                <td>{{exam.chart_id}}</td>
                <td>{{exam.patient_name}}</td>
                <td>
                  <span>{{`${new Date(exam.taken).getFullYear()}.${(new Date(exam.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(exam.taken).getMonth()+1).toString():new Date(exam.taken).getMonth()+1}.${(new Date(exam.taken).getDate()).toString().length == 1 ? '0'+(new Date(exam.taken).getDate()).toString() : new Date(exam.taken).getDate()}`}} </span>
                  <span>{{new Date(exam.taken).toTimeString().slice(0,8)}}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- 버튼 -->
        <div class="button_wrap">
          <div class="buttons">
            <button @click="goToMain" class="cancel_btn basic_btn">{{lang[$store.state.lang].EDIT_INFO_BUTTON_CLOSE}}<!--메인페이지로 이동--></button>
            <button @click="downloadReport" :disabled="checkedExam.length == 0" class="save_btn basic_btn">{{lang[$store.state.lang].MODAL_BTN_SAVE_PDF}}<!--PDF 다운로드--></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import wsMixin from '@/wsMixin';
import CalendarModal from './CalendarModal.vue'
import alert from '@/alert'

export default {
  name : 'ModalVue',
  mixins: [wsMixin],
  components : {
    CalendarModal
  },
  data(){
    return {
      lang,
      exams : [],
      dateText : lang[sessionStorage.getItem('lang')].FILTER_BTN_ALLTIME,
      from : '',
      to : '',
      keyword : "",
      examType : "examType", // 'examType' || 'all' || 'PX' || 'CT'
      checkedExam : [],
      all : false, 
      limit: 15,
      index: 0,
      body: {},
      examsAreLoaded: false,
      spinnerTop: 0,
      tab: 'print',
      menus: [
        {value: 'print', text: lang[this.$store.state.lang].MENU_PRINT_MULTIPLE }, // 판독소견서 
      ],
    }
  },
  methods : {
    alert,
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      /*
      ** 157번 action(= 영상목록 조회)에 대한 응답이 수신되었을 때의 처리
      ** id = 1 컴포넌트 마운트, 특정 조건을 가진 영상목록을 조회하여 search()가 호출되었을 때
      ** id = 10 영상목록에서 scroll 이벤트가 발생하여 끝까지 스크롤 되었을 때
      */
      if( data.action == 157 ) {
        // 응답이 성공인 경우
        if(data.body[0].content.success){
          if(data.body[1].content.length == this.limit) this.index = this.index + this.limit; 
          // id = 1 인 경우, 수신된 영상목록을 exams 에 할당하여 영상목록을 변경한다.
          if(data.id == 1) {
            this.$refs.scrollExamBox.scrollTop = 0;
            this.exams = [...data.body[1].content];
          }
          // id = 10 인 경우, 수신된 영상목록을 기존의 영상목록에 추가한다.
          if(data.id == 10) {
            // 수신된 영상목록의 길이가 0 보다 큰 경우에 대한 처리
            if(data.body[1].content.length > 0) {
              let recvExams = [...data.body[1].content];
              // 응답으로 받은 exams와 기존의 exams를 비교하여 겹치는 exam은 제거한다.
              this.exams.forEach((exam) => {
                recvExams.forEach((comparisonExam,idx) => {
                  if(exam.seq == comparisonExam.seq) recvExams.splice(idx,1);
                })
              });
              this.exams = [...this.exams, ...recvExams];
            }
          }
        }
        // 응답이 실패인 경우
        else this.alert('error',this.lang[this.$store.state.lang].LIST_ALERT_TXT_FAIL_RECV_EXAMS); // '영상목록을 조회하는 것에 실패하였습니다.\n다시 시도해주세요'
        this.examsAreLoaded = true;
      }
      // 29번 action(= pdf 저장) 에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 29) {
        console.log('29번 action > Modal ', data);
        if(data.body[0].content.success) {
          if(!data.body[0].content.ready && !data.body[0].content.err) {
            this.alert('default',lang[this.$store.state.lang].MODAL_TXT_DOWNLOAD_PDF); // 잠시후 다운로드가 시작됩니다.
          }
          else if(!data.body[0].content.ready && data.body[0].content.err) this.alert('error',lang[this.$store.state.lang].MODAL_TXT_FAIL_DOWNLOAD_PDF) // pdf 저장에 실패하였습니다.\n다시 시도해주세요.
          else if(data.body[0].content.ready) {
            let key = process.env["VUE_APP_DEBUG"]?process.env["VUE_APP_WEB_DEV_URL"]:process.env["VUE_APP_WEB_URL"];
            window.open(key + "report/"+data.body[0].content.token);
          }
        }else this.alert('error',lang[this.$store.state.lang].MODAL_TXT_FAIL_DOWNLOAD_PDF) // pdf 저장에 실패하였습니다.\n다시 시도해주세요.
      }
    },
    pickOnlyStartDate(){
      this.to = this.from;
      this.dateText = `${this.__makeDateString(new Date(this.from.replace(/-/g, '/')),'.')}`;
    },
    __makeNumberToString(number){
      // 문자열로 변환된 number 의 길이가 1 인 경우(= 일의 자리 숫자)에 0을 앞에 붙여 반환한다.
      if(number.toString().length == 1) return `0${number}`;
      // 문자열로 변환된 number 의 길이가 1 이 아닌 경우(= 십의 자리 숫자)에 문자열로 변환된 number를 반환한다.
      else return number.toString();
    },
    __makeDateString(date,separator){
      return `${date.getFullYear()}${separator}${this.__makeNumberToString(date.getMonth()+1)}${separator}${this.__makeNumberToString(date.getDate())}`
    },
    setDate(range){
      /*
      ** range의 data type은 array이고, 0번 index는 dateFrom, 1번 index는 dateTo를 가리킨다.
      ** dateFrom, dateTo가 결정되지 않은 경우 기본값은 빈 문자열 이다. 
      */
     // dateTo 가 결정된 경우에 대한 처리로, 화면에 시작일, 종료일을 문자열로 표시하고 from, to 를 변경한다.
      if(range[1].length>0){
        this.dateText = `${this.__makeDateString(new Date(range[0].replace(/-/g, '/')),'.')}~${this.__makeDateString(new Date(range[1].replace(/-/g, '/')),'.')}` 
        this.to = this.__makeDateString(new Date(range[1].replace(/-/g, '/')),'-');
      }
      // dateTo가 결정되지 않은 경우에 대한 처리로, 화면에 시작일을 표시하고, from, to를 변경한다.
      else{
        this.dateText = this.__makeDateString(new Date(range[0].replace(/-/g, '/')),'.');
        this.from = this.__makeDateString(new Date(range[0].replace(/-/g, '/')),'-');
        this.to = '';
      }
    },
    setDateUsingBtn(ev){
      // click 이벤트가 발생한 target의 id 속성이 'all'인 경우에 대한 처리
      if(ev.target.id == 'all'){
        this.dateText = lang[sessionStorage.getItem('lang')].FILTER_BTN_ALLTIME;
        this.from = "";
        this.to = "";
      }
      // click 이벤트가 발생한 target의 id 속성이 'today'인 경우에 대한 처리
      else if(ev.target.id == 'today') {
        this.dateText = this.__makeDateString(new Date(),'.');
        this.from = this.__makeDateString(new Date(),'-');
        this.to = this.__makeDateString(new Date(),'-');
      }
      // click 이벤트가 발생한 target의 id 속성이 'all 이거나, 'today'인 경우를 제외한 때에 대한 처리
      else {
        let today = new Date();
        let start = "";
        // click 이벤트가 발생한 target의 id 속성이 'week'인 경우에 대한 처리
        if(ev.target.id == 'week'){
          start = new Date(today.getFullYear(),today.getMonth(),today.getDate()-6)
        }
        // click 이벤트가 발생한 target의 id 속성이 'month'인 경우에 대한 처리
        else if(ev.target.id == 'month'){
          start = new Date(today.getFullYear(),today.getMonth()-1,today.getDate());
        }
        this.from = this.__makeDateString(start,'-');
        this.to = this.__makeDateString(today,'-');
        this.dateText = `${this.__makeDateString(start,'.')}~${this.__makeDateString(today,'.')}`
      }
    },
    allCheck(){
      this.all = !this.all;
      // 모든 영상목록이 선택된 경우
      if(this.all){
        this.checkedExam = [];
        // 영상목록의 길이만큼 반복문을 실행하여 모든 영상목록 객체의 seq를 checkedExam에 push하여 어떤 영상을 선택했는지 식별할 수 있도록 한다.
        this.exams.forEach((exam)=>{
          this.checkedExam.push(parseInt(exam.seq))
        })
      }
      // 모든 영상목록의 선택이 해제된 경우
      else{
        this.checkedExam = [];
      }
    },
    check(ev){
      let selectedSeq = parseInt(ev.target.value);
      // checkedExam에 click이벤트가 발생한 target의 value가 포함되어 있는 경우, checkedExam에서 해당 value를 제거하여 선택을 해제한다.
      if(this.checkedExam.includes(selectedSeq)){
        this.checkedExam = this.checkedExam.filter(seq => seq != selectedSeq);
        // 전체선택이 되어있다가 해제되는 경우에 대한 처리
        if(this.checkedExam.length != this.exams.length && this.all) this.all = false; 
      }
      // checkedExam에 click이벤트가 발생한 target의 value가 포함되어 있지 않은 경우, checkedExam에 해당 value를 push한다.
      else{
        this.checkedExam.push(selectedSeq)
        // 전체선택이 되어있지 않다가 영상목록 전체가 선택되는 경우에 대한 처리
        if(this.checkedExam.length == this.exams.length && !this.all) this.all = true;
      }
    },
    search(){
      this.checkedExam = [];
      this.all = false;
      this.examsAreLoaded = false;
      this.$refs.scrollExamBox.scrollTop = 0;
      this._changeSpinnerTop();
      this.index = 0;
      let body = {
        contentType: 1,
        content: {
          rangeFrom : this.index,
          rangeTo : this.index + this.limit,
          filter: {
            status: 'completed'
          }
        }
      };
      
      /*
      ** 사용자가 특정 조건을 가진 영상목록을 조회하는 경우에 대한 처리
      ** 시작일이 설정되어 있거나, 영상의 종류가 'all', 'examType'(기본값) 이거나, keyword의 값이 0 보다 큰 경우에 대한 처리
      ** 위와 같은 상황은 사용자가 조회할 때, 전체가 아닌 특정 조건을 가진 영상을 조회한 것 이다.
      */
      if(!(this.from.length == 0 && (this.examType == 'all'||this.examType == 'examType') && this.keyword.length==0)){
        // 시작일이 설정되어있는 경우에 대한 처리
        if(this.from.length > 0) {
          body.content.filter.dateFrom = this.from;
          body.content.filter.dateTo = this.to;
        }
        // 영상의 종류가 'all' 이거나 기본값인 'examType' 이 아닌 경우에 대한 처리
        if(this.examType != 'all'&&this.examType != 'examType') body.content.filter.type = this.examType;
        // keyword의 길이가 0보다 큰 경우에 대한 처리
        if(this.keyword.length > 0) body.content.filter.keyword = this.keyword;
      } 
      this.body = body;
      let searchInfo = this.$$build(
        157,
        1,
        [body]
      );
      this.$socket.send(searchInfo);
    },
    scrollExamBox(ev){
      if(!this.examsAreLoaded) return;
      // 스크롤이 끝까지 다 내려간 경우에 영상목록을 추가로 요청한다.
      if(Math.ceil(ev.target.scrollTop+ev.target.clientHeight)>=ev.target.scrollHeight){
        this._changeSpinnerTop();
        this.examsAreLoaded = false;
        this.body.content.rangeFrom = this.index;
        this.body.content.rangeTo = this.index + this.limit;
        let readyExams = this.$$build(157,10,[this.body]);
        this.$socket.send(readyExams);
      }
    },
    closeCalendar(target){
      // click 이벤트가 발생한 target이 달력을 여는 버튼을 포함하지 않는 외부영역인 경우, 달력을 닫는다.
      if(!this.$refs.calendarBtn.contains(target)) this.$store.state.modalCalendar = false;
    },
    controlModalCalendar(){
      this.$store.state.modalCalendar = !this.$store.state.modalCalendar;
    },
    downloadReport(){
      let packet = this.$$build(29,[{
        contentType: 1,
        content: {
          exams: this.checkedExam,
          lang: this.$store.state.lang == 'ko' ? 'kr' : this.$store.state.lang
        }
      }]);
      this.$socket.send(packet);
    },
    goToMain(){
      this.$store.state.modalViewModal = false;
      this.$store.state.menu = sessionStorage.getItem('examType');
    },
    _changeSpinnerTop(){
      this.spinnerTop = this.$refs.scrollExamBox.scrollTop;
    },
    _enterToSearch(ev){
      if(ev.keyCode == 13) this.search();
    }
  },
  watch:{
    exams(){
      if(this.all) {
        if(this.exams.length != this.checkedExam.length) {
          this.checkedExam = [];
          this.exams.forEach((exam)=>{
            this.checkedExam.push(parseInt(exam.seq))
          });
        }
      }
    }
  },
  mounted(){
    this.body = {
      contentType: 1,
      content: {
        rangeFrom: this.index,
        rangeTo: this.index + this.limit,
        filter: {
          status: 'completed'
        }
      }
    }
    let readyExams = this.$$build(157,1,[this.body]);
    this.$socket.send(readyExams);
  },
}
</script>

<style scoped>
  /* 모달css */
  .modal{
    height: 100vh;
    display: grid;
    grid-template-columns: 223px 1fr;
    background-color: #1A1A1B;
    color: white;
    font-weight: 700;
    font-size: 16px;
    overflow: hidden;
    z-index: 1;
  }
  .menus_bg{
    background-color: #2E2E33;
  }
  .menus{
    padding-left: 24px;
    box-sizing: border-box;
  }
  .menus>div{
    margin-bottom: 49px;
  }
  .menus h2{
    margin-bottom: 26px;
    font-size: 20px;
  }
  .logo{
    padding: 24px 0;
    text-align: center;
    margin-bottom: 21px;
  }
  .menus ul{
    margin-left: 14px;
  }
  .menus ul>li{
    padding-left: 12px;
    height: 32px;
    margin-bottom: 16px;
    border-left: 2px solid transparent;
    line-height: 32px;
    cursor: pointer;
  }
  .menus ul>.selected,
  .menus ul>li:hover {
    border-left-color: #5B5B5E;
    background: #212125;
  }
  .modal_box{
    position: relative;
    width: 640px;
    height: 100vh;
    padding-top: 84px;
    padding-bottom: 60px;
    display: grid;
    grid-template-rows: 50px auto auto 1fr 60px;
    grid-row-gap: 6px;
    background-color: #1A1A1B;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .content{
    width: 100%;
    min-width: 750px;
    height: 100vh;
    display: flex;
    justify-content: center;
  }
  .title{
    transform: translateX(-106px);
    transition: transform 0.5s;
  }
  .close_btn{
    position: absolute;
    top: 40px;
    right: 48px;
    height: 24px;
    padding: 0;
    background:none;
  }
  .util_wrap{
    display: grid;
    grid-gap: 6px;
  }
  .util{
    display: flex;
  }
  .util_btn_wrap{
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .util_btn_wrap button{
    width: 157px;
    height: 36px;
  }
  .util_btn_wrap .clear_btn img{
    width: 100%;
  }
  .title{
    margin: 0;
    font-size: 24px;
    color: white;
  }
  .search_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    color: white;
    border: 1px solid #9560f5;
    background-color: #0e0e0e;
    border-radius: 4px;
    font-weight: 700;
  }
  .search_btn:hover{
    background: #9560F5;
  }
  .caution{
    text-align: right;
    font-size: 13px;
    color: #A0A0A0;
  }
  p{
    margin:0;
  }
  .info_wrap{
    display: flex;
    align-items: center;
  }
  .flex_end {
    justify-content: flex-end;
  }
  .upload{
    display: flex;
    align-items: flex-start;
    margin-bottom: 3px;
  }
  .footer_txt{
    margin-bottom: 3px;
    padding-left: 5px;
    padding-right: 10px;
    color: #B7B7B7;
    font-size: 11px;
    line-height: 18px;
  }
  .print_info_txt{
    color: white;
    font-size: 12px;
  }
  .upload_button{
    height: 24px;
    padding: 0 3px;
    border: 1px solid #DFDEE0;
    background: #0e0e0e;
    border-radius: 2px;
    color: #DFDEE0;
    box-sizing: border-box;
    font-size: 11px;
  }
  .button_wrap{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .print_btn_wrap{
    display: flex;
    column-gap: 16px;
  }
  .buttons{
    display: flex;
    column-gap: 12px;
    height: 44px;
  }
  .basic_btn{
    background-color: #0e0e0e;
    border-radius: 4px;
    height: 100%;
  }
  .cancel_btn{
    width: 176px;
    border: 1px solid #545458;
    color: #DFDEE0;
  }
  .save_btn:disabled{
    border: 1px solid transparent;
    opacity: 0.2; 
    cursor: auto;
  }
  .save_btn, .print_btn{
    width: 176px;
    color: white;
    background:  #A45CFE;
    font-weight: 700;
  }
  .save_btn:hover{
    border: 1px solid white;
  }
  .request_btn{
    width: 120px;
    opacity: 0.2;
    color: white;
    font-weight: 700;
    cursor: default;
  }
  .request_btn:hover{
    background-color: #9560F5;
  }
  .request_btn_active{
    opacity: 1;
    cursor: pointer;
  }
  .signature_wrap{
    display: flex;
  }
  .exam_box{
    position: relative;
    border: 1px solid #A0A0A0;
    border-left-width: 0;
    border-right-width: 0;
    overflow-y: scroll;
  }
  .exam_box::-webkit-scrollbar{
    width: 4px;
    height: 4px;
  }
  .exam_box::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .exam_box::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    background-color: #403F45;
  }
  table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 12px;
  }
  thead{
    position: sticky;
    top: 0;
    background-color: #1A1A1B;
    z-index: 1;
  }
  th{
    height: 42px;
    color: #A0A0A0;
    border-bottom: #606060 solid 1px;
  }
  th:first-child{
    width: 52x;
  }
  th:nth-child(2){
    width: 122px;
  }
  th:nth-child(3), th:nth-child(4){
    width: 189px;
  }
  th:last-child{
    width: 162px;
  }
  td{
    height: 36px;
    border-top: 1px solid #303030;
    color: #D0D0D0;
    font-size: 12px;
    text-align: center;
  }
  .check_wrap{
    display: inline-block;
    position: relative;
    width: 12px;
    height: 12px;
  }
  .check_wrap input{
    display: none;
    width: 100%;
    height: 100%;
  }
  .check_wrap label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://panvi.kr/images/dise_pu_chk-c01e43bd3e4ec973d845..png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .check_wrap > input[type="checkbox"]:checked + label{
    background-image: url('https://panvi.kr/images/dise_pu_chk_on-9bcce7de261a2ea0bdaf..png');
  }

  /**dateFilter css*/

  .date_filter{
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: 218px 1fr;
    grid-column-gap: 4px;
  }
  .date_btns{
    display: grid;
    grid-template-columns: repeat(3, 1fr 1px) 1fr;
    place-items: center center;
    border: 1px solid #2E2E33;
    border-radius: 4px;
  }
  .date_btns button{
    width: 100%;
    height: 100%;
    color: #5B5B5E;
    background-color: #0e0e0e;
    border-radius: 4px;
    border: 1px solid #0e0e0e;
    font-size: 14px;
  }
  .date_btns button:hover{
    color: white;
    border-color: #9560F5;
  }
  .date_btns div{
    width: 1px;
    height: 24px;
    background-color: #707070;
    z-index: 1;
  }
  .date_calender{
    position: relative;
    display: grid;
    grid-template-columns: 40px 1fr;
    align-items: center;
    border: 1px solid #2E2E33;
    border-radius: 4px;
    background-color: #0e0e0e;
  }
  .date_calender:hover{
    border-color :#9560F5;
  }
  .date_calender:hover .calender_hover{
    display: block;
  }
  .date_calender:hover .calender_normal{
    display: none;
  }
  .calender_hover{
    display: none;
  }
  .icon{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .date_calender input{
    position: absolute;
    top: 0;
    width: 100%;
    color: #EEECF1;
    background-color: transparent;
    cursor: pointer;
  }

  /**featureFilter css */

  .feature_filter{
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-column-gap: 6px;
  }
  .feature select{
    width: 100%;
    height: 100%;
    padding-left: 6px;
    color: #848488;
    background-color: #0e0e0e;
    border: 1px solid #2E2E33;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    font-size: 12px;
  }
  .feature select:hover,
  .feature select:focus{
    border-color: #9560F5;
    color: white;
  }
  .keyword input{
    width: 100%;
    height: 100%;
    padding-left: 13px;
    box-sizing: border-box;
    color: white;
    background-color: #0e0e0e;
    border: 1px solid #2E2E33;
    border-radius: 4px;
    outline: none;
  }
  .keyword input:hover, 
  .keyword input:focus{
    border-color: #9560F5;
  }
  .loading_bg{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading_bg>.spinner{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: var(--color-selected);
    border-bottom-color: var(--color-selected);
    animation: spinner .8s ease infinite;
    z-index: 3;
  }
  .bar_horiz{
    display: inline-block;
    width: 6px;
    height: 1px;
    background: #848488;
  }
  .date_text{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 14px;
    color: #DFDEE0;
    font-size: 12px;
  }

  @media (max-width:1124px) {
    .title{
      transform: translateX(-70px);
    }
  }
</style>