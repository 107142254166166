<template>
  <div>
    <h1>프론트엔드-백엔드 통신프로토콜 예제코드</h1>

    필드1 <input type="text" v-model="field1"><br>
    
    필드2 <select v-model="field2">
      <option value="1">선택1</option>
      <option value="2">선택2</option>
      <option value="3">선택3</option>
    </select><br>

    필드3 <input type="file" accept="image/*" ref="ref1"><br>

    <button type="button" @click="run">눌러보기</button>

    <img v-if="image" :src="image">
  </div>
</template>

<script>
import wsMixin from '@/wsMixin'

export default {
  name: "SamplePage",
  mixins: [wsMixin],
  data: function() {
    return {
      field1: "",
      field2: "1",
      image: undefined
    }
  },
  methods: {
    run: function() {
      // 일반 텍스트 필드 읽기
      console.log( this.field1, this.field2 );

      // 파일 읽기
      let fr = new FileReader();
      fr.onload = (e) => {
        this.image = e.target.result;
        console.log( "이미지파일의 내용", this.image );
      };
      fr.readAsDataURL(this.$refs["ref1"].files[0]);
    }
  }
}
</script>