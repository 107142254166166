import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
Vue.use(VueToast);

function alert(type,msg,onClick) {
  Vue.$toast.open({
    message: msg,
    type: type,
    position: 'top-right',
    duration: 4000,
    onClick: onClick,
  });
}

export default alert;
