<template>
  <div class="my_info_modal">
    <div class='header border_bt margin_24'>
      <h3>{{$store.state.hospitalInfo.name}}</h3>
      <p v-if="$store.state.lang == 'ko'" class='name'>{{$store.state.doctorInfo.name}} {{lang[$store.state.lang].MY_INFO_TXT_DOCTOR}}<!--원장--></p>
      <p v-else class='name'>{{lang[$store.state.lang].MY_INFO_TXT_DOCTOR}}<!--Dr.-->{{$store.state.doctorInfo.name}}</p>
      <p class='mail'>{{$store.state.doctorInfo.email}}</p>
    </div>
    <div class='sub_info border_bt margin_24'>
      <span class='sub_info_title'>{{lang[$store.state.lang].MY_INFO_TXT_SUB_INFO}}<!--구독 정보--></span>
      <span class='sub_info_detail'>
        <button class="badge bg_purple">free trial</button>
        <span class="day">D-99</span>
      </span>
    </div>
    <div class="request_history margin_24" style="display:none">
      <div class='history_item' v-for="(history,idx) in histories" :key="'histories-'+idx+'-'+history.type">
        <div class='item_left' v-if="history.status">
          <span>{{history.type}}</span>
          <span>{{history.patient_name}}</span>
          <span>{{history.status == 'completed' ? lang[$store.state.lang].MY_INFO_TXT_COMPLETE : lang[$store.state.lang].MY_INFO_TXT_UNREADABLE}}</span>
        </div>
        <div class="item_left" v-if="history.written_by">
          <span>{{history.type}}</span>
          <span>{{history.written_by}}</span>
          <span>{{lang[$store.state.lang].MY_INFO_TXT_NEW_MESSAGE}}<!--새메시지--></span>
        </div>
        <div class="item_left" v-if="history.type == 'bill'">
          <span v-if="$store.state.lang == 'ko'">{{new Date(history.received).getFullYear()}}년{{new Date(history.received).getMonth()+1}}월 청구내역 도착</span>
          <span v-if="$store.state.lang != 'ko'">Billing of {{months[new Date(history.received).getMonth()].slice(0,3)}} {{new Date(history.received).getDate()}} Arrival</span> 
        </div>
        <div>
          <span v-if="history.status">{{__returnDateElapsed(history.taken)}}</span>
          <span v-if="history.written_by">{{__returnDateElapsed(history.written_at)}}</span>
          <span v-if="history.type == 'bill'">{{__returnDateElapsed(history.received)}}</span>
        </div>
      </div>
    </div>
    <div class="info_btns">
      <div class='btn_top'>
        <button @click="openChangePrivateInfoView('myInfo')">{{lang[$store.state.lang].MY_INFO_TXT_MY_INFO}}<!--내 정보--></button>
        <button @click="openChangePrivateInfoView('membership')">{{lang[$store.state.lang].MY_INFO_TXT_SUB_INFO}}<!--구독 정보--></button>
      </div>
      <div>
        <button @click="logout" class='logout_btn'>{{lang[$store.state.lang].MY_INFO_TXT_LOG_OUT}}<!--로그아웃--></button>
      </div>
    </div>
  </div>  
 
</template>

<script>
import lang from '@/lang'
import wsMixin from '@/wsMixin'
export default {
  name : 'MyInfoModal',
  mixins: [wsMixin],
  data(){
    return{
      lang,
      months: ['January','February','March','April','Jun','July','August','September','October','November','December'],
      histories: [
        {
          type: 'PX',
          patient_name: '이환자',
          taken: "2023-05-13T15:00:00.000Z",
          status: "completed",
          seq: 1
        },
        {
          type: 'PX',
          patient_name: '김환자',
          taken: "2023-04-19T09:11:47.000Z",
          status: "unreadable",
          seq: 2
        },
        {
          type: 'PX',
          written_by: '홍길동',
          written_at: "2023-04-19T09:11:24.000Z",
          seq: 0
        },
        {
          type: 'bill',
          received: "2023-04-19T09:11:24.000Z"
        },
      ]
    }
  },
  methods : {
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      if(data.action == 103) {
        this.histories.unshift({
          type: 'PX',
          written_by: data.body[0].content.witten_by.name,
          written_at: new Date().toISOString(),
          seq: data.body[0].content.exam
        })
      }
    },
    openChangePrivateInfoView(button){
      if(button == 'myInfo')this.$store.state.membership = [false];
      else if(button == 'membership') this.$store.state.membership = [true];
      // 개인정보 변경 창이 꺼져있고, 멤버십 정보도 false 인 경우 개인정보 변경 창이 보이도록 한다.
      // 현재 멤버십 정보는 모달로 처리하므로, 멤버십 정보가 false 인 조건이 충족되어야 한다.
      if(!this.$store.state.changePrivateInfoView && !this.$store.state.membership[0]) this.$store.state.changePrivateInfoView = true;
      else this.$store.state.myInfoModal = false;
    },
    logout(){
      let packet = this.$$build(12,[]);
      this.$socket.send(packet);
    },
    __makeNumberToStringLengthTwo(num){
      return num.toString().length == 2 ? num.toString() : `0${num.toString()}`;
    },
    // 시간이 얼마나 지났는지를 계산한다. ( 어제, 시간, 분 )
    __returnDateElapsed(dateStr){
      const dateObj = {
        year: new Date(dateStr).getFullYear(),
        month: new Date(dateStr).getMonth()+1,
        date: new Date(dateStr).getDate()
      }
      const today = {
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        date: new Date().getDate()
      }
      const yesterday = {
        year: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1).getFullYear(),
        month: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1).getMonth()+1,
        date: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1).getDate()
      }
      if(new Date(dateObj.year,dateObj.month,dateObj.date).getTime() == new Date(today.year,today.month,today.date).getTime()){
        const diff = new Date().getTime() - new Date(dateStr).getTime();
        let hour = diff / (1000 * 60 * 60 );
        if(hour >= 1) return `${parseInt(hour)}${this.lang[this.$store.state.lang].MY_INFO_TXT_HOURS_AGO}`; // 시간 전
        else {
          let minute = diff / (1000 * 60);
          return `${parseInt(minute)}${this.lang[this.$store.state.lang].MY_INFO_TXT_MINUTES_AGO}`; // 분 전
        }
      }else if(new Date(dateObj.year,dateObj.month,dateObj.date).getTime() == new Date(yesterday.year,yesterday.month,yesterday.date).getTime()){
        return this.lang[this.$store.state.lang].MY_INFO_TXT_YESTERDAY; // 어제
      }else{
        if(this.$store.state.lang == 'ko') return `${dateObj.year}.${this.__makeNumberToStringLengthTwo(dateObj.month)}.${this.__makeNumberToStringLengthTwo(dateObj.date)}`
        else return `${this.months[parseInt(dateObj.month) -1 ].slice(0,3)} ${this.__makeNumberToStringLengthTwo(dateObj.date)}, ${dateObj.year}`;
      }
    }
  },
}
</script>

<style scoped>
  .modal_bg_black{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 3;
  }
  .request_history::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .request_history::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .request_history::-webkit-scrollbar-thumb {
    background-color: #403f45;
    border-radius: 3px;
  }
  .my_info_modal{
    position: absolute;
    bottom: 128px;
    left: var(--sidebar-width);
    padding-top: 30px;
    padding-bottom: 4px;
    width: 326px;
    box-sizing: border-box;
    background: #212125;
    border: 1px solid #DFDEE0;
    border-radius: 8px;
  }
  .border_bt{
    margin-bottom: 21px;
    border-bottom: 1px solid #2E2E33;
  }
  .margin_24{
    margin-right: 24px;
    margin-left: 24px;
    text-align: center;
  }
  h3,p{
    margin: 0;
  }
  .header h3{
    margin-bottom: 15px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
  .header p{
    color: #DFDEE0;
    font-size: 16px;
    line-height: 24px;
  }
  .header>.name{
    margin-bottom: 8px;
  }
  .header>.mail{
    margin-bottom: 15px;
  }
  .sub_info{
    padding: 0 0 21px 12px;
    display: flex;
    align-items: center;
    color: #DFDEE0;
    font-size: 14px;
  }
  .sub_info_title{
    margin-right: 40px;
    font-weight: 400;
    line-height: 22px;
  }
  .sub_info_detail{
    display: flex;
    align-items: center ;
    gap: 6px;
  }
  .sub_info_detail .day{
    color: #A45CFE;
    font-size: 12px;
    font-weight: 700;
  }
  .request_history{
    margin-bottom: 15px;
    padding: 0 12px;
    height: 60px;
    overflow: auto;
  }
  .request_history>div{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: white;
  }
  .item_left{
    display: flex;
    gap: 6px;
  }
  .info_btns{
    padding: 0 5px;
  }
  .info_btns button{
    height: 44px;
  }
  .btn_top{
    margin-bottom: 4px;
    display: flex;
    gap: 8px;
  }
  .btn_top button{
    flex: 1;
    border: 1px solid #848488;
    border-radius: 4px;
    background-color: transparent;
    color: white;
  }
  .logout_btn{
    width: 100%;
    background: transparent;
    color: white;
    border-radius: 4px;
    border: 1.5px solid #574EE0;
    font-weight: 600;
  }
  .logout_btn:hover{
    background: rgba(87, 78, 224, 0.5);
    border-color: rgba(87, 78, 224, 0.5);
  }
  .badge{
    display: flex;
    align-items: center;
    padding: 1px 3px;
    width: auto;
    height: 17px;
    line-height: 17px;
    color: white;
    font-size: 11px;
    border: none;
    border-radius: 2px;
  }
  .bg_purple{
    background-color: #A45CFE;
  }
  .bg_blue{
    background-color: #678DF2;
  }
</style>