<template>
  <div class="cross_section_modal">
    <div class="modal_box">
      <div class="close_btn" @click="$store.state.crossSectionModal=false">
        <button>
          <img src="https://panvi.kr/images/dise_pu_close-48048e892d290a5210d9..png" alt="close_btn">
        </button>
      </div>
      <div class="img_box">
        <div class="row_top">
          <div class="img" :style="{backgroundImage:`url(${imgUrl})`}"></div>
          <div class="img" :style="{backgroundImage:`url(${imgUrl})`}"></div>
        </div>
        <div class="row_bottom">
          <div class="img" :style="{backgroundImage:`url(${imgUrl})`}"></div>
          <div class="img" :style="{backgroundImage:`url(${imgUrl})`}"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'CrossSectionModal',
  props : {
    imgUrl : String
  },
}
</script>

<style scoped>
  .cross_section_modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }
  .modal_box{
    position: relative;
    padding: 30px;
    width: 70%;
    height: 80%;
    border: 2px solid gray;
    border-radius: 4px;
    background-color: #1A1A1B;
    box-sizing: border-box;
  }
  .close_btn{
    position: absolute;
    top: 0;
    right: 10px;
  }
  .close_btn button{
    font-size: 24px;
  }
  .img_box{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
  .row_top,.row_bottom{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>