import {Buffer} from 'buffer'
import wsEvent from '@/wsEvent'

export default {
  created: function() {
    if(typeof this.onConnected=='function') wsEvent.$on("connected", this.onConnected);
    if(typeof this.onReconnected=='function') wsEvent.$on("reconnected", this.onConnected);
    if(typeof this.onDisconnected=='function') wsEvent.$on("disconnected", this.onDisconnected);
    if(typeof this.onRecv=='function') wsEvent.$on("recv", this.onRecv);
    if(typeof this.onError=='function') wsEvent.$on("error", this.onError);
  },
  destroyed: function() {
    if(typeof this.onConnected=='function') wsEvent.$off("connected", this.onConnected);
    if(typeof this.onReconnected=='function') wsEvent.$off("reconnected", this.onConnected);
    if(typeof this.onDisconnected=='function') wsEvent.$off("disconnected", this.onDisconnected);
    if(typeof this.onRecv=='function') wsEvent.$off("recv", this.onRecv);
    if(typeof this.onError=='function') wsEvent.$off("error", this.onError);
  },
  methods: {
    $$build(action, id = 0, body = []) {
      let stamp = (new Date()).getTime();
      let now = stamp;


      if(arguments.length==2 && Array.isArray(arguments[1])) { body = arguments[1]; id = 0; }
      let packet = Buffer.alloc(4+(body.length*5));
      let packetIdx = 0;
      let bufs = [];

      now = (new Date()).getTime();
      stamp = now;

      packet.writeUInt16BE(action, packetIdx); packetIdx+=2;
      packet.writeUInt8(id, packetIdx++);
      packet.writeUInt8(body.length, packetIdx++);
      for(let i=0; i<body.length; i+=1) {

        now = (new Date()).getTime();
        stamp = now;
        
        packet.writeUInt8(body[i].contentType, packetIdx++);
        let buf;
        if(Buffer.isBuffer(body[i].content)) {
          buf = body[i].content;
        }
        else if(typeof body[i].content=='object'||Array.isArray(body[i].content)) {
          if(body[i].contentType==3) {
            if(body[i].content.patient_name && body[i].content.chart_id && body[i].content.sex && body[i].content.age && body[i].content.date && body[i].content.time) {
              buf = Buffer.from(`{"filename":"${body[i].content.filename}","file":[${body[i].content.file.join(",")}], "patient_name":"${body[i].content.patient_name}", "chart_id":"${body[i].content.chart_id}", "sex":"${body[i].content.sex}", "age":"${body[i].content.age}", "date":"${body[i].content.date}", "time":"${body[i].content.time}"}`);
            } else {
              buf = Buffer.from(`{"filename":"${body[i].content.filename}","file":[${body[i].content.file.join(",")}]}`);
            }
          } else {
            buf = Buffer.from(JSON.stringify(body[i].content));
          }
        }
        else {
          buf = Buffer.from(body[i].content);
        }
        bufs.push(buf);
        packet.writeUInt32BE(buf.length, packetIdx);
        packetIdx+=4;
      }

      now = (new Date()).getTime();
      stamp = now;
      
      packet = Buffer.concat([packet, ...bufs]);

      now = (new Date()).getTime();
      stamp = now;

      return packet;
    }
  }
}