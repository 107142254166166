<template>
  <div class="select" ref="select">
    <div @click="controlStates" :class="['selected_option',selectInfo.hoveredColor ? 'selected_option_hovered' : '']" :style="{border: opened ? (selectInfo.borderColor? `1px solid ${selectInfo.borderColor}` : ''):'', fontSize:`${selectInfo.fontSize}px`}">
      <span>{{selected.text}}</span>
      <button>
        <img v-if="!opened" src="@/assets/images/mn_down.png" alt="chevronDown">
        <img v-else src="@/assets/images/mn_up.png" alt="chevronUp">
      </button>
    </div>
    <ul class='options' v-if="opened" @click="selectOption" :style="{border:selectInfo.borderColor? `1px solid ${selectInfo.borderColor}` : '1px solid #2E2E33', fontSize:`${selectInfo.fontSize}px`, backgroundColor:selectInfo.backgroundColor,top: (!direction || direction == 'down') && '110%', bottom: direction == 'up' && '110%'}">
      <li v-show="idx != 0" :class="['option',selectInfo.hoveredColor?'hovered_option':'']" :data-option="item.value" v-for="(item,idx) in list" :key="'selectList-'+idx+'-'+item.value">{{item.text}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectComponent',
  props: ['selectInfo','list','val','direction'],
  data: function() {
    return{
      opened: false,
      selected: {value:'', text:''}
    }
  },
  methods: {
    controlStates(){
      this.opened = !this.opened;
    },
    selectOption(ev){
      if(ev.target.dataset.option) {
        this.selected = this.list.find(item => item.value == ev.target.dataset.option);
        this.$emit('selectOption',this.selected.value);
        this.opened = false;
      }
    },
    documentClick(ev){
      let select = this.$refs.select;
      if(!(select && select.contains(ev.target))) this.opened = false;
    }
  },
  watch: {
    val() {
      this.selected = this.list.find(item => item.value == this.val);
    }
  },
  created() {
    document.addEventListener('click',this.documentClick);
  },
  destroyed(){
    document.removeEventListener('click',this.documentClick);
  },
  mounted(){
    if(this.val) this.selected = this.list.find(item => item.value == this.val);
    else this.selected = {...this.list[0]};
  }
}
</script>

<style scoped>
  ul{
    padding: 0;
    margin: 0;
  }
  li{
    list-style: none;
  }
  .wrapper{
    width: 100px;
  }
  .select{
    height: 100%;
    position: relative;
    color: #DFDEE0;
    font-size: 12px;
  }
  .selected_option{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 9px;
    padding-right: 10px;
    width: 100%;
    height: 100%;
    line-height: 36px;
    background-color: transparent;
    border: 1px solid #2E2E33;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .border_focus{
    border: 1px solid #9560F5;
  }
  .selected button{
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  .options{
    width: 100%;
    position: absolute;
    left: 0;
    padding: 12px 0;
    border-radius: 4px;
    box-sizing: border-box;
    z-index: 5;
  }
  .option{
    padding-left: 12px ;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    box-sizing: border-box;
  }
  .hovered_option:hover{
    background-color:  rgba(126, 81, 182, 0.8);
  }
  .selected_option_hovered:hover{
    border-color: #9560F5;
  }
  .option:not(.hovered_option):hover{
    background-color: #1A1A1B;
  }
  .hide{
    display: none;
  }
</style>

