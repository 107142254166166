<template>
  <div class="calendar_modal" :style="{top:`${top}px`,left:`${left}px`}" id="calendarModal" ref="calendar">
    <div class="date_controller">
      <span class="month_change_btn" @click="movePrevMonth"><img src="@/assets/images/mn_cnt_prev.png" alt="prev_btn"></span>
      <div class="select_area">
        <div class="select_date">
          <select class="month" v-model="nowMonth">
            <option :value="parseInt(month.ko.replace('월',''))" v-for="(month,idx) in months" :key="'month-'+idx+'-'+month.ko">{{month[$store.state.lang]}}</option>
          </select>
          <input class="year" type="number" v-model="nowYear">
        </div>
      </div>
      <span class="month_change_btn" @click="moveNextMonth"><img src="@/assets/images/mn_cnt_next.png" alt="prev_btn"></span>
    </div>
    <div class="date_container">
      <table class="inner_container" @mouseleave="mouseLeaveDate">
        <thead>
          <tr>
            <th v-for="(dayObj,idx) in days" :key="'days-'+idx+'-'+dayObj.ko">{{dayObj[$store.state.lang]}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row,idx) in dates" :key="'dates-'+'row-'+idx">
            <td 
              v-for="(dateItem,i) in row" :key="'dates-'+'row-'+idx+'-col-'+i"
              :id="dateItem.dateStr"
              :class="[
                today == dateItem.dateStr && 'today',
                !pickedDate.start&&'day',
                pickedDate.start&&(dateItem.time<new Date(pickedDate.start).getTime())&&'font_gray',
                dateItem.month != nowMonth&&'font_gray',
                dateItem.time > new Date(today).getTime() && 'font_gray day_disabled',
                (!pickedDate.start)&&from!=to&&new Date(from.replace(/-/g, '/')).getTime()==dateItem.time&&'start',
                (!pickedDate.start)&&from!=to&&(new Date(to.replace(/-/g, '/')).getTime()==dateItem.time)&&'end',
                (!pickedDate.start)&&isBetween(dateItem)&&'between',
                (!pickedDate.start)&&from==to&&new Date(from.replace(/-/g, '/')).getTime()==dateItem.time&&'bg_purple',  
                (!from && to) && ( new Date(dateItem.dateStr.replaceAll('-','/')).getTime() < new Date(to.replaceAll('-','/')).getTime() ) && 'between',
                dateItem.start &&  'start', 
                dateItem.end && 'end', 
                dateItem.between && 'between'
              ]"
              @click="pickDate(dateItem)"
              @mousemove="moveDate(dateItem)">
              {{dateItem.date}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name : "CalendarModal",
  props :{
    from : String,
    to : String,
    top : String,
    left : String,
    type: String
  },
  data(){
    return{
      days : [
        {ko: '일', en: 'S'},
        {ko: '월', en: 'M'},
        {ko: '화', en: 'T'},
        {ko: '수', en: 'W'},
        {ko: '목', en: 'T'},
        {ko: '금', en: 'F'},
        {ko: '토', en: 'S'},
      ],
      months: [
        {ko: '1월', en:'JAN'},
        {ko: '2월', en:'Feb'},
        {ko: '3월', en:'Mar'},
        {ko: '4월', en:'Apr'},
        {ko: '5월', en:'May'},
        {ko: '6월', en:'Jun'},
        {ko: '7월', en:'Jul'},
        {ko: '8월', en:'Aug'},
        {ko: '9월', en:'Sep'},
        {ko: '10월', en:'Oct'},
        {ko: '11월', en:'Nov'},
        {ko: '12월', en:'Dec'},
      ],
      nowYear : new Date().getFullYear(),
      nowMonth : new Date().getMonth()+1,
      nowDay : new Date().getDay(),
      dates : [],
      pickedDate : {
        start : "",
        end : ""
      },
      today : new Date().toISOString().slice(0,10)
    }
  },
  methods : {
    makeDatesArr(){
      let firstDay = new Date(`${this.nowYear}-${this.__makeNumberToString(this.nowMonth)}-01`.replace(/-/g, '/')).getDay();
      this.dates = [];
      // 해당 월의 1일의 요일을 구했을 때 0인 경우는 1일 부터 시작하고, 아닌 경우에는 1에서 firstDay를 빼주어 해당 월의 1일이 되기 이전의 날짜를 구할수 있도록 한다.
      let day = firstDay == 0 ? 1 : 1 - firstDay;
      let month;
      let year;
      // 달력이 총 6행 이므로, 0 ~ 5 반복되는 반복문을 실행하여 달력의 행을 완성한다.
      for(let row=0; row<6; row++){
        let datesRow = [];
        // 월 ~ 일 까지 총 7일 이므로 0 ~ 6 반복되는 반복문을 실행하여 달력의 열을 완성한다.
        for(let col=0; col<7; col++){
          let dateObj = {};
          // 해당 월의 1일이 0이 아닌 경우에는 이전 달의 month, year를 구한다.
          if(day <= 0){
            month = new Date(this.nowYear,this.nowMonth-1).getMonth();
            year = new Date(this.nowYear,this.nowMonth-1).getFullYear();
          }
          // day가 해당 월의 마지막 date보다 크거나, 이미 해당 월의 일이 끝나 다음 달의 일을 가리키는 경우에 대한 처리
          else if(day >= new Date(this.nowYear,this.nowMonth,0).getDate()+1 || row >=3 && day<=14){
            // day가 해당 월의 마지막 date보다 큰 경우에 day에 1을 할당하여 다음달 1일부터 시작하도록 한다.
            if(day >= new Date(this.nowYear,this.nowMonth,0).getDate()+1) day = 1;
            month = new Date(this.nowYear,this.nowMonth).getMonth();
            year = new Date(this.nowYear,this.nowMonth).getFullYear();
          }
          // 위의 조건들에 해당하지 않는 경우에 대한 처리
          else {
            month = new Date(this.nowYear,this.nowMonth-1).getMonth();
            year = new Date(this.nowYear,this.nowMonth-1).getFullYear();
          }
          dateObj.dateStr = `${new Date(year,month,day).getFullYear()}-${this.__makeNumberToString(new Date(year,month,day).getMonth()+1)}-${this.__makeNumberToString(new Date(year,month,day).getDate())}`
          dateObj.time = new Date(dateObj.dateStr.replace(/-/g, '/')).getTime();
          dateObj.year = new Date(year,month,day).getFullYear();
          dateObj.month = new Date(year,month,day).getMonth()+1;
          dateObj.date = new Date(year,month,day).getDate();
          dateObj.start = false;
          dateObj.end = false;
          dateObj.between = false;
          datesRow.push(dateObj);
          day+=1;
        }
        this.dates.push(datesRow);
      }
    },
    movePrevMonth(){
      const newDate = new Date(this.nowYear,this.nowMonth-2);
      this.nowMonth = newDate.getMonth()+1;
      this.nowYear = newDate.getFullYear();
      this.makeDatesArr();
    },
    moveNextMonth(){
      const newDate = new Date(this.nowYear,this.nowMonth);
      this.nowMonth = newDate.getMonth()+1;
      this.nowYear = newDate.getFullYear();
      this.makeDatesArr();
    },
    __makeNumberToString(number){
      // 문자열로 변환한 number의 길이가 1인 경우(= number가 일의자리 숫자인 경우)
      if(number.toString().length == 1) return `0${number}`;
      // 문자열로 변환한 number의 길이가 1이 아닌 경우(= number가 일의자리 숫자가 아닌 경우)
      else return number.toString();
    },
    pickDate(dateItem){
      // pickedDate.start의 길이가 0인 경우(= 시작날짜가 정해지지 않아, pickedDate.start의 값이 빈 문자열인 경우)에 대한 처리
      if(this.pickedDate.start.length == 0){
        if(dateItem.time > new Date(this.today.replace(/-/g, '/')).getTime()) return;
        dateItem.start = true;
        this.pickedDate.start = dateItem.dateStr;
        this.$emit('pick_date',[this.pickedDate.start,this.pickedDate.end]);
      }
      // pickedDate.start의 길이가 0이 아닌 경우(= 시작날짜가 정해져, pickedDate.start의 값이 빈 문자열이 아닌경우)에 대한 처리
      else{
        if(dateItem.time < new Date(this.pickedDate.start.replace(/-/g, '/')).getTime()) return;
        if(dateItem.time > new Date(this.today.replace(/-/g, '/')).getTime()) return;
        dateItem.end = true;
        this.pickedDate.end = dateItem.dateStr;
        this.$emit('pick_date',[this.pickedDate.start,this.pickedDate.end]);
        this.$emit('complete');
      }
    },
    moveDate(dateItem){
      // pickedDate.start의 길이가 0인 경우(= 시작날짜가 정해지지 않아, pickedDate.start의 값이 빈 문자열인 경우)에 대한 처리
      if(this.pickedDate.start.length == 0) return;
      const start = new Date(this.pickedDate.start.replace(/-/g, '/')).getTime();
      const end = dateItem.time;
      // end 날짜 보다 start 날짜가 더 큰 경우(= end가 start 이전 날짜인 경우)와 마지막 날짜가 오늘 이후의 날짜인 경우에 대한 처리
      if(end < start || end > new Date(this.today.replace(/-/g, '/')).getTime()) {
        this.dates.forEach(row=>{
          row.forEach(date =>{
            if(date.time != start){
              date.between = false;
              date.end = false;
            }
          });
        });
        return;
      }
      const betweenArr = [];
      const elseArr = [];
      // dates 의 길이만큼 반복문을 실행하여 달력의 행을 순회한다.
      for(let row=0; row<this.dates.length; row++){
        // dates[row]의 길이만큼 반복문을 실행하여 달력의 열을 순회하며 시작일, 끝일, 중간일에 따라 구별한다.
        for(let col=0; col<this.dates[row].length; col++){
          // 날짜 객체가 중간일에 해당하는 경우에 대한 처리
          if(this.dates[row][col].time > start &&this.dates[row][col].time < end){
            betweenArr.push(this.dates[row][col])
          }
          // 날짜 객체가 시작일, 끝일, 중간일 중 어느 것에도 해당하지 않는 경우에 대한 처리
          else if(this.dates[row][col].time != start && this.dates[row][col].time != end){
            elseArr.push(this.dates[row][col])
          }
          // 날짜 객체가 시작일에 해당하는 경우에 대한 처리
          else if(this.dates[row][col].time == start){
            this.dates[row][col].start = true;
            this.dates[row][col].end = false;
            this.dates[row][col].between = false;
          }
          // 날짜 객체가 끝일에 해당하는 경우에 대한 처리
          else if(this.dates[row][col].time == end){
            this.dates[row][col].end = true;
            this.dates[row][col].start = false;
            this.dates[row][col].between = false;
          }
        }
      }
      // 중간일에 해당하는 날짜 객체들이 포함되는 betweenArr의 길이만큼 반복문을 실행하여 날짜 객체의 정보를 수정한다.
      betweenArr.forEach((dateObj)=>{
        dateObj.end = false;
        dateObj.start = false;
        dateObj.between = true;
      });

      // 시작일, 끝일, 중간일 중 어느 것에도 해당하지 않는 날짜 객체들이 포함되는 elseArr의 길이만큼 반복문을 실행하여 날짜 객체의 정보를 수정한다.
      elseArr.forEach((dateObj)=>{
        dateObj.end = false;
        dateObj.start = false;
        dateObj.between = false;
      });
    },
    mouseLeaveDate(){
      // 시작날짜는 설정 되었지만, 끝 날짜가 결정되지 않은 경우에 대한 처리
      if(this.pickedDate.start&&!this.pickedDate.end){
        // dates의 길이만큼 반복문을 실행하여 달력의 행을 순회한다.
        this.dates.forEach(row=>{
          // 행의 길이만큼 반복문을 실행하여 날짜 객체의 정보를 수정한다.
          row.forEach(date =>{
            // 날짜 객체의 time이 시작날짜의 time과 다른 경우(= 시작일을 제외한 모든 날을 의미)에 대한 처리
            if(date.time != new Date(this.pickedDate.start).getTime()){
              date.between = false;
              date.end = false;
            }
          });
        });
      }
    },
    isBetween(dateItem){
      // from, to의 값이 falsy 인 경우에는 시작, 끝 일 사이의 날이 없으므로 함수를 종료한다.
      if(!this.from && !this.to) return;
      // dateItem의 time이 from 과 to 사이에 위치하여 중간일에 해당하는 경우 true를 반환한다.
      if(dateItem.time > new Date(this.from.replace(/-/g, '/')).getTime() && dateItem.time < new Date(this.to.replace(/-/g, '/')).getTime()){
        return true;
      }
      return false;
    },
    documentClick(ev){
      // 달력컴포넌트의 상태가 하나라도 true인 경우에 대한 처리
      if(this.$store.state.listCalendar || this.$store.state.modalCalendar){
        // click 이벤트가 발생한 target이 달력에 포함되지 않는 외부 영역인 경우, 이벤트를 방출하여 달력을 닫도록 한다.
        if(!this.$refs.calendar.contains(ev.target)) {
          this.$emit('closeCalendar',ev.target);
        }
      }
    }
  },
  watch : {
    nowYear(){
      this.makeDatesArr();
    },
    nowMonth(){
      this.makeDatesArr();
    },
  },
  mounted(){
    // from의 값이 truthy인 경우 from 날에 해당하는 연, 월, 일로 데이터를 할당하여 달력을 만들도록 한다.
    if(this.from) {
      this.nowYear = new Date(this.to.replace(/-/g, '/')).getFullYear();
      this.nowMonth = new Date(this.to.replace(/-/g, '/')).getMonth()+1;
      this.nowDay = new Date(this.to.replace(/-/g, '/')).getDay();
    }
    this.makeDatesArr();
  },
  created(){
    document.addEventListener('click',this.documentClick);
  },
  destroyed(){
    // 컴포넌트가 없어질 때 시작일은 설정되었지만, 종료일이 결정되지 않은 경우에는 'pickOnlyStartDate'이벤트를 방출하여 종료일을 시작날짜와 같이 설정하도록 한다.
    if(this.pickedDate.start && !this.pickedDate.end) this.$emit('pickOnlyStartDate');
    document.removeEventListener('click',this.documentClick);
  }
}
</script>

<style scoped>
  .calendar_modal{
    position: absolute;
    right: auto;
    width: 352px;
    height: 426px;
    color: #fff;
    background-color: #1A1A1B;
    border: 1px solid #7E51B6;
    border-radius: 5px;
    z-index: 4;
  }
  .date_controller{
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select_date{
    display: flex;
    gap: 10px;
  }
  .month_change_btn{
    display: flex;
    align-items: center;
    height: 34px;
    padding: 10px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
  }
  .month_change_btn:first-child{
    padding-left: 30px;
  }
  .month_change_btn:last-child{
    padding-right: 30px;
  }
  .year{
    width: 60px;
  }
  .year:hover{
    background: rgba(192,187,167,0.05);
  }
  .month{
    outline: none;
    background: none;
    border: none;
    cursor: pointer;
  }
  .month option{
    background-color: #3f4458;
  }
  .year,.month{
    color: #fff;
    font-size: 19px;
  }
  .date_container{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 33px 0 33px;
  }
  .inner_container{
    width: 100%;
  }
  table{
    border-spacing: 0;
  }
  thead{
    height: 28px;
  }
  tbody tr{
    height: 39px;
  }
  td{
    width: 39px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    user-select: none;
  }
  .font_gray{
    color: rgba(255,255,255,0.3);
  }
  .day_disabled{
    cursor: default;
  }
  .day{
    border: 1px solid transparent;
    border-radius: 50%;
  }
  .day:hover{
    background: #262529;
    transform: translateY(-1px);
    cursor: pointer;
  }
  .day_disabled:hover{
    cursor: default;
    background: transparent;
    transform: translateY(0);
  }
  .today{
    border: 1px solid #eee;
    border-radius: 50%;
  }
  .start{
    border-radius: 50px 0 0 50px;
    background: #9560F5 !important;
    color: white;
    cursor: pointer;
  }
  .between{
    background: #262529;
    border: none;
    border-radius: 0;
  }
  .end{
    border-radius: 0 50px 50px 0;
    background: #9560F5 !important;
    color: white;
    cursor: pointer;
  }
  .bg_purple{
    background: #9560F5;
    border-radius: 50%;
    color: white;
  }
</style>