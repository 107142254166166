<template>
  <div class="change_privateInfo_view">
    <div class="menus_bg">
      <div class="logo">
        <img src="@/assets/images/logo_without_rd.png" alt="logo" width="170">
      </div>
      <div class="menus">
        <div class="list_my_info">
          <h2>{{lang[$store.state.lang].EDIT_INFO_LIST_TITLE_MY_INFO}}<!--내 정보--></h2>
          <ul>
            <li @click="setTab(list)" :class="[tab == list.value ? 'selected' : '']" v-for="(list,idx) in myInfoList" :key="'myInfoList-'+idx+'-'+list.text">{{list.text}}</li>
          </ul>
        </div>

        <div class="list_sub_info" style="display:none">
          <h2>{{lang[$store.state.lang].EDIT_INFO_LIST_TITLE_MEMBERSHIP}}<!--멤버십 정보--></h2>
          <ul>
            <li @click="setTab(list)" :class="[tab == list.value ? 'selected' : '']" v-for="(list,idx) in membershipList" :key="'membershipList-'+idx+'-'+list.text">{{list.text}}</li>
          </ul>
        </div>
      </div>
    </div>
    <main>
      <div v-if="tabType == 'myInfo'" class="myInfo_view">
        <ChangePrivateInfoModal :tab = tab @goToMain="$emit('goToMain')"/>
      </div>
      <div v-if="tabType == 'membership'" style="display:none">
        <Membership :tab = tab  @goToMain="$emit('goToMain')" />
      </div>
    </main>
  </div>
</template>

<script>
import lang from '@/lang'
import ChangePrivateInfoModal from '@/components/ChangePrivateInfoModal.vue'
import Membership from './Membership.vue'

export default {
  name: 'ChangePrivateInfoView',
  props: ['type','firstMenu'],
  components: {
    ChangePrivateInfoModal,
    Membership
  },
  data: function() {
    return{
      lang,
      tabType: '', // myInfo || membership
      tab : '', // 메뉴 list의 요소 (객체)의 value
      myInfoList: [
        {value: 'doctor', tabType: 'myInfo', text: lang[this.$store.state.lang].EDIT_INFO_TAB_TXT_DOCTOR}, // 의사 정보
        {value: 'hospital', tabType: 'myInfo', text: lang[this.$store.state.lang].EDIT_INFO_TAB_TXT_HOSPITAL}, // 병원 정보
        {value: 'passwd', tabType: 'myInfo', text: lang[this.$store.state.lang].EDIT_INFO_TAB_TXT_PASSWORD}, // 비밀번호 변경
      ],
      membershipList: [
        {value: 'membership', tabType: 'membership', text: lang[this.$store.state.lang].EDIT_INFO_TAB_TXT_MEMBERSHIP}, // 멤버십 관리
        {value: 'payment', tabType: 'membership', text: lang[this.$store.state.lang].EDIT_INFO_TAB_TXT_PAYMENT_INFO}, // 결제정보 관리
        {value: 'paymentDetail', tabType: 'membership', text: lang[this.$store.state.lang].EDIT_INFO_TAB_TXT_PAYMENT_DETAIL}, // 결제 상세
      ],
    }
  },
  methods: {
    setTab(tabObj) {
      this.tab = tabObj.value;
      this.tabType = tabObj.tabType;
    }
  },
  watch: {
    // membership 이 변경되면 tab, tabType을 변경한다. 아직 멤버십 기능이 활성화되지 않았으므로 주석처리함
    // membership(newVal){
    //   if(newVal[0]) {
    //     this.tab = this.membershipList[0].value;
    //     this.tabType = 'membership';
    //     return;
    //   }else {
    //     this.tab = this.myInfoList[0].value;
    //     this.tabType = 'myInfo';
    //   }
    // },
  },
  computed: {
    membership(){
      return this.$store.state.membership;
    }
  },
  mounted() {
    this.$store.state.myInfoModal = false;
    if(this.$store.state.modalViewModal) this.$store.state.modalViewModal = false;
    this.tabType = this.type; 
    if(this.tabType == 'myInfo') {
      this.title = this.myInfoList[0].text;
      this.tab = this.myInfoList[0].value;
    }
    else if(this.tabType == 'membership') {
      this.title = this.membershipList[0].text;
      this.tab = this.membershipList[0].value;
    }
  },
}
</script>

<style scoped>
  .change_privateInfo_view{
    height: 100vh;
    display: grid;
    grid-template-columns: 223px 1fr;
    background-color: #1A1A1B;
    color: white;
    font-weight: 700;
    font-size: 16px;
    overflow-x: auto;
    z-index: 1;
  }
  .menus_bg{
    height: 100vh;
    background-color: #2E2E33;
  }
  .menus{
    padding-left: 24px;
    box-sizing: border-box;
  }
  .menus>div{
    margin-bottom: 49px;
  }
  .menus h2{
    margin-bottom: 26px;
    font-size: 20px;
  }
  .logo{
    padding: 24px 0;
    text-align: center;
    margin-bottom: 21px;
  }
  .menus ul{
    margin-left: 14px;
  }
  .menus ul>li{
    padding-left: 12px;
    height: 32px;
    margin-bottom: 16px;
    border-left: 2px solid transparent;
    line-height: 32px;
    cursor: pointer;
  }
  .menus ul>.selected,
  .menus ul>li:hover {
    border-left-color: #5B5B5E;
    background: #212125;
  }
  .myInfo_view{
    height: 100%;
    box-sizing: border-box;
  }
</style>