<template>
  <section id="main">
    <ExamInfoForm v-if="$store.state.fileInfoModal"/>
    <!--임시 비밀번호 발급 모달 / 무료체험중 모달-->
    <div class="find_passwd_modal"  @click="hideTempPasswdModal" v-if="$store.state.membership[0] || $store.state.tempPasswdModal">
      <div class="content_box" ref="tempPasswdModalBox">
        <div class="test_box" v-if="!$store.state.membership[0]">
          <div class="title">{{lang[$store.state.lang].EDIT_INFO_PASSWORD_FORGET_MODAL_TXT_TITLE}}<!--임시 비밀번호 발급--></div>
          <div class="txt" v-html="lang[$store.state.lang].EDIT_INFO_PASSWORD_FORGET_MODAL_TXT_DESCRIPTION"></div><!--아이디용 Email로\n 임시 비밀번호를 발급해 드렸습니다.-->
        </div>

        <div class="test_box" v-if="$store.state.membership[0]">
          <div class="title">{{lang[$store.state.lang].EDIT_INFO_TXT_FREE_TRIAL_MODAL_TITLE}}<!--무료 체험중--></div>
          <div class="txt">
            <h3 class="sub_title">{{lang[$store.state.lang].EDIT_INFO_TXT_FREE_TRIAL_MODAL_SUB_TITLE}}<!--PANVI AI 무료 체험판을 사용하고 계십니다.--></h3>
            <p v-html="lang[$store.state.lang].EDIT_INFO_TXT_FREE_TRIAL_MODAL_INFO"></p><!--서비스에 대한 건의사항이나 의견을 주시면 서비스 개선 에 큰 도움이 됩니다. dhkim@invisionlab.kr-->
          </div>
        </div> 
        
        <button @click="modalConfirm">{{lang[$store.state.lang].EDIT_INFO_PASSWORD_FORGET_MODAL_BUTTON_CONFIRM}}<!--확인--></button>
      </div>
    </div>
    <ReadingReport v-if="$store.state.readingReport" :selectedExam="selectedExam" @update="updateReport"/>
    <CrossSectionModal v-if="$store.state.crossSectionModal" :imgUrl="baseUrl+selectedExam.url"/>
    <!-- 내정보모달 -->
    <div id="modalBg" class="modal_bg_black" v-if="$store.state.myInfoModal">
      <MyInfoModal v-if="$store.state.myInfoModal" @close_my_info="$store.state.myInfoModal=false" @show_logoutModal="$store.state.logoutModal = true"/>
    </div>
    <Sidebar ref="sidebar" :selectedExam="selectedExam" @setStartView="setStartView"  @updateReport="updateReport" ></Sidebar>
    <ChangePrivateInfoView @goToMain="setStartView" :type="$store.state.membership[0] ? 'membership' : 'myInfo'" v-if="$store.state.menu == 'myInfo' && $store.state.changePrivateInfoView"/>
    <ModalView v-if="$store.state.modalViewModal" @goToMain="setStartView" @close_modal="$store.state.modalViewModal = false" type="print_all"/>
    
    <article id="page">
      <List ref="list" :scrollIsAlerted="scrollIsAlerted" :exams="exams" :selectedExam="selectedExam" :examsLoaded="examsLoaded" :limit="limit" @set_exam="setExam" @search="search" @updatePage="updatePage" @refreshExams="refreshExams" @setExamByKey="setExamByKey"></List>
      <Viewer :exam="selectedExam" :shouldOpenChat="shouldOpenChat" @updateNewChat="updateNewChat" @clickChatToast="setRecvChatExam"></Viewer>
    </article>
  </section>
</template>


<script>
import Sidebar from '@/components/Sidebar'
import List from '@/components/List'
import Viewer from '@/components/Viewer'
import wsMixin from '@/wsMixin'
import lang from '@/lang'
import CrossSectionModal from '@/components/CrossSectionModal.vue'
import MyInfoModal from '@/components/MyInfoModal.vue'
import alert from '@/alert';
import ChangePrivateInfoView from '@/components/ChangePrivateInfoView.vue'
import ModalView from '@/components/ModalView.vue'
import ReadingReport from '@/components/ReadingReport.vue'
import ExamInfoForm from '@/components/ExamInfoForm.vue'


export default {
  name: "MainPage",
  mixins: [wsMixin],
  components: {
    Sidebar,
    List,
    Viewer,
    CrossSectionModal,
    MyInfoModal,
    ChangePrivateInfoView,
    ModalView,
    ReadingReport,
    ExamInfoForm
  },
  data: function() {
    return {
      lang,
      exams : [],
      selectedExam : {},
      baseUrl : (process.env.VUE_APP_DEBUG?process.env.VUE_APP_STATIC_DEV_URL:process.env.VUE_APP_STATIC_URL),
      examsLoaded : false,
      limit: 15,
      index: 0,
      filterBody:{},
      scrollIsAlerted: false,
      shouldOpenChat: false,
    }
  },
  methods: {
    alert,
    onRecv: function(data) {
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      // 11번 액션(= 로그인)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 11){
        /*
        ** 응답이 성공일 때의 처리 
        ** store에 병원정보, 의사정보 등을 저장하여 다른 컴포넌트들에서도 접근이 가능하도록 한다.
        ** 157번 action을 통해서 영상목록을 요청한다.
        */
        if( data.body[0].content.success){
          if(process.env.VUE_APP_DEBUG) console.log('login...',data)
          this.$store.state.socket.isLoggedIn = true;
          // 로그인된 회원의 정보 중에서 signature 의 값이 있는 경우에 store.state.signature에 서명정보를 저장하여 다른 컴포넌트에서도 접근 가능하도록 한다.
          if(data.body[0].content.signature) this.$store.state.signature = data.body[0].content.signature;
          if(data.body[0].content.hospital.name) this.$store.state.hospitalInfo.name = data.body[0].content.hospital.name;
          if(data.body[0].content.hospital.addr1) this.$store.state.hospitalInfo.addr1 = data.body[0].content.hospital.addr1;
          if(data.body[0].content.hospital.addr2) this.$store.state.hospitalInfo.addr2 = data.body[0].content.hospital.addr2;
          if(data.body[0].content.hospital.ceo) this.$store.state.hospitalInfo.ceo = data.body[0].content.hospital.ceo;
          if(data.body[0].content.hospital.company_no) this.$store.state.hospitalInfo.company_no = data.body[0].content.hospital.company_no;
          if(data.body[0].content.hospital.phone) this.$store.state.hospitalInfo.contact = data.body[0].content.hospital.phone;
          if(data.body[0].content.hospital.taxinfo) this.$store.state.hospitalInfo.taxInfo = data.body[0].content.hospital.taxinfo;
          if(data.body[0].content.doctor.license_no) this.$store.state.doctorInfo.license = data.body[0].content.doctor.license_no;
          if(data.body[0].content.doctor.name) this.$store.state.doctorInfo.name = data.body[0].content.doctor.name;
          if(data.body[0].content.doctor.phone) this.$store.state.doctorInfo.phone = data.body[0].content.doctor.phone;
          if(data.body[0].content.doctor.email) this.$store.state.doctorInfo.email = data.body[0].content.doctor.email;
          let exams = this.$$build(157,[{
            contentType: 1,
            content: {
              rangeFrom: this.index,
              rangeTo: this.index + this.limit
            }
          }]);
          this.$socket.send(exams);
        }
      }
      /*
      ** 157번 action(= 영상목록 조회)에 대한 응답이 수신되었을 때의 처리
      ** id = 0 Main 마운트시, 브라우저 새로고침시, List에서 새로고침 버튼을 클릭하여 refreshExams()가 호출되었을 때
      ** id = 4 List 영상목록에서 scroll 이벤트가 발생하여 updatePage()가 호출되었을 때
      ** id = 5 Viewer에서 해당 환자 영상의 이전, 다음 영상을 요청했을 때
      ** id = 6 List 영상목록을 조건에 따라 조회하여 search()가 호출되었을 때
      ** id = 7 157번 action id = 0 일 때에 205번 action을 보내어 새로운 채팅목록이 있는 영상번호를 수신하여 해당 영상들을 요청했을 때 
      ** id = 8 157번 action id = 6 일 때에 205번 action을 보내어 새로운 채팅목록이 있는 영상번호를 수신하여 해당 영상들을 요청했을 때 
      ** id = 9 103번 action을 수신하였는데, 해당 영상이 현재 영상목록에 포함되어 있지 않은 경우
      ** id = 30 채팅 수신 toast popup을 클릭했을 때, 해당 영상을 요청한 경우 
      */
      if(data.action == 157){
        // id = 0, id = 6 인 경우는 영상목록을 요청하여 this.exams를 대체하는 경우로, 공통된 동작을 하는 부분이 있어 if로 묶어 분기처리를 했다. 
        if(data.id == 0 || data.id == 6){
          // 응답이 성공인 경우에 대한 처리
          if(data.body[0].content.success){
            let exams = [...data.body[1].content];
            this.exams = exams;
            
            if(this.exams.length == this.limit) this.index = this.index + this.limit; 
            
            this.scrollIsAlerted = false;
            let packet;
            /*
            ** id = 0 인경우에 대한 처리
            ** id = 0 인 경우에는 특정 조건이 없지만, id = 6인 경우에는 search()를 호출하여 특정 조건에 맞는 영상을 조회한 것 이므로 
            ** 새로운 채팅목록이 있는 영상에 대해서 처리하는 것이 다르기 때문에 if문으로 분기처리를 하였다.
            */
            if(data.id == 0){
              packet = this.$$build(205,[]);
            }
            // id = 6 인경우에 대한 처리
            if(data.id == 6){
              packet = this.$$build(205,1,[]);
            }
            this.$socket.send(packet);
            this.examsLoaded = true;
            if( process.env.VUE_APP_DEBUG ) {
              console.log('exams .... ',this.exams);
            }
          }
          // 응답이 실패인 경우에 대한 처리 
          else this.alert('error',this.lang[this.$store.state.lang].LIST_ALERT_TXT_FAIL_RECV_EXAMS); // '영상목록을 조회하는 것에 실패하였습니다.\n다시 시도해주세요.'
        }
        // List 영상목록에서 scroll 이벤트가 발생하여 updatePage()가 호출되었을 때
        if(data.id == 4) {
          // 응답이 성공인 경우에 대한 처리
          if(data.body[0].content.success){
            if(process.env.VUE_APP_DEBUG) console.log('scroll',this.index,this.index+this.limit,data.body[1].content);
            let exams = [...data.body[1].content];
            if(exams.length > 0) {
              this.scrollIsAlerted = false;
              this.exams.forEach((exam) => {
                exams.forEach((comparisonExam,idx) => {
                  if(exam.seq == comparisonExam.seq) exams.splice(idx,1);
                })
              });
              this.exams = [...this.exams, ...exams];
              // 수신된 영상목록의 길이가 limit의 길이와 같은 경우, index에 limit 만큼 더해주어 다음 영상목록 요청의 시작점을 변경한다.
              if(data.body[1].content.length == this.limit) this.index = this.index + this.limit;
              // 수신된 영상목록의 길이가 limit의 길이보다 작은 경우 더 이상의 영상이 없는 것을 의미하므로, 이를 의미하는 붉은 선을 표시하기 위해 scrollIsAlerted를 true 로 변경한다.
              if(data.body[1].content.length < this.limit) {
                if(!this.scrollIsAlerted) {
                  this.scrollIsAlerted = true;
                }
              }
            }
            // 수신된 영상목록의 길이가 0인 경우에 대한 처리
            else if(exams.length == 0) {
              // list scroll 시, 수신된 영상목록의 길이가 0 인 경우, 더 이상의 영상이 없는 것을 의미하므로, 이를 의미하는 붉은 선을 표시하기 위해 scrollIsAlerted를 true 로 변경한다.
              if(!this.scrollIsAlerted) {
                this.scrollIsAlerted = true;
              }
            }
            this.examsLoaded = true;
          }
          // 응답이 실패인 경우에 대한 처리
          else this.alert('error',this.lang[this.$store.state.lang].LIST_ALERT_TXT_FAIL_RECV_EXAMS); // '영상목록을 조회하는 것에 실패하였습니다.\n다시 시도해주세요.'
        }
        // id = 5 && id = 30 은 모두 selectedExam을 변경하는 것 이므로, 함께 작성한다.
        if(data.id == 5 || data.id == 30){
          // 응답이 성공인 경우에 대한 처리
          if(data.body[0].content.success){
            this.selectedExam = [...data.body[1].content][0];
            
            if(data.id == 30) this.shouldOpenChat = true;
          }
          // 응답이 실패인 경우에 대한 처리
          else this.alert('error',this.lang[this.$store.state.lang].LIST_ALERT_TXT_FAIL_RECV_EXAM); // '영상을 조회하는 것에 실패하였습니다.\n다시 시도해주세요.'
        }
        if(data.id == 7){
          /*
          ** 응답이 성공인 경우에 대한 처리
          ** 현재 영상목록에서 새로운 채팅이 있는 영상객체를 제거하여 응답으로 수신된 영상목록과 중복되지 않도록 하고, 기존 영상목록과 응답으로 수신된 영상목록을 합친다.
          */
          if(data.body[0].content.success){
            this.exams = this.exams.filter(exam => !exam._new_chat);
            const newChatExams = [...data.body[1].content];
            this.exams = [...newChatExams, ...this.exams];
          }
          // 응답이 실패인 경우에 대한 처리
          else this.alert('error',this.lang[this.$store.state.lang].LIST_ALERT_TXT_FAIL_RECV_NEW_CHAT_EXAMS); // '새로운 채팅목록을 영상목록 상위에 노출하는 것에 실패하였습니다.'
        }
        // id = 8, id = 9 인 경우는 현재 영상 조회 조건에 따라 수신된 영상목록을 추가해야 하므로, if문으로 묶어 작성하였다.
        if(data.id == 8 || data.id == 9){
          // 응답이 성공인 경우에 대한 처리
          if(data.body[0].content.success){
            // id = 8 인 경우는, 현재 영상목록 중에 응답으로 수신된 영상목록과 중복되는 영상이 있을 수 있으므로, 현재 영상목록에서 새로운 채팅목록이 있는 영상들을 제거한다.
            if(data.id == 8){
              this.exams = this.exams.filter(exam => !exam._new_chat);
            }
            let addedExams = [];
            let newChatExams = [...data.body[1].content];
            // 특정 영상 조회 조건이 없는 경우에 addedExams 에 수신된 영상목록을 spread operator로 대입하여 이후에 기존 영상목록에 추가되도록 한다.
            if(!this.filterBody.content.filter) addedExams = [...newChatExams];
            // 특정 영상 조회 조건이 있는 경우에 조건에 맞는 영상객체들이 추가되도록 한다.
            else {
              // 수신된 영상목록의 index 만큼 반목문을 실행하여 각 영상객체들이 현재 영상조회 조건에 부합하는지 확인한다.
              for(let index=0; index<newChatExams.length; index++){
                let isAdded = false;
                // 현재 영상조회 조건에 dateFrom이 있는 경우에 대한 처리
                if(this.filterBody.content.filter.dateFrom){
                  let date = `${new Date(newChatExams[index].taken).getFullYear()}-${(new Date(newChatExams[index].taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(newChatExams[index].taken).getMonth()+1).toString():(new Date(newChatExams[index].taken).getMonth()+1).toString()}-${(new Date(newChatExams[index].taken).getDate()).toString().length == 1 ? '0'+(new Date(newChatExams[index].taken).getDate()).toString():(new Date(newChatExams[index].taken).getDate()).toString()}`
                  let start = this.filterBody.content.filter.dateFrom;
                  let end = this.filterBody.content.filter.dateTo;
                  // 해당 영상객체의 촬영날짜가 dateFrom ~ dateTo 사이에 위치하는 경우에 대한 처리
                  if(new Date(date.replace(/-/g, '/')).getTime() >= new Date(start.replace(/-/g, '/')) && new Date(date.replace(/-/g, '/')).getTime() <= new Date(end.replace(/-/g, '/'))) isAdded = true;
                  // 해당 영상객체의 촬영날짜가 dateFrom ~ dateTo 사이에 위치하지 않아, 조건에 부합하지 않는 경우에 대한 처리
                  else continue;
                }
                // 현재 영상조회 조건에 dateFrom이 없는 경우에 대한 처리
                else isAdded = true;
                // 현재 영상조회 조건에 type 이 있는 경우에 대한 처리
                if(this.filterBody.content.filter.type){
                  // 해당 영상객체의 type 이 조건에 부합하는 경우에 대한 처리
                  if(this.filterBody.content.filter.type == newChatExams[index].type) isAdded = true;
                  // 해당 영상객체의 type 이 조건에 부합하지 않는 경우에 대한 처리
                  else continue;
                }
                // 현재 영상조회 조건에 type이 없는 경우에 대한 처리
                else isAdded = true;
                // 현재 영상조회 조건에 status가 있는 경우에 대한 처리
                if(this.filterBody.content.filter.status){
                  // 해당 영상객체의 status가 조건에 부합하는 경우에 대한 처리
                  if(this.filterBody.content.filter.status == 'chat'){
                    if(newChatExams[index].chat) isAdded = true;
                    else continue;
                  }else if(this.filterBody.content.filter.status == 'highlight'){
                    if(newChatExams[index].highlight) isAdded = true;
                    else continue;
                  }
                  else {
                    if((this.filterBody.content.filter.status == newChatExams[index].status) ) isAdded = true;
                    else continue;
                  }
                }
                // 현재 영상조회 조건에 status가 없는 경우에 대한 처리
                else isAdded = true;
                // 현재 영상조회 조건에 keyword가 있는 경우에 대한 처리
                if(this.filterBody.content.filter.keyword){
                  // 해당 영상객체의 환자이름, 환자번호 값이 조회하려는 keyword를 포함하여 조건에 부합하는 경우에 대한 처리
                  if(
                    newChatExams[index].patient_name.includes(this.filterBody.content.filter.keyword) || 
                    newChatExams[index].chart_id.includes(this.filterBody.content.filter.keyword)  
                  ) isAdded = true;
                  // 해당 영상객체의 환자이름, 환자번호 값이 조회하려는 keyword를 포함하지 않아 조건에 부합하지 않는 경우에 대한 처리
                  else continue;
                }
                // 현재 영상조회 조건에 keyword가 없는 경우에 대한 처리
                else isAdded = true;
                // 모든 조건에 부합하는 경우에 대한 처리
                if(isAdded) addedExams.push(newChatExams[index])
              }
            }
            this.exams = [...addedExams, ...this.exams];
          }else this.alert('error',this.lang[this.$store.state.lang].LIST_ALERT_TXT_FAIL_RECV_NEW_CHAT_EXAMS); // '새로운 채팅목록을 영상목록 상위에 노출하는 것에 실패하였습니다.'
        }
      }
      // 205번 action(= 신규채팅메세지가 포함된 영상정보 조회)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 205) {
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success){
          // 수신된 영상목록의 길이가 0보다 큰 경우에 대한 처리
          if(data.body[1].content.length > 0) {
            let id;
            // id = 0 인 경우에는 특정 영상 조회 조건이 없는 경우 이지만, id = 1인 경우에는 그렇지 않으므로 action을 보낸 이후의 동작이 서로 다르므로 if문을 이용하여 분기처리 하였다.
            if(data.id == 0) id = 7;
            if(data.id == 1) id = 8;
            let packet = this.$$build(157,id,[{
              contentType: 1,
              content: {filter: {exam: data.body[1].content}} 
            }])
            this.$socket.send(packet);
          }
        }
      }
      /**
       * 63번 action(= 판독의뢰)에 대한 응답이 수신되었을 때의 처리
       * id = 0 Viewer 의 popup에서 판독의뢰를 한 경우 ( 한 장 )
       */
      if(data.action == 63){
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success){
          if(data.id == 0) {
            const nowExam = this.exams.find((exam) => exam.seq == this.selectedExam.seq);
            // 영상목록에 selectedExam 의 seq와 같은 seq를 가진 영상객체가 있는 경우에 대한 처리
            if(nowExam) nowExam.status = 'pending';
            this.selectedExam.status = 'pending';
          }
        }
        // 응답이 실패인 경우에 대한 처리
        else this.alert('error',this.lang[this.$store.state.lang].MAIN_ALERT_TXT_FAIL_REQ); // '판독의뢰 요청에 실패하였습니다.\n다시 시도해주세요.'
      }
      // 2000번 action(= polygon생성)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 2000){
        if(process.env.VUE_APP_DEBUG) console.log('2000 action main',data)
        this.$store.state.examsUploaded = this.$store.state.examsUploaded.filter((id) => id != data.id);
        const received = this.exams.find((exam) => exam.seq == data.body[0].content.exam.seq);
        if(received&&received.__examSeq) {
          delete received.__examSeq;
        }
        if(data.body[0].content.success) {
          let polygonCreatedLabel = data.body[0].content.exam.labels.disease.filter(label => label.polygon);
          let founded = this.exams.find((exam) => exam.seq == data.body[0].content.exam.seq);

          if(founded) {
            founded.labels.implant = [...data.body[0].content.exam.labels.implant];
            if(data.body[0].content.exam.labels.disease.length == polygonCreatedLabel.length) {
              founded.labels.disease = [...data.body[0].content.exam.labels.disease];
              if(founded.status != data.body[0].content.exam.status) founded.status = data.body[0].content.exam.status; 
            }
          }
          if(this.selectedExam.seq == data.body[0].content.exam.seq){
            this.selectedExam.labels.implant = [...data.body[0].content.exam.labels.implant];
            if(data.body[0].content.exam.labels.disease.length == polygonCreatedLabel.length) {
              this.selectedExam.labels.disease = [...data.body[0].content.exam.labels.disease];
              if(this.selectedExam.status != data.body[0].content.exam.status) this.selectedExam.status = data.body[0].content.exam.status;
            }
          }
        }
      }
      // 102번 action(= 채팅메시지 보내기)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 102){
        // 응답이 성공일 경우에 대한 처리
        if(data.body[0].content.success){
          // selectedExam의 chat이 false일 때에 대한 처리
          if(!this.selectedExam.chat) {
            const founded = this.exams.find((exam)=>exam.seq == this.selectedExam.seq);
            // 현재 영상목록에 selectedExam의 seq와 같은 seq를 가진 영상객체가 있는 경우에 대한 처리
            if(founded) founded.chat = true;
          }
        }
      }
      // 105번 action(= 채팅메시지 읽음처리)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 105){
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success){
          let founded = this.exams.find((exam)=>exam.seq == this.selectedExam.seq);
          if(founded) founded._new_chat = false;
          this.selectedExam._new_chat = false;
          this.shouldOpenChat = false;
        }
      }
      // 2001번 action(= 신규영상 등록 )에 대한 응답이 수신되었을 때의 처리
      if(data.action == 2001) {
        if(process.env.VUE_APP_DEBUG) console.log('2001 action main',data)
        let recv = data.body[1].content[0];
        recv = {...recv, labels: {disease:[],implant:[]}};
        
        if(!this.filterBody.content.filter) {
          recv.__isNew = true;
          recv.__examSeq = recv.seq;
          this.exams.unshift(recv);
        }
        else {
          let shouldAdded = true;
          if(this.filterBody.content.filter.status) shouldAdded = false;
          if(this.filterBody.content.filter.dateFrom){
            let date = `${new Date(recv.taken).getFullYear()}-${(new Date(recv.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(recv.taken).getMonth()+1).toString():(new Date(recv.taken).getMonth()+1).toString()}-${(new Date(recv.taken).getDate()).toString().length == 1 ? '0'+(new Date(recv.taken).getDate()).toString():(new Date(recv.taken).getDate()).toString()}`
            let start = this.filterBody.content.filter.dateFrom;
            let end = this.filterBody.content.filter.dateTo;
            if(!(new Date(date.replace(/-/g, '/')).getTime() >= new Date(start.replace(/-/g, '/')) && new Date(date.replace(/-/g, '/')).getTime() <= new Date(end.replace(/-/g, '/')))) shouldAdded = false;
          }
          if(this.filterBody.content.filter.type){
            if(this.filterBody.content.filter.type != recv.type) shouldAdded = false;
          }
          if(this.filterBody.content.filter.keyword){
            if(
              !recv.patient_name.includes(this.filterBody.content.filter.keyword) && 
              !recv.chart_id.includes(this.filterBody.content.filter.keyword)  
            ) shouldAdded = false;
          }
          if(shouldAdded) {
            recv.__isNew = true;
            recv.__examSeq = recv.seq;
            this.exams.unshift(recv);
          }
        }
      }
      // 64번 action(= 파일 업로드 )에 대한 응답이 수신되었을 때의 처리
      if(data.action == 64) {
        if(process.env.VUE_APP_DEBUG) console.log('64 action ',data)
        if(!data.body[0].content.success) {
          this.$store.state.examsUploaded = this.$store.state.examsUploaded.filter((id) => id != data.id);
          this.alert('error',this.lang[this.$store.state.lang].MAIN_ALERT_TXT_FAIL_TO_UPLOAD_EXAM); //파일 업로드에 실패하였습니다. 다시 시도해주세요
        }
      }
      // 12번 action(= 로그아웃)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 12){
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success){
          sessionStorage.removeItem('id');
          sessionStorage.removeItem('passwd');
          this.$store.state.socket.isLoggedIn = false;
          this.$store.state.myInfoModal = false;
          this.$store.state.readingReport = false;
          this.$store.state.modalViewModal = false;
          this.$store.state.reqHistoryModal = false;
          this.$store.state.changePrivateInfoModal = false;
          this.$store.state.alarmHistoryModal = false;
          this.$store.state.changePrivateInfoView = false;
          this.$store.state.tempPasswdModal = false
          this.$store.state.signature = '';

          for(let key in this.$store.state.hospitalInfo){
            this.$store.state.hospitalInfo[key] = '';
          }

          for(let key in this.$store.state.doctorInfo){
            this.$store.state.doctorInfo[key] = '';
          }

          sessionStorage.removeItem('examType');
          this.$router.push('/');
        }
        // 응답이 실패인 경우에 대한 처리
        else {
          this.alert('error',this.lang[this.$store.state.lang].MAIN_ALERT_TXT_FAIL_TO_LOGOUT); // 로그아웃에 실패하셨습니다. 다시 시도해주세요
        }
      }
    },
    onConnected: function() {
      const id = sessionStorage.getItem('id');
      const passwd = sessionStorage.getItem('passwd');
      // sessionStorage에 아이디, 비밀번호 값이 저장되어 있는 경우에 대한 처리
      if( id && passwd ) {
        let userInfo = this.$$build(
          11,
          [
            {
              contentType: 1,
              content: {type:"RD",email:id,passwd:passwd},
            }
          ]
        );
        this.$socket.send(userInfo);
      }
      this.$store.state.lang = sessionStorage.getItem('lang');
      this.$store.state.diseaseLang = sessionStorage.getItem('diseaseLang');
    },
    setExam(exam){
      this.selectedExam = {...exam};
      if( process.env.VUE_APP_DEBUG ){
        console.log('selected',this.selectedExam)
      }
    },
    updateReport(report){
      const nowExam = this.exams.find((exam) => exam.seq == this.selectedExam.seq);
      // 현재 영상목록에 selectedExam 의 seq와 같은 seq를 가진 영상객체가 있는 경우에 대한 처리
      if(nowExam) nowExam.report = {...report};
      this.selectedExam = {...this.selectedExam,report:{...report}};
    },
    // 새로운 채팅 수신시, 해당 exam을 상위에 위치시키고, exam의 _new_chat을 변경한다.
    updateNewChat(seq){
      let founded = this.exams.find((exam)=>exam.seq == seq);
      // 현재 영상목록에 인자로 받은 seq와 같은 seq를 가진 영상객체가 있는 경우에 대한 처리
      if(founded) {
        founded._new_chat = true;
        // founded 의 chat이 false인 경우에 대한 처리
        if(!founded.chat) founded.chat = true;
        this.exams = this.exams.filter(exam => exam.seq != seq);
        this.exams = [founded, ...this.exams];
      }
      // 현재 영상목록에 인자로 받은 seq와 같은 seq를 가진 영상객체가 없는 경우에 대한 처리
      else {
        let packet = this.$$build(157,9,[{
          contentType: 1,
          content: {
            filter: {exam: [seq]} 
          }
        }])
        this.$socket.send(packet);
      }
      // 인자로 전달받은 seq와 selectedExam의 seq가 같은 경우에 대한 처리
      if( this.selectedExam.seq == seq){
        this.selectedExam._new_chat = true;
      }
    },
    updatePage(){
      this.filterBody.content.rangeFrom = this.index;
      this.filterBody.content.rangeTo = this.index + this.limit;
      let packet = this.$$build(157,4,[this.filterBody]);
      this.$socket.send(packet);
      this.examsLoaded = false;
    },
    refreshExams(){
      this.index = 0;
      this.filterBody = {
        contentType: 1,
        content: {}
      }
      this.filterBody.content.rangeFrom = this.index;
      this.filterBody.content.rangeTo = this.index+this.limit;
      let packet = this.$$build(157,[this.filterBody]);
      this.$socket.send(packet);
      this.examsLoaded = false;
    },
    search(filterBody){
      this.index = 0;
      /*
      ** filterBody가 falsy 값인 경우에 대한 처리
      ** filterBody가 falsy 값인 때는 영상 조회를 위한 특정 조건이 없을 때 이다. ( 영상종류 / 판독상태 의 경우에는 '전체'인 경우도 포함한다.)
      */
      if(!filterBody) {
        this.filterBody = {
          contentType: 1,
          content: {}
        }
      }
      /*
      ** filterBody가 truthy 값인 경우에 대한 처리
      ** filterBody가 truthy 값인 때는, 영상 조회를 위한 특정 조건이 설정되었을 때 이다. ( 영상종류 / 판독상태 의 경우에는 '전체'인 경우를 제외한다. )
      */
      else this.filterBody = filterBody;
      this.filterBody.content.rangeFrom = this.index;
      this.filterBody.content.rangeTo = this.index+this.limit;
      let searchInfo = this.$$build(
        157,
        6,
        [this.filterBody]
      );
      this.$socket.send(searchInfo);
      this.examsLoaded = false;
    },
    // 새로운 채팅 수신시, toast 혹은 알림목록에서 목록을 클릭한 경우 selectedExam을 변경하고, Chatting 컴포넌트가 보이도록 한다.
    setRecvChatExam(seq){
      const inInExams = this.exams.find((exam) => exam.seq == seq);
      if(inInExams) {
        this.selectedExam = {...inInExams};
        this.shouldOpenChat = true;
      }else {
        let packet = this.$$build(157,30,[{
          contentType: 1,
          content: {
            filter: {
              exam: [seq]
            }
          }
        }]);
        this.$socket.send(packet);
      }
    },
    setExamByKey(index){
      this.selectedExam = this.exams[index];
    },
    // 모달, sidebar 등에서 메인으로 가기 버튼을 클릭한 경우로, 파일을 업로드 할 수 있는 화면이 보인다.
    setStartView(){
      this.selectedExam = {};
    },
    // 멤버십 안내 모달이 떠 있을 때, 외부를 클릭하는 경우 해당 모달을 끈다.
    hideTempPasswdModal(ev){
      if(!this.$store.state.membership[0]) return;
      if(!this.$refs.tempPasswdModalBox.contains(ev.target)) this.$store.state.membership = [false];
    },
    modalConfirm() {
      // 임시비밀번호 발급 안내 모달, 멤버십 안내 모달이 떠 있는 경우를 분기처리한다.
      // 임시비밀번호 발급 안내 모달이 떠 있는 경우, 해당 함수가 호출될 때 로그아웃 처리를 한다.
      if(this.$store.state.tempPasswdModal) {
        let packet = this.$$build(12,[]);
        this.$socket.send(packet);
      }
      // 멤버십 안내 모달이 떠 있는 경우, 모달을 끈다.
      else if(this.$store.state.membership[0]) {
        this.$store.state.membership = [false];
      }
    }
  },
  mounted(){
    const id = sessionStorage.getItem('id');
    const passwd = sessionStorage.getItem('passwd');
    // 소켓이 연결되어있고, 로그인이 되어있는 경우에 대한 처리
    if(this.$store.state.socket.isConnected && this.$store.state.socket.isLoggedIn) {
      let exams = this.$$build(157,[{
        contentType: 1,
        content: {
          rangeFrom: this.index,
          rangeTo: this.index + this.limit,
        }
      }]);
      this.$socket.send(exams);
    }
    // sessionStorage에 아이디, 비밀번호 정보가 저장되어있지 않은 경우에 대한 처리
    if(!id&&!passwd) {
      this.$router.push('/');
      return;
    }
    this.filterBody = {
      contentType: 1,
      content: {
        rangeFrom: this.index,
        rangeTo: this.index + this.limit,
      }
    }
    sessionStorage.setItem('examType','px');
    this.$store.state.menu = 'px';
  },
  created(){
    document.addEventListener('click',(ev) => {
      let targetId = ev.target.id.toLowerCase();
      if(targetId.includes('modalbg')) {
        this.$store.state.myInfoModal = false;
      }
    });
  },
}
</script>

<style scoped>
  .modal_bg_black{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 3;
    overflow: auto;
  }
  .find_passwd_modal{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #FFFFFF;
    z-index: 10;
  }
  .find_passwd_modal .content_box{
    display: flex;
    flex-direction: column;
    gap: 64px;
    justify-content: space-between;
    padding: 42px 48px;
    width: 524px;
    background: #212125;
    border: 2px solid #5B5B5E;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .find_passwd_modal .content_box .title{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 28px;
  }
  .find_passwd_modal .content_box .txt{
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  .find_passwd_modal .content_box button{
    width: 100%;
    height: 44px;
    background-color: #A45CFE;
    color: #FFFFFF;
    border-radius: 4px;
  }
  .find_passwd_modal .test_box{
    flex: 1;
    gap: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .find_passwd_modal .test_box .sub_title{
    margin-bottom: 20px;
  }
  .find_passwd_modal .test_box .txt > p{
    font-size: 16px;
  }
</style>