<template>
  <div class="diagnosis" ref="diagnosisContainer">
    <div @click="openChat" v-if="!chatState&&exam.seq&&exam._new_chat" class="img_size" :style="{opacity: exam.type=='CT' && isShowPrevImg ? '0' : '1'}">
      <img class="chat_img_position" src="@/assets/images/panvi_chatting_new.png" width="75px" height="75px" alt="chatting_img_on">
    </div>
    <div @click="openChat" v-if="!chatState&&exam.seq&&!exam._new_chat" class="img_size" :style="{opacity: exam.type=='CT' && isShowPrevImg ? '0' : '1'}">
      <img class="chat_img_position" src="@/assets/images/panvi_chatting.png" width="75px" height="75px" alt="chatting_img_off">
    </div>

    <Chatting v-if="chatState" @add="addNewChat" @close="chatState=false" :seq="exam.seq" :msgs="msgs" :msgsIsLoaded="msgsIsLoaded" :shouldOpenChat="shouldOpenChat" :hasNewChat="exam._new_chat"/>
    <div class="diag_black"></div>
    <div id="diagnosis_window" :class="['window',exam.chart_id?'flex_on':'']" v-if="exam.chart_id">
      <div id="diagnosis_request_popup" :style="{display: requestPopup?'grid':'none'}" :class="['diagnosis_black']">
        <div class="request_popup">
          <div class="request_head">
            <h3>{{lang[$store.state.lang].MAIN_MODAL_TITLE}}<!--영상 판독 의뢰하기--></h3>
            <button @click="closeRequestPopup" class="close_btn"><img src="@/assets/images/dise_pu_close.png" width="24" height="24" alt="" /></button>
          </div>
          <div class="request_box">
            <div class="select_filter">
              <select id="selectCC" v-model="selectedCC">
                <option value="기본">{{lang[$store.state.lang].MAIN_TXT_SELECT_CC}}<!--C.C 선택--></option>
                <option value="사랑니발치">{{lang[$store.state.lang].MAIN_TXT_CC_OPT1}}<!--사랑니발치--></option>
                <option value="과잉치·매복치">{{lang[$store.state.lang].MAIN_TXT_CC_OPT2}}<!--과잉치·매복치--></option>
                <option value="TMJ">{{lang[$store.state.lang].MAIN_TXT_CC_OPT3}}<!--TMJ--></option>
                <option value="근관치료 실패 원인 확인">{{lang[$store.state.lang].MAIN_TXT_CC_OPT4}}<!--근관치료 실패 원인 확인--></option>
                <option value="3cm 이상 병소">{{lang[$store.state.lang].MAIN_TXT_CC_OPT5}}<!--3cm 이상 병소--></option>
                <option value="C.C 직접 입력">{{lang[$store.state.lang].MAIN_TXT_CC_OPT6}}<!--C.C 직접 입력--></option>
              </select>
              <label for="selectCC"></label>
            </div>
            <div :class="[showReqPopupTextarea?'show':'hidden','form_cont']">
              <textarea id="ccType" cols="30" rows="10" placeholder=" " v-model="ccText"></textarea>
              <label for="ccType">{{lang[$store.state.lang].MAIN_TXT_ENTER_CC}}<!--C.C 입력사항을 입력하세요.--></label>
            </div>
          </div>
          <div class="request_note">
            <p>{{lang[$store.state.lang].MAIN_MODAL_GUIDE1}}<!--&middot; C.C 선택 및 입력은 선택사항입니다.--></p>
            <p>{{lang[$store.state.lang].MAIN_MODAL_GUIDE2}}<!--&middot; 영상치의학 전문의가 영상판독 해드립니다.--></p>
            <p>{{lang[$store.state.lang].MAIN_MODAL_GUIDE3}}<!--&middot; 영상판독이 완료되면 판독 소견서를 인쇄할 수 있습니다.--></p>
          </div>
          <div class="btn_wrap">
            <button @click="closeRequestPopup" class="basic_btn cancel_btn press_btn black_btn">{{lang[$store.state.lang].MAIN_BTN_CANCEL}}<!--취소--></button>
            <button @click="requestExam" class="req_btn press_btn purple_btn online-only">{{lang[$store.state.lang].MAIN_BTN_REQUEST2}}<!--판독의뢰--></button>
          </div>
        </div>
      </div>

      <div class="patient_wrap">
        <div class="top" :style="{flexWrap: exam.patient_name.length > 10 ? 'nowrap' : 'wrap'}">
          <div class="patient">
            <div class="dot"></div>
            <div class="window_patient">
              <div id="window_patient_name">{{exam.patient_name}}</div>
              <div class="window_patient_info">
                <p id="window_patient_id" class="first">{{exam.chart_id}}</p>
                <p id="window_patient_sex">{{exam.patient_gender}}</p>
                <p id="window_patient_age">{{exam.patient_age}}</p>
              </div>
            </div>
          </div>

          <div class="control_wrap" v-if="exam.patient_name.length <= 10">
            <button @click="goPrevExam"><img src="@/assets/images/mn_cnt_prev.png" width="16" height="16" alt="" /></button>
            <div class="active_info">
              <h4 id="control_study_type">{{exam.type=='PX'?'PANORAMA':'CBCT'}}</h4>
              <h4 id="control_study_date">{{`${new Date(exam.taken).getFullYear()}.${(new Date(exam.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(exam.taken).getMonth()+1).toString():new Date(exam.taken).getMonth()+1}.${(new Date(exam.taken).getDate()).toString().length == 1 ? '0'+(new Date(exam.taken).getDate()).toString() : new Date(exam.taken).getDate()}`}}</h4>
              <h4 id="control_study_time">{{new Date(exam.taken).toTimeString().slice(0,8)}}</h4>
            </div>
            <button @click="goNextExam"><img src="@/assets/images/mn_cnt_next.png" width="16" height="16" alt="" /></button>
          </div>

          <div class="print_wrap" :style="{flexDirection: 'column', alignItems: 'flex-end', flexWrap:'nowrap', justifyContent:'center'}">
            <div class="print" @click="$store.state.readingReport=true">
              <button><img src="@/assets/images/mn_cnt_print.png" width="24" height="24" alt="" /><span>{{lang[$store.state.lang].MAIN_TXT_REPORT}}<!--판독소견서--></span></button>
            </div>

            <div class="control_wrap" v-if="exam.patient_name.length > 10">
              <button @click="goPrevExam"><img src="@/assets/images/mn_cnt_prev.png" width="16" height="16" alt="" /></button>
              <div class="active_info">
                <h4 id="control_study_type">{{exam.type=='PX'?'PANORAMA':'CBCT'}}</h4>
                <h4 id="control_study_date">{{`${new Date(exam.taken).getFullYear()}.${(new Date(exam.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(exam.taken).getMonth()+1).toString():new Date(exam.taken).getMonth()+1}.${(new Date(exam.taken).getDate()).toString().length == 1 ? '0'+(new Date(exam.taken).getDate()).toString() : new Date(exam.taken).getDate()}`}}</h4>
                <h4 id="control_study_time">{{new Date(exam.taken).toTimeString().slice(0,8)}}</h4>
              </div>
              <button @click="goNextExam"><img src="@/assets/images/mn_cnt_next.png" width="16" height="16" alt="" /></button>
            </div>
          </div>
        </div>
      </div>

      <div id="diagnosis_result" :class="['result_wrap', exam.chart_id?'block_on':'']" ref="diagnosisResult">

        <div class='viewer_filter_wrap' ref="viewerFilter" style="position:relative">
          <div class="parameter_box" v-if="parameter">
            <h4>{{lang[$store.state.lang].MAIN_TXT_PARAMETER_POPUP_TITLE}}<!--진단기준에 따라 AI가 표시하는 질환범위가 달라집니다.--></h4>
            <ul>
              <li>
                <span>{{lang[$store.state.lang].MAIN_TXT_DISEASE_FILTER_SENSITIVE}}<!--민감하게--> : </span> <span>{{lang[$store.state.lang].MAIN_TXT_PARAMETER_POPUP_SENSITIVE_DES}}<!--가능성 있는 모든 질환을 검출합니다.--></span>
              </li>
              <li>
                <span>{{lang[$store.state.lang].MAIN_TXT_DISEASE_FILTER_STANDARD}}<!--표준진단--> : </span> <span>{{lang[$store.state.lang].MAIN_TXT_PARAMETER_POPUP_STANDARD_DES}}<!--일반적인 임상 수준의 질환을 검출합니다.--></span>
              </li>
              <li>
                <span>{{lang[$store.state.lang].MAIN_TXT_DISEASE_FILTER_PRECISE}}<!--정확하게--> : </span> <span>{{lang[$store.state.lang].MAIN_TXT_PARAMETER_POPUP_PRECISE_DES}}<!--되도록 확실한 질환만 검출합니다.--></span>
              </li>
            </ul>
          </div>
          <div class='disease_boundary'>
            <div>
              <span>{{lang[$store.state.lang].MAIN_TXT_DISEASE_FILTER_TITLE}}<!--진단기준--></span>
              <img id="parameterIcon" @click="parameter=true" src="@/assets/images/questionIcon.png" alt="questionIcon">
            </div>
            <div @click="setDiseaseBoundary">
              <button name=20 :class="['polygon_filter_btn',diseaseBoundary == 20 ? 'disease_boundary_selected' : '']">{{lang[$store.state.lang].MAIN_TXT_DISEASE_FILTER_SENSITIVE}}<!--민감하게--></button>
              <button name=50 :class="['polygon_filter_btn',diseaseBoundary == 50 ? 'disease_boundary_selected' : '']">{{lang[$store.state.lang].MAIN_TXT_DISEASE_FILTER_STANDARD}}<!--표준진단--></button>
              <button name=90 :class="['polygon_filter_btn',diseaseBoundary == 90 ? 'disease_boundary_selected' : '']">{{lang[$store.state.lang].MAIN_TXT_DISEASE_FILTER_PRECISE}}<!--정확하게--></button>
            </div>
          </div>
          <div class="pano_con img_control">
            <button id="pano_zoom_out" @click="zoomOut">
              <img src="@/assets/images/minus.png" width="36" height="36" alt="" draggable="false" />
            </button>
            <button id="pano_zoom_in" @click="zoomIn">
              <img src="@/assets/images/plus.png" width="36" height="36" alt="" draggable="false" />
            </button>
            <button id="pano_zoom_default" @click="zoomDefault">
              <img src="@/assets/images/reset.png" width="36" height="36" alt="" draggable="false" />
            </button>
          </div>
          <div class='filter_right'>
            <div class='show_disease'>
              <div>{{lang[$store.state.lang].MAIN_TXT_SHOW_DISEASE}}<!--질환--></div>
              <div @click="showPolygon=!showPolygon" :class="['slide',!showPolygon?'slide_off':'slide_on']">
                <div :class="['slide_dot',!showPolygon?'dot_on':'']"></div>
                <span>ON</span>
                <span>OFF</span>
              </div>
            </div>
            <div class='show_disease'>
              <div>{{lang[$store.state.lang].MAIN_TXT_SHOW_IMPLANT}}<!--임플란트보기--></div>
              <div @click="showImplant = !showImplant" :class="['slide',!showImplant?'slide_off':'slide_on']">
                <div :class="['slide_dot',!showImplant?'dot_on':'']"></div>
                <span>ON</span>
                <span>OFF</span>
              </div>
            </div>
          </div>
        </div>

        <div class="disease_image_abs" ref="imgBoxRef" :style="{height: imgAbHeight+'px'}">
          <div class="disease_image">
            <div v-if="!$store.state.imgScrollContainerOpacity" id="result_loading" class="loading on">
              <div class="spinner"></div>
            </div>
            <div id="sub_window_black"></div>
            <div v-if="exam.type=='PX'" id="sub_window_pano" class="pano" :style="{display:exam.type=='PX'&&'block'}">
              <div class="img_box no-scrollbar" ondragstart="return false;" ref="boundaryBox">
                <div class="center">
                  <div ref="imgScrollContainer" @mousewheel="wheelImg" class="svg_container" :style="{width:`${imgScrollContainer.width}px`, height:`${imgScrollContainer.height}px`,opacity:$store.state.imgScrollContainerOpacity}">
                    <div class="px_img" ref="img" @mousedown="setStartPoint" @mousemove="moveImg" @mouseup="setEndPoint" @mouseleave="setEndPoint" :style="{top:`${imgTop}px`,left:`${imgLeft}px`,width:`${imgSize.width}px`, height:`${imgSize.height}px`}">
                      <!-- 이미지 + polygon -->
                      <svg class="image_with_polygon resizable" id="pano_svg" :viewBox="`0 0 ${imgScrollContainer.width} ${imgScrollContainer.height}`"
                      preserveAspectRatio="xMidYMid" :style="{width:`${imgSize.width}px`, height:`${imgSize.height}px`}">
                        <image :href="imgUrl" width="100%" height="100%"></image>
                        <polygon @mouseenter="hoverPolygon" :data-score="bboxObj.score" :id="bboxObj.id" :style="{opacity: (showPolygon && bboxObj.score > diseaseBoundary)? ( targetInfo.id.length != 0 ? ( targetInfo.id.includes(bboxObj.id.toString()) ? '0' : '0.1' ) : '0.25') : '0' ,transform:`translate(${bboxObj.startCoord.x}px,${bboxObj.startCoord.y}px)`, fill: bboxObj.color, cursor: (showPolygon && bboxObj.score > diseaseBoundary) ? 'pointer' : 'move'}" :points="bboxObj.points" v-for="(bboxObj,idx) in convertedCoords" :key="'convertedCoords-'+'PX-'+idx+'-'+'bbox'"/>
                      </svg>
                      <!--bbox-->
                      <div v-show="showPolygon && bbox.score > diseaseBoundary && targetInfo.id.includes(bbox.id.toString())" @mouseleave="resetTargetIdInfo"  :class="['bbox']" v-for="(bbox,idx) in convertedCoords" :key="'labels-'+idx+'-'+bbox.name" :style="{width:`${ (bbox.size.width/imgRatio.width)}px`,height:`${ (bbox.size.height/imgRatio.height)}px`,top:`${bbox.lt.y / imgRatio.height}px`, left:`${bbox.lt.x / imgRatio.width}px`, border: `3px solid ${bbox.color}`}">
                        <div ref="diseaseBboxName" class="bbox_name" :data-bboxNameId="bbox.id" :style="{fontSize: bboxNameFontSize * (imgPer / 100) + 'px'}">{{bbox.name}}</div>
                      </div>
                      <!--implant-->
                      <div v-show="showImplant && clickedImplant == `implant${implant.point[0]}${implant.point[1]}`" class="implant_bbox" :style="{top:`${(implant.point[1] / imgRatio.height) - ((implantSize.height * (imgPer / 100)) / 2)}px`, left:`${(implant.point[0] / imgRatio.width)- ((implantSize.width * (imgPer / 100)) / 2)}px`, width: `${implantSize.width * (imgPer / 100)}px`, height: `${implantSize.height * (imgPer / 100)}px`}" v-for="(implant,idx) in exam.labels.implant" :key="'implants-'+idx+'-'+implant.point[0]+'-'+implant.point[1]">
                        <div ref="implantInfo" class="implant_info_box" :style="{...implantInfoBoxPos, fontSize: implantInfoBoxSize.font * (imgPer / 100) + 'px', padding: `${implantInfoBoxSize.padding[0] * (imgPer / 100)}px ${implantInfoBoxSize.padding[1] * (imgPer / 100) + 'px'}`}">
                          <div class="implant_wrap" v-for="(model,idx) in implant.model" :key="'implantModel-'+idx+'-'+implant.point[0]+'-'+model[0]" :style="{marginBottom: idx != implant.model.length-1 ? implantInfoBoxSize.rowMargin * (imgPer / 100)+'px' : '0'}">
                            <div class="col_left" :style="{paddingBottom: implantInfoBoxSize.rowPadding * (imgPer / 100)+'px'}">
                              <span>{{model[0]}}</span>
                              <span>{{model[1]}}</span>
                            </div>
                            <div v-if="model[2].toString().length == 1" class="col_right" :style="{paddingBottom: implantInfoBoxSize.rowPadding * (imgPer / 100)+'px' }">
                              <span style="opacity:0">0</span>
                              <span>{{model[2]}}%</span>
                            </div>
                            <div v-else class="col_right" :style="{paddingBottom: implantInfoBoxSize.rowPadding * (imgPer / 100)+'px'}"><span>{{model[2]}}%</span></div>
                          </div>
                        </div>
                      </div>
                      
                      <img @mouseenter="hoverImplant" src="@/assets/images/implant.png" alt="implant" :id="`implant${implant.point[0]}${implant.point[1]}`" class="implant_img default" :style="{ width: 28 + ( 28 * ((imgPer - 100) / 100) ) + 'px',height: 28 + ( 28 * ((imgPer - 100) / 100) ) + 'px', opacity: targetInfo.id.length!=0 ? '0.5' : '1' , display: showImplant ? ((hoveredImplant != `implant${implant.point[0]}${implant.point[1]}`) && (clickedImplant != `implant${implant.point[0]}${implant.point[1]}`) ? 'inline-block' : 'none') :'none' ,position: 'absolute', top:`${(implant.point[1] / imgRatio.height) - (14 * (imgPer / 100))}px`, left:`${(implant.point[0] / imgRatio.width)- (14 * (imgPer / 100))}px`,}" v-for="(implant,idx) in exam.labels.implant" :key="'implantDefaultImg-'+idx+'-'+implant.model[0][0]+'-'+implant.point[0]">
                      <img @mouseleave="leaveImplant" @click="showImplantInfo" src="@/assets/images/implantSelected.png" alt="implantSelected" :id="`implant${implant.point[0]}${implant.point[1]}`" class="implant_img selected" :style="{ width: 28 + ( ( 28 *( (imgPer - 100) / 100 ))) + 'px',height: 28 + ( 28 * ((imgPer - 100) / 100) ) + 'px', opacity: targetInfo.id.length!=0 ? '0.5' : '1' , display: showImplant ? ( hoveredImplant == `implant${implant.point[0]}${implant.point[1]}` || clickedImplant == `implant${implant.point[0]}${implant.point[1]}` ? 'inline-block' : 'none') : 'none' ,position: 'absolute', top:`${(implant.point[1] / imgRatio.height) - (14 * (imgPer / 100))}px`, left:`${(implant.point[0] / imgRatio.width)- (14 * (imgPer / 100))}px`,}" v-for="(implant,idx) in exam.labels.implant" :key="'implantSelectedImg-'+idx+'-'+implant.model[0][0]+'-'+implant.point[0]">

                    </div>
                  </div>
                </div>
                <!-- 이미지 구성 참고를 위해 주석처리함
                <svg class="image_with_polygon resizable" id="pano_svg" viewBox="0 0 1504 768"
                  preserveAspectRatio="xMidYMid" :style="{width:`${imgSize.width}px`, height:`${imgSize.height}px`}">
                  <image :href="baseUrl+exam.url" width="100%" height="100%"></image>
                                            <image class="img" xlink:href="/images/body_img.png" width="100%" height="100%"></image>
                                            <polygon points='914,466 888,482 886,488 887,501 905,530 909,547 913,554 915,566 919,573 922,586 928,596 929,603 934,612 935,619 942,637 957,659 965,667 978,668 980,666 981,660 980,647 967,598 951,524 946,510 935,489 934,482 930,472 923,466 914,466'/>
                                            <polygon points='399,377 388,383 378,401 375,417 372,423 365,465 357,480 341,520 340,526 336,532 335,539 330,548 328,563 333,572 338,571 348,562 353,556 356,548 375,524 378,516 384,509 387,502 399,493 405,493 410,498 414,498 416,502 415,507 410,511 409,514 409,530 416,531 419,533 425,528 430,519 433,507 441,493 446,476 457,456 457,453 466,435 467,403 464,399 441,396 436,392 427,389 421,384 404,377 399,377'/>                                            <polygon points='304,352 292,361 288,370 285,393 283,438 265,486 264,514 266,521 269,523 276,522 282,517 288,505 297,496 308,469 316,461 325,460 328,464 326,490 321,492 321,501 319,506 321,515 323,509 325,507 329,507 332,499 337,498 338,494 346,486 357,465 357,461 361,453 362,442 366,427 374,403 373,369 362,360 353,357 346,357 337,361 332,361 317,352 304,352'/>
                                            <polygon points='790,501 785,503 776,511 774,511 772,517 771,552 777,605 779,642 784,671 790,688 793,690 803,690 807,687 806,603 810,518 806,502 790,501'/>
                                            <polygon points='988,440 981,445 970,448 963,454 938,455 936,458 936,486 951,516 964,536 967,546 975,558 979,574 985,587 987,600 998,624 1012,644 1018,650 1025,651 1028,649 1029,646 1014,607 1009,581 1007,577 1007,560 1010,557 1013,555 1017,557 1026,566 1030,576 1035,583 1037,592 1045,605 1048,613 1056,626 1061,631 1069,634 1073,630 1075,623 1074,615 1067,602 1055,565 1051,558 1050,552 1046,545 1044,536 1036,518 1031,500 1030,487 1021,456 1013,446 998,440 988,440'/>
                                            <polygon points='1088,407 1082,412 1075,415 1067,422 1060,423 1050,429 1037,431 1027,441 1026,458 1032,478 1038,490 1054,510 1067,539 1076,554 1077,561 1087,579 1101,597 1104,603 1110,605 1115,611 1119,611 1123,619 1130,619 1131,617 1124,611 1122,611 1120,608 1120,602 1114,583 1110,576 1104,556 1098,545 1098,525 1104,521 1112,527 1137,567 1150,593 1159,600 1162,600 1166,596 1166,571 1158,554 1156,543 1151,530 1138,504 1135,490 1128,472 1126,461 1118,443 1112,424 1106,414 1099,408 1088,407'/>
                                            <polygon points='1037,203 1035,209 1032,212 1028,213 1028,275 1039,322 1040,337 1043,351 1052,374 1053,380 1057,386 1084,387 1087,386 1099,373 1104,371 1110,364 1114,355 1114,339 1112,331 1108,319 1095,292 1089,272 1083,259 1076,250 1073,240 1068,235 1066,229 1063,229 1060,226 1056,229 1053,226 1050,210 1046,208 1041,208 1037,203'/>
                                            <polygon points='601,450 595,455 589,466 587,474 584,479 579,506 574,560 573,605 571,627 571,646 574,652 583,652 587,648 591,638 593,627 603,607 613,542 625,503 625,480 620,464 615,456 604,450 601,450'/>
                                            <polygon points='876,491 871,494 866,500 860,515 858,528 860,547 874,594 900,650 904,655 906,661 911,666 914,675 918,679 927,680 929,678 931,671 931,654 929,639 921,614 907,546 904,542 897,517 890,503 881,492 876,491'/>
                                            <polygon points='1180,523 1179,526 1181,527 1180,523'/>
                                            <polygon points='1181,384 1177,388 1177,395 1180,399 1183,395 1183,387 1181,384'/>
                                            <polygon points='1169,376 1161,382 1159,387 1158,439 1161,454 1166,463 1169,477 1174,489 1174,493 1177,496 1177,488 1183,483 1186,483 1202,502 1213,518 1219,522 1226,523 1236,512 1236,505 1219,471 1214,457 1208,433 1204,400 1198,381 1194,378 1178,380 1172,376 1169,376'/>
                                            <polygon points='969,203 965,210 964,218 965,264 968,306 968,386 974,402 979,410 988,418 1010,418 1014,412 1020,407 1022,407 1024,410 1028,412 1034,413 1040,410 1047,396 1047,373 1045,365 1030,325 1023,296 1020,291 1018,283 1012,273 1011,268 1003,257 996,243 987,217 975,203 969,203'/>
                                            <polygon points='1083,178 1079,185 1077,197 1079,219 1084,237 1095,262 1097,278 1104,292 1109,297 1110,320 1113,325 1114,333 1117,338 1119,347 1126,355 1133,360 1142,360 1148,355 1160,335 1161,331 1174,314 1175,298 1171,289 1163,279 1152,259 1147,253 1138,236 1127,207 1119,196 1115,202 1112,198 1110,212 1108,215 1105,214 1101,179 1083,178'/>
                                            <polygon points='769,266 765,268 761,274 753,300 753,318 750,338 748,374 739,413 737,435 734,449 735,477 741,484 745,485 777,485 784,478 790,467 791,462 791,439 789,430 789,414 786,390 786,365 788,350 788,300 786,282 783,271 780,266 769,266'/>
                                            <polygon points='452,163 445,165 417,230 395,264 376,314 376,327 379,335 392,344 409,365 420,365 424,363 432,356 436,350 452,298 456,261 465,217 464,164 452,163'/>
                                            <polygon points='387,145 384,146 380,151 380,155 384,159 383,162 380,165 378,165 373,172 368,183 355,203 348,219 329,247 320,257 317,265 317,270 320,279 329,290 344,318 347,321 353,323 359,323 367,320 374,312 384,288 386,276 399,253 409,227 414,185 413,156 408,150 402,149 398,146 387,145'/>
                                            <polygon points='935,219 929,222 925,234 925,317 923,334 921,401 931,425 934,428 943,431 951,441 955,443 960,438 967,436 969,432 974,428 975,411 973,399 970,392 965,362 956,261 950,244 946,226 944,222 935,219'/>
                                            <polygon points='627,215 623,217 621,229 619,225 617,228 616,227 613,218 611,218 610,267 608,277 605,283 602,303 601,322 597,344 595,379 595,407 598,423 601,433 605,438 605,441 615,450 620,450 631,443 636,433 649,363 649,320 644,253 635,218 632,215 627,215'/>
                                            <polygon points='710,510 702,512 700,521 700,586 703,611 708,635 716,652 719,655 723,655 727,649 730,633 730,573 733,523 730,511 710,510'/>
                                            <polygon points='499,408 477,417 471,425 463,450 461,481 458,487 456,498 453,503 452,510 447,517 435,548 429,570 424,577 422,585 417,595 416,601 420,605 430,601 445,586 464,556 474,532 490,515 497,513 500,516 500,525 502,532 501,541 503,545 512,542 516,532 522,527 526,507 537,484 539,475 547,455 547,428 546,425 540,425 537,427 532,426 526,422 523,417 514,409 499,408'/>
                                            <polygon points='714,261 711,263 708,271 696,325 693,364 680,419 679,448 683,461 688,469 698,470 708,476 721,476 726,474 730,469 733,450 733,358 735,302 734,283 730,267 726,263 714,261'/>
                                            <polygon points='505,176 501,179 499,183 492,213 480,244 476,246 470,255 467,256 462,269 458,283 454,307 449,320 448,329 443,344 444,377 449,381 467,379 471,382 479,393 483,394 494,393 499,388 505,385 512,375 517,362 521,341 522,318 520,306 520,248 523,229 523,180 521,177 518,176 505,176'/>
                                            <polygon points='847,683 848,690 850,685 848,682 847,683'/>
                                            <polygon points='825,494 823,495 820,501 817,516 813,525 813,561 831,633 837,668 845,687 846,696 849,698 850,701 850,711 859,719 865,714 868,708 873,682 874,655 868,599 863,569 846,501 844,497 840,495 831,496 825,494'/>
                                            <polygon points='895,253 889,256 889,267 891,275 891,311 889,316 889,350 887,384 884,403 884,431 886,433 891,434 894,437 898,447 903,454 910,460 915,460 925,447 929,432 928,399 921,360 918,261 917,256 895,253'/>
                                            <polygon points='566,439 560,442 557,452 547,470 542,486 539,509 535,523 533,548 527,587 524,595 518,627 518,645 520,648 526,647 537,637 541,627 547,620 556,600 569,559 579,488 585,472 594,459 594,449 587,442 581,439 566,439'/>
                                            <polygon points='590,243 588,244 586,251 586,260 580,284 578,285 577,283 576,285 574,282 572,285 571,284 570,287 564,322 563,340 561,344 561,352 556,377 556,397 559,411 569,426 579,426 581,424 590,412 594,404 596,395 596,335 599,284 599,249 596,243 590,243'/>
                                            <polygon points='669,486 666,488 663,503 663,525 665,538 665,609 668,630 669,655 674,681 678,686 682,686 685,685 690,678 694,652 696,574 699,541 699,498 698,494 690,486 669,486'/>
                                            <polygon points='813,254 808,258 806,262 801,287 799,328 797,340 796,389 791,443 791,469 797,478 804,479 816,478 822,471 825,470 832,458 833,445 828,392 828,353 824,297 821,281 820,263 817,255 813,254'/>
                                            <polygon points='855,227 852,229 849,239 847,260 839,305 837,350 833,384 833,407 839,442 846,467 859,485 865,485 869,480 871,480 873,476 875,463 883,437 882,415 880,406 880,361 877,353 877,342 875,335 878,283 875,259 870,237 865,228 855,227'/>
                                            <polygon points='540,200 534,202 532,206 530,226 531,258 528,263 529,283 527,304 513,356 510,377 511,392 513,398 517,400 523,407 526,408 532,414 540,414 546,411 555,397 562,373 565,333 564,268 566,254 567,203 563,200 540,200'/>
                                            <polygon points='645,465 638,467 634,470 626,489 623,511 615,546 613,571 613,612 615,639 621,665 625,694 628,702 631,705 638,705 642,703 646,691 655,646 661,582 661,517 657,499 654,472 650,466 645,465'/>
                                            <polygon points='532,454 523,456 520,494 516,496 513,491 511,492 504,500 502,507 499,511 496,521 496,527 492,537 484,568 477,584 467,599 461,612 461,630 463,632 473,630 480,624 487,611 497,602 503,594 513,576 523,542 523,537 532,498 538,483 543,462 542,455 532,454'/>
                                            <polygon points='671,239 667,243 665,261 659,287 654,323 650,377 644,406 644,429 646,440 653,449 657,450 661,455 668,457 673,456 683,446 686,438 685,379 688,303 687,272 682,244 681,241 677,239 671,239'/>
                                            <polygon points='737,507 734,512 734,540 740,613 747,642 751,668 753,672 760,673 763,670 767,654 766,571 769,544 773,529 773,509 769,507 737,507'/>
                                            <polygon points='452,423 448,425 449,431 439,437 438,471 436,474 433,474 429,471 420,476 413,477 410,475 399,473 395,478 392,478 392,484 395,479 396,485 400,491 410,488 412,490 412,493 410,504 405,513 403,523 396,533 384,562 375,578 373,585 373,595 376,599 385,598 393,591 400,573 412,561 419,551 427,534 436,505 443,491 449,471 463,438 463,423 452,423'/>
                                            <polygon points='1162,454 1163,463 1164,454 1162,454'/>
                                            <polygon points='1126,388 1119,395 1114,405 1113,420 1116,431 1120,440 1136,462 1137,468 1141,473 1143,482 1147,489 1148,496 1154,512 1163,528 1166,530 1172,541 1183,551 1192,551 1192,530 1178,502 1178,498 1177,497 1175,500 1172,496 1171,491 1169,474 1169,401 1166,402 1163,410 1159,401 1149,389 1126,388'/>
                                            <polygon points='1490,634 1480,639 1469,653 1462,658 1446,665 1442,670 1441,682 1443,690 1457,707 1489,706 1495,703 1500,697 1502,687 1502,635 1490,634'/>
                                            <polygon points='873,300 870,320 867,404 869,419 874,434 877,439 880,439 885,431 884,311 882,301 873,300'/>
                                            <polygon points='361,402 352,403 351,430 348,439 346,439 344,426 343,431 339,434 334,444 334,448 331,452 327,445 320,444 319,472 313,493 310,497 301,523 301,539 304,545 309,545 314,542 317,539 319,532 323,528 325,529 331,523 337,514 343,502 347,489 355,476 360,456 361,441 364,430 369,420 371,410 370,402 361,402'/>
                                            <polygon points='608,224 604,226 601,225 601,235 602,236 603,234 605,237 605,250 603,254 602,265 601,306 597,331 595,364 595,402 598,421 600,423 611,420 611,412 619,391 621,370 621,352 618,326 616,230 614,224 608,224'/>
                                            <polygon points='338,418 336,455 338,498 341,494 343,487 348,482 352,464 356,460 358,442 363,431 364,424 362,421 338,418'/>
                                            <polygon points='394,408 388,412 389,417 395,418 399,416 398,409 394,408'/>
                                            <polygon points='377,406 373,422 371,445 368,450 364,473 359,479 358,486 353,497 353,500 356,504 356,509 352,512 356,513 357,535 359,539 363,535 366,535 374,526 383,502 388,499 394,487 402,482 405,478 406,449 396,443 396,436 388,435 385,437 386,443 384,445 380,444 380,436 378,429 379,409 377,406'/>
                                            <polygon points='197,626 178,633 170,640 166,649 166,656 168,661 170,665 175,669 199,671 210,677 221,679 239,687 247,695 249,702 248,709 240,724 240,733 255,733 265,715 273,707 277,705 284,704 292,709 294,708 293,703 287,701 287,693 284,690 282,684 264,663 224,632 212,627 197,626'/>
                                            <polygon points='104,640 93,646 89,651 89,659 96,668 101,670 116,671 128,675 139,689 148,693 152,698 160,697 164,688 176,680 176,674 169,666 165,658 153,648 142,644 122,640 104,640'/>
                                            <polygon points='198,627 174,638 168,644 167,660 173,668 200,671 208,676 222,679 238,685 268,685 277,682 275,675 269,669 227,634 214,627 198,627'/>
                                            <polygon points='391,218 389,229 389,268 377,291 376,300 378,304 381,304 386,298 387,278 389,275 393,255 393,243 396,242 396,239 394,241 392,236 391,218'/>
                                            <polygon points='418,159 416,216 414,221 413,222 411,220 406,227 405,230 408,231 412,236 410,241 406,245 406,249 403,255 398,260 398,265 405,266 411,270 413,299 415,299 416,291 416,267 419,261 424,261 426,264 434,264 432,246 430,255 427,255 424,258 421,258 417,255 417,237 420,225 422,206 424,200 427,197 429,197 431,193 428,174 418,159'/>
                                            <polygon points='1447,718 1446,724 1443,727 1441,727 1433,719 1427,722 1427,726 1432,734 1426,739 1423,746 1423,755 1425,761 1442,761 1447,754 1453,750 1456,742 1464,736 1465,730 1451,729 1449,727 1449,718 1447,718'/>
                                            <polygon points='1076,372 1077,378 1079,379 1080,373 1078,371 1076,372'/>
                                            <polygon points='1105,321 1101,326 1101,342 1099,356 1100,362 1104,366 1112,358 1114,349 1112,332 1105,321'/>
                                            <polygon points='1027,186 1022,188 1015,211 1013,228 1013,253 1023,286 1030,297 1034,309 1035,318 1041,325 1049,366 1057,374 1059,379 1063,383 1072,385 1078,380 1084,378 1089,367 1088,339 1086,335 1087,293 1085,279 1075,272 1075,264 1072,258 1071,249 1064,244 1057,245 1051,240 1051,236 1046,223 1046,217 1042,210 1041,202 1036,187 1027,186'/>
                                            <polygon points='1275,706 1269,712 1270,723 1288,747 1292,749 1300,748 1300,741 1294,728 1297,721 1296,717 1290,711 1280,706 1275,706'/>
                                           
                </svg> -->
              </div>
            </div>
            <div v-if="exam.type=='CT'" class="ct_container" :style="{opacity:$store.state.imgScrollContainerOpacity}">
              <div :style="{width:`${imgScrollContainer.width}px`, height:`${imgScrollContainer.height}px`}">
                <svg :style="{width:`${imgSize.width}px`, height:`${imgSize.height}px`}">
                  <image :href="baseUrl+ctImgUrl" width="100%" height="100%"></image>
                  <polygon v-show="rangeVal==bboxObj.image && showPolygon" @click="_showCrossSectionModal" :style="{transform:`translate(${bboxObj.startCoord.x}px,${bboxObj.startCoord.y}px)`}"  :points="bboxObj.points" v-for="(bboxObj,_,idx) in convertedCoords" :key="'convertedCoords-'+'CT-'+idx+'-'+'bbox'"></polygon>
                </svg>
              </div>
              <div :style="{height:`${imgScrollContainer.height}px`,position:'relative'}">
                <div v-if="isShowPrevImg" class="prev_img_box" :style="{top:`${ctPreviewImgTop}px`,left:`-115px`}">
                  <img :src="baseUrl+ctPreviewImg">
                </div>
                <input type="range"  :style="{width:`${imgScrollContainer.height}px`,top:`${Math.floor(imgScrollContainer.height/2)-4}px`,left:`-${Math.floor(imgScrollContainer.height/2)-4}px`}" @mousedown="moveToClickedImg" @mouseenter="_showPrevImg" @mouseleave="_hidePrevImg" @mousemove="movePreview" v-model="rangeVal" min="1" :max="maxRangeVal" :step="1">
                <div style="width:12px; height:100%; position:relative; left:8px">
                  <div v-for="diseasePointImgNum in diseaseImgNum" :key="'diseaseImgNum-'+diseasePointImgNum" class="disease_point_highlight" :style="{height:`${imgScrollContainer.height/maxRangeVal}px`,top:`${imgScrollContainer.height-((imgScrollContainer.height/maxRangeVal)*diseasePointImgNum)}px`}"></div>
                </div>
              </div>
            </div>
            <!-- 이미지 구성 참고를 위해 주석처리함
            <div v-if="exam.type=='CT'" id="sub_window_ct" class="ct on" :style="{display:exam.type=='CT'&&'grid'}">
              <div class="image_box">
                <div class="full svg_box no-scrollbar">
                  <svg class="image_with_polygon" id="ct_full_svg" viewBox="0 0 1504 768"
                    preserveAspectRatio="xMidYMid">
                    <image :href="baseUrl+exam.url" width="100%" height="100%"></image>  
                                                <image class="img" xlink:href="/images/body_img.png" width="100%" height="100%"></image>
                                                <polygon points='914,466 888,482 886,488 887,501 905,530 909,547 913,554 915,566 919,573 922,586 928,596 929,603 934,612 935,619 942,637 957,659 965,667 978,668 980,666 981,660 980,647 967,598 951,524 946,510 935,489 934,482 930,472 923,466 914,466'/>
                                                <polygon points='399,377 388,383 378,401 375,417 372,423 365,465 357,480 341,520 340,526 336,532 335,539 330,548 328,563 333,572 338,571 348,562 353,556 356,548 375,524 378,516 384,509 387,502 399,493 405,493 410,498 414,498 416,502 415,507 410,511 409,514 409,530 416,531 419,533 425,528 430,519 433,507 441,493 446,476 457,456 457,453 466,435 467,403 464,399 441,396 436,392 427,389 421,384 404,377 399,377'/>                                            <polygon points='304,352 292,361 288,370 285,393 283,438 265,486 264,514 266,521 269,523 276,522 282,517 288,505 297,496 308,469 316,461 325,460 328,464 326,490 321,492 321,501 319,506 321,515 323,509 325,507 329,507 332,499 337,498 338,494 346,486 357,465 357,461 361,453 362,442 366,427 374,403 373,369 362,360 353,357 346,357 337,361 332,361 317,352 304,352'/>
                                                <polygon points='790,501 785,503 776,511 774,511 772,517 771,552 777,605 779,642 784,671 790,688 793,690 803,690 807,687 806,603 810,518 806,502 790,501'/>
                                                <polygon points='988,440 981,445 970,448 963,454 938,455 936,458 936,486 951,516 964,536 967,546 975,558 979,574 985,587 987,600 998,624 1012,644 1018,650 1025,651 1028,649 1029,646 1014,607 1009,581 1007,577 1007,560 1010,557 1013,555 1017,557 1026,566 1030,576 1035,583 1037,592 1045,605 1048,613 1056,626 1061,631 1069,634 1073,630 1075,623 1074,615 1067,602 1055,565 1051,558 1050,552 1046,545 1044,536 1036,518 1031,500 1030,487 1021,456 1013,446 998,440 988,440'/>
                                                <polygon points='1088,407 1082,412 1075,415 1067,422 1060,423 1050,429 1037,431 1027,441 1026,458 1032,478 1038,490 1054,510 1067,539 1076,554 1077,561 1087,579 1101,597 1104,603 1110,605 1115,611 1119,611 1123,619 1130,619 1131,617 1124,611 1122,611 1120,608 1120,602 1114,583 1110,576 1104,556 1098,545 1098,525 1104,521 1112,527 1137,567 1150,593 1159,600 1162,600 1166,596 1166,571 1158,554 1156,543 1151,530 1138,504 1135,490 1128,472 1126,461 1118,443 1112,424 1106,414 1099,408 1088,407'/>
                                                <polygon points='1037,203 1035,209 1032,212 1028,213 1028,275 1039,322 1040,337 1043,351 1052,374 1053,380 1057,386 1084,387 1087,386 1099,373 1104,371 1110,364 1114,355 1114,339 1112,331 1108,319 1095,292 1089,272 1083,259 1076,250 1073,240 1068,235 1066,229 1063,229 1060,226 1056,229 1053,226 1050,210 1046,208 1041,208 1037,203'/>
                                                <polygon points='601,450 595,455 589,466 587,474 584,479 579,506 574,560 573,605 571,627 571,646 574,652 583,652 587,648 591,638 593,627 603,607 613,542 625,503 625,480 620,464 615,456 604,450 601,450'/>
                                                <polygon points='876,491 871,494 866,500 860,515 858,528 860,547 874,594 900,650 904,655 906,661 911,666 914,675 918,679 927,680 929,678 931,671 931,654 929,639 921,614 907,546 904,542 897,517 890,503 881,492 876,491'/>
                                                <polygon points='1180,523 1179,526 1181,527 1180,523'/>
                                                <polygon points='1181,384 1177,388 1177,395 1180,399 1183,395 1183,387 1181,384'/>
                                                <polygon points='1169,376 1161,382 1159,387 1158,439 1161,454 1166,463 1169,477 1174,489 1174,493 1177,496 1177,488 1183,483 1186,483 1202,502 1213,518 1219,522 1226,523 1236,512 1236,505 1219,471 1214,457 1208,433 1204,400 1198,381 1194,378 1178,380 1172,376 1169,376'/>
                                                <polygon points='969,203 965,210 964,218 965,264 968,306 968,386 974,402 979,410 988,418 1010,418 1014,412 1020,407 1022,407 1024,410 1028,412 1034,413 1040,410 1047,396 1047,373 1045,365 1030,325 1023,296 1020,291 1018,283 1012,273 1011,268 1003,257 996,243 987,217 975,203 969,203'/>
                                                <polygon points='1083,178 1079,185 1077,197 1079,219 1084,237 1095,262 1097,278 1104,292 1109,297 1110,320 1113,325 1114,333 1117,338 1119,347 1126,355 1133,360 1142,360 1148,355 1160,335 1161,331 1174,314 1175,298 1171,289 1163,279 1152,259 1147,253 1138,236 1127,207 1119,196 1115,202 1112,198 1110,212 1108,215 1105,214 1101,179 1083,178'/>
                                                <polygon points='769,266 765,268 761,274 753,300 753,318 750,338 748,374 739,413 737,435 734,449 735,477 741,484 745,485 777,485 784,478 790,467 791,462 791,439 789,430 789,414 786,390 786,365 788,350 788,300 786,282 783,271 780,266 769,266'/>
                                                <polygon points='452,163 445,165 417,230 395,264 376,314 376,327 379,335 392,344 409,365 420,365 424,363 432,356 436,350 452,298 456,261 465,217 464,164 452,163'/>
                                                <polygon points='387,145 384,146 380,151 380,155 384,159 383,162 380,165 378,165 373,172 368,183 355,203 348,219 329,247 320,257 317,265 317,270 320,279 329,290 344,318 347,321 353,323 359,323 367,320 374,312 384,288 386,276 399,253 409,227 414,185 413,156 408,150 402,149 398,146 387,145'/>
                                                <polygon points='935,219 929,222 925,234 925,317 923,334 921,401 931,425 934,428 943,431 951,441 955,443 960,438 967,436 969,432 974,428 975,411 973,399 970,392 965,362 956,261 950,244 946,226 944,222 935,219'/>
                                                <polygon points='627,215 623,217 621,229 619,225 617,228 616,227 613,218 611,218 610,267 608,277 605,283 602,303 601,322 597,344 595,379 595,407 598,423 601,433 605,438 605,441 615,450 620,450 631,443 636,433 649,363 649,320 644,253 635,218 632,215 627,215'/>
                                                <polygon points='710,510 702,512 700,521 700,586 703,611 708,635 716,652 719,655 723,655 727,649 730,633 730,573 733,523 730,511 710,510'/>
                                                <polygon points='499,408 477,417 471,425 463,450 461,481 458,487 456,498 453,503 452,510 447,517 435,548 429,570 424,577 422,585 417,595 416,601 420,605 430,601 445,586 464,556 474,532 490,515 497,513 500,516 500,525 502,532 501,541 503,545 512,542 516,532 522,527 526,507 537,484 539,475 547,455 547,428 546,425 540,425 537,427 532,426 526,422 523,417 514,409 499,408'/>
                                                <polygon points='714,261 711,263 708,271 696,325 693,364 680,419 679,448 683,461 688,469 698,470 708,476 721,476 726,474 730,469 733,450 733,358 735,302 734,283 730,267 726,263 714,261'/>
                                                <polygon points='505,176 501,179 499,183 492,213 480,244 476,246 470,255 467,256 462,269 458,283 454,307 449,320 448,329 443,344 444,377 449,381 467,379 471,382 479,393 483,394 494,393 499,388 505,385 512,375 517,362 521,341 522,318 520,306 520,248 523,229 523,180 521,177 518,176 505,176'/>
                                                <polygon points='847,683 848,690 850,685 848,682 847,683'/>
                                                <polygon points='825,494 823,495 820,501 817,516 813,525 813,561 831,633 837,668 845,687 846,696 849,698 850,701 850,711 859,719 865,714 868,708 873,682 874,655 868,599 863,569 846,501 844,497 840,495 831,496 825,494'/>
                                                <polygon points='895,253 889,256 889,267 891,275 891,311 889,316 889,350 887,384 884,403 884,431 886,433 891,434 894,437 898,447 903,454 910,460 915,460 925,447 929,432 928,399 921,360 918,261 917,256 895,253'/>
                                                <polygon points='566,439 560,442 557,452 547,470 542,486 539,509 535,523 533,548 527,587 524,595 518,627 518,645 520,648 526,647 537,637 541,627 547,620 556,600 569,559 579,488 585,472 594,459 594,449 587,442 581,439 566,439'/>
                                                <polygon points='590,243 588,244 586,251 586,260 580,284 578,285 577,283 576,285 574,282 572,285 571,284 570,287 564,322 563,340 561,344 561,352 556,377 556,397 559,411 569,426 579,426 581,424 590,412 594,404 596,395 596,335 599,284 599,249 596,243 590,243'/>
                                                <polygon points='669,486 666,488 663,503 663,525 665,538 665,609 668,630 669,655 674,681 678,686 682,686 685,685 690,678 694,652 696,574 699,541 699,498 698,494 690,486 669,486'/>
                                                <polygon points='813,254 808,258 806,262 801,287 799,328 797,340 796,389 791,443 791,469 797,478 804,479 816,478 822,471 825,470 832,458 833,445 828,392 828,353 824,297 821,281 820,263 817,255 813,254'/>
                                                <polygon points='855,227 852,229 849,239 847,260 839,305 837,350 833,384 833,407 839,442 846,467 859,485 865,485 869,480 871,480 873,476 875,463 883,437 882,415 880,406 880,361 877,353 877,342 875,335 878,283 875,259 870,237 865,228 855,227'/>
                                                <polygon points='540,200 534,202 532,206 530,226 531,258 528,263 529,283 527,304 513,356 510,377 511,392 513,398 517,400 523,407 526,408 532,414 540,414 546,411 555,397 562,373 565,333 564,268 566,254 567,203 563,200 540,200'/>
                                                <polygon points='645,465 638,467 634,470 626,489 623,511 615,546 613,571 613,612 615,639 621,665 625,694 628,702 631,705 638,705 642,703 646,691 655,646 661,582 661,517 657,499 654,472 650,466 645,465'/>
                                                <polygon points='532,454 523,456 520,494 516,496 513,491 511,492 504,500 502,507 499,511 496,521 496,527 492,537 484,568 477,584 467,599 461,612 461,630 463,632 473,630 480,624 487,611 497,602 503,594 513,576 523,542 523,537 532,498 538,483 543,462 542,455 532,454'/>
                                                <polygon points='671,239 667,243 665,261 659,287 654,323 650,377 644,406 644,429 646,440 653,449 657,450 661,455 668,457 673,456 683,446 686,438 685,379 688,303 687,272 682,244 681,241 677,239 671,239'/>
                                                <polygon points='737,507 734,512 734,540 740,613 747,642 751,668 753,672 760,673 763,670 767,654 766,571 769,544 773,529 773,509 769,507 737,507'/>
                                                <polygon points='452,423 448,425 449,431 439,437 438,471 436,474 433,474 429,471 420,476 413,477 410,475 399,473 395,478 392,478 392,484 395,479 396,485 400,491 410,488 412,490 412,493 410,504 405,513 403,523 396,533 384,562 375,578 373,585 373,595 376,599 385,598 393,591 400,573 412,561 419,551 427,534 436,505 443,491 449,471 463,438 463,423 452,423'/>
                                                <polygon points='1162,454 1163,463 1164,454 1162,454'/>
                                                <polygon points='1126,388 1119,395 1114,405 1113,420 1116,431 1120,440 1136,462 1137,468 1141,473 1143,482 1147,489 1148,496 1154,512 1163,528 1166,530 1172,541 1183,551 1192,551 1192,530 1178,502 1178,498 1177,497 1175,500 1172,496 1171,491 1169,474 1169,401 1166,402 1163,410 1159,401 1149,389 1126,388'/>
                                                <polygon points='1490,634 1480,639 1469,653 1462,658 1446,665 1442,670 1441,682 1443,690 1457,707 1489,706 1495,703 1500,697 1502,687 1502,635 1490,634'/>
                                                <polygon points='873,300 870,320 867,404 869,419 874,434 877,439 880,439 885,431 884,311 882,301 873,300'/>
                                                <polygon points='361,402 352,403 351,430 348,439 346,439 344,426 343,431 339,434 334,444 334,448 331,452 327,445 320,444 319,472 313,493 310,497 301,523 301,539 304,545 309,545 314,542 317,539 319,532 323,528 325,529 331,523 337,514 343,502 347,489 355,476 360,456 361,441 364,430 369,420 371,410 370,402 361,402'/>
                                                <polygon points='608,224 604,226 601,225 601,235 602,236 603,234 605,237 605,250 603,254 602,265 601,306 597,331 595,364 595,402 598,421 600,423 611,420 611,412 619,391 621,370 621,352 618,326 616,230 614,224 608,224'/>
                                                <polygon points='338,418 336,455 338,498 341,494 343,487 348,482 352,464 356,460 358,442 363,431 364,424 362,421 338,418'/>
                                                <polygon points='394,408 388,412 389,417 395,418 399,416 398,409 394,408'/>
                                                <polygon points='377,406 373,422 371,445 368,450 364,473 359,479 358,486 353,497 353,500 356,504 356,509 352,512 356,513 357,535 359,539 363,535 366,535 374,526 383,502 388,499 394,487 402,482 405,478 406,449 396,443 396,436 388,435 385,437 386,443 384,445 380,444 380,436 378,429 379,409 377,406'/>
                                                <polygon points='197,626 178,633 170,640 166,649 166,656 168,661 170,665 175,669 199,671 210,677 221,679 239,687 247,695 249,702 248,709 240,724 240,733 255,733 265,715 273,707 277,705 284,704 292,709 294,708 293,703 287,701 287,693 284,690 282,684 264,663 224,632 212,627 197,626'/>
                                                <polygon points='104,640 93,646 89,651 89,659 96,668 101,670 116,671 128,675 139,689 148,693 152,698 160,697 164,688 176,680 176,674 169,666 165,658 153,648 142,644 122,640 104,640'/>
                                                <polygon points='198,627 174,638 168,644 167,660 173,668 200,671 208,676 222,679 238,685 268,685 277,682 275,675 269,669 227,634 214,627 198,627'/>
                                                <polygon points='391,218 389,229 389,268 377,291 376,300 378,304 381,304 386,298 387,278 389,275 393,255 393,243 396,242 396,239 394,241 392,236 391,218'/>
                                                <polygon points='418,159 416,216 414,221 413,222 411,220 406,227 405,230 408,231 412,236 410,241 406,245 406,249 403,255 398,260 398,265 405,266 411,270 413,299 415,299 416,291 416,267 419,261 424,261 426,264 434,264 432,246 430,255 427,255 424,258 421,258 417,255 417,237 420,225 422,206 424,200 427,197 429,197 431,193 428,174 418,159'/>
                                                <polygon points='1447,718 1446,724 1443,727 1441,727 1433,719 1427,722 1427,726 1432,734 1426,739 1423,746 1423,755 1425,761 1442,761 1447,754 1453,750 1456,742 1464,736 1465,730 1451,729 1449,727 1449,718 1447,718'/>
                                                <polygon points='1076,372 1077,378 1079,379 1080,373 1078,371 1076,372'/>
                                                <polygon points='1105,321 1101,326 1101,342 1099,356 1100,362 1104,366 1112,358 1114,349 1112,332 1105,321'/>
                                                <polygon points='1027,186 1022,188 1015,211 1013,228 1013,253 1023,286 1030,297 1034,309 1035,318 1041,325 1049,366 1057,374 1059,379 1063,383 1072,385 1078,380 1084,378 1089,367 1088,339 1086,335 1087,293 1085,279 1075,272 1075,264 1072,258 1071,249 1064,244 1057,245 1051,240 1051,236 1046,223 1046,217 1042,210 1041,202 1036,187 1027,186'/>
                                                <polygon points='1275,706 1269,712 1270,723 1288,747 1292,749 1300,748 1300,741 1294,728 1297,721 1296,717 1290,711 1280,706 1275,706'/>

                  </svg>
                </div>
                <div class="svg_box no-scrollbar">
                  <svg class="image_with_polygon resizable" id="ct_auto_pano_svg" viewBox="0 0 1504 768"
                    preserveAspectRatio="xMidYMid">
                                                <image class="img" xlink:href="/images/body_img.png" width="100%" height="100%"></image>
                                                <polygon points='914,466 888,482 886,488 887,501 905,530 909,547 913,554 915,566 919,573 922,586 928,596 929,603 934,612 935,619 942,637 957,659 965,667 978,668 980,666 981,660 980,647 967,598 951,524 946,510 935,489 934,482 930,472 923,466 914,466'/>
                                                <polygon points='399,377 388,383 378,401 375,417 372,423 365,465 357,480 341,520 340,526 336,532 335,539 330,548 328,563 333,572 338,571 348,562 353,556 356,548 375,524 378,516 384,509 387,502 399,493 405,493 410,498 414,498 416,502 415,507 410,511 409,514 409,530 416,531 419,533 425,528 430,519 433,507 441,493 446,476 457,456 457,453 466,435 467,403 464,399 441,396 436,392 427,389 421,384 404,377 399,377'/>                                            <polygon points='304,352 292,361 288,370 285,393 283,438 265,486 264,514 266,521 269,523 276,522 282,517 288,505 297,496 308,469 316,461 325,460 328,464 326,490 321,492 321,501 319,506 321,515 323,509 325,507 329,507 332,499 337,498 338,494 346,486 357,465 357,461 361,453 362,442 366,427 374,403 373,369 362,360 353,357 346,357 337,361 332,361 317,352 304,352'/>
                                                <polygon points='790,501 785,503 776,511 774,511 772,517 771,552 777,605 779,642 784,671 790,688 793,690 803,690 807,687 806,603 810,518 806,502 790,501'/>
                                                <polygon points='988,440 981,445 970,448 963,454 938,455 936,458 936,486 951,516 964,536 967,546 975,558 979,574 985,587 987,600 998,624 1012,644 1018,650 1025,651 1028,649 1029,646 1014,607 1009,581 1007,577 1007,560 1010,557 1013,555 1017,557 1026,566 1030,576 1035,583 1037,592 1045,605 1048,613 1056,626 1061,631 1069,634 1073,630 1075,623 1074,615 1067,602 1055,565 1051,558 1050,552 1046,545 1044,536 1036,518 1031,500 1030,487 1021,456 1013,446 998,440 988,440'/>
                                                <polygon points='1088,407 1082,412 1075,415 1067,422 1060,423 1050,429 1037,431 1027,441 1026,458 1032,478 1038,490 1054,510 1067,539 1076,554 1077,561 1087,579 1101,597 1104,603 1110,605 1115,611 1119,611 1123,619 1130,619 1131,617 1124,611 1122,611 1120,608 1120,602 1114,583 1110,576 1104,556 1098,545 1098,525 1104,521 1112,527 1137,567 1150,593 1159,600 1162,600 1166,596 1166,571 1158,554 1156,543 1151,530 1138,504 1135,490 1128,472 1126,461 1118,443 1112,424 1106,414 1099,408 1088,407'/>
                                                <polygon points='1037,203 1035,209 1032,212 1028,213 1028,275 1039,322 1040,337 1043,351 1052,374 1053,380 1057,386 1084,387 1087,386 1099,373 1104,371 1110,364 1114,355 1114,339 1112,331 1108,319 1095,292 1089,272 1083,259 1076,250 1073,240 1068,235 1066,229 1063,229 1060,226 1056,229 1053,226 1050,210 1046,208 1041,208 1037,203'/>
                                                <polygon points='601,450 595,455 589,466 587,474 584,479 579,506 574,560 573,605 571,627 571,646 574,652 583,652 587,648 591,638 593,627 603,607 613,542 625,503 625,480 620,464 615,456 604,450 601,450'/>
                                                <polygon points='876,491 871,494 866,500 860,515 858,528 860,547 874,594 900,650 904,655 906,661 911,666 914,675 918,679 927,680 929,678 931,671 931,654 929,639 921,614 907,546 904,542 897,517 890,503 881,492 876,491'/>
                                                <polygon points='1180,523 1179,526 1181,527 1180,523'/>
                                                <polygon points='1181,384 1177,388 1177,395 1180,399 1183,395 1183,387 1181,384'/>
                                                <polygon points='1169,376 1161,382 1159,387 1158,439 1161,454 1166,463 1169,477 1174,489 1174,493 1177,496 1177,488 1183,483 1186,483 1202,502 1213,518 1219,522 1226,523 1236,512 1236,505 1219,471 1214,457 1208,433 1204,400 1198,381 1194,378 1178,380 1172,376 1169,376'/>
                                                <polygon points='969,203 965,210 964,218 965,264 968,306 968,386 974,402 979,410 988,418 1010,418 1014,412 1020,407 1022,407 1024,410 1028,412 1034,413 1040,410 1047,396 1047,373 1045,365 1030,325 1023,296 1020,291 1018,283 1012,273 1011,268 1003,257 996,243 987,217 975,203 969,203'/>
                                                <polygon points='1083,178 1079,185 1077,197 1079,219 1084,237 1095,262 1097,278 1104,292 1109,297 1110,320 1113,325 1114,333 1117,338 1119,347 1126,355 1133,360 1142,360 1148,355 1160,335 1161,331 1174,314 1175,298 1171,289 1163,279 1152,259 1147,253 1138,236 1127,207 1119,196 1115,202 1112,198 1110,212 1108,215 1105,214 1101,179 1083,178'/>
                                                <polygon points='769,266 765,268 761,274 753,300 753,318 750,338 748,374 739,413 737,435 734,449 735,477 741,484 745,485 777,485 784,478 790,467 791,462 791,439 789,430 789,414 786,390 786,365 788,350 788,300 786,282 783,271 780,266 769,266'/>
                                                <polygon points='452,163 445,165 417,230 395,264 376,314 376,327 379,335 392,344 409,365 420,365 424,363 432,356 436,350 452,298 456,261 465,217 464,164 452,163'/>
                                                <polygon points='387,145 384,146 380,151 380,155 384,159 383,162 380,165 378,165 373,172 368,183 355,203 348,219 329,247 320,257 317,265 317,270 320,279 329,290 344,318 347,321 353,323 359,323 367,320 374,312 384,288 386,276 399,253 409,227 414,185 413,156 408,150 402,149 398,146 387,145'/>
                                                <polygon points='935,219 929,222 925,234 925,317 923,334 921,401 931,425 934,428 943,431 951,441 955,443 960,438 967,436 969,432 974,428 975,411 973,399 970,392 965,362 956,261 950,244 946,226 944,222 935,219'/>
                                                <polygon points='627,215 623,217 621,229 619,225 617,228 616,227 613,218 611,218 610,267 608,277 605,283 602,303 601,322 597,344 595,379 595,407 598,423 601,433 605,438 605,441 615,450 620,450 631,443 636,433 649,363 649,320 644,253 635,218 632,215 627,215'/>
                                                <polygon points='710,510 702,512 700,521 700,586 703,611 708,635 716,652 719,655 723,655 727,649 730,633 730,573 733,523 730,511 710,510'/>
                                                <polygon points='499,408 477,417 471,425 463,450 461,481 458,487 456,498 453,503 452,510 447,517 435,548 429,570 424,577 422,585 417,595 416,601 420,605 430,601 445,586 464,556 474,532 490,515 497,513 500,516 500,525 502,532 501,541 503,545 512,542 516,532 522,527 526,507 537,484 539,475 547,455 547,428 546,425 540,425 537,427 532,426 526,422 523,417 514,409 499,408'/>
                                                <polygon points='714,261 711,263 708,271 696,325 693,364 680,419 679,448 683,461 688,469 698,470 708,476 721,476 726,474 730,469 733,450 733,358 735,302 734,283 730,267 726,263 714,261'/>
                                                <polygon points='505,176 501,179 499,183 492,213 480,244 476,246 470,255 467,256 462,269 458,283 454,307 449,320 448,329 443,344 444,377 449,381 467,379 471,382 479,393 483,394 494,393 499,388 505,385 512,375 517,362 521,341 522,318 520,306 520,248 523,229 523,180 521,177 518,176 505,176'/>
                                                <polygon points='847,683 848,690 850,685 848,682 847,683'/>
                                                <polygon points='825,494 823,495 820,501 817,516 813,525 813,561 831,633 837,668 845,687 846,696 849,698 850,701 850,711 859,719 865,714 868,708 873,682 874,655 868,599 863,569 846,501 844,497 840,495 831,496 825,494'/>
                                                <polygon points='895,253 889,256 889,267 891,275 891,311 889,316 889,350 887,384 884,403 884,431 886,433 891,434 894,437 898,447 903,454 910,460 915,460 925,447 929,432 928,399 921,360 918,261 917,256 895,253'/>
                                                <polygon points='566,439 560,442 557,452 547,470 542,486 539,509 535,523 533,548 527,587 524,595 518,627 518,645 520,648 526,647 537,637 541,627 547,620 556,600 569,559 579,488 585,472 594,459 594,449 587,442 581,439 566,439'/>
                                                <polygon points='590,243 588,244 586,251 586,260 580,284 578,285 577,283 576,285 574,282 572,285 571,284 570,287 564,322 563,340 561,344 561,352 556,377 556,397 559,411 569,426 579,426 581,424 590,412 594,404 596,395 596,335 599,284 599,249 596,243 590,243'/>
                                                <polygon points='669,486 666,488 663,503 663,525 665,538 665,609 668,630 669,655 674,681 678,686 682,686 685,685 690,678 694,652 696,574 699,541 699,498 698,494 690,486 669,486'/>
                                                <polygon points='813,254 808,258 806,262 801,287 799,328 797,340 796,389 791,443 791,469 797,478 804,479 816,478 822,471 825,470 832,458 833,445 828,392 828,353 824,297 821,281 820,263 817,255 813,254'/>
                                                <polygon points='855,227 852,229 849,239 847,260 839,305 837,350 833,384 833,407 839,442 846,467 859,485 865,485 869,480 871,480 873,476 875,463 883,437 882,415 880,406 880,361 877,353 877,342 875,335 878,283 875,259 870,237 865,228 855,227'/>
                                                <polygon points='540,200 534,202 532,206 530,226 531,258 528,263 529,283 527,304 513,356 510,377 511,392 513,398 517,400 523,407 526,408 532,414 540,414 546,411 555,397 562,373 565,333 564,268 566,254 567,203 563,200 540,200'/>
                                                <polygon points='645,465 638,467 634,470 626,489 623,511 615,546 613,571 613,612 615,639 621,665 625,694 628,702 631,705 638,705 642,703 646,691 655,646 661,582 661,517 657,499 654,472 650,466 645,465'/>
                                                <polygon points='532,454 523,456 520,494 516,496 513,491 511,492 504,500 502,507 499,511 496,521 496,527 492,537 484,568 477,584 467,599 461,612 461,630 463,632 473,630 480,624 487,611 497,602 503,594 513,576 523,542 523,537 532,498 538,483 543,462 542,455 532,454'/>
                                                <polygon points='671,239 667,243 665,261 659,287 654,323 650,377 644,406 644,429 646,440 653,449 657,450 661,455 668,457 673,456 683,446 686,438 685,379 688,303 687,272 682,244 681,241 677,239 671,239'/>
                                                <polygon points='737,507 734,512 734,540 740,613 747,642 751,668 753,672 760,673 763,670 767,654 766,571 769,544 773,529 773,509 769,507 737,507'/>
                                                <polygon points='452,423 448,425 449,431 439,437 438,471 436,474 433,474 429,471 420,476 413,477 410,475 399,473 395,478 392,478 392,484 395,479 396,485 400,491 410,488 412,490 412,493 410,504 405,513 403,523 396,533 384,562 375,578 373,585 373,595 376,599 385,598 393,591 400,573 412,561 419,551 427,534 436,505 443,491 449,471 463,438 463,423 452,423'/>
                                                <polygon points='1162,454 1163,463 1164,454 1162,454'/>
                                                <polygon points='1126,388 1119,395 1114,405 1113,420 1116,431 1120,440 1136,462 1137,468 1141,473 1143,482 1147,489 1148,496 1154,512 1163,528 1166,530 1172,541 1183,551 1192,551 1192,530 1178,502 1178,498 1177,497 1175,500 1172,496 1171,491 1169,474 1169,401 1166,402 1163,410 1159,401 1149,389 1126,388'/>
                                                <polygon points='1490,634 1480,639 1469,653 1462,658 1446,665 1442,670 1441,682 1443,690 1457,707 1489,706 1495,703 1500,697 1502,687 1502,635 1490,634'/>
                                                <polygon points='873,300 870,320 867,404 869,419 874,434 877,439 880,439 885,431 884,311 882,301 873,300'/>
                                                <polygon points='361,402 352,403 351,430 348,439 346,439 344,426 343,431 339,434 334,444 334,448 331,452 327,445 320,444 319,472 313,493 310,497 301,523 301,539 304,545 309,545 314,542 317,539 319,532 323,528 325,529 331,523 337,514 343,502 347,489 355,476 360,456 361,441 364,430 369,420 371,410 370,402 361,402'/>
                                                <polygon points='608,224 604,226 601,225 601,235 602,236 603,234 605,237 605,250 603,254 602,265 601,306 597,331 595,364 595,402 598,421 600,423 611,420 611,412 619,391 621,370 621,352 618,326 616,230 614,224 608,224'/>
                                                <polygon points='338,418 336,455 338,498 341,494 343,487 348,482 352,464 356,460 358,442 363,431 364,424 362,421 338,418'/>
                                                <polygon points='394,408 388,412 389,417 395,418 399,416 398,409 394,408'/>
                                                <polygon points='377,406 373,422 371,445 368,450 364,473 359,479 358,486 353,497 353,500 356,504 356,509 352,512 356,513 357,535 359,539 363,535 366,535 374,526 383,502 388,499 394,487 402,482 405,478 406,449 396,443 396,436 388,435 385,437 386,443 384,445 380,444 380,436 378,429 379,409 377,406'/>
                                                <polygon points='197,626 178,633 170,640 166,649 166,656 168,661 170,665 175,669 199,671 210,677 221,679 239,687 247,695 249,702 248,709 240,724 240,733 255,733 265,715 273,707 277,705 284,704 292,709 294,708 293,703 287,701 287,693 284,690 282,684 264,663 224,632 212,627 197,626'/>
                                                <polygon points='104,640 93,646 89,651 89,659 96,668 101,670 116,671 128,675 139,689 148,693 152,698 160,697 164,688 176,680 176,674 169,666 165,658 153,648 142,644 122,640 104,640'/>
                                                <polygon points='198,627 174,638 168,644 167,660 173,668 200,671 208,676 222,679 238,685 268,685 277,682 275,675 269,669 227,634 214,627 198,627'/>
                                                <polygon points='391,218 389,229 389,268 377,291 376,300 378,304 381,304 386,298 387,278 389,275 393,255 393,243 396,242 396,239 394,241 392,236 391,218'/>
                                                <polygon points='418,159 416,216 414,221 413,222 411,220 406,227 405,230 408,231 412,236 410,241 406,245 406,249 403,255 398,260 398,265 405,266 411,270 413,299 415,299 416,291 416,267 419,261 424,261 426,264 434,264 432,246 430,255 427,255 424,258 421,258 417,255 417,237 420,225 422,206 424,200 427,197 429,197 431,193 428,174 418,159'/>
                                                <polygon points='1447,718 1446,724 1443,727 1441,727 1433,719 1427,722 1427,726 1432,734 1426,739 1423,746 1423,755 1425,761 1442,761 1447,754 1453,750 1456,742 1464,736 1465,730 1451,729 1449,727 1449,718 1447,718'/>
                                                <polygon points='1076,372 1077,378 1079,379 1080,373 1078,371 1076,372'/>
                                                <polygon points='1105,321 1101,326 1101,342 1099,356 1100,362 1104,366 1112,358 1114,349 1112,332 1105,321'/>
                                                <polygon points='1027,186 1022,188 1015,211 1013,228 1013,253 1023,286 1030,297 1034,309 1035,318 1041,325 1049,366 1057,374 1059,379 1063,383 1072,385 1078,380 1084,378 1089,367 1088,339 1086,335 1087,293 1085,279 1075,272 1075,264 1072,258 1071,249 1064,244 1057,245 1051,240 1051,236 1046,223 1046,217 1042,210 1041,202 1036,187 1027,186'/>
                                                <polygon points='1275,706 1269,712 1270,723 1288,747 1292,749 1300,748 1300,741 1294,728 1297,721 1296,717 1290,711 1280,706 1275,706'/>

                  </svg>
                </div>
                <div class="svg_box no-scrollbar">
                  <svg class="image_with_polygon" id="ct_sagittal_svg" viewBox="0 0 1504 768"
                    preserveAspectRatio="xMidYMid">
                                                <image class="img" xlink:href="/images/body_img.png" width="100%" height="100%"></image>
                                                <polygon points='914,466 888,482 886,488 887,501 905,530 909,547 913,554 915,566 919,573 922,586 928,596 929,603 934,612 935,619 942,637 957,659 965,667 978,668 980,666 981,660 980,647 967,598 951,524 946,510 935,489 934,482 930,472 923,466 914,466'/>
                                                <polygon points='399,377 388,383 378,401 375,417 372,423 365,465 357,480 341,520 340,526 336,532 335,539 330,548 328,563 333,572 338,571 348,562 353,556 356,548 375,524 378,516 384,509 387,502 399,493 405,493 410,498 414,498 416,502 415,507 410,511 409,514 409,530 416,531 419,533 425,528 430,519 433,507 441,493 446,476 457,456 457,453 466,435 467,403 464,399 441,396 436,392 427,389 421,384 404,377 399,377'/>                                            <polygon points='304,352 292,361 288,370 285,393 283,438 265,486 264,514 266,521 269,523 276,522 282,517 288,505 297,496 308,469 316,461 325,460 328,464 326,490 321,492 321,501 319,506 321,515 323,509 325,507 329,507 332,499 337,498 338,494 346,486 357,465 357,461 361,453 362,442 366,427 374,403 373,369 362,360 353,357 346,357 337,361 332,361 317,352 304,352'/>
                                                <polygon points='790,501 785,503 776,511 774,511 772,517 771,552 777,605 779,642 784,671 790,688 793,690 803,690 807,687 806,603 810,518 806,502 790,501'/>
                                                <polygon points='988,440 981,445 970,448 963,454 938,455 936,458 936,486 951,516 964,536 967,546 975,558 979,574 985,587 987,600 998,624 1012,644 1018,650 1025,651 1028,649 1029,646 1014,607 1009,581 1007,577 1007,560 1010,557 1013,555 1017,557 1026,566 1030,576 1035,583 1037,592 1045,605 1048,613 1056,626 1061,631 1069,634 1073,630 1075,623 1074,615 1067,602 1055,565 1051,558 1050,552 1046,545 1044,536 1036,518 1031,500 1030,487 1021,456 1013,446 998,440 988,440'/>
                                                <polygon points='1088,407 1082,412 1075,415 1067,422 1060,423 1050,429 1037,431 1027,441 1026,458 1032,478 1038,490 1054,510 1067,539 1076,554 1077,561 1087,579 1101,597 1104,603 1110,605 1115,611 1119,611 1123,619 1130,619 1131,617 1124,611 1122,611 1120,608 1120,602 1114,583 1110,576 1104,556 1098,545 1098,525 1104,521 1112,527 1137,567 1150,593 1159,600 1162,600 1166,596 1166,571 1158,554 1156,543 1151,530 1138,504 1135,490 1128,472 1126,461 1118,443 1112,424 1106,414 1099,408 1088,407'/>
                                                <polygon points='1037,203 1035,209 1032,212 1028,213 1028,275 1039,322 1040,337 1043,351 1052,374 1053,380 1057,386 1084,387 1087,386 1099,373 1104,371 1110,364 1114,355 1114,339 1112,331 1108,319 1095,292 1089,272 1083,259 1076,250 1073,240 1068,235 1066,229 1063,229 1060,226 1056,229 1053,226 1050,210 1046,208 1041,208 1037,203'/>
                                                <polygon points='601,450 595,455 589,466 587,474 584,479 579,506 574,560 573,605 571,627 571,646 574,652 583,652 587,648 591,638 593,627 603,607 613,542 625,503 625,480 620,464 615,456 604,450 601,450'/>
                                                <polygon points='876,491 871,494 866,500 860,515 858,528 860,547 874,594 900,650 904,655 906,661 911,666 914,675 918,679 927,680 929,678 931,671 931,654 929,639 921,614 907,546 904,542 897,517 890,503 881,492 876,491'/>
                                                <polygon points='1180,523 1179,526 1181,527 1180,523'/>
                                                <polygon points='1181,384 1177,388 1177,395 1180,399 1183,395 1183,387 1181,384'/>
                                                <polygon points='1169,376 1161,382 1159,387 1158,439 1161,454 1166,463 1169,477 1174,489 1174,493 1177,496 1177,488 1183,483 1186,483 1202,502 1213,518 1219,522 1226,523 1236,512 1236,505 1219,471 1214,457 1208,433 1204,400 1198,381 1194,378 1178,380 1172,376 1169,376'/>
                                                <polygon points='969,203 965,210 964,218 965,264 968,306 968,386 974,402 979,410 988,418 1010,418 1014,412 1020,407 1022,407 1024,410 1028,412 1034,413 1040,410 1047,396 1047,373 1045,365 1030,325 1023,296 1020,291 1018,283 1012,273 1011,268 1003,257 996,243 987,217 975,203 969,203'/>
                                                <polygon points='1083,178 1079,185 1077,197 1079,219 1084,237 1095,262 1097,278 1104,292 1109,297 1110,320 1113,325 1114,333 1117,338 1119,347 1126,355 1133,360 1142,360 1148,355 1160,335 1161,331 1174,314 1175,298 1171,289 1163,279 1152,259 1147,253 1138,236 1127,207 1119,196 1115,202 1112,198 1110,212 1108,215 1105,214 1101,179 1083,178'/>
                                                <polygon points='769,266 765,268 761,274 753,300 753,318 750,338 748,374 739,413 737,435 734,449 735,477 741,484 745,485 777,485 784,478 790,467 791,462 791,439 789,430 789,414 786,390 786,365 788,350 788,300 786,282 783,271 780,266 769,266'/>
                                                <polygon points='452,163 445,165 417,230 395,264 376,314 376,327 379,335 392,344 409,365 420,365 424,363 432,356 436,350 452,298 456,261 465,217 464,164 452,163'/>
                                                <polygon points='387,145 384,146 380,151 380,155 384,159 383,162 380,165 378,165 373,172 368,183 355,203 348,219 329,247 320,257 317,265 317,270 320,279 329,290 344,318 347,321 353,323 359,323 367,320 374,312 384,288 386,276 399,253 409,227 414,185 413,156 408,150 402,149 398,146 387,145'/>
                                                <polygon points='935,219 929,222 925,234 925,317 923,334 921,401 931,425 934,428 943,431 951,441 955,443 960,438 967,436 969,432 974,428 975,411 973,399 970,392 965,362 956,261 950,244 946,226 944,222 935,219'/>
                                                <polygon points='627,215 623,217 621,229 619,225 617,228 616,227 613,218 611,218 610,267 608,277 605,283 602,303 601,322 597,344 595,379 595,407 598,423 601,433 605,438 605,441 615,450 620,450 631,443 636,433 649,363 649,320 644,253 635,218 632,215 627,215'/>
                                                <polygon points='710,510 702,512 700,521 700,586 703,611 708,635 716,652 719,655 723,655 727,649 730,633 730,573 733,523 730,511 710,510'/>
                                                <polygon points='499,408 477,417 471,425 463,450 461,481 458,487 456,498 453,503 452,510 447,517 435,548 429,570 424,577 422,585 417,595 416,601 420,605 430,601 445,586 464,556 474,532 490,515 497,513 500,516 500,525 502,532 501,541 503,545 512,542 516,532 522,527 526,507 537,484 539,475 547,455 547,428 546,425 540,425 537,427 532,426 526,422 523,417 514,409 499,408'/>
                                                <polygon points='714,261 711,263 708,271 696,325 693,364 680,419 679,448 683,461 688,469 698,470 708,476 721,476 726,474 730,469 733,450 733,358 735,302 734,283 730,267 726,263 714,261'/>
                                                <polygon points='505,176 501,179 499,183 492,213 480,244 476,246 470,255 467,256 462,269 458,283 454,307 449,320 448,329 443,344 444,377 449,381 467,379 471,382 479,393 483,394 494,393 499,388 505,385 512,375 517,362 521,341 522,318 520,306 520,248 523,229 523,180 521,177 518,176 505,176'/>
                                                <polygon points='847,683 848,690 850,685 848,682 847,683'/>
                                                <polygon points='825,494 823,495 820,501 817,516 813,525 813,561 831,633 837,668 845,687 846,696 849,698 850,701 850,711 859,719 865,714 868,708 873,682 874,655 868,599 863,569 846,501 844,497 840,495 831,496 825,494'/>
                                                <polygon points='895,253 889,256 889,267 891,275 891,311 889,316 889,350 887,384 884,403 884,431 886,433 891,434 894,437 898,447 903,454 910,460 915,460 925,447 929,432 928,399 921,360 918,261 917,256 895,253'/>
                                                <polygon points='566,439 560,442 557,452 547,470 542,486 539,509 535,523 533,548 527,587 524,595 518,627 518,645 520,648 526,647 537,637 541,627 547,620 556,600 569,559 579,488 585,472 594,459 594,449 587,442 581,439 566,439'/>
                                                <polygon points='590,243 588,244 586,251 586,260 580,284 578,285 577,283 576,285 574,282 572,285 571,284 570,287 564,322 563,340 561,344 561,352 556,377 556,397 559,411 569,426 579,426 581,424 590,412 594,404 596,395 596,335 599,284 599,249 596,243 590,243'/>
                                                <polygon points='669,486 666,488 663,503 663,525 665,538 665,609 668,630 669,655 674,681 678,686 682,686 685,685 690,678 694,652 696,574 699,541 699,498 698,494 690,486 669,486'/>
                                                <polygon points='813,254 808,258 806,262 801,287 799,328 797,340 796,389 791,443 791,469 797,478 804,479 816,478 822,471 825,470 832,458 833,445 828,392 828,353 824,297 821,281 820,263 817,255 813,254'/>
                                                <polygon points='855,227 852,229 849,239 847,260 839,305 837,350 833,384 833,407 839,442 846,467 859,485 865,485 869,480 871,480 873,476 875,463 883,437 882,415 880,406 880,361 877,353 877,342 875,335 878,283 875,259 870,237 865,228 855,227'/>
                                                <polygon points='540,200 534,202 532,206 530,226 531,258 528,263 529,283 527,304 513,356 510,377 511,392 513,398 517,400 523,407 526,408 532,414 540,414 546,411 555,397 562,373 565,333 564,268 566,254 567,203 563,200 540,200'/>
                                                <polygon points='645,465 638,467 634,470 626,489 623,511 615,546 613,571 613,612 615,639 621,665 625,694 628,702 631,705 638,705 642,703 646,691 655,646 661,582 661,517 657,499 654,472 650,466 645,465'/>
                                                <polygon points='532,454 523,456 520,494 516,496 513,491 511,492 504,500 502,507 499,511 496,521 496,527 492,537 484,568 477,584 467,599 461,612 461,630 463,632 473,630 480,624 487,611 497,602 503,594 513,576 523,542 523,537 532,498 538,483 543,462 542,455 532,454'/>
                                                <polygon points='671,239 667,243 665,261 659,287 654,323 650,377 644,406 644,429 646,440 653,449 657,450 661,455 668,457 673,456 683,446 686,438 685,379 688,303 687,272 682,244 681,241 677,239 671,239'/>
                                                <polygon points='737,507 734,512 734,540 740,613 747,642 751,668 753,672 760,673 763,670 767,654 766,571 769,544 773,529 773,509 769,507 737,507'/>
                                                <polygon points='452,423 448,425 449,431 439,437 438,471 436,474 433,474 429,471 420,476 413,477 410,475 399,473 395,478 392,478 392,484 395,479 396,485 400,491 410,488 412,490 412,493 410,504 405,513 403,523 396,533 384,562 375,578 373,585 373,595 376,599 385,598 393,591 400,573 412,561 419,551 427,534 436,505 443,491 449,471 463,438 463,423 452,423'/>
                                                <polygon points='1162,454 1163,463 1164,454 1162,454'/>
                                                <polygon points='1126,388 1119,395 1114,405 1113,420 1116,431 1120,440 1136,462 1137,468 1141,473 1143,482 1147,489 1148,496 1154,512 1163,528 1166,530 1172,541 1183,551 1192,551 1192,530 1178,502 1178,498 1177,497 1175,500 1172,496 1171,491 1169,474 1169,401 1166,402 1163,410 1159,401 1149,389 1126,388'/>
                                                <polygon points='1490,634 1480,639 1469,653 1462,658 1446,665 1442,670 1441,682 1443,690 1457,707 1489,706 1495,703 1500,697 1502,687 1502,635 1490,634'/>
                                                <polygon points='873,300 870,320 867,404 869,419 874,434 877,439 880,439 885,431 884,311 882,301 873,300'/>
                                                <polygon points='361,402 352,403 351,430 348,439 346,439 344,426 343,431 339,434 334,444 334,448 331,452 327,445 320,444 319,472 313,493 310,497 301,523 301,539 304,545 309,545 314,542 317,539 319,532 323,528 325,529 331,523 337,514 343,502 347,489 355,476 360,456 361,441 364,430 369,420 371,410 370,402 361,402'/>
                                                <polygon points='608,224 604,226 601,225 601,235 602,236 603,234 605,237 605,250 603,254 602,265 601,306 597,331 595,364 595,402 598,421 600,423 611,420 611,412 619,391 621,370 621,352 618,326 616,230 614,224 608,224'/>
                                                <polygon points='338,418 336,455 338,498 341,494 343,487 348,482 352,464 356,460 358,442 363,431 364,424 362,421 338,418'/>
                                                <polygon points='394,408 388,412 389,417 395,418 399,416 398,409 394,408'/>
                                                <polygon points='377,406 373,422 371,445 368,450 364,473 359,479 358,486 353,497 353,500 356,504 356,509 352,512 356,513 357,535 359,539 363,535 366,535 374,526 383,502 388,499 394,487 402,482 405,478 406,449 396,443 396,436 388,435 385,437 386,443 384,445 380,444 380,436 378,429 379,409 377,406'/>
                                                <polygon points='197,626 178,633 170,640 166,649 166,656 168,661 170,665 175,669 199,671 210,677 221,679 239,687 247,695 249,702 248,709 240,724 240,733 255,733 265,715 273,707 277,705 284,704 292,709 294,708 293,703 287,701 287,693 284,690 282,684 264,663 224,632 212,627 197,626'/>
                                                <polygon points='104,640 93,646 89,651 89,659 96,668 101,670 116,671 128,675 139,689 148,693 152,698 160,697 164,688 176,680 176,674 169,666 165,658 153,648 142,644 122,640 104,640'/>
                                                <polygon points='198,627 174,638 168,644 167,660 173,668 200,671 208,676 222,679 238,685 268,685 277,682 275,675 269,669 227,634 214,627 198,627'/>
                                                <polygon points='391,218 389,229 389,268 377,291 376,300 378,304 381,304 386,298 387,278 389,275 393,255 393,243 396,242 396,239 394,241 392,236 391,218'/>
                                                <polygon points='418,159 416,216 414,221 413,222 411,220 406,227 405,230 408,231 412,236 410,241 406,245 406,249 403,255 398,260 398,265 405,266 411,270 413,299 415,299 416,291 416,267 419,261 424,261 426,264 434,264 432,246 430,255 427,255 424,258 421,258 417,255 417,237 420,225 422,206 424,200 427,197 429,197 431,193 428,174 418,159'/>
                                                <polygon points='1447,718 1446,724 1443,727 1441,727 1433,719 1427,722 1427,726 1432,734 1426,739 1423,746 1423,755 1425,761 1442,761 1447,754 1453,750 1456,742 1464,736 1465,730 1451,729 1449,727 1449,718 1447,718'/>
                                                <polygon points='1076,372 1077,378 1079,379 1080,373 1078,371 1076,372'/>
                                                <polygon points='1105,321 1101,326 1101,342 1099,356 1100,362 1104,366 1112,358 1114,349 1112,332 1105,321'/>
                                                <polygon points='1027,186 1022,188 1015,211 1013,228 1013,253 1023,286 1030,297 1034,309 1035,318 1041,325 1049,366 1057,374 1059,379 1063,383 1072,385 1078,380 1084,378 1089,367 1088,339 1086,335 1087,293 1085,279 1075,272 1075,264 1072,258 1071,249 1064,244 1057,245 1051,240 1051,236 1046,223 1046,217 1042,210 1041,202 1036,187 1027,186'/>
                                                <polygon points='1275,706 1269,712 1270,723 1288,747 1292,749 1300,748 1300,741 1294,728 1297,721 1296,717 1290,711 1280,706 1275,706'/>

                  </svg>
                </div>
                <div class="svg_box no-scrollbar">
                  <svg class="image_with_polygon" id="ct_coronal_svg" viewBox="0 0 1504 768"
                    preserveAspectRatio="xMidYMid">
                                                <image class="img" xlink:href="/images/body_img.png" width="100%" height="100%"></image>
                                                <polygon points='914,466 888,482 886,488 887,501 905,530 909,547 913,554 915,566 919,573 922,586 928,596 929,603 934,612 935,619 942,637 957,659 965,667 978,668 980,666 981,660 980,647 967,598 951,524 946,510 935,489 934,482 930,472 923,466 914,466'/>
                                                <polygon points='399,377 388,383 378,401 375,417 372,423 365,465 357,480 341,520 340,526 336,532 335,539 330,548 328,563 333,572 338,571 348,562 353,556 356,548 375,524 378,516 384,509 387,502 399,493 405,493 410,498 414,498 416,502 415,507 410,511 409,514 409,530 416,531 419,533 425,528 430,519 433,507 441,493 446,476 457,456 457,453 466,435 467,403 464,399 441,396 436,392 427,389 421,384 404,377 399,377'/>                                            <polygon points='304,352 292,361 288,370 285,393 283,438 265,486 264,514 266,521 269,523 276,522 282,517 288,505 297,496 308,469 316,461 325,460 328,464 326,490 321,492 321,501 319,506 321,515 323,509 325,507 329,507 332,499 337,498 338,494 346,486 357,465 357,461 361,453 362,442 366,427 374,403 373,369 362,360 353,357 346,357 337,361 332,361 317,352 304,352'/>
                                                <polygon points='790,501 785,503 776,511 774,511 772,517 771,552 777,605 779,642 784,671 790,688 793,690 803,690 807,687 806,603 810,518 806,502 790,501'/>
                                                <polygon points='988,440 981,445 970,448 963,454 938,455 936,458 936,486 951,516 964,536 967,546 975,558 979,574 985,587 987,600 998,624 1012,644 1018,650 1025,651 1028,649 1029,646 1014,607 1009,581 1007,577 1007,560 1010,557 1013,555 1017,557 1026,566 1030,576 1035,583 1037,592 1045,605 1048,613 1056,626 1061,631 1069,634 1073,630 1075,623 1074,615 1067,602 1055,565 1051,558 1050,552 1046,545 1044,536 1036,518 1031,500 1030,487 1021,456 1013,446 998,440 988,440'/>
                                                <polygon points='1088,407 1082,412 1075,415 1067,422 1060,423 1050,429 1037,431 1027,441 1026,458 1032,478 1038,490 1054,510 1067,539 1076,554 1077,561 1087,579 1101,597 1104,603 1110,605 1115,611 1119,611 1123,619 1130,619 1131,617 1124,611 1122,611 1120,608 1120,602 1114,583 1110,576 1104,556 1098,545 1098,525 1104,521 1112,527 1137,567 1150,593 1159,600 1162,600 1166,596 1166,571 1158,554 1156,543 1151,530 1138,504 1135,490 1128,472 1126,461 1118,443 1112,424 1106,414 1099,408 1088,407'/>
                                                <polygon points='1037,203 1035,209 1032,212 1028,213 1028,275 1039,322 1040,337 1043,351 1052,374 1053,380 1057,386 1084,387 1087,386 1099,373 1104,371 1110,364 1114,355 1114,339 1112,331 1108,319 1095,292 1089,272 1083,259 1076,250 1073,240 1068,235 1066,229 1063,229 1060,226 1056,229 1053,226 1050,210 1046,208 1041,208 1037,203'/>
                                                <polygon points='601,450 595,455 589,466 587,474 584,479 579,506 574,560 573,605 571,627 571,646 574,652 583,652 587,648 591,638 593,627 603,607 613,542 625,503 625,480 620,464 615,456 604,450 601,450'/>
                                                <polygon points='876,491 871,494 866,500 860,515 858,528 860,547 874,594 900,650 904,655 906,661 911,666 914,675 918,679 927,680 929,678 931,671 931,654 929,639 921,614 907,546 904,542 897,517 890,503 881,492 876,491'/>
                                                <polygon points='1180,523 1179,526 1181,527 1180,523'/>
                                                <polygon points='1181,384 1177,388 1177,395 1180,399 1183,395 1183,387 1181,384'/>
                                                <polygon points='1169,376 1161,382 1159,387 1158,439 1161,454 1166,463 1169,477 1174,489 1174,493 1177,496 1177,488 1183,483 1186,483 1202,502 1213,518 1219,522 1226,523 1236,512 1236,505 1219,471 1214,457 1208,433 1204,400 1198,381 1194,378 1178,380 1172,376 1169,376'/>
                                                <polygon points='969,203 965,210 964,218 965,264 968,306 968,386 974,402 979,410 988,418 1010,418 1014,412 1020,407 1022,407 1024,410 1028,412 1034,413 1040,410 1047,396 1047,373 1045,365 1030,325 1023,296 1020,291 1018,283 1012,273 1011,268 1003,257 996,243 987,217 975,203 969,203'/>
                                                <polygon points='1083,178 1079,185 1077,197 1079,219 1084,237 1095,262 1097,278 1104,292 1109,297 1110,320 1113,325 1114,333 1117,338 1119,347 1126,355 1133,360 1142,360 1148,355 1160,335 1161,331 1174,314 1175,298 1171,289 1163,279 1152,259 1147,253 1138,236 1127,207 1119,196 1115,202 1112,198 1110,212 1108,215 1105,214 1101,179 1083,178'/>
                                                <polygon points='769,266 765,268 761,274 753,300 753,318 750,338 748,374 739,413 737,435 734,449 735,477 741,484 745,485 777,485 784,478 790,467 791,462 791,439 789,430 789,414 786,390 786,365 788,350 788,300 786,282 783,271 780,266 769,266'/>
                                                <polygon points='452,163 445,165 417,230 395,264 376,314 376,327 379,335 392,344 409,365 420,365 424,363 432,356 436,350 452,298 456,261 465,217 464,164 452,163'/>
                                                <polygon points='387,145 384,146 380,151 380,155 384,159 383,162 380,165 378,165 373,172 368,183 355,203 348,219 329,247 320,257 317,265 317,270 320,279 329,290 344,318 347,321 353,323 359,323 367,320 374,312 384,288 386,276 399,253 409,227 414,185 413,156 408,150 402,149 398,146 387,145'/>
                                                <polygon points='935,219 929,222 925,234 925,317 923,334 921,401 931,425 934,428 943,431 951,441 955,443 960,438 967,436 969,432 974,428 975,411 973,399 970,392 965,362 956,261 950,244 946,226 944,222 935,219'/>
                                                <polygon points='627,215 623,217 621,229 619,225 617,228 616,227 613,218 611,218 610,267 608,277 605,283 602,303 601,322 597,344 595,379 595,407 598,423 601,433 605,438 605,441 615,450 620,450 631,443 636,433 649,363 649,320 644,253 635,218 632,215 627,215'/>
                                                <polygon points='710,510 702,512 700,521 700,586 703,611 708,635 716,652 719,655 723,655 727,649 730,633 730,573 733,523 730,511 710,510'/>
                                                <polygon points='499,408 477,417 471,425 463,450 461,481 458,487 456,498 453,503 452,510 447,517 435,548 429,570 424,577 422,585 417,595 416,601 420,605 430,601 445,586 464,556 474,532 490,515 497,513 500,516 500,525 502,532 501,541 503,545 512,542 516,532 522,527 526,507 537,484 539,475 547,455 547,428 546,425 540,425 537,427 532,426 526,422 523,417 514,409 499,408'/>
                                                <polygon points='714,261 711,263 708,271 696,325 693,364 680,419 679,448 683,461 688,469 698,470 708,476 721,476 726,474 730,469 733,450 733,358 735,302 734,283 730,267 726,263 714,261'/>
                                                <polygon points='505,176 501,179 499,183 492,213 480,244 476,246 470,255 467,256 462,269 458,283 454,307 449,320 448,329 443,344 444,377 449,381 467,379 471,382 479,393 483,394 494,393 499,388 505,385 512,375 517,362 521,341 522,318 520,306 520,248 523,229 523,180 521,177 518,176 505,176'/>
                                                <polygon points='847,683 848,690 850,685 848,682 847,683'/>
                                                <polygon points='825,494 823,495 820,501 817,516 813,525 813,561 831,633 837,668 845,687 846,696 849,698 850,701 850,711 859,719 865,714 868,708 873,682 874,655 868,599 863,569 846,501 844,497 840,495 831,496 825,494'/>
                                                <polygon points='895,253 889,256 889,267 891,275 891,311 889,316 889,350 887,384 884,403 884,431 886,433 891,434 894,437 898,447 903,454 910,460 915,460 925,447 929,432 928,399 921,360 918,261 917,256 895,253'/>
                                                <polygon points='566,439 560,442 557,452 547,470 542,486 539,509 535,523 533,548 527,587 524,595 518,627 518,645 520,648 526,647 537,637 541,627 547,620 556,600 569,559 579,488 585,472 594,459 594,449 587,442 581,439 566,439'/>
                                                <polygon points='590,243 588,244 586,251 586,260 580,284 578,285 577,283 576,285 574,282 572,285 571,284 570,287 564,322 563,340 561,344 561,352 556,377 556,397 559,411 569,426 579,426 581,424 590,412 594,404 596,395 596,335 599,284 599,249 596,243 590,243'/>
                                                <polygon points='669,486 666,488 663,503 663,525 665,538 665,609 668,630 669,655 674,681 678,686 682,686 685,685 690,678 694,652 696,574 699,541 699,498 698,494 690,486 669,486'/>
                                                <polygon points='813,254 808,258 806,262 801,287 799,328 797,340 796,389 791,443 791,469 797,478 804,479 816,478 822,471 825,470 832,458 833,445 828,392 828,353 824,297 821,281 820,263 817,255 813,254'/>
                                                <polygon points='855,227 852,229 849,239 847,260 839,305 837,350 833,384 833,407 839,442 846,467 859,485 865,485 869,480 871,480 873,476 875,463 883,437 882,415 880,406 880,361 877,353 877,342 875,335 878,283 875,259 870,237 865,228 855,227'/>
                                                <polygon points='540,200 534,202 532,206 530,226 531,258 528,263 529,283 527,304 513,356 510,377 511,392 513,398 517,400 523,407 526,408 532,414 540,414 546,411 555,397 562,373 565,333 564,268 566,254 567,203 563,200 540,200'/>
                                                <polygon points='645,465 638,467 634,470 626,489 623,511 615,546 613,571 613,612 615,639 621,665 625,694 628,702 631,705 638,705 642,703 646,691 655,646 661,582 661,517 657,499 654,472 650,466 645,465'/>
                                                <polygon points='532,454 523,456 520,494 516,496 513,491 511,492 504,500 502,507 499,511 496,521 496,527 492,537 484,568 477,584 467,599 461,612 461,630 463,632 473,630 480,624 487,611 497,602 503,594 513,576 523,542 523,537 532,498 538,483 543,462 542,455 532,454'/>
                                                <polygon points='671,239 667,243 665,261 659,287 654,323 650,377 644,406 644,429 646,440 653,449 657,450 661,455 668,457 673,456 683,446 686,438 685,379 688,303 687,272 682,244 681,241 677,239 671,239'/>
                                                <polygon points='737,507 734,512 734,540 740,613 747,642 751,668 753,672 760,673 763,670 767,654 766,571 769,544 773,529 773,509 769,507 737,507'/>
                                                <polygon points='452,423 448,425 449,431 439,437 438,471 436,474 433,474 429,471 420,476 413,477 410,475 399,473 395,478 392,478 392,484 395,479 396,485 400,491 410,488 412,490 412,493 410,504 405,513 403,523 396,533 384,562 375,578 373,585 373,595 376,599 385,598 393,591 400,573 412,561 419,551 427,534 436,505 443,491 449,471 463,438 463,423 452,423'/>
                                                <polygon points='1162,454 1163,463 1164,454 1162,454'/>
                                                <polygon points='1126,388 1119,395 1114,405 1113,420 1116,431 1120,440 1136,462 1137,468 1141,473 1143,482 1147,489 1148,496 1154,512 1163,528 1166,530 1172,541 1183,551 1192,551 1192,530 1178,502 1178,498 1177,497 1175,500 1172,496 1171,491 1169,474 1169,401 1166,402 1163,410 1159,401 1149,389 1126,388'/>
                                                <polygon points='1490,634 1480,639 1469,653 1462,658 1446,665 1442,670 1441,682 1443,690 1457,707 1489,706 1495,703 1500,697 1502,687 1502,635 1490,634'/>
                                                <polygon points='873,300 870,320 867,404 869,419 874,434 877,439 880,439 885,431 884,311 882,301 873,300'/>
                                                <polygon points='361,402 352,403 351,430 348,439 346,439 344,426 343,431 339,434 334,444 334,448 331,452 327,445 320,444 319,472 313,493 310,497 301,523 301,539 304,545 309,545 314,542 317,539 319,532 323,528 325,529 331,523 337,514 343,502 347,489 355,476 360,456 361,441 364,430 369,420 371,410 370,402 361,402'/>
                                                <polygon points='608,224 604,226 601,225 601,235 602,236 603,234 605,237 605,250 603,254 602,265 601,306 597,331 595,364 595,402 598,421 600,423 611,420 611,412 619,391 621,370 621,352 618,326 616,230 614,224 608,224'/>
                                                <polygon points='338,418 336,455 338,498 341,494 343,487 348,482 352,464 356,460 358,442 363,431 364,424 362,421 338,418'/>
                                                <polygon points='394,408 388,412 389,417 395,418 399,416 398,409 394,408'/>
                                                <polygon points='377,406 373,422 371,445 368,450 364,473 359,479 358,486 353,497 353,500 356,504 356,509 352,512 356,513 357,535 359,539 363,535 366,535 374,526 383,502 388,499 394,487 402,482 405,478 406,449 396,443 396,436 388,435 385,437 386,443 384,445 380,444 380,436 378,429 379,409 377,406'/>
                                                <polygon points='197,626 178,633 170,640 166,649 166,656 168,661 170,665 175,669 199,671 210,677 221,679 239,687 247,695 249,702 248,709 240,724 240,733 255,733 265,715 273,707 277,705 284,704 292,709 294,708 293,703 287,701 287,693 284,690 282,684 264,663 224,632 212,627 197,626'/>
                                                <polygon points='104,640 93,646 89,651 89,659 96,668 101,670 116,671 128,675 139,689 148,693 152,698 160,697 164,688 176,680 176,674 169,666 165,658 153,648 142,644 122,640 104,640'/>
                                                <polygon points='198,627 174,638 168,644 167,660 173,668 200,671 208,676 222,679 238,685 268,685 277,682 275,675 269,669 227,634 214,627 198,627'/>
                                                <polygon points='391,218 389,229 389,268 377,291 376,300 378,304 381,304 386,298 387,278 389,275 393,255 393,243 396,242 396,239 394,241 392,236 391,218'/>
                                                <polygon points='418,159 416,216 414,221 413,222 411,220 406,227 405,230 408,231 412,236 410,241 406,245 406,249 403,255 398,260 398,265 405,266 411,270 413,299 415,299 416,291 416,267 419,261 424,261 426,264 434,264 432,246 430,255 427,255 424,258 421,258 417,255 417,237 420,225 422,206 424,200 427,197 429,197 431,193 428,174 418,159'/>
                                                <polygon points='1447,718 1446,724 1443,727 1441,727 1433,719 1427,722 1427,726 1432,734 1426,739 1423,746 1423,755 1425,761 1442,761 1447,754 1453,750 1456,742 1464,736 1465,730 1451,729 1449,727 1449,718 1447,718'/>
                                                <polygon points='1076,372 1077,378 1079,379 1080,373 1078,371 1076,372'/>
                                                <polygon points='1105,321 1101,326 1101,342 1099,356 1100,362 1104,366 1112,358 1114,349 1112,332 1105,321'/>
                                                <polygon points='1027,186 1022,188 1015,211 1013,228 1013,253 1023,286 1030,297 1034,309 1035,318 1041,325 1049,366 1057,374 1059,379 1063,383 1072,385 1078,380 1084,378 1089,367 1088,339 1086,335 1087,293 1085,279 1075,272 1075,264 1072,258 1071,249 1064,244 1057,245 1051,240 1051,236 1046,223 1046,217 1042,210 1041,202 1036,187 1027,186'/>
                                                <polygon points='1275,706 1269,712 1270,723 1288,747 1292,749 1300,748 1300,741 1294,728 1297,721 1296,717 1290,711 1280,706 1275,706'/>

                  </svg>
                </div>
                <div class="svg_box no-scrollbar">
                  <svg class="image_with_polygon" id="ct_axial_svg" viewBox="0 0 1504 768"
                    preserveAspectRatio="xMidYMid">
                                                <image class="img" xlink:href="/images/body_img.png" width="100%" height="100%"></image>
                                                <polygon points='914,466 888,482 886,488 887,501 905,530 909,547 913,554 915,566 919,573 922,586 928,596 929,603 934,612 935,619 942,637 957,659 965,667 978,668 980,666 981,660 980,647 967,598 951,524 946,510 935,489 934,482 930,472 923,466 914,466'/>
                                                <polygon points='399,377 388,383 378,401 375,417 372,423 365,465 357,480 341,520 340,526 336,532 335,539 330,548 328,563 333,572 338,571 348,562 353,556 356,548 375,524 378,516 384,509 387,502 399,493 405,493 410,498 414,498 416,502 415,507 410,511 409,514 409,530 416,531 419,533 425,528 430,519 433,507 441,493 446,476 457,456 457,453 466,435 467,403 464,399 441,396 436,392 427,389 421,384 404,377 399,377'/>                                            <polygon points='304,352 292,361 288,370 285,393 283,438 265,486 264,514 266,521 269,523 276,522 282,517 288,505 297,496 308,469 316,461 325,460 328,464 326,490 321,492 321,501 319,506 321,515 323,509 325,507 329,507 332,499 337,498 338,494 346,486 357,465 357,461 361,453 362,442 366,427 374,403 373,369 362,360 353,357 346,357 337,361 332,361 317,352 304,352'/>
                                                <polygon points='790,501 785,503 776,511 774,511 772,517 771,552 777,605 779,642 784,671 790,688 793,690 803,690 807,687 806,603 810,518 806,502 790,501'/>
                                                <polygon points='988,440 981,445 970,448 963,454 938,455 936,458 936,486 951,516 964,536 967,546 975,558 979,574 985,587 987,600 998,624 1012,644 1018,650 1025,651 1028,649 1029,646 1014,607 1009,581 1007,577 1007,560 1010,557 1013,555 1017,557 1026,566 1030,576 1035,583 1037,592 1045,605 1048,613 1056,626 1061,631 1069,634 1073,630 1075,623 1074,615 1067,602 1055,565 1051,558 1050,552 1046,545 1044,536 1036,518 1031,500 1030,487 1021,456 1013,446 998,440 988,440'/>
                                                <polygon points='1088,407 1082,412 1075,415 1067,422 1060,423 1050,429 1037,431 1027,441 1026,458 1032,478 1038,490 1054,510 1067,539 1076,554 1077,561 1087,579 1101,597 1104,603 1110,605 1115,611 1119,611 1123,619 1130,619 1131,617 1124,611 1122,611 1120,608 1120,602 1114,583 1110,576 1104,556 1098,545 1098,525 1104,521 1112,527 1137,567 1150,593 1159,600 1162,600 1166,596 1166,571 1158,554 1156,543 1151,530 1138,504 1135,490 1128,472 1126,461 1118,443 1112,424 1106,414 1099,408 1088,407'/>
                                                <polygon points='1037,203 1035,209 1032,212 1028,213 1028,275 1039,322 1040,337 1043,351 1052,374 1053,380 1057,386 1084,387 1087,386 1099,373 1104,371 1110,364 1114,355 1114,339 1112,331 1108,319 1095,292 1089,272 1083,259 1076,250 1073,240 1068,235 1066,229 1063,229 1060,226 1056,229 1053,226 1050,210 1046,208 1041,208 1037,203'/>
                                                <polygon points='601,450 595,455 589,466 587,474 584,479 579,506 574,560 573,605 571,627 571,646 574,652 583,652 587,648 591,638 593,627 603,607 613,542 625,503 625,480 620,464 615,456 604,450 601,450'/>
                                                <polygon points='876,491 871,494 866,500 860,515 858,528 860,547 874,594 900,650 904,655 906,661 911,666 914,675 918,679 927,680 929,678 931,671 931,654 929,639 921,614 907,546 904,542 897,517 890,503 881,492 876,491'/>
                                                <polygon points='1180,523 1179,526 1181,527 1180,523'/>
                                                <polygon points='1181,384 1177,388 1177,395 1180,399 1183,395 1183,387 1181,384'/>
                                                <polygon points='1169,376 1161,382 1159,387 1158,439 1161,454 1166,463 1169,477 1174,489 1174,493 1177,496 1177,488 1183,483 1186,483 1202,502 1213,518 1219,522 1226,523 1236,512 1236,505 1219,471 1214,457 1208,433 1204,400 1198,381 1194,378 1178,380 1172,376 1169,376'/>
                                                <polygon points='969,203 965,210 964,218 965,264 968,306 968,386 974,402 979,410 988,418 1010,418 1014,412 1020,407 1022,407 1024,410 1028,412 1034,413 1040,410 1047,396 1047,373 1045,365 1030,325 1023,296 1020,291 1018,283 1012,273 1011,268 1003,257 996,243 987,217 975,203 969,203'/>
                                                <polygon points='1083,178 1079,185 1077,197 1079,219 1084,237 1095,262 1097,278 1104,292 1109,297 1110,320 1113,325 1114,333 1117,338 1119,347 1126,355 1133,360 1142,360 1148,355 1160,335 1161,331 1174,314 1175,298 1171,289 1163,279 1152,259 1147,253 1138,236 1127,207 1119,196 1115,202 1112,198 1110,212 1108,215 1105,214 1101,179 1083,178'/>
                                                <polygon points='769,266 765,268 761,274 753,300 753,318 750,338 748,374 739,413 737,435 734,449 735,477 741,484 745,485 777,485 784,478 790,467 791,462 791,439 789,430 789,414 786,390 786,365 788,350 788,300 786,282 783,271 780,266 769,266'/>
                                                <polygon points='452,163 445,165 417,230 395,264 376,314 376,327 379,335 392,344 409,365 420,365 424,363 432,356 436,350 452,298 456,261 465,217 464,164 452,163'/>
                                                <polygon points='387,145 384,146 380,151 380,155 384,159 383,162 380,165 378,165 373,172 368,183 355,203 348,219 329,247 320,257 317,265 317,270 320,279 329,290 344,318 347,321 353,323 359,323 367,320 374,312 384,288 386,276 399,253 409,227 414,185 413,156 408,150 402,149 398,146 387,145'/>
                                                <polygon points='935,219 929,222 925,234 925,317 923,334 921,401 931,425 934,428 943,431 951,441 955,443 960,438 967,436 969,432 974,428 975,411 973,399 970,392 965,362 956,261 950,244 946,226 944,222 935,219'/>
                                                <polygon points='627,215 623,217 621,229 619,225 617,228 616,227 613,218 611,218 610,267 608,277 605,283 602,303 601,322 597,344 595,379 595,407 598,423 601,433 605,438 605,441 615,450 620,450 631,443 636,433 649,363 649,320 644,253 635,218 632,215 627,215'/>
                                                <polygon points='710,510 702,512 700,521 700,586 703,611 708,635 716,652 719,655 723,655 727,649 730,633 730,573 733,523 730,511 710,510'/>
                                                <polygon points='499,408 477,417 471,425 463,450 461,481 458,487 456,498 453,503 452,510 447,517 435,548 429,570 424,577 422,585 417,595 416,601 420,605 430,601 445,586 464,556 474,532 490,515 497,513 500,516 500,525 502,532 501,541 503,545 512,542 516,532 522,527 526,507 537,484 539,475 547,455 547,428 546,425 540,425 537,427 532,426 526,422 523,417 514,409 499,408'/>
                                                <polygon points='714,261 711,263 708,271 696,325 693,364 680,419 679,448 683,461 688,469 698,470 708,476 721,476 726,474 730,469 733,450 733,358 735,302 734,283 730,267 726,263 714,261'/>
                                                <polygon points='505,176 501,179 499,183 492,213 480,244 476,246 470,255 467,256 462,269 458,283 454,307 449,320 448,329 443,344 444,377 449,381 467,379 471,382 479,393 483,394 494,393 499,388 505,385 512,375 517,362 521,341 522,318 520,306 520,248 523,229 523,180 521,177 518,176 505,176'/>
                                                <polygon points='847,683 848,690 850,685 848,682 847,683'/>
                                                <polygon points='825,494 823,495 820,501 817,516 813,525 813,561 831,633 837,668 845,687 846,696 849,698 850,701 850,711 859,719 865,714 868,708 873,682 874,655 868,599 863,569 846,501 844,497 840,495 831,496 825,494'/>
                                                <polygon points='895,253 889,256 889,267 891,275 891,311 889,316 889,350 887,384 884,403 884,431 886,433 891,434 894,437 898,447 903,454 910,460 915,460 925,447 929,432 928,399 921,360 918,261 917,256 895,253'/>
                                                <polygon points='566,439 560,442 557,452 547,470 542,486 539,509 535,523 533,548 527,587 524,595 518,627 518,645 520,648 526,647 537,637 541,627 547,620 556,600 569,559 579,488 585,472 594,459 594,449 587,442 581,439 566,439'/>
                                                <polygon points='590,243 588,244 586,251 586,260 580,284 578,285 577,283 576,285 574,282 572,285 571,284 570,287 564,322 563,340 561,344 561,352 556,377 556,397 559,411 569,426 579,426 581,424 590,412 594,404 596,395 596,335 599,284 599,249 596,243 590,243'/>
                                                <polygon points='669,486 666,488 663,503 663,525 665,538 665,609 668,630 669,655 674,681 678,686 682,686 685,685 690,678 694,652 696,574 699,541 699,498 698,494 690,486 669,486'/>
                                                <polygon points='813,254 808,258 806,262 801,287 799,328 797,340 796,389 791,443 791,469 797,478 804,479 816,478 822,471 825,470 832,458 833,445 828,392 828,353 824,297 821,281 820,263 817,255 813,254'/>
                                                <polygon points='855,227 852,229 849,239 847,260 839,305 837,350 833,384 833,407 839,442 846,467 859,485 865,485 869,480 871,480 873,476 875,463 883,437 882,415 880,406 880,361 877,353 877,342 875,335 878,283 875,259 870,237 865,228 855,227'/>
                                                <polygon points='540,200 534,202 532,206 530,226 531,258 528,263 529,283 527,304 513,356 510,377 511,392 513,398 517,400 523,407 526,408 532,414 540,414 546,411 555,397 562,373 565,333 564,268 566,254 567,203 563,200 540,200'/>
                                                <polygon points='645,465 638,467 634,470 626,489 623,511 615,546 613,571 613,612 615,639 621,665 625,694 628,702 631,705 638,705 642,703 646,691 655,646 661,582 661,517 657,499 654,472 650,466 645,465'/>
                                                <polygon points='532,454 523,456 520,494 516,496 513,491 511,492 504,500 502,507 499,511 496,521 496,527 492,537 484,568 477,584 467,599 461,612 461,630 463,632 473,630 480,624 487,611 497,602 503,594 513,576 523,542 523,537 532,498 538,483 543,462 542,455 532,454'/>
                                                <polygon points='671,239 667,243 665,261 659,287 654,323 650,377 644,406 644,429 646,440 653,449 657,450 661,455 668,457 673,456 683,446 686,438 685,379 688,303 687,272 682,244 681,241 677,239 671,239'/>
                                                <polygon points='737,507 734,512 734,540 740,613 747,642 751,668 753,672 760,673 763,670 767,654 766,571 769,544 773,529 773,509 769,507 737,507'/>
                                                <polygon points='452,423 448,425 449,431 439,437 438,471 436,474 433,474 429,471 420,476 413,477 410,475 399,473 395,478 392,478 392,484 395,479 396,485 400,491 410,488 412,490 412,493 410,504 405,513 403,523 396,533 384,562 375,578 373,585 373,595 376,599 385,598 393,591 400,573 412,561 419,551 427,534 436,505 443,491 449,471 463,438 463,423 452,423'/>
                                                <polygon points='1162,454 1163,463 1164,454 1162,454'/>
                                                <polygon points='1126,388 1119,395 1114,405 1113,420 1116,431 1120,440 1136,462 1137,468 1141,473 1143,482 1147,489 1148,496 1154,512 1163,528 1166,530 1172,541 1183,551 1192,551 1192,530 1178,502 1178,498 1177,497 1175,500 1172,496 1171,491 1169,474 1169,401 1166,402 1163,410 1159,401 1149,389 1126,388'/>
                                                <polygon points='1490,634 1480,639 1469,653 1462,658 1446,665 1442,670 1441,682 1443,690 1457,707 1489,706 1495,703 1500,697 1502,687 1502,635 1490,634'/>
                                                <polygon points='873,300 870,320 867,404 869,419 874,434 877,439 880,439 885,431 884,311 882,301 873,300'/>
                                                <polygon points='361,402 352,403 351,430 348,439 346,439 344,426 343,431 339,434 334,444 334,448 331,452 327,445 320,444 319,472 313,493 310,497 301,523 301,539 304,545 309,545 314,542 317,539 319,532 323,528 325,529 331,523 337,514 343,502 347,489 355,476 360,456 361,441 364,430 369,420 371,410 370,402 361,402'/>
                                                <polygon points='608,224 604,226 601,225 601,235 602,236 603,234 605,237 605,250 603,254 602,265 601,306 597,331 595,364 595,402 598,421 600,423 611,420 611,412 619,391 621,370 621,352 618,326 616,230 614,224 608,224'/>
                                                <polygon points='338,418 336,455 338,498 341,494 343,487 348,482 352,464 356,460 358,442 363,431 364,424 362,421 338,418'/>
                                                <polygon points='394,408 388,412 389,417 395,418 399,416 398,409 394,408'/>
                                                <polygon points='377,406 373,422 371,445 368,450 364,473 359,479 358,486 353,497 353,500 356,504 356,509 352,512 356,513 357,535 359,539 363,535 366,535 374,526 383,502 388,499 394,487 402,482 405,478 406,449 396,443 396,436 388,435 385,437 386,443 384,445 380,444 380,436 378,429 379,409 377,406'/>
                                                <polygon points='197,626 178,633 170,640 166,649 166,656 168,661 170,665 175,669 199,671 210,677 221,679 239,687 247,695 249,702 248,709 240,724 240,733 255,733 265,715 273,707 277,705 284,704 292,709 294,708 293,703 287,701 287,693 284,690 282,684 264,663 224,632 212,627 197,626'/>
                                                <polygon points='104,640 93,646 89,651 89,659 96,668 101,670 116,671 128,675 139,689 148,693 152,698 160,697 164,688 176,680 176,674 169,666 165,658 153,648 142,644 122,640 104,640'/>
                                                <polygon points='198,627 174,638 168,644 167,660 173,668 200,671 208,676 222,679 238,685 268,685 277,682 275,675 269,669 227,634 214,627 198,627'/>
                                                <polygon points='391,218 389,229 389,268 377,291 376,300 378,304 381,304 386,298 387,278 389,275 393,255 393,243 396,242 396,239 394,241 392,236 391,218'/>
                                                <polygon points='418,159 416,216 414,221 413,222 411,220 406,227 405,230 408,231 412,236 410,241 406,245 406,249 403,255 398,260 398,265 405,266 411,270 413,299 415,299 416,291 416,267 419,261 424,261 426,264 434,264 432,246 430,255 427,255 424,258 421,258 417,255 417,237 420,225 422,206 424,200 427,197 429,197 431,193 428,174 418,159'/>
                                                <polygon points='1447,718 1446,724 1443,727 1441,727 1433,719 1427,722 1427,726 1432,734 1426,739 1423,746 1423,755 1425,761 1442,761 1447,754 1453,750 1456,742 1464,736 1465,730 1451,729 1449,727 1449,718 1447,718'/>
                                                <polygon points='1076,372 1077,378 1079,379 1080,373 1078,371 1076,372'/>
                                                <polygon points='1105,321 1101,326 1101,342 1099,356 1100,362 1104,366 1112,358 1114,349 1112,332 1105,321'/>
                                                <polygon points='1027,186 1022,188 1015,211 1013,228 1013,253 1023,286 1030,297 1034,309 1035,318 1041,325 1049,366 1057,374 1059,379 1063,383 1072,385 1078,380 1084,378 1089,367 1088,339 1086,335 1087,293 1085,279 1075,272 1075,264 1072,258 1071,249 1064,244 1057,245 1051,240 1051,236 1046,223 1046,217 1042,210 1041,202 1036,187 1027,186'/>
                                                <polygon points='1275,706 1269,712 1270,723 1288,747 1292,749 1300,748 1300,741 1294,728 1297,721 1296,717 1290,711 1280,706 1275,706'/>

                  </svg>
                </div>
              </div>
              <div class="ct_control">
                <div class="auto_pano float_box">
                  <h2 class="title">Auto PANORAMA</h2>
                  <button class="preview_btn"><img id="preview_auto_pano" alt="image"></button>
                </div>
                <div class="cbct_wrap">
                  <div class="cbct float_box">
                    <h2 class="title">CBCT</h2>
                    <button class="prev_btn"><img src="@/assets/images/white_arrow_up.png" alt="" /></button>
                    <div id="preview_list" class="img_list no-scrollbar">
                      기능개발전 코드 참고를 위해 주석처리함
                      
                                                    <button class="preview_btn"><img src="@/assets/images/cbct_img2.png" alt="image"></button>
                                                    <button class="preview_btn"><img src="@/assets/images/cbct_img2.png" alt="image"></button>
                                                    <button class="preview_btn"><img src="@/assets/images/cbct_img2.png" alt="image"></button>
                                                    <button class="preview_btn"><img src="@/assets/images/cbct_img2.png" alt="image"></button>
                                                   
                    </div>
                    <button class="next_btn"><img src="@/assets/images/white_arrow_down.png" alt="" /></button>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- 기능개발전 코드 참고를 위해 주석처리함 -->
            <!--
                                <div class="ct">
                                    <div class="big_img">
                                        <img src="@/assets/images/ct_img1.png" width="1057" height="501"  alt="" />

                                        <div class="cbct_img">
                                            <ul class="clear">
                                                <li><img src="@/assets/images/cbct_img1.png" width="517" height="248"  alt="" /></li>
                                                <li><img src="@/assets/images/cbct_img2.png" width="417" height="292"  alt="" /></li>
                                                <li><img src="@/assets/images/cbct_img3.png" width="416" height="292"  alt="" /></li>
                                                <li><img src="@/assets/images/cbct_img4.png" width="417" height="292"  alt="" /></li>
                                            </ul>
                                        </div>

                                        <div class="ct_img_control">
                                            <ul class="clear">
                                                <li class="img_c1">
                                                    <button><img src="@/assets/images/minus.png" width="36" height="36"  alt="" /></button>
                                                </li>

                                                <li class="img_c2">
                                                    <button><img src="@/assets/images/plus.png" width="36" height="36"  alt="" /></button>
                                                </li>

                                                <li class="img_c3">
                                                    <button><img src="@/assets/images/reset.png" width="36" height="36"  alt="" /></button>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="ct_hide_btn">
                                            <button><img src="@/assets/images/not_hide.png" width="48" height="48"  alt="" /></button>
                                        </div>
                                    </div>

                                    <div class="ct_right">
                                        <div class="ctr_top">
                                            <h4>Auto PANORAMA</h4>
                                            <div class="m_img"><a href=""><img src="@/assets/images/ct_img1_sm.png" width="160" height="77"  alt="" /></a></div>
                                        </div>

                                        <div class="ctr_btm">
                                            <h4>CBCT</h4>

                                            <div class="visual">
                                                <ul>
                                                    <li><a href=""><img src="@/assets/images/ct_img2_sm.png" width="160" height="160"  alt="" /></a></li>
                                                    <li><a href=""><img src="@/assets/images/ct_img3_sm.png" width="160" height="160"  alt="" /></a></li>
                                                    <li><a href=""><img src="@/assets/images/ct_img4_sm.png" width="160" height="160"  alt="" /></a></li>
                                                    <li><a href=""><img src="@/assets/images/ct_img5_sm.png" width="160" height="160"  alt="" /></a></li>
                                                </ul>

                                                <div class="prev_next">
                                                    <button class="prev"><img src="@/assets/images/ct_slider_prev.png" width="160" height="32"  alt="" /></button>
                                                    <button class="next"><img src="@/assets/images/ct_slider_next.png" width="160" height="32"  alt="" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                -->
          </div>
        </div>

        <div id="disease_table_wrapper" class="btm gray-scrollbar" ref="bottomTable">

          <table id="disease_table" class="hover_selectable_table">
            <thead>
              <tr ref="th">
                <th>
                </th>
                <th>{{lang[$store.state.lang].MAIN_DISEASES_TABLE_TXT_CATEGORY}}<!--질환분류--></th>
                <th>{{lang[$store.state.lang].MAIN_DISEASES_TABLE_TXT_NAME}}<!--질환명--></th>
                <th>{{lang[$store.state.lang].MAIN_DISEASES_TABLE_TXT_POSITION}}<!--위치--></th>
                <th>{{lang[$store.state.lang].MAIN_DISEASES_TABLE_TXT_ADDITIONAL_EXAM}}<!--추가검사--></th>
                <th>{{lang[$store.state.lang].MAIN_DISEASES_TABLE_TXT_DESCRIPTION}}<!--상세설명--></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr ref="diseasesTableRow" :id="row.id.toString()" @mouseenter="hoverTableRow" @mouseleave="resetTargetIdInfo" v-for="(row,idx) in diseasesTable" :key="'diseasesTable-'+idx+'-'+row.name">
                <td :class="[targetInfo.startDirection == 'polygon' && row.id.includes(targetInfo.id.join())?'tr_selected':'tr_basic']"><span :style="{backgroundColor: row.color}"></span></td>
                <td :colspan="index==4?'2':'1'" :class="[targetInfo.startDirection == 'polygon' && row.id.includes(targetInfo.id.join())?'tr_selected':'tr_basic']" v-for="(value,property,index) in row.info" :key="'diseasesTableRow-'+index+'-'+property">{{value}}</td>
              </tr>       
            </tbody>
          </table>
        </div>
      </div>

      <div id="request_result_black" class="black_announce" style="display:none">
        <div id="send_loading" class="loading on">
          <div class="spinner"></div>
        </div>
        <button class="press_btn purple_btn open_req_btn on">{{lang[$store.state.lang].MAIN_BTN_REQUEST}}<!--영상 판독 의뢰하기--></button>
        <strong id="status_text"></strong>
      </div>
    </div>
    <div v-if="!exam.seq" class="black_announce" style="position:'relative'" @dragenter="$store.state.draggedPosition = 'main'">
      <div class="dragged_boundary" v-if="$store.state.draggedPosition == 'main'" @drop="onDrop" @dragover="onDragOver" @dragleave="$store.state.draggedPosition = ''"></div>
      <div class="select_file">
        <label for="examFile"><img src="@/assets/images/crossWhite.png" alt="cross_icon"></label>
        <input type="file" id="examFile" multiple style="display:none" @change="uploadExamFiles">
        
        <div class="select_file_text">
          <p>{{lang[$store.state.lang].MAIN_TXT_DRAG_PX_EXAM}}<!--새로운 파노라마 영상을 마우스로 끌어오세요--></p>
          <p>{{lang[$store.state.lang].MAIN_TXT_DRAGGABLE_PX_EXAM_FORM}}<!--DICOM(여러개 파일 가능), PNG, JPG, BMP--></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import wsMixin from '@/wsMixin';
import Chatting from '@/components/Chatting.vue'
import diseases from '@/diseases'
import alert from '@/alert'
import chatAlarm from '@/assets/audio/채팅 수신음.mp3'
import dicomParser from 'dicom-parser'

export default {
  name: "MainViewer",
  mixins: [wsMixin],
  components : {Chatting},
  props : {
    exam : Object,
    shouldOpenChat: Boolean
  },
  data: function() {
    return {
      lang,
      imgUrl: '',
      baseUrl : (process.env.NODE_ENV=='production'?process.env.VUE_APP_STATIC_URL:process.env.VUE_APP_STATIC_DEV_URL),
      imgBox : {width:0,height:0},
      imgScrollContainer : {width:0,height:0},
      imgRatio : {width:0,height:0},
      requestPopup : false,
      imgPer : 100,
      imgSize : {width:0,height:0},
      startPoint : {x:0, y:0},
      endPoint : {x:0, y:0},
      imgTop : 0,
      imgLeft : 0,
      rangeVal : 1,
      maxRangeVal : 0,
      ctImgUrl : '',
      ctPreviewImgTop : 0,
      isShowPrevImg : false,
      ctPreviewImg : '',
      convertedCoords : [],
      diseaseImgNum : [],
      showPolygon : true,
      showImplant: false,
      bboxIdArr: [],
      msgs: [],
      chatState: false,
      chatTimer : undefined,
      diseasesTable:[],
      targetInfo : {
        id: [],
        startDirection: ''
      },
      msgsIsLoaded: false,
      selectedCC: '기본',
      showReqPopupTextarea: false,
      ccText: '',
      pos: {x:0,y:0},
      imgAbHeight: 0,
      diseaseBoundary: 20,
      implantSize: {width: 40, height: 100},
      implantInfoBoxSize: {font: 12, padding: [8,16], rowPadding: 8, rowMargin: 8},
      hoveredImplant: '',
      clickedImplant: '',
      implantInfoBoxPos: {left: 'calc(100% + 3px)'},
      bboxNameFontSize: 20,
      parameter: false, // 진단기준 모달 상태
      examChangeComplete: false
    }
  },
  methods : {
    alert,
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      // 101번 action(= 특정 영상정보에 대한 채팅메시지 열람)에 대한 응답이 수신된 경우에 대한 처리 
      if(data.action == 101){
        // 응답이 성공인 경우에 대한 처리로, 수신된 채팅목록을 msgs에 대입한다.
        if(data.body[0].content.success){
          let copy = [...data.body[0].content.list]
          let date = '';
          // 각 날에서 가장 첫 번째로 보낸 rd -> labeler 인 채팅 다음에 서비스 준비중 채팅 보여주기
          [...data.body[0].content.list].forEach((msg) => {
            if(date != new Date(msg.created).toLocaleDateString('en')) {
              date = new Date(msg.created).toLocaleDateString('en');
              let foundedIndex = copy.findIndex((msg) => (new Date(msg.created).toLocaleDateString('en') == date) && (msg.direction == "RD TO LABELER"));
              if(foundedIndex >= 0) {
                copy.splice(foundedIndex+1,0,{
                  created: copy[foundedIndex].created,
                  msg: this.lang[this.$store.state.lang].CHATTING_TXT_AUTO_RESPONSE, //'환자영상에 대한 전문의 상담채팅 서비스가 준비중입니다.\n 서비스에 대한 일반 문의의 경우 24시간 이내에 처리하겠습니다.',
                  direction : "LABELER TO RD"
                })
              }
            }
          })
          this.msgs = [...copy];
          this.msgsIsLoaded = true;
        }else {
          const reload = () => {
            this.msgsIsLoaded = false;
            let packet = this.$$build(101,[{
              contentType: 1,
              content: {exam: this.exam.seq}
            }]);
            this.$socket.send(packet);
          }
          this.alert('error',this.lang[this.$store.state.lang].CHATTING_TXT_FAIL_LOAD_CHATTING_LIST,reload); // 해당 영상의 채팅목록을 조회하는것에 실패하였습니다.\n다시 시도해주세요.
          this.msgsIsLoaded = false;
        }
      }
      // 103번 action(= 채팅메시지 수신)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 103){
        const onClickChattingToast = () => {
          if(this.exam.seq == data.body[0].content.exam){
            if(!this.chatState) this.chatState = true;
          }else {
            this.$emit('clickChatToast',data.body[0].content.exam);
          }
        }
        this.alert('default',lang[this.$store.state.lang].CHATTING_TXT_RECV,onClickChattingToast); // 채팅이 수신되었습니다.

        if(!this.chatTimer &&  sessionStorage.getItem('sound')) {
          let audio = new Audio(chatAlarm);
          audio.play();
          this.chatTimer = setTimeout(()=> {
            clearTimeout(this.chatTimer);
            this.chatTimer = undefined;
          }, 10 * 1000)
        }
        
        // 현재 exam의 seq와 수신된 채팅의 exam번호가 같은 경우에 대한 처리로, msgs에 수신된 메시지를 객체형태로 push한다.
        if(this.exam.seq == data.body[0].content.exam){
          this.msgs.push({
            msg: data.body[0].content.msg,
            created: new Date().toISOString(),
            direction : "LABELER TO RD",
            written_by: {name:data.body[0].content.witten_by.name}
          })
        }
        /*
        ** 수신된 채팅의 exam 번호와 현재 선택한 exam seq 가 같으면서 chat이 true인 경우를 제외한 상황에 대한 처리 
        ** 윗줄과 같은 상황은, 사용자가 수신된 채팅의 exam과 같은 exam을 선택하여 해당 exam의 채팅을 보고있는 경우를 제외한 것 이다.
        ** 이벤트를 방출하여 new_chat의 상태를 변경하도록 하고, 해당 exam이 상위에 위치하여 보이도록 한다.
        */
        if(!((this.exam.seq == data.body[0].content.exam) && this.chatState)){
          this.$emit('updateNewChat',data.body[0].content.exam);
        }
      }
      // 2000번 action (= polygon 생성)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 2000){
        if(data.body[0].content.success){
          if(this.exam.seq == data.body[0].content.exam.seq){
            let polygonCreatedLabel = data.body[0].content.exam.labels.disease.filter(label => label.polygon);
            if(data.body[0].content.exam.labels.disease.length == polygonCreatedLabel.length) {
              this.convertedCoords = [];
              this.bboxIdArr = [];
              this.exam.labels.disease.forEach((label)=>{
                label.id = this._getRandomBboxId();
              })
              this.diseaseImgNum = [];
              let diseaseLists = [];
              this.exam.labels.disease.forEach((label) => {
                if(!diseaseLists.includes(label.disease) && label.polygon) diseaseLists.push(label.disease);
              });
              const browserInnerWidth = window.innerWidth;
              const th = browserInnerWidth <= 1024 ? 22 : 32;
              const tr = browserInnerWidth <= 1024 ? 24 : 34;
              const bottomTableMaxHeight = browserInnerWidth <= 1024 ? 140 : 180;
              if(diseaseLists.length >= 5) this.imgAbHeight = this.$refs.diagnosisResult.clientHeight - (10 + bottomTableMaxHeight);
              else this.imgAbHeight = this.$refs.diagnosisResult.clientHeight - (10 + th + (diseaseLists.length * tr));
              this.imgAbHeight -=  this.$refs.viewerFilter.clientHeight;
              this.imgBox.width = this.$refs.diagnosisResult.clientWidth;
              this.imgBox.height = this.imgAbHeight;
              this.resize();
              this.convertDiseaseCoord(this.exam.labels);
            }
          }
        }
      } 
    },
    // labeler로 채팅을 작성한 경우, 작성한 문자열을 전달받아, msgs에 추가한다.
    addNewChat(msg){
      let today = new Date().toLocaleDateString('en');
      let todayFirstMsg = this.msgs.filter((msg) => new Date(msg.created).toLocaleDateString('en') == today && msg.direction == "RD TO LABELER" );
      this.msgs.push({
        created: new Date().toISOString(),
        msg,
        direction : "RD TO LABELER"
      });
      // 해당 날짜에 처음으로 보내는 rd -> labeler 채팅인 경우, 서비스 준비중 문구를 보이도록 한다.
      if(todayFirstMsg.length == 0) {
        let timer = setTimeout(() => {
          this.msgs.push({
            created: new Date().toISOString(),
            msg: this.lang[this.$store.state.lang].CHATTING_TXT_AUTO_RESPONSE, //'환자영상에 대한 전문의 상담채팅 서비스가 준비중입니다.\n 서비스에 대한 일반 문의의 경우 24시간 이내에 처리하겠습니다.',
            direction : "LABELER TO RD"
          });
          clearTimeout(timer);
        }, 500);
      }
    },
    closeRequestPopup(){
      this.requestPopup = false;
    },
    requestExam(){
      let body = [{contentType:1,content:{exam:this.exam.seq}}];
      // 사용자가 입력한 cc를 body에 추가한다.
      if(this.selectedCC != '기본') {
        if(this.selectedCC.trim().includes('직접')) {
          if(this.ccText.trim().length > 0) body[0].content.cc = {text: this.ccText};
        }else body[0].content.cc = {text: this.selectedCC};
      }
      let packet = this.$$build(63,body);
      this.$socket.send(packet);
    },
    // 이미지를 담고있는 container의 사이즈를 구한다.
    resize(){
      const splittedUrl = this.exam.url.split('/');
      const splittedImgInfo = splittedUrl[splittedUrl.length-1].split('.');
      const originalImgSize = {width : splittedImgInfo[2],height:splittedImgInfo[3]};
      const aspectRatio = originalImgSize.width / originalImgSize.height;
      this.imgScrollContainer.width = this.imgBox.width;
      this.imgScrollContainer.height = this.imgScrollContainer.width / aspectRatio;
      // imgBox의 width를 기준으로 imgScrollContainer의 width, height를 결정했을 때, imgScrollContainer의 높이가 imgBox보다 더 큰 경우에 대한 처리 
      if(this.imgScrollContainer.height > this.imgBox.height){
        this.imgScrollContainer.height = this.imgBox.height;
        this.imgScrollContainer.width = this.imgScrollContainer.height * aspectRatio;
      }
      this.imgSize = {width:this.imgScrollContainer.width,height:this.imgScrollContainer.height}
      this.imgRatio = {width:originalImgSize.width / this.imgSize.width, height:originalImgSize.height / this.imgSize.height};
    },
    // 이미지 확대 비율을 고려하여 이미지 자체의 사이즈를 구한다.
    updateImgSize(){
      let zoomRatio = this.imgPer / 100;
      this.imgSize = {width:this.imgScrollContainer.width * zoomRatio, height:this.imgScrollContainer.height * zoomRatio}
      const splittedUrl = this.exam.url.split('/');
      const splittedImgInfo = splittedUrl[splittedUrl.length-1].split('.');
      const originalImgSize = {width : splittedImgInfo[2],height:splittedImgInfo[3]};
      this.imgRatio = {width : originalImgSize.width / this.imgSize.width, height: originalImgSize.height / this.imgSize.height}
    },
    zoomIn(){
      // 190% 까지만 확대되도록 하기 위하여 imgPer의 값이 180을 초과하는 경우 함수를 종료한다.
      if(this.imgPer > 180 ) return;
      this.imgPer += 10;
      this.updateImgSize();
      // 이미지 중앙을 기준으로 이미지가 확대됨
      const img = this.$refs.img;
      this.imgTop = this.imgTop - ((this.imgSize.height - img.clientHeight)/2);
      this.imgLeft = this.imgLeft - ((this.imgSize.width - img.clientWidth)/2);
    },
    zoomOut(){
      // 이미지는 원본크기 보다 작아지면 안되므로 이미지 확대 비율이 100 인 경우 함수를 종료한다.
      if(this.imgPer == 100) return;
      let zoomTimes = (this.imgPer / 100) * 10 - 10;
      this.imgLeft += Math.abs(this.imgLeft / zoomTimes);
      this.imgTop += Math.abs(this.imgTop / zoomTimes);
      this.imgPer -= 10;
      this.updateImgSize();
    },
    zoomDefault(){
      this.imgPer = 100;
      this.imgTop = 0;
      this.imgLeft = 0;
      this.updateImgSize();
    },
    wheelImg(ev){
      // mousewheel 이벤트 발생시, 움직인 방향을 감지하여 wheelDelta는 방향에 따라 -120, 120 이 된다.
      // 위 방향으로 움직인 경우에 대한 처리
      if(ev.wheelDelta > 0){
        // 이미지가 확대된 비율이 180을 초과하면 함수를 종료한다.
        if(this.imgPer > 180 )return;
        this.imgPer += 10;
        this.updateImgSize();

        // 마우스 위치 기준 확대
        const img = this.$refs.img;
        const imgContainerPosition = this.$refs.imgScrollContainer.getBoundingClientRect();
        if(this.pos.x == 0) {
          const mouseX = (ev.clientX - imgContainerPosition.x - this.imgLeft) / img.clientWidth;
          const mouseY = (ev.clientY - imgContainerPosition.y - this.imgTop) / img.clientHeight;
          this.pos = {x:mouseX, y:mouseY};
        }
        const diffX = this.imgSize.width - img.clientWidth;
        const diffY = this.imgSize.height - img.clientHeight;
        const xToMove = diffX * this.pos.x;
        const yToMove = diffY * this.pos.y;
        this.imgLeft -= xToMove;
        this.imgTop -= yToMove;
      }
      // 아래 방향으로 움직인 경우에 대한 처리
      else if(ev.wheelDelta < 0){
        // // 이미지가 확대된 비율이 100보다 작으면 함수를 종료한다.
        if(this.imgPer == 100) return;
        this.imgLeft += Math.abs(this.imgLeft /((this.imgPer - 100) / 10 ));
        this.imgTop += Math.abs(this.imgTop /((this.imgPer - 100) / 10 ));
        this.imgPer -= 10;
        this.updateImgSize();
      }
    },
    // 드래그로 영상을 움직이는 기능의 시작점을 지정한다.
    setStartPoint(ev){
      // imgPer 이 100 인 경우는 이미지가 확대되지 않은 상태를 가리키므로, 이미지가 움직이지 못하도록 함수를 종료한다.
      if(this.imgPer == 100) return
      this.startPoint = {x:ev.clientX, y:ev.clientY};
    },
    // 드래그로 영상을 움직이는 기능의 시작점, 끝점을 지정하여 영상이 움직이도록 한다.
    moveImg(ev){
      // 마우스를 움직였을 때 이미지 확대 기준점을 다시 잡도록 pos를 초기화한다.
      if(this.pos.x != 0) this.pos = {x:0,y:0}

      // 시작지점이 결정되지 않은 경우에는 함수를 종료한다.
      if(!this.startPoint.x) return;

      this.endPoint = {x:ev.clientX, y:ev.clientY};
      const imgRect = this.$refs.img.getBoundingClientRect();
      const imgScrollContainer = this.$refs.boundaryBox.getBoundingClientRect();
      
      let left = imgRect.x+imgRect.width > imgScrollContainer.x+imgScrollContainer.width;
      let right = imgRect.x < imgScrollContainer.x;
      let down = imgRect.y < imgScrollContainer.y;
      let top = imgRect.y+imgRect.height > imgScrollContainer.y+imgScrollContainer.height;
      let diffX = this.endPoint.x - this.startPoint.x;
      let diffY = this.endPoint.y - this.startPoint.y;

      /*
      ** diffX가 0보다 작으면서(= 좌측이동) 이미지의 오른쪽 x좌표가 imgScrollContainer의 오른쪽 x좌표 보다 더 큰 경우 이거나
      ** diffX가 0보다 크면서(= 우측이동) 이미지의 x좌표가 imgScrollContainer의 x좌표 보다 작은 경우에만 이미지의 left를 변경하여 좌우로 움직이도록 한다.
      */
      if(diffX < 0 && left || diffX > 0 && right) this.imgLeft = this.imgLeft + (this.endPoint.x - this.startPoint.x);
      /*
      ** diffY가 0보다 작으면서(= 상측이동) 이미지의 하단 y좌표가 imgScrollContainer의 하단 y좌표 보다 더 큰 경우 이거나
      ** diffY가 0보다 크면서(= 하측이동) 이미지의 y좌표가 imgScrollContainer의 y좌표 보다 작은 경우에만 이미지의 top을 변경하여 상하로 움직이도록 한다.
      */
      if(diffY < 0 && top || diffY > 0 && down) this.imgTop = this.imgTop + (this.endPoint.y - this.startPoint.y);
      this.startPoint = {...this.endPoint};
    },
    setEndPoint(){
      /*
      ** 이미지의 위치를 옮기는 작업이 끝나, 시작점과 종료점을 초기화하는 함수로, 
      ** 시작지점이 결정되지 않은 경우(= 이미지의 위치를 움직이는 중이 아닌 상황)에는 함수를 종료하여 필요한 상황에만 아래의 코드가 작동되도록 한다.
      */
      if(this.startPoint.x == 0) return;
      this.startPoint = {x:0, y:0};
      this.endPoint = {x:0, y:0};
    },
    _setPreImg(){
      let imgArr = [];
      /*
      ** 선택된 exam의 이미지가 여러장인 경우, 모든 이미지의 url을 얻기 위하여 반복문을 실행한다.
      ** 모든 이미지의 시작은 1번이고, maxRangeNum은 각 exam의 마지막 이미지 번호이다. 
      */
      for(let imgNum=1; imgNum<=this.maxRangeVal; imgNum++){
        let url = this.exam.url.split('.')
        url.splice(-3,1,imgNum.toString());
        url = url.join('.');
        url = this.baseUrl + url;
        imgArr.push(url);
      }
      this._preloading(imgArr);
    },
    _preloading (imageArray) {
      let promises = imageArray.map((imgUrl) => {
        return new Promise((resolve) => {
          let img = new Image();
          img.src = imgUrl;  
          img.onload = () => {
            resolve(true);
          }
        });
          
      })
      Promise.all(promises).then(()=>{
        this.examChangeComplete = true;
      });
    },
    movePreview(ev){
      // mousemove 이벤트가 발생한 위치의 offsetX 값이 0 보다 작거나, imgScrollContainer의 높이 보다 큰 경우, preview이미지를 숨기고, 함수를 종료한다.
      if(ev.offsetX < 0 || ev.offsetX > this.imgScrollContainer.height) return this._hidePrevImg();
      // mousemove 이벤트가 발생한 위치의 offsetX 값이 0 ~ imgScrollContainer의 높이 인 경우, preview이미지를 보인다.
      else this._showPrevImg();
      let mousePoint = ev.offsetX;

      // preview img box 의 높이(60)보다 mounsePoint 가 작은 경우, 높이를 preview img box 로 설정하여 화면에서 사라지지 않도록 한다.
      if(mousePoint <= 60) this.ctPreviewImgTop = this.imgScrollContainer.height - 60;
      else this.ctPreviewImgTop = this.imgScrollContainer.height  - ev.offsetX;

      const oneImgPerMax = this.imgScrollContainer.height / this.maxRangeVal;
      let imgNum = mousePoint / oneImgPerMax;
      // imgNum 의 나머지가 0으로 떨어지지 않으면서, imgNum이 0 보다 큰 경우, imgNum을 올림한다.
      if(imgNum.toString().includes('.') && imgNum > 0) imgNum = Math.ceil(imgNum);
      let url = this.exam.url.split('.')
      url.splice(-3,1,imgNum.toString());
      url = url.join('.');
      this.ctPreviewImg = url;
    },
    _showPrevImg(){
      this.isShowPrevImg = true;
    },
    _hidePrevImg(){
      this.isShowPrevImg = false;
    },
    moveToClickedImg(ev){
      const mousePoint = ev.offsetX;
      const oneImgPerMax = this.imgScrollContainer.height / this.maxRangeVal;
      let imgNum = mousePoint / oneImgPerMax;
      // imgNum 의 나머지가 0으로 떨어지지 않으면서, imgNum이 0 보다 큰 경우, imgNum을 올림한다.
      if(imgNum.toString().includes('.') && imgNum > 0) imgNum = Math.ceil(imgNum);
      // imgNum이 0 보다 작은 경우, 1을 대입하여 첫 번째 이미지를 보여주도록 한다.
      if(imgNum < 0) imgNum = 1;
      this.rangeVal = imgNum
    },
    // exam의 질환 label 정보를 화면에 보이는 영상의 크기에 맞게 변경한다.
    convertDiseaseCoord(labels){
      // 선택한 영상객체의 labels 정보를(= 배열) 인자로 받아서, label들의 좌표를 현재 화면 크기게 맞게 변환하고, diseasesTable에 질환 정보를 저장한다.
      /**
       * convertedCoords 내부의 객체에는 
       * br( {x:int, y:int } ), lt( {x:int, y:int } ), category(String), color(String), id(int), name(String), points(String), size({width:int,height:int}), startCoord({x:double,y:double}), score:int가 있다.
       * score는 질환의 민감도에 따른 표시로, 20 | 50 | 90 으로 나뉜다. 숫자가 커질수록 정확한 범위를 특정한다.
       * 
       * diseasesTable은 질환 table을 그리기 위한 질환 정보를 담고 있으며, 내부의 객체에는
       * id(int[]), info({additional:String, category: String, name: String, position: String, recommend: String}), color(String) 이 포함된다.
       */
      this.convertedCoords = [];
      let str
      let arr = [];
      this.diseasesTable = [];
      // 전달받은 labels를 순회하며 각 label이 가진 정보를 가공한다.
      labels.disease.forEach((label)=>{
        // 영상의 종류가 'PX'이면서 label의 polygon 키가 있는 경우에 대한 처리
        if(label.polygon&&this.exam.type=='PX'){
          str = label.polygon.toString().split(',');
          // polygon좌표가 x, y 순으로 나열된 str 만큼 반복문을 순회하여 각각의 좌표를 화면 비율에 맞추어 변환한다. 
          for(let i=0; i<str.length; i++){
            // 좌표는 x, y 순 으로 나열되어 있으므로, i % 2 === 0 인 조건은 x 좌표일 때를 가리킨다. 
            if(i % 2 === 0){
              str[i] = str[i] / this.imgRatio.width;
            }
            // 좌표는 x, y 순 으로 나열되어 있으므로, i % 2 !== 0 인 조건은 y 좌표일 때를 가리킨다. 
            else str[i] = str[i] / this.imgRatio.height;
          }
          let category = diseases.find((disease) => disease.key.replaceAll(' ','') == label.disease.replaceAll(' ',''))['ko'].category.replaceAll(' ','');
          let color;
          // 질환 카테고리에 따라 색상을 달리 지정한다.
          if(category == '치아관련질환') color = '#A036F6';
          else if(category == '연조직석회화') color = '#58E4BD';
          else if(category == 'TMJ이상') color = '#FFD67E';
          else color = '#FF6975';

          let diseaseName = diseases.find((disease) => disease.key.replaceAll(' ','') == label.disease.replaceAll(' ',''))[this.$store.state.lang].name;
          arr.push({
            id:label.id,
            score: label.score ? label.score : 25,
            color,
            category,
            name: diseaseName,
            points:str.toString(),
            br: label.br,
            lt: label.lt,
            startCoord:{x:label.lt.x / this.imgRatio.width, y: label.lt.y / this.imgRatio.height},
            size: {width: label.br.x - label.lt.x, height: label.br.y - label.lt.y}
          });
          
          let labelDiseaseName = diseases.find((disease) => disease.key.replaceAll(' ','') == label.disease.replaceAll(' ',''))[this.$store.state.diseaseLang].name.replaceAll(' ','');
          let founded = this.diseasesTable.find((disease)=>disease.info.name.replaceAll(' ','') == labelDiseaseName);
          // 선택한 영상의 labels를 순회하며 질환에 대한 정보를 저장하고 있는 diseasesTable에 해당 label의 질환이 포함되어 있지 않은 경우, label의 id와 질환정보를 저장한다.
          if(!founded){
            let diseaseTrObj = diseases.find((disease)=>disease.key.replaceAll(' ','') == label.disease.replaceAll(' ',''));
            let diseaseTrObjLang = diseaseTrObj[this.$store.state.diseaseLang];
            this.diseasesTable.push({id:[label.id.toString()], info:{...diseaseTrObjLang}, color});
          }
          //diseasesTable에 해당 label의 질환이 포함되어 있는 경우, diseasesTable의 질환 객체에 label의 아이디값만 추가한다.
          else {
            founded.id.push(label.id.toString())
          }
        }
        // 선택한 영상의 종류가 'CT'인 경우에 대한 처리
        if(this.exam.type == 'CT'){
          // 해당 label의 bboxes 를 순회하며 각 bbox가 가진 정보를 가공한다.
          label.bboxes.forEach((bbox)=>{
            // bbox의 polygon 키가 있는 경우에 대한 처리
            if(bbox.polygon){
              let str = bbox.polygon.toString().split(',');
              // polygon좌표가 x, y 순으로 나열된 str 만큼 반복문을 순회하여 각각의 좌표를 화면 비율에 맞추어 변환한다. 
              for(let i=0; i<str.length; i++){
                // 좌표는 x, y 순 으로 나열되어 있으므로, i % 2 === 0 인 조건은 x 좌표일 때를 가리킨다.
                if(i % 2 === 0){
                  str[i] = str[i] / this.imgRatio.width;
                }else str[i] = str[i] / this.imgRatio.height;
              }
              arr.push({id:label.bboxes.id,image:bbox.image,points:str.toString(),startCoord:{x:bbox.lt.x / this.imgRatio.width, y: bbox.lt.y / this.imgRatio.height}});
              // 해당 이미지 번호가 diseaseImgNum에 포함되어 있지 않은 경우, 이미지 번호를 push하여 질환이 있는 위치가 range bar 옆에 표시되도록 한다.
              if(!(this.diseaseImgNum.includes(bbox.image))) this.diseaseImgNum.push(bbox.image);
            }
          })
        }
      })
      this.convertedCoords = [...arr];
    },
    _showCrossSectionModal(){
      this.$store.state.crossSectionModal = true;
    },
    _getRandomBboxId(){
      let randomId = 0;
      // 조건식이 true인 경우 while문을 실행한다.
      while(this.exam.seq){
        randomId = Math.floor(Math.random() * 100  + 1);
        /*
        ** 선택한 영상의 labels에서 각 label의 아이디를 담고 있는 bboxIdArr 가 randomId를 포함하고 있지 않는 경우에 대한 처리
        ** randomId를 bboxIdArr에 push하고, 반복문을 종료한다.
        ** bboxIdArr 가 randomId를 포함하고 있는 경우에는 중복이 되므로 continue문으로 다시 randomId를 얻도록 한다.
        */
       if(this.bboxIdArr.indexOf(randomId) == -1){
          this.bboxIdArr.push(randomId);
          break;
        }else continue;
      }
      return randomId;
    },
    // 파일 업로드시 action 을 구분하기 위하여 고유 아이디를 랜덤으로 생성한다.
    _getRandomFileId(){
      let randomId = 0;
      while('true'.length){
        randomId = Math.floor(Math.random() * 100  + 100);
        if(this.$store.state.examsUploaded.indexOf(randomId) == -1){
          this.$store.state.examsUploaded.push(randomId);
          break;
        }else continue;
      }
      return randomId;
    },
    openChat(){
      // 선택된 영상이 없는 경우에는 함수를 종료한다.
      if(!this.exam.seq) return;
      this.chatState = true;
    },
    hoverPolygon(ev){
      // polygon이 보이지 않도록 숨긴 경우에는 함수를 종료한다.
      if(!this.showPolygon || parseInt(ev.target.dataset.score) < parseInt(this.diseaseBoundary)) return;
      this.targetInfo.startDirection = 'polygon';
      this.targetInfo.id = [ev.target.id];
      // 질환목록 테이블의 행들을 순회하여 선택한 polygon과 같은 아이디값을 포함하고 있는 행을 찾아 그 행이 가운데에 위치하도록 한다.
      this.$refs.diseasesTableRow.forEach((row) => {
        if(row.id.split(',').includes(ev.target.id)){
          row.scrollIntoView({behavior:'smooth',block:'center'});
        }
      })
    },
    hoverTableRow(ev){
      // polygon이 보이지 않도록 숨긴 경우에는 함수를 종료한다.
      if(!this.showPolygon) return;
      this.targetInfo.startDirection = 'table';
      // target의 id가 ',' 를 포함하고 있는 경우(= 아이디가 2개 이상으로 질환이 중복되는 경우)에 id값은 문자열 타입이므로 id값을 split하여 저장한다.
      if(ev.target.id.includes(',')) this.targetInfo.id = ev.target.id.split(',');
      // 질환이 중복되지 않는 경우에 id값을 저장한다.
      else this.targetInfo.id = [ev.target.id];
    },
    resetTargetIdInfo(){
      this.targetInfo = {
        id: [],
        startDirection: ''
      }
    },
    goPrevExam(){
      // 해당 영상의 _prevExam 키가 없는 경우(= 이전 영상이 없는 경우)에 함수를 종료한다.
      if(!this.exam._prevExam) return;
      let packet = this.$$build(157,5,[{
        contentType: 1,
        content: {
          filter: {
            exam: [this.exam._prevExam.seq]
          }
        }
      }])
      this.$socket.send(packet);
    },
    goNextExam(){
      // 해당 영상의 _nextExam 키가 없는 경우(= 다음 영상이 없는 경우)에 함수를 종료한다.
      if(!this.exam._nextExam) return;
      let packet = this.$$build(157,5,[{
        contentType: 1,
        content: {
          filter: {
            exam: [this.exam._nextExam.seq]
          }
        }
      }])
      this.$socket.send(packet);
    },
    // 질환 민감도를 설정한다.
    setDiseaseBoundary(ev){
      if(!ev.target.name) return;
      this.diseaseBoundary = ev.target.name;
    },
    onDragOver(ev){
      ev.preventDefault();
    },
    getFileType(file){
      let type = file.name.split('.');
      return type[type.length-1].toLowerCase(); 
    },
    onDrop(ev){
      this.$store.state.draggedPosition = '';
      ev.preventDefault();
      let files = [];
      let filesByFormat = {
        dcm:{files: [], length: 0},
        png:{files: [], length: 0},
        jpg:{files: [], length: 0},
        bmp:{files: [], length: 0}
      };
      if (ev.dataTransfer.items) {
        [...ev.dataTransfer.items].forEach((item) => {
          if (item.kind === "file") {
            const file = item.getAsFile();
            let type = this.getFileType(file);
            if( type != 'dcm' && type != 'png' && type != 'jpg' && type != 'bmp' ) return;
            filesByFormat[type].files.push(file);
            filesByFormat[type].length += 1;
            files.push(file);
          }
        });
      }
      else {
        [...ev.dataTransfer.files].forEach((file) => {
          let type = this.getFileType(file);
          if( type != 'dcm' && type != 'png' && type != 'jpg' && type != 'bmp' ) return;
          filesByFormat[type].files.push(file);
          filesByFormat[type].length += 1;
          files.push(file);
        });
      }

      // 형식에 맞는 파일이 하나라도 업로드 된 경우
      if(files.length > 0) {
        // 파일 형식이 dcm인 경우
        if(filesByFormat.dcm.length > 0) this.proc(filesByFormat.dcm.files,'dcm');
        // 파일 형식이 dcm이 아닌 경우, 가장 처음 얹은 파일을 업로드 한다.
        else this.proc([files[0]],this.getFileType(files[0]));
      }
      else {
        return this.alert('error',this.lang[this.$store.state.lang].MAIN_ALERT_TXT_EXAM_FILE_NOT_ALLOWED); // dcm, png, jpg, bmp 형식의 파일만 업로드가 가능합니다.
      }
    },
    proc(files,type) {
      let promises = files.map((file) => {
        return new Promise((resolve) => {
          let fr = new FileReader();
          fr.onload = (e) => {
            let buf = new Uint8Array(e.target.result);
            // PX이미지인지 확인
            if( type == 'dcm' ){
              let dataSet = dicomParser.parseDicom(buf);
              if( dataSet.string('x00080060') != "PX") {
                this.alert('error',`${file.name} ${this.lang[this.$store.state.lang].MAIN_ALERT_TXT_EXAM_FILE_NOT_ALLOWED2}`);
                resolve(false);
                return;
              }
            }
            
            this.$store.state.fileArr.push({
              contentType: 3,
              content: {
                filename: file.name,
                file: Array.from(buf)
              }
            });
            resolve(true);
          }
          fr.readAsArrayBuffer(file);
        });
      });
      
      Promise.all(promises).then(()=>{
        if(this.$store.state.fileArr.length > 0) {
          if(type == 'dcm') {
            const packetId = this._getRandomFileId();
            let packet = this.$$build(64,packetId,this.$store.state.fileArr);
            this.$socket.send(packet);
            this.$store.state.fileArr = [];
          }
          // 파일 형식이 dcm 이 아닌 경우, 파일 정보를 작성하는 모달을 띄운다.
          else this.$store.state.fileInfoModal = true;
        }
      });
    },
    uploadExamFiles(ev){
      this.proc([...ev.target.files]);
    },
    convertImplantCoord(implants){
      this.implants = [];
      implants.forEach((implant) => {
        this.implants.push({
          point: {x: implant.point[0] / this.imgRatio.width, y: implant.point[1] / this.imgRatio.height },
          models: [...implant.models],
        })
      });
    },
    showImplantInfo(ev){
      if(!this.showImplant) return;
      this.clickedImplant = ev.target.id;
    },
    hoverImplant(ev){
      if(!this.showImplant) return;
      this.hoveredImplant = ev.target.id;
    },
    leaveImplant() {
      this.hoveredImplant = '';
    }
  },
  watch : {
    exam (newVal,prevVal){
      if(!newVal.seq) return;
      if(this.examChangeComplete) this.examChangeComplete = false;

      let img;
      if(newVal.type == 'PX') {
        this.imgUrl = this.baseUrl+newVal.url;
        img = new Image();
        img.src = this.imgUrl;
      }

      this.$store.state.readingReport = false;
      this.showReqPopupTextarea = false;
      this.ccText = "";
      this.selectedCC = "기본";
      if(this.chatState) this.chatState = false;
      this.showPolygon = true;
      this.showImplant = false;
      // 선택된 영상의 chat이 false인 경우 채팅목록이 없으므로, msgs를 비워준다. 
      if(!newVal.chat) this.msgs = [];
      // 선택된 영상의 chat이 true 이면서 다른 영상을 선택했을 때 msgs를 비우고, 채팅메시지 열람 action을 보낸다.
      if(newVal.seq != prevVal.seq){
        this.msgs = [];
        let packet = this.$$build(101,[{
          contentType: 1,
          content: {exam: newVal.seq}
        }])
        this.$socket.send(packet);
        this.msgsIsLoaded = false;
      }
      this.rangeVal = 1;
      this.convertedCoords = [];
      this.zoomDefault();
      /*
      ** 선택한 영상의 종류가 'CT'인 경우에 대한 처리
      ** 영상의 마지막 이미지번호, imgUrl을 저장하고 이미지를 로딩하는 함수를 호출한다. 
      */
      if(this.exam.type == 'CT'){
        this.maxRangeVal = parseInt(this.exam.url.split('.').slice(-2,-1)[0]);
        this.ctImgUrl = this.exam.url;
        this.ctPreviewImg = this.exam.url;
        this._setPreImg();
      }
      this.bboxIdArr = [];
      // 선택한 영상의 labels를 순회하며 각 label의 id값에 중복되지 않는 랜덤 숫자를 대입한다. 
      this.exam.labels.disease.forEach((label)=>{
        label.id = this._getRandomBboxId();
      })

      if(this.shouldOpenChat) this.chatState = true;
      if(this.$refs.bottomTable?.scrollTop != 0 && prevVal.seq) this.$refs.bottomTable.scrollTop = 0;
      this.clickedImplant = '';
      this.hoveredImplant = '' ;

      this.$nextTick(()=>{
        this.diseaseImgNum = [];
        let diseaseLists = [];
        this.exam.labels.disease.forEach((label) => {
          if(!diseaseLists.includes(label.disease) && label.polygon) diseaseLists.push(label.disease);
        });
        const browserInnerWidth = window.innerWidth;
        const th = browserInnerWidth <= 1024 ? 22 : 32;
        const tr = browserInnerWidth <= 1024 ? 24 : 34;
        const bottomTableMaxHeight = browserInnerWidth <= 1024 ? 140 : 180;
        if(diseaseLists.length >= 5) this.imgAbHeight = this.$refs.diagnosisResult.clientHeight - (10 + bottomTableMaxHeight);
        else this.imgAbHeight = this.$refs.diagnosisResult.clientHeight - (10 + th + (diseaseLists.length * tr));
        this.imgAbHeight -=  this.$refs.viewerFilter.clientHeight;
        this.imgBox.width = this.$refs.diagnosisResult.clientWidth;
        this.imgBox.height = this.imgAbHeight;
        this.resize();
        
        if(newVal.type == 'PX'){
          img.onload = () => {
            this.examChangeComplete = true;
          }
        }
      })
    },
    rangeVal(){
      let url = this.ctImgUrl.split('.');
      url.splice(-3,1,this.rangeVal.toString());
      url = url.join('.');
      this.ctImgUrl = url;
    },
    selectedCC(newVal){
      if(newVal.includes('직접')) this.showReqPopupTextarea = true;
      else {
        this.showReqPopupTextarea = false;
        this.ccText = '';
      }
    },
    opacity(newVal){
      if(this.$refs.imgScrollContainer){
        if(newVal == 0) {
          this.$refs.imgScrollContainer.style.transition = 'opacity 0s';
        }else this.$refs.imgScrollContainer.style.transition =  'opacity 1.5s'
      }
    },
    'exam.status'(){
      if(this.exam.status == 'ready' && this.exam.type != 'PX') this.requestPopup = true;
    },
    clickedImplant(val){
      this.$nextTick(() => {
        if(val) {
          let width;
          let implantInfoBox = this.$refs.implantInfo;
          let bbox = {x: 0, width: 0};
          implantInfoBox.forEach((box) => {
            let boxRect = box.getBoundingClientRect();
            if(boxRect.x > 0) {
              width = boxRect.width;
              bbox = {x: box.parentNode.getBoundingClientRect().x, width: parseInt(box.parentNode.style.width.replace('px',''))}
            }
          });
          let container = this.$refs.imgBoxRef.getBoundingClientRect();
          // implant 정보를 보여주는 box 의 오른쪽 x좌표가 화면을 넘어가는 경우, 왼쪽으로 위치하여 보이도록 한다.
          if( bbox.x + bbox.width + width > container.x + this.$refs.imgBoxRef.clientWidth ) this.implantInfoBoxPos = {right: 'calc(100% + 3px)',textAlign: 'start'};
          // implant 정보를 보여주는 box 의 오른쪽 x좌표가 화면을 넘어가지 않는 경우, 오른쪽으로 위치하여 보이도록 한다.
          else this.implantInfoBoxPos = {left: 'calc(100% + 3px)', textAlign: 'start'};
        }
      })
    },
    "targetInfo.id"(val){
      if(val.length == 0) return;
      this.$nextTick(() => {
        this.$refs.diseaseBboxName.forEach((bboxName) => {
          if(this.targetInfo.id.includes(bboxName.dataset.bboxnameid)){
            let bboxNameX = bboxName.parentNode.getBoundingClientRect().x + 3;
            let imgContainer = this.$refs.imgBoxRef.getBoundingClientRect().x + this.$refs.imgBoxRef.clientWidth;
            // 질환명이 화면을 넘어가는 경우, bbox의 오른쪽에 맞춰 위치하도록 한다.
            if(bboxNameX + bboxName.clientWidth > imgContainer) {
              if(bboxName.style.left) bboxName.style.left = null;
              bboxName.style.right = '0';
            }
            // 질환명이 화면을 넘어가지 않는 경우, bbox의 왼쪽에 맞춰 위치하도록 한다.
            else {
              if(bboxName.style.right) bboxName.style.right = null;
              bboxName.style.left = '0';
            }
          }
        })
      })
    },
    examChangeComplete(val){
      if(!val) return; 
      this.convertDiseaseCoord(this.exam.labels);
      this.$store.state.imgScrollContainerOpacity = 1;
      this.examChangeComplete = false;
    }
  },
  computed: {
    opacity(){
      return this.$store.state.imgScrollContainerOpacity;
    },
  },
  mounted(){
    document.addEventListener('click',(ev)=>{
      let id = ev.target.id;
      if( this.clickedImplant && (! id || !id.includes('implant'))) this.clickedImplant = '';
      if(id != 'parameterIcon') this.parameter = false;
    })
  },
}
</script>

<style scoped>
  .window{
    display: block;
  }
  .block_on{
    display: block !important;
  }
  .flex_on{
    display: flex !important;
  }
  .grid_on{
    display: grid !important;
  }
  .img_container{
    margin: 0 auto;
    overflow: hidden;
    position: relative;
  }
  .img{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .ct_container{
    display: grid;
    grid-template-columns: 1fr 21px;
    place-items: center;
  }
  .prev_img_box{
    position: absolute;
    width: 100px;
    height: 60px;
    border: 2px solid white;
  }
  .prev_img_box img{
    width: 100%;
    height: 100%;
  }
  input[type=range]{
    -webkit-appearance: none;
    height: 8px;
    position: absolute;
    transform: rotate(-90deg);
    border-radius: 10px;
    background-color: #5B5B5E;
    outline: none;
  }
  input[type=range]::-webkit-slider-thumb{
    -webkit-appearance: none;
    margin-left: -2px;
    width: 12px;
    height: 24px;
    border-radius: 32px;
    background: #A45CFE;
  }
  .disease_point_highlight{
    position: absolute;
    width: 12px;
    top: 30px;
    left: 0;
    background: #F06D77;
  }
  .center{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .svg_container{
    position: relative;
  }
  .px_img{
    position: absolute;
  }
  polygon{
    position: absolute;
    cursor: pointer;
    transition: fill linear 0.3s;
  }
  polygon:hover{
    opacity: 0.1;
    transition: none;
  }
  .bg_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  polygon{
    transition: opacity 0.5s, fill 0.5s !important;
  }
  .fill_transparent{
    fill: transparent !important;
    stroke: rgba(87, 78, 224, 1);
    stroke-width: 2px;
    stroke-dasharray: 6 6;
  }
  .tr_selected{
    background: rgba(87, 78, 224, 0.33) !important;
  }
  .tr_basic{
    background: #1A1A1B;
  }
  .img_size{
    position:fixed;
    bottom: 50px;
    right: 40px;
    width: 60px;
    height: 60px;
    border-radius: 40%;
    cursor: pointer;
    z-index: 5;
  }
  .chat_img_position{
    position: absolute;
    right: -10px;
    bottom: -10px;
  }
  .hidden{
    opacity: 0;
    visibility: hidden;
  }
  .show{
    opacity: 1;
    visibility: visible;
  }
  .viewer_filter_wrap{
    padding: 0 11px;
    height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 14px;
  }
  .disease_boundary{
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .disease_boundary>div:first-child{
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .disease_boundary button{
    margin-right: 6px;
    transition: background-color 0.5s;
  }
  .polygon_filter_btn{
    width: 72px;
    height: 22px;
    border-radius: 12px;
    color: white;
    background: #848488;
    cursor: pointer;
  }
  .disease_boundary_selected{
    background-color: #A45CFE;
  }
  button{
    border: none;
  }
  .show_disease{
    display: flex;
    gap: 7px;
  }
  .filter_right{
    display: flex;
    gap: 34px;
  }
  .slide{
    position: relative;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;
    height: 22px;
    border: 1px solid black;
    border-radius: 10px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .slide span{
    font-size: 10px;
  }
  .slide_dot{
    width: 18px;
    height: 18px;
    position: absolute;
    right: 5px;
    background-color: #9560F5;
    border-radius: 50%;
    transition: transform 0.5s;
  }
  .dot_on{
    transform: translateX(-22px);
    background: #848488;
  }
  .slide_on{
    color: #574EE0;
    background-color: white;
  }
  .slide_off{
    color:  #5B5B5E;
    background: #DFDEE0;
  }
  .bbox{
    display: inline-block;
    position: absolute;
    box-sizing: border-box;
  }
  .bbox_name{
    position: absolute;
    top: calc(100% + 3px);
    background-color:  rgba(64, 63, 69, 0.5);
    color: white;
    white-space: nowrap;
  }
  .select_file{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .select_file label{
    cursor: pointer;
  }
  .select_file_text>p:first-child{
    color: white;
    font-size: 28px;
    font-weight: 700;
  }
  .select_file_text>p:last-child{
    color: #DFDEE0;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }
  #main .diagnosis>.black_announce{
    border: 6px solid transparent;
    box-sizing: border-box;
  }
  .dragged_boundary{
    width: 100%;
    height: 100%;
    position: absolute;
    top:0;
    left:0;
    border: 6px solid #3b3688;
    background-color: rgba(59, 54, 136,0.3);
    box-sizing: border-box;
  }
  .print_wrap .print button:hover{
    background-color: rgba(87, 78, 224, 0.5);
  }
  #main .diagnosis .top .print:hover{
    border-color: rgba(87, 78, 224, 0.5);
  }

  .implant_bbox{
    position: absolute;
    box-sizing: border-box;
    border: 3px solid #A036F6;
  }
  .implant_info_box{
    padding: 8px 16px;
    background-color: #403f45cc;
    position: absolute;
    top: -3px;
  }
  .implant_img{
    transition: opacity 0.5s;
  }
  .parameter_box{
    position: absolute;
    top: 110%;
    left: 95px;
    width: 307px;
    padding: 8px 16px;
    background: rgba(64, 63, 69, 0.8);
    font-size: 12px;
    z-index: 2;
  }
  .parameter_box ul{
    margin-top: 10px;
    list-style-type: disc;
    padding-left: 20px;
  }
  .parameter_box ul>li{
    margin-bottom: 5px;
  }
  .implant_wrap{
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
  }
  .implant_wrap>.col_left,
  .implant_wrap>.col_right{
    padding-bottom: 8px;
    border-bottom: 1px solid #5B5B5E;
  }
  .implant_wrap:last-child .col_left,
  .implant_wrap:last-child .col_right{
    border-bottom: none;
  }
  .implant_wrap:last-child {
    margin-bottom: 0;
  }
  .implant_wrap span{
    white-space: nowrap;
  }
  .implant_wrap>.col_left span:first-child{
    padding-right: 12px;
  }
  .diagnosis .top .control_wrap button{
    display: flex;
    align-items: center;
  }
  .diagnosis .top .control_wrap button:last-child{
    justify-content: flex-end;
  }
</style>