import Vue from 'vue'
import VueRouter from 'vue-router'
import App from "./App"
import routes from './routes'
import store from './store'
import VueNativeSock from 'vue-native-websocket'
import {Buffer} from 'buffer'

Buffer.prototype.printDebugString = function() {
  let str = "";
  str += "[";
  for(let i=0; i<this.length; i+=1) {
    str += ("0"+this[i].toString(16)).slice(-2);
    if(i<this.length-1) str += ",";
  }
  str += "]";
  return str;
};

// vue.js 설정
Vue.config.productionTip = false

// 라우터 생성
Vue.use( VueRouter );
let router = new VueRouter({routes});

// 웹소켓 생성
Vue.use(VueNativeSock, process.env.VUE_APP_DEBUG?process.env.VUE_APP_API_DEV_URL:process.env.VUE_APP_API_URL, {
  store,
  reconnection: true,
  reconnectionDelay: 500
});

// 앱 초기화
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
