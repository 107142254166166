<template>
  <div class="filter_block on">
    <div class="filter" style="position:relative">
      
      <div 
        v-if="$store.state.draggedPosition == 'main' || $store.state.draggedPosition == 'list'" 
        :class="['dragged_boundary', $store.state.draggedPosition == 'main' ?  'main_dragged_width' : 'list_dragged_width']" 
        :style="{height: `${listPosInfo.height}px`, top: `${listPosInfo.y}px`, borderRight: $store.state.draggedPosition == 'main' ? '6px solid rgb(32,31,47)' : '6px solid #3b3688'}"
        @dragleave="$store.state.draggedPosition = ''"
        @dragenter="$store.state.draggedPosition = 'list'"
        @drop="onDrop" 
        @dragover="onDragOver"
      >
      </div>

      <div id="logo" @click="refreshPage">
        <button><img src="@/assets/images/logo_without_rd.png" width="202" height="60" alt="" /></button>
      </div>
      <div id="main_filter" class="top">

        <div id="main_date_filter" class="date_filter">
          <div class="date_buttons" @click="setDateUsingBtn">
            <button :class="['today', selectedDateBtn == 'today'? 'selected_date_btn' :'']" name="today">{{lang[$store.state.lang].FILTER_TXT_TODAY}}<!--오늘--></button>
            <div></div>
            <button :class="['week', selectedDateBtn == 'week'? 'selected_date_btn' :'']" name="week">{{lang[$store.state.lang].FILTER_TXT_WEEK}}<!--일주일--></button>
            <div></div>
            <button :class="['month', selectedDateBtn == 'month'? 'selected_date_btn' :'']" name="month">{{lang[$store.state.lang].FILTER_TXT_MONTH}}<!--한달--></button>
            <div></div>
            <button :class="['all', selectedDateBtn == 'all'? 'selected_date_btn' :'']" name="all">{{lang[$store.state.lang].FILTER_TXT_ALLTIME}}<!--전체--></button>
          </div>

          <div ref="calendarBtn" id="listCalendarBtn" class='date_calender' :style="{border:$store.state.listCalendar?'1px solid #9560F5':'1px solid #2E2E33'}">
            <CalendarModal @closeCalendar="closeCalendar" type="list" v-if="$store.state.listCalendar" @pickOnlyStartDate="pickOnlyStartDate" @pick_date="setDate" @complete="$store.state.listCalendar = false" :from="from" :to="to" top='199' left='97'/>
            <div id="listCalendarIcon" :class="['icon_position','icon_basic']"></div>
            <div class="date_text" id="listCalendarInput" @click="controlListModal">
              <span style="text-align:end">{{from ? from.replaceAll('-','.') : ''}}</span>
              <span class="bar_horiz"></span>
              <span>{{to ? to.replaceAll('-','.') : ''}}</span>
            </div>
          </div>
        </div>

        <div class="feature_filter">
          <div class="select_filter">
            <select id="main_exam_type_select" v-model="examType">
              <option selected disabled value="examType">{{lang[$store.state.lang].FILTER_TXT_TYPE}}<!--영상종류--></option>
              <option value="all">{{lang[$store.state.lang].FILTER_TXT_ALLTYPE}}<!--전체--></option>
              <option value="PX">PANORAMA</option>
              <option value="CT">CBCT</option>
            </select>
            <label for="main_exam_type_select"></label>
          </div>
          <div class="select_filter">
            <select id="main_exam_status_select" v-model="readingState">
              <option selected disabled value="status">{{lang[$store.state.lang].FILTER_TXT_STATUS}}<!--판독상태--></option>
              <option value="all">{{lang[$store.state.lang].FILTER_TXT_ALLSTATUS}}<!--전체--></option>
              <option value="pending">{{lang[$store.state.lang].FILTER_TXT_REQUESTED}}<!--판독의뢰--></option>
              <option value="completed">{{lang[$store.state.lang].FILTER_TXT_COMPLETED}}<!--판독완료--></option>
              <option value="unreadable">{{lang[$store.state.lang].FILTER_TXT_UNREADABLE}}<!--판독불가--></option>
              <option value="chat">{{lang[$store.state.lang].FILTER_TXT_CHAT}}<!--채팅상담--></option>
              <option value="highlight">{{lang[$store.state.lang].FILTER_TXT_SPECIFIC}}<!--특이소견--></option>
            </select>
            <label for="main_exam_status_select"></label>
          </div>
        </div>

        <div class="keyword_filter"  :class="[isSearched && keyword ? 'border_active' : '']">
          <div class="form_cont">
            <input type="text" id="main_filter_keyword" placeholder=" " v-model="keyword" @keydown="searchEnter">
            <label for="main_filter_keyword">{{lang[$store.state.lang].FILTER_TXT_ENTER_KEYWORD}}<!--환자명 또는 진료번호를 입력하세요--></label>
          </div>
        </div>

        <div class="submit_btn_wrap">
          <button @click="search" :class="['basic_btn', 'purple_btn', 'search_btn']">{{lang[$store.state.lang].FILTER_BTN_SEARCH}}<!--검색하기--></button>
          <button @click="refreshExams" class="basic_btn black_btn clear_btn"><img src="@/assets/images/refresh.svg" :alt="lang[$store.state.lang].FILTER_BTN_CLEAR"></button>
        </div>

        <div class="exam_box gray-scrollbar" @scroll="scrollDownExams" ref="examsScrollContainer" @dragenter="$store.state.draggedPosition = 'list'">
          <div v-if="isNoExam" class="no_result">{{lang[$store.state.lang].LIST_TXT_NO_EXAM_FOUNDED}}<!--검색결과가 없습니다.--></div>
          <table v-else id="exam_table" class="exam_table hover_selectable_table" ref="examsTable">
            <tbody>
              <tr :ref="'exam'+exam.seq" :class="[exam.seq == selectedExam.seq ? 'selected_exam' : '', exam.__isNew?'new_added':'']" v-for="(exam,idx) in exams" :key="'exams-'+idx+'-'+exam.seq" @click="setExam(exam)">
                <td class="highlight">
                  <div><span style="display:none"></span></div>
                </td>
                <td class="exam_type" style="display:none">
                    <div><p>{{exam.type}}</p></div>
                </td>
                <td class="patient_id">
                    <div><p>{{exam.chart_id}}</p></div>
                    <div><p>{{`${new Date(exam.taken).getFullYear()}-${(new Date(exam.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(exam.taken).getMonth()+1).toString():new Date(exam.taken).getMonth()+1}-${(new Date(exam.taken).getDate()).toString().length == 1 ? '0'+(new Date(exam.taken).getDate()).toString() : new Date(exam.taken).getDate()}`}}</p></div>
                </td>
                <td class="patient_name">
                    <div><p>{{exam.patient_name}}</p></div>
                    <div><p>{{new Date(exam.taken).toTimeString().slice(0,8)}}</p></div>
                </td>
                <td class="state" style="display:none">
                    <div>
                        <p :style="{display:exam.status=='pending'?'block':'none'}" class="requested">{{lang[$store.state.lang].LIST_TXT_REQUESTED}}</p>
                        <p :style="{display:exam.status=='completed'?'block':'none'}" class="complete">{{lang[$store.state.lang].LIST_TXT_COMPLETED}}</p>
                        <p :style="{display:exam.status=='unreadable'?'block':'none'}" class="complete">{{lang[$store.state.lang].LIST_TXT_UNREADABLE}}</p>
                    </div>
                </td>
                <td>
                  <p class="bar_vertical"></p>
                </td>
                <td class="chat">
                  <div v-if="exam.chat" :class="[exam.chat ? (exam.new_chat ? 'new_chat' : 'chat_img')  : '']"></div>
                  <div v-if="!exam.chart && exam.__examSeq || exam.status != 'completed'" class="exam_loading" style="display:flex; alignItems:center; justifyContent:center">
                    <div class="spinner_sm"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div :style="{backgroundColor:scrollIsAlerted?'#dc3545':'transparent'}" class="alert_line"></div>

          <div class="loading" :style="{display:examsLoaded?'none':'flex', top:examScrollTop+'px'}">
            <div class="spinner"></div>
          </div>
        </div>
        <div id="full_scan" class="exam_file_upload">
          <input type="file" id="examFileUploadFromList" multiple style="display:none" @change="uploadExamFiles">
          <label class="basic_btn" for="examFileUploadFromList">
            <span>{{lang[$store.state.lang].LIST_BTN_UPLOAD_PX_FILE}}<!--파노라마 업로드--></span> 
              <div class="exam_loading" v-if="$store.state.examsUploaded.length">
                <div class="spinner_sm"></div>
              </div>
          </label>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import wsMixin from '@/wsMixin';
import CalendarModal from './CalendarModal.vue';
import dicomParser from 'dicom-parser'
import alert from '@/alert'

export default {
  name: "MainList",
  mixins : [wsMixin],
  components : {
    CalendarModal,
  },
  props : {
    exams : Array,
    examsLoaded : Boolean,
    limit: Number,
    selectedExam: Object,
    scrollIsAlerted: Boolean,
  },
  data: function() {
    return {
      lang,
      from : "",
      to : "",
      keyword : "",
      examType : "examType",
      readingState : "status",
      isSearched : false,
      isNoExam : false,
      examScrollTop: 0,
      hasExamInExams: false,
      listPosInfo: {
        height: 0,
        y: 0
      }, // 리스트 위에서 파일드롭을 할 때 업로드 가능 범위를 나타내는 ui를 그리기 위한 정보
      selectedDateBtn: '', // today || week || month || all
    }
  },
  methods : {
    alert,
    setExam(exam){
      this.$store.state.imgScrollContainerOpacity = 0;
      this.$emit('set_exam',exam);
    },
    // 달력에서 날짜를 선택할 때 해당 함수가 실행된다.
    setDate(range){
      this.selectedDateBtn = '';
      /*
      ** range의 data type은 array이고, 0번 index는 dateFrom, 1번 index는 dateTo를 가리킨다.
      ** dateFrom, dateTo가 결정되지 않은 경우 기본값은 빈 문자열 이다. 
      */
      // dateTo 가 결정된 경우에 대한 처리로, to 를 변경한다.
      if(range[1].length>0){
        this.to = this.__makeDateString(new Date(range[1].replace(/-/g, '/')),'-');
      }
      // dateTo가 결정되지 않은 경우에 대한 처리로, 화면에 시작일을 표시하고, from, to를 변경한다.
      else{
        this.from = this.__makeDateString(new Date(range[0].replace(/-/g, '/')),'-');
        this.to = '';
      }
    },
    // to가 선택되지 않은 상태에서 달력이 닫힌 경우에 해당 함수가 호출된다.
    pickOnlyStartDate(){
      this.to = this.from;
    },
    search(){
      let body = [];
      /*
      ** 사용자가 특정 조건을 가진 영상목록을 조회하는 경우에 대한 처리
      ** 시작일이 설정되어있거나, 영상의 종류가 'all', 'examType'(기본값) 이 아니거나, 판독상태가 'all', 'status'(기본값) 이 아니거나, keyword의 값이 0 보다 큰 경우에 대한 처리
      ** 위와 같은 상황은 사용자가 조회할 때, 전체가 아닌 특정 조건을 가진 영상을 조회한 것 이다.
      */
      if(!((this.from.length == 0 ) && (this.examType == 'all'||this.examType == 'examType') && (this.readingState=="all"||this.readingState=="status") && this.keyword.length==0)){
        let filterObj = {contentType:1,content:{filter:{}}};
        // 시작일이 설정되어있는 경우에 대한 처리
        if(this.from.length > 0 ) {
          filterObj.content.filter.dateFrom = this.from;
          filterObj.content.filter.dateTo = this.to;
        }
        // 영상의 종류가 'all', 'examType'(기본값) 이 아닌 경우에 대한 처리
        if(this.examType != 'all'&& this.examType != 'examType') filterObj.content.filter.type = this.examType;
        // 판독상태가 'all','status'(기본값) 가 아닌 경우에 대한 처리
        if(this.readingState != "all" && this.readingState != 'status') filterObj.content.filter.status = this.readingState;
        // keyword의 길이가 0보다 큰 경우에 대한 처리
        if(this.keyword.length > 0) filterObj.content.filter.keyword = this.keyword;
        body.push(filterObj);
      } 
      this.$refs.examsScrollContainer.scrollTop = 0;
      this.$emit('search',body[0]);
      this.isSearched = true;
    },
    __makeNumberToString(number){
      // 문자열로 변환된 number 의 길이가 1 인 경우(= 일의 자리 숫자)에 0을 앞에 붙여 반환한다.
      if(number.toString().length == 1) return `0${number}`;
      // 문자열로 변환된 number 의 길이가 1 이 아닌 경우(= 십의 자리 숫자)에 문자열로 변환된 number를 반환한다.
      else return number.toString();
    },
    __makeDateString(date,separator){
      return `${date.getFullYear()}${separator}${this.__makeNumberToString(date.getMonth()+1)}${separator}${this.__makeNumberToString(date.getDate())}`
    },
    // 기간이 표시된 버튼을 클릭하여 from, to 를 설정하는 경우 해당 함수가 호출된다.
    setDateUsingBtn(ev){
      this.selectedDateBtn = ev.target.name;
      // click 이벤트가 발생한 target의 name 속성이 'all'인 경우에 대한 처리
      if(ev.target.name == 'all'){
        this.from = "";
        this.to = new Date().toISOString().slice(0,10);
      }
      // click 이벤트가 발생한 target의 name 속성이 'today'인 경우에 대한 처리
      else if(ev.target.name == 'today') {
        this.from = this.__makeDateString(new Date(),'-');
        this.to = this.__makeDateString(new Date(),'-');
      }
      // click 이벤트가 발생한 target의 name 속성이 'all 이거나, 'today'인 경우를 제외한 때에 대한 처리
      else {
        let today = new Date();
        let start = "";
        // click 이벤트가 발생한 target의 name 속성이 'week'인 경우에 대한 처리
        if(ev.target.name == 'week'){
          start = new Date(today.getFullYear(),today.getMonth(),today.getDate()-6)
        }
        // click 이벤트가 발생한 target의 name 속성이 'month'인 경우에 대한 처리
        else if(ev.target.name == 'month'){
          start = new Date(today.getFullYear(),today.getMonth()-1,today.getDate());
        }
        this.from = this.__makeDateString(start,'-');
        this.to = this.__makeDateString(today,'-');
      }
    },
    refreshExams(){
      this.from = "";
      this.to = "";
      this.selectedDateBtn = '';
      this.examType = "examType";
      this.readingState = "status";
      this.keyword = "";
      this.$refs.examsScrollContainer.scrollTop = 0;
      this.$emit('refreshExams');
      this.isSearched = false;
    },
    // 로고를 클릭하여 페이지를 새로고침 할 때 해당 함수가 호출된다.
    refreshPage(){
      this.$router.go();
    },
    scrollDownExams(ev){
      // 스크롤이 끝까지 다 내려간 경우에 이벤트를 방출하여 상위 컴포넌트에서 영상목록을 추가하도록 한다.
      if(Math.ceil(ev.target.scrollTop+ev.target.clientHeight)>=ev.target.scrollHeight){
        if(this.examsLoaded) this.$emit('updatePage');
      }
      // examsLoaded 의 값이 false 인 경우(= 영상목록 action에 대한 응답이 도착하지 않은 경우)에 영상목록이 로딩중임을 알려주기 위한 인디케이터의 위치를 영상목록이 스크롤 된 만큼 옮겨주어, 중앙에 위치하도록 한다.
      if(!this.examsLoaded) {
        this.examScrollTop = this.$refs.examsScrollContainer.scrollTop;
      }
    },
    // 키워드입력 후 엔터를 입력하는 경우 해당 함수가 호출된다.
    searchEnter(ev){
      if(ev.keyCode == 13) this.search();
    },
    closeCalendar(target){
      // click 이벤트가 발생한 target이 달력을 여는 버튼을 포함하지 않는 외부영역인 경우, 달력을 닫는다.
      if(!this.$refs.calendarBtn.contains(target)) this.$store.state.listCalendar = false;
    },
    setExamByKeyboard(ev){
      if(
        ev.target.tagName == 'TEXTAREA' || 
        ev.target.tagName == 'INPUT' ||
        ev.target.tagName == 'SELECT' ||
        this.$store.state.modalViewModal ||
        this.$store.state.alarmHistoryModal ||
        this.$store.state.reqHistoryModal ||
        this.$store.state.myInfoModal ||
        this.$store.state.logoutModal || 
        this.$store.state.readingReport || 
        !this.hasExamInExams
      ) return;
      if(this.selectedExam.seq) {
        let nowIndex = this.exams.findIndex((exam) => exam.seq == this.selectedExam.seq);
        // up 키를 누른 경우
        if(ev.keyCode == 38) {
          // 현재 exam이 가장 상위에 위치하거나, 현재 선택된 exam이 없는 경우를 제외
          if(nowIndex != 0 && nowIndex != -1) {
            if(this.$refs.examsScrollContainer.scrollTop != 0) {
              this.$refs['exam'+this.exams[nowIndex].seq][0].scrollIntoView({block:'nearest'});
            }
            this.$store.state.imgScrollContainerOpacity = 0;
            this.$emit('setExamByKey',nowIndex - 1);
          }
        }
        // down 키를 누른 경우
        else if(ev.keyCode == 40) {
          // 현재 exam이 가장 마지막에 위치하거나, 현재 선택된 exam이 없는 경우를 제외
          if(nowIndex != this.exams.length-1 && nowIndex != -1) {
            this.$refs['exam'+this.exams[nowIndex].seq][0].scrollIntoView({block:'nearest'});
            this.$store.state.imgScrollContainerOpacity = 0;
            this.$emit('setExamByKey',nowIndex + 1);
          }
        }
      } 
    },
    controlListModal(){
      // 날짜가 입력되는 div를 클릭할 때 마다 달력의 상태가 변경된다.
      this.$store.state.listCalendar = !this.$store.state.listCalendar;
    },
    onDragOver(ev){
      ev.preventDefault();
    },
    // file을 인자로 전달받아 파일의 타입을 소문자로 반환한다.
    getFileType(file){
      let type = file.name.split('.');
      return type[type.length-1].toLowerCase(); 
    },
    // 파일 업로드 action을 보낼 때 어떤 액션에 대한 응답이 왔는지 구분하기 위해 겹치지 않도록 랜덤한 id를 반환한다.
    _getRandomFileId(){
      let randomId = 0;
      while('true'.length){
        randomId = Math.floor(Math.random() * 100  + 100);
        if(this.$store.state.examsUploaded.indexOf(randomId) == -1){
          this.$store.state.examsUploaded.push(randomId);
          break;
        }else continue;
      }
      return randomId;
    },
    onDrop(ev){
      this.$store.state.draggedPosition = '';
      ev.preventDefault();
      let files = [];
      let filesByFormat = {
        dcm:{files: [], length: 0},
        png:{files: [], length: 0},
        jpg:{files: [], length: 0},
        bmp:{files: [], length: 0}
      };
      if (ev.dataTransfer.items) {
        [...ev.dataTransfer.items].forEach((item) => {
          if (item.kind === "file") {
            const file = item.getAsFile();
            let type = this.getFileType(file);
            if( type != 'dcm' && type != 'png' && type != 'jpg' && type != 'bmp' ) return;
            filesByFormat[type].files.push(file);
            filesByFormat[type].length += 1;
            files.push(file);
          }
        });
      }
      else {
        [...ev.dataTransfer.files].forEach((file) => {
          let type = this.getFileType(file);
          if( type != 'dcm' && type != 'png' && type != 'jpg' && type != 'bmp' ) return;
          filesByFormat[type].files.push(file);
          filesByFormat[type].length += 1;
          files.push(file);
        });
      }
      // 형식에 맞는 파일이 있는 경우에 대한 처리
      if(files.length > 0) {
        // drop한 파일들 중 dcm 파일이 있는 경우에 대한 처리
        if(filesByFormat.dcm.length > 0) this.proc(filesByFormat.dcm.files,'dcm');
        else this.proc([files[0]],this.getFileType(files[0]));
      }
      else {
        return this.alert('error',this.lang[this.$store.state.lang].MAIN_ALERT_TXT_EXAM_FILE_NOT_ALLOWED); // dcm, png, jpg, bmp 형식의 파일만 업로드가 가능합니다.
      }
    },
    proc(files,type) {
      let promises = files.map((file) => {
        return new Promise((resolve) => {
          let fr = new FileReader();
          fr.onload = (e) => {
            let buf = new Uint8Array(e.target.result);
            // PX이미지인지 확인
            if( type == 'dcm' ) {
              let dataSet = dicomParser.parseDicom(buf);
              if( dataSet.string('x00080060') != "PX") {
                this.alert('error',`${file.name} ${this.lang[this.$store.state.lang].MAIN_ALERT_TXT_EXAM_FILE_NOT_ALLOWED2}`);
                resolve(false);
                return;
              }
            }
            
            this.$store.state.fileArr.push({
              contentType: 3,
              content: {
                filename: file.name,
                file: Array.from(buf)
              }
            });
            resolve(true);
          }
          fr.readAsArrayBuffer(file);
        });
      });

      Promise.all(promises).then(()=>{
        if(this.$store.state.fileArr.length > 0) {
          // 업로드하려는 파일의 확장자가 dcm 인 경우에 대한 처리
          if(type == 'dcm') {
            const packetId = this._getRandomFileId();
            let packet = this.$$build(64,packetId,this.$store.state.fileArr);
            this.$socket.send(packet);
            this.$store.state.fileArr = [];
          }
          // 업로드 하려는 파일의 확장자가 dcm이 아닌 경우에 대한 처리로, 파일 정보를 입력하는 모달을 띄운다.
          else this.$store.state.fileInfoModal = true;
        }
      });
    },
    uploadExamFiles(ev){
      this.proc([...ev.target.files]);
    },
    onResize(){
      this.listPosInfo = {
        height: this.$refs.examsScrollContainer.clientHeight,
        y: this.$refs.examsScrollContainer.getBoundingClientRect().y,
      }
    },
  },
  watch : {
    exams(newExam){
      // 특정 조건을 갖춘 영상목록을 조회하였으나, 그 영상목록의 길이가 0 인 경우에 조회된 결과가 없음을 사용자에게 알려주기 위하여 isNoExam 에 true를 대입한다.
      if(this.isSearched&&newExam.length==0) {
        this.isNoExam = true;
      }
      // 특정 조건을 갖춘 영상목록을 조회하였을 때 그 영상목록의 길이가 0 이 아닌 경우에 대한 처리
      else{
        this.isNoExam = false;
      }
      const hasExam = newExam.find((exam) => exam.seq == this.selectedExam.seq);
      if(hasExam && !this.hasExamInExams) this.hasExamInExams = true;
      if(!hasExam && this.hasExamInExams) this.hasExamInExams = false;
    },
    selectedExam(){
      if(!this.hasExamInExams) this.hasExamInExams = true;
    },
  },
  mounted(){
    this.onResize();
  },
  created(){
    window.addEventListener('keydown',this.setExamByKeyboard);
    window.addEventListener("resize", this.onResize);
  },
  destroyed(){
    window.removeEventListener('keypress',this.setExamByKeyboard);
    window.removeEventListener("resize", this.onResize);
  }
}
</script>

<style scoped>
  @keyframes new{
    0% {
      background-color: white;
    }
    100% {
      background-color: var(--gray-color);
    }
  }
  .new_added{
    animation: new 2s 0s 1;
  }
  .exam_loading>.spinner_sm {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 4px solid transparent;
    border-top-color: var(--color-selected);
    border-bottom-color: var(--color-selected);
    animation: spinner .8s ease infinite;
    z-index: 3
  }
  .date_calender .icon,
  .date_calender input{
    cursor: pointer;
    user-select: none;
  }
  .chat_img{
    background-image: url('https://panvi.kr/images/mn_msg-3d24fa96a5ded18e8bdf..png') !important;
  }
  .new_chat{
    background-image: url('https://panvi.kr/images/mn_new_msg-9ca334463b8dd49b52e7..png') !important;
  }
  .no_result{
    font-size: 16px;
    padding: 10px;
    color: rgba(255, 255, 255,0.7);
  }
  .display_none{
    display: none;
  }
  .date_filter .date_calender:focus-within{
    border-color: #2E2E33;
  }
  .date_filter .date_calender:hover{
    border: 1px solid rgb(149, 96, 245)  !important;
  }
  .border_gray{
    border: 1px solid #2E2E33;
  }
  .border_purple{
    border: 1px solid #9560F5;
  }
  .date_filter .date_calender:hover 
  .icon_position{
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB7ZVdDoIwDIALMSRqTDwCnMy9qjHiCcATIDHq6zyZ3EASoz4BtgrIjxDDAGPCl5QNurVrx1aAX7Jj1xNJVT3Rg3JUQT1IyZcDuxseeLoE0hgqEEDgyiDzGR+scg727GbhAB3qYT3nQ5M6vYR3Ri2uQJvyvkN9zG9ALQ6WPlnJ6jEDqg8+7ckSxXzZe/NMS2Q8xAmliJQ+MTdOcekm48o0ET0hQ8PkIojyWhftR1D0x3xLNgONR9A56Bz8mQOXHhY7q1CRxFw3+hafZLzHbawFhgIK1VkQIzhGvdS1sGWXDZbLiUjJRLEXfGRCWzwAbdVXIcgR1XkAAAAASUVORK5CYII=') !important;
  }
  .icon_active{
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB7ZVdDoIwDIALMSRqTDwCnMy9qjHiCcATIDHq6zyZ3EASoz4BtgrIjxDDAGPCl5QNurVrx1aAX7Jj1xNJVT3Rg3JUQT1IyZcDuxseeLoE0hgqEEDgyiDzGR+scg727GbhAB3qYT3nQ5M6vYR3Ri2uQJvyvkN9zG9ALQ6WPlnJ6jEDqg8+7ckSxXzZe/NMS2Q8xAmliJQ+MTdOcekm48o0ET0hQ8PkIojyWhftR1D0x3xLNgONR9A56Bz8mQOXHhY7q1CRxFw3+hafZLzHbawFhgIK1VkQIzhGvdS1sGWXDZbLiUjJRLEXfGRCWzwAbdVXIcgR1XkAAAAASUVORK5CYII=');
  }
  .icon_basic{
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEMSURBVHgB7ZXRDYIwEIavyoOPuoG+OYKvRAdxA/VJVIwkSoQn4wY6iMFNYAQGIJ53ISCiEFMEY8KXtIW7tkd/2ivALzFNy91uLVfWzyh5TkToCiHvZ57cu521oWZKpQ1y+DTlSdfns8jQfExuH6jRqLRAHh47UNWhcJzLlQ0JiXDMdRBgzzAWXhjUQm51XXsrRNpvGPuuoghXCDHhV7Y1Ev3bYadwcob09UhnDzJI+xNjY4lzf/JqpfWK+JkGlMzLCiJdv0X1K8jaMZ+SVqD0FdQB6gB/FsDnilMuSJIY60e2+CQj4pHy+IbzOZ1GKALi7Rw9xzca30CqOupQju+D/K3m04fa6/VSg6q4A2YmXN64aSE5AAAAAElFTkSuQmCC');
  }
  .icon_position{
    justify-self: center;
    display: block;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  .selected_exam{
    background-color: rgba(87,78,224,0.33);
  }
  .alert_line{
    margin-top: 5px;
    height: 3px;
    background: #dc3545;
  }
  .feature_filter select{
    text-align: center;
  }
  .keyword_filter label{
    left: 0;
    width: 100%;
    text-align: center;
  }
  .select_filter{
    background-color: var(--base-color);
  }
  .select_filter select{
    padding-left: 0;
  }
  .exam_box{
    position: relative;
  }
  .dragged_boundary{
    width: calc(100% + 6px);
    height: 100%;
    position: absolute;
    left:0;
    border-top: 6px solid #3b3688;
    border-left: 6px solid #3b3688;
    border-bottom: 6px solid #3b3688;
    border-right: 6px solid rgb(32,31,47);
    background-color: rgba(59, 54, 136,0.3);
    box-sizing: border-box;
    z-index: 6;
  }
  .main_dragged_width{
    width: calc(100% + 6px);
  }
  .list_dragged_width{
    width: calc(100% - 12px);
  }
  .exam_file_upload label{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    line-height: 44px;
  }
  .exam_file_upload .basic_btn:hover{
    border-color: #A45CFE;
    cursor: pointer;
  }  
  .bar_horiz{
    display: inline-block;
    width: 6px;
    height: 1px;
    background: #848488;
  }
  .date_text{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 14px;
    color: #DFDEE0;
    font-size: 14px;
  }
  .selected_date_btn{
    border: 1px solid #9560f5;
    color: white;
  }
  .isSearched_search_btn{
    background-color: #9560F5;
  }
  .border_active,
  .submit_btn_wrap .clear_btn:hover{
    border: 1px solid #9560f5;
  }
  /* #main_filter .exam_table .chat>div{
    border-left: 1px solid #29292d;
  } */
  .bar_vertical{
    width: 1px;
    height: 40px;
    background-color: #29292d;
    box-sizing: border-box;
  }
</style>