<template>
  <div class="membership">
    <!-- 멤버십 관리 화면 -->
    <div v-if="tab == 'membership'" class="page membership_page">
      <h2 class="title">{{lang[$store.state.lang].EDIT_INFO_TAB_TXT_MEMBERSHIP}}<!--멤버십 관리--></h2>
      <div class="main_content">
        <div class="membership_options">
          <div class="option_box">
            <div class="option_des_left">
              <div class="op_title">
                <span>
                  <img v-if="checkedMembership == 'px'" src="@/assets/images/checked.png" alt="checked" width="22">
                  <img v-else src="@/assets/images/uncheckedPlus.png" alt="unChecked" width="22">
                </span>
                <span>Panorama AI</span>
              </div>
              <div class="price">{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_PRICE_PER_MONTH}}<!--월 10,000원--></div>
              <div class="option_des_detail">
                <p>
                  <span><img src="@/assets/images/audioUnchecked.png" alt="audioUnchecked"></span>
                  <span>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_DESCRIPTION1}}<!--파노라마 영상 AI 판독비서--></span>
                </p>
                <p>
                  <span><img src="@/assets/images/audioUnchecked.png" alt="audioUnchecked"></span>
                  <span>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_DESCRIPTION2}}<!--치아/치주/연조직 질환 00개 판독--></span>
                </p>
                <p>
                  <span><img src="@/assets/images/audioUnchecked.png" alt="audioUnchecked"></span>
                  <span>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_DESCRIPTION3}}<!--영상전송 SW 무료설치--></span>
                </p>
              </div>
            </div>
            <div class="option_des_right">
              <button class="op_badge bg_purple px_badge">Pano AI</button>
              <span style="display:none">{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IN_USE}}<!--이용중--></span>
            </div>
          </div>
          <div class="option_box option_selected">
            <div class="option_des_left">
              <div class="op_title">
                <span>
                  <img v-if="checkedMembership != 'implant'" src="@/assets/images/uncheckedPlus.png" alt="unChecked" width="22">
                  <img v-else src="@/assets/images/checked.png" alt="checked" width="22">
                </span>
                <span>Implant AI</span>
              </div>
              <div class="price">{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IMPLANT_PRICE_PER_MONTH}}<!--월 10,000원--></div>
              <div class="option_des_detail">
                <p>
                  <span><img src="@/assets/images/audioUnchecked.png" alt="audioUnchecked"></span>
                  <span>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IMPLANT_DESCRIPTION1}}<!--임플란트 정보 AI 판독비서--></span>
                </p>
                <p>
                  <span><img src="@/assets/images/audioUnchecked.png" alt="audioUnchecked"></span>
                  <span>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IMPLANT_DESCRIPTION2}}<!--정확도 평균 00%--></span>
                </p>
              </div>
            </div>
            <div class="option_des_right">
              <button class="op_badge imp_badge">Implant AI</button>
              <span>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IN_USE}}<!--이용중--></span>
            </div>
          </div>
        </div>
        <ul class="description">
          <li>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PAY_DESCRIPTION}}<!--멤버십 요금은 결제 주기 시작일에 청구되며, 요금을 변경하시는 경우 해당 날짜를 기준으로 결제 주기가 다시 시작됩니다.--></li>
        </ul>
        <div class="cancel">
          <div>
            <span>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_BUTTON_START_MEMBERSHIP}}<!--멤버십 시작-->: </span> <span>2022.01</span>
          </div>
          <div class="cancel_btn">
            <button>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_BUTTON_CANCEL_MEMBERSHIP}}<!--멤버십 해지--></button>
          </div>
        </div>
        <div class="membership_change_txt">
          <p>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_CHANGE_MEMBERSHIP_DESCRIPTION1}}<!--멤버십을 변경하시면 등록하신 결제정보로 월 이용료가 결제됩니다.--></p>
          <p>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_CHANGE_MEMBERSHIP_DESCRIPTION2}}<!--이전달 이용분은 일할 계산되어 환불해드립니다. (카드 취소분은 수일이 걸릴수도 있습니다.).--></p>
        </div>
      </div>
      <footer>
        <div>
          <button @click="goToMain">{{lang[$store.state.lang].EDIT_INFO_BUTTON_CLOSE}}<!--메인페이지로 이동--></button>
          <button class="bg_purple">{{lang[$store.state.lang].EDIT_INFO_BUTTON_CHANGE_MEMBERSHIP}}<!--변경하기--></button>
        </div>
        <div style="display:none">
          <button class="free_btn">{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_MEMBERSHIP_BUTTON_START_FREE}}<!--무료로 시작하기--></button>
        </div>
      </footer>
    </div>

    <!-- 결제정보 관리 화면 / 아직 ui 디자인 안나왔음 -->
    <div v-if="tab == 'payment'" class="page payment_page"></div>
    
    <!-- 결제상세 화면 -->
    <div v-if="tab == 'paymentDetail'" class="page paymentDetail_page">
      <h2 class="title">{{lang[$store.state.lang].EDIT_INFO_TAB_TXT_PAYMENT_DETAIL}}<!--결제상세--></h2>
      <div class="main_content">
        <div class="date_controller">
          <button @click="moveYear(-1)"><img src="@/assets/images/mn_cnt_prev.png" alt="previousBtn"></button>
          <span>{{year}}</span>
          <button @click="moveYear(+1)"><img src="@/assets/images/mn_cnt_next.png" alt="nextBtn"></button>
        </div>
        <div class="table"> 
          <table>
            <tr>
              <td>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_SERVICE}}<!--서비스명--></td>
              <td>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_PAYMENT_DATE}}<!--결제일--></td>
              <td>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_PAYMENT_METHOD}}<!--결제수단--></td>
              <td>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_PAYMENT_PRICE}}<!--결제금액--></td>
              <td>{{lang[$store.state.lang].EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_RECEIPT}}<!--영수증--></td>
            </tr>
            <tr v-for="(item,idx) in list" :key="'paymentDetailList-'+idx+'-'+item.by">
              <td>{{item.service}}</td>
              <td>{{new Date(item.date).getFullYear()}}.{{new Date(item.date).getMonth().toString().length == 1 ? '0'+new Date(item.date).getMonth().toString() : new Date(item.date).getMonth()}}.{{new Date(item.date).getDate()}}</td>
              <td>{{item.by}}</td>
              <td>{{__makePriceToString(item.price)}}</td>
              <td><button><img src="@/assets/images/download.png" alt="download"></button></td>
            </tr>
          </table>
        </div>
      </div>
      <footer>
        <button @click="goToMain">{{lang[$store.state.lang].EDIT_INFO_BUTTON_CLOSE}}<!--메인페이지로 이동--></button>
      </footer>
    </div>
  </div>
</template>

<script>
import wsMixin from '@/wsMixin';
import lang from '@/lang'

export default {
  name: 'MembershipManagement',
  props: ['tab'],
  mixins: [wsMixin],
  data: function(){
    return{
      lang,
      year: new Date().getFullYear(), // 결제상세 페이지에서 사용되는 데이터로, 연도 정보에 해당하는 결제 정보를 보여준다.
      list: [
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 35000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 100000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
        { service: 'PANO', date:'2023-05-31T04:07:15.815Z', by: '국민카드2424', price: 50000 },
      ], // 결제상세 임시 데이터 
      checkedMembership: 'implant'
    }
  },
  methods: {
    moveYear(direction){
      if(direction > 0) this.year+=1;
      else this.year-=1;
    },
    goToMain(){
      this.$store.state.readingReport = false;
      this.$store.state.menu = sessionStorage.getItem('examType');
      this.$store.state.changePrivateInfoView = false;
    },
    // 가격 정보를 인자로 전달받아, ',' 를 세 자리마다 추가하여 반환한다.
    __makePriceToString(price) {
      let arr = price.toString().split('');
      if(arr.length <= 3) return price.toString();
      for(let i=arr.length-3; i>0; i-=3) arr.splice(i,0,',');
      return arr.join('');
    }
  }
}
</script>

<style scoped>
  .page{
    margin: auto;
    padding-top: 84px;
    padding-bottom: 61px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    box-sizing: border-box;
  }
  .title{
    font-size: 28px;
    margin-bottom: 28px;
  }
  footer button{
    width: 176px;
    height: 44px;
  }
  button{
    color: white;
    background-color: transparent;
    border: 1px solid #848488;
    border-radius: 4px;
  }
  .membership_options{
    display: flex;
    gap: 14px;
  }
  .option_box{
    display: flex;
    justify-content: space-between;
    padding: 25px 34px 24px 32px;
    width: 350px;
    /* height: 204px; */
    border-top: 2px solid  #5B5B5E;
    border-bottom: 2px solid  #5B5B5E;
    box-sizing: border-box;
  }
  .option_selected{
    border-top: 2px solid #A45CFE;
    border-bottom: 2px solid #A45CFE;
  }
  .op_title{
    display: flex;
    align-items: center;
    gap: 13px;
    margin-bottom: 27px;
  }
  .op_title span:first-child{
    width: 22px;
    height: 22px;
  }
  .price{
    padding-left: 10px;
    font-size: 14px;
  }
  .option_des_detail{
    padding-left: 10px;
    margin-top: 14px;
    font-size: 11px;
  }
  .option_des_detail p{
    display: flex;
    align-items: center;
    margin-bottom: 3px;
  }
  .option_des_right{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .op_badge{
    display: flex;
    align-items: center;
    padding: 1px 3px;
    width: auto;
    height: 17px;
    line-height: 17px;
    color: white;
    font-size: 11px;
    border: none;
    border-radius: 2px;
  }
  .bg_purple{
    background-color: #A45CFE;
    border: none;
  }
  .imp_badge{
    background-color: #678DF2;
  }
  .px_badge{
    width: 50px;
    justify-content: center;
  }
  footer>div{
    display: flex;
    justify-content: center;
    gap: 12px;
  }
  .membership_page .main_content{
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    overflow: auto;
  }
  .membership_change_txt{
    padding: 12px 24px;
    align-self: flex-end;
    margin-top: 25px;
    margin-bottom: 36px;
    background: rgba(87, 78, 224, 0.5);
    font-size: 14px;
  }
  .membership_page .main_content::-webkit-scrollbar,
  .table::-webkit-scrollbar {
    width: 10px;
  }
  .membership_page .main_content::-webkit-scrollbar-track,
  .table::-webkit-scrollbar-track {
    background: transparent;
  }
  .membership_page .main_content::-webkit-scrollbar-thumb,
  .table::-webkit-scrollbar-thumb {
    width: 3px;
    background: #5B5B5E;
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: 8px;
  }
  .description{
    margin-top: 28px;
    padding-left: 17px;
    color: #DFDEE0;
    font-size: 11px;
    list-style: disc;
    font-weight: 400;
  }
  .cancel{
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 16px;
    font-weight: 400;
  }
  .cancel_btn button{
    padding: 0 5px;
    height: 32px;
  }
  .free_btn{
    width: 276px;
    height: 56px;
    background: linear-gradient(169.8deg, #9560F5 4.58%, #574EE0 92.07%);
    border-radius: 28px;
    font-size: 24px;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  }
  footer{
    text-align: center;
  }
  .date_controller{
    margin-bottom: 18px;
    text-align: end;
  }
  .date_controller button{
    border: none;
  }
  .date_controller span{
    margin-left: 25px;
    margin-right: 25px;
    color: #EEECF1;
    font-size: 20px;
  }
  .table{
    box-sizing: border-box;
  }
  table tr{
    padding-left: 36px;
    padding-right: 36px;
    display: grid;
    grid-template-columns: 126px 120px 154px 116px 88px;
  }
  table tr td{
    margin-right: 5px;
    text-align: center;
  }
  table tr td:last-child{
    margin-right: 0;
  }
  table tr:first-child{
    background-color: #262529;
  }
  table tr{
    margin-top: 6px;
    height: 34px;
    color: #DFDEE0;
    line-height: 34px;
    font-size: 14px;
  }
  table tr:first-child{
    position: sticky;
    top: 0;
    margin-top: 0px;
    height: 32px;
    color: #B7B7B7;
    line-height: 32px;
    font-size: 12px;
  }
  table tr td button{
    border: none;
  }

</style>