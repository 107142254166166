<template>
  <article id="sidebar" :style="{zIndex: $store.state.changePrivateInfoView || $store.state.modalViewModal ? '2' : '1'}">

    <div :class="['category',stretched?'stretched':'shrink', closed? 'closed' : 'opened']" ref="category">
      <!-- PX -->
      <div id="cate_home" :class="['cate', 'highlight', $store.state.menu == 'px' ? 'selected' : '']"  @click="setMenu('px')">
        <a :style="{overflow: hidden ? 'hidden' : 'visible'}">
          <div class='tag'>
            <img class="off" src="@/assets/images/pano_off.png" alt="" />
            <img class="on" src="@/assets/images/pano_on.png" alt="" />
            <img class="white" v-if="!stretched" src="@/assets/images/px_white.png" alt="">
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_PANORAMA}}<!--PANORAMA--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_PANORAMA}}<!--PANORAMA--></p>
        </div>
      </div>

      <!-- CBCT ( 추후 추가할 수 있음 ) -->
      <!-- <div id="cate_home" :class="['cate', 'highlight', $store.state.menu == 'ct' ? 'selected' : '']" @click="setMenu('ct')">
        <a :style="{overflow: hidden ? 'hidden' : 'visible'}">
          <div class='tag'>
            <img class="off" src="@/assets/images/ct_off.png" alt="" />
            <img class="on" src="@/assets/images/ct_on.png" alt="" />
            <img class="white" v-if="!stretched" src="@/assets/images/ct_white.png" alt="">
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_CBCT}}</p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_CBCT}}</p>
        </div>
      </div> -->

      <!-- 판독소견서 인쇄 -->
      <div id="cate_print" :class="['cate', 'highlight', $store.state.menu == 'print' ?'selected':'']" @click="setMenu('print')">
        <a @click="showModal()" :style="{overflow: hidden ? 'hidden' : 'visible'}">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_gnb4.png" alt="" />
            <img class="on" src="@/assets/images/mn_gnb4_on.png" alt="" />
            <img class="white" v-if="!stretched" src="@/assets/images/print_white.png" alt="">
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_PRINT_MULTIPLE}}<!--판독소견서 다운로드--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_PRINT_MULTIPLE}}<!--판독소견서 다운로드--></p>
        </div>
      </div>

      <div></div>

      <!-- 언어설정 -->
      <div id="cate_member" class="cate highlight" :style="{visibility:stretched?'visible':'hidden'}">
        <a :style="{overflow: hidden ? 'hidden' : 'visible'}">
          <div class="tag">
            <img class="off" src="@/assets/images/langOff.png" alt="" />
            <img class="on" src="@/assets/images/langOn.png" alt="" />
          </div>
          <div class="title">
            <p>
              <span class="select_lang">
                <Select v-if="stretched" @selectOption="changeLang" :val="$store.state.diseaseLang" :list="languages" :selectInfo="{backgroundColor: '#212125',fontSize:12}"/>
              </span>
            </p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_SET_LANG}}</p>
        </div>
      </div>
      
      <!-- 내 정보 -->
      <div id="cate_member" :class="['cate', 'highlight', $store.state.myInfoModal || $store.state.changePrivateInfoView?'selected':'']" @click="setMenu('myInfo')">
        <a :style="{overflow: hidden ? 'hidden' : 'visible'}">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_util_icon2.png" alt="" />
            <img class="on" src="@/assets/images/mn_util_icon2_on.png" alt="" />
            <img class="white" v-if="!stretched" src="@/assets/images/person_white.png" alt="">
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_MYINFO}}<!--내 정보--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_MYINFO}}<!--내 정보--></p>
        </div>
      </div>

      <!-- 메뉴 -->
      <div id="cate_stretch" class="cate controller" ref="controller">
        <a style="cursor:pointer;" @click="toggleStretched">
          <div class="tag">
            <img :class="['off',stretched?'hide':'']" src="@/assets/images/mn_util_icon4.png" alt="" />
            <img :class="['on',stretched?'show':'']" src="@/assets/images/mn_util_icon4_on.png" alt="" />
            <img :class="['white', stretched? 'hide' : '']" src="@/assets/images/menu_white.png" alt="" />
          </div>
        </a>
      </div>
      
    </div>
  </article>
</template>

<script>
import lang from '@/lang'
import Select from '@/components/Select.vue'

export default {
  name: "MainSidebar",
  props: ['selectedExam'],
  components : {
    Select
  },
  data: function() {
    return {
      lang,
      stretched: false,
      hidden: true, // a tag overflow ( hidden || visible )
      timer: undefined,
      examType: 'px',
      languages: [
        {value: 'default', text: 'languages'},
        {value: 'en', text: 'English'},
        {value: 'ko', text: 'Korean'},
      ],
      closed: true, // tooltip display ( closed 일 때 hover시 tootip이 보이도록 한다. )
      closedTimer: undefined
    }
  },
  methods: {
    toggleStretched: function() {
      this.stretched = !this.stretched;
    },
    showModal(){
      this.$store.state.modalViewModal = true
    },
    closeModal(){
      this.$store.state.modalViewModal = false;
      this.$store.state.modalCalendar = false;
    },
    changeLang(selectedLang){
      this.stretched = false;
      if(selectedLang == 'ko' || selectedLang == 'en') {
        sessionStorage.setItem('lang',selectedLang);
        sessionStorage.setItem('diseaseLang',selectedLang);
      }else {
        sessionStorage.setItem('lang','en');
        sessionStorage.setItem('diseaseLang',selectedLang);
      }
    },
    updateReport(report){
      this.$emit('updateReport',report);
    },
    setMenu(menu){
      this.stretched = false;
      this.$store.state.menu = menu;
      if(menu == 'px' || menu == 'ct') {
        if(this.$store.state.modalViewModal) this.$store.state.modalViewModal = false;
        if(this.$store.state.changePrivateInfoView) this.$store.state.changePrivateInfoView = false;
        sessionStorage.setItem('examType',menu);
        this.$emit('setStartView');
      }
      if(menu != 'myInfo') this.$store.state.changePrivateInfoView = false;
      else if(menu == 'myInfo') this.$store.state.myInfoModal = true;
    },
    // sidebar 외부 화면을 클릭하면 sidebar가 닫히도록 한다.
    offSidebar(ev){
      if(this.stretched){
        if(!this.$refs.category.contains(ev.target) && !this.$refs.controller.contains(ev.target)) {
          this.stretched = false;
        }
      }
    }
  },
  computed: {
    myInfo(){
      return this.$store.state.myInfoModal;
    },
  },
  watch: {
    stretched(stretched) {
      if(this.timer) clearTimeout(this.timer);
      if(stretched) {
        this.closed = false;
        this.timer = setTimeout(() => {
          this.hidden = false;
          clearTimeout(this.timer);
          this.timer = undefined;
        },500)
      }else {
        this.hidden = true;
        this.closedTimer = setTimeout(() => {
          this.closed = true;
          if(this.$store.state.lang != sessionStorage.getItem('lang')){ 
            window.location.reload(true);
          }
          clearTimeout(this.closedTimer);
          this.closedTimer = undefined;
        },500);
      }
    },
    myInfo(newVal) {
      if(!newVal) {
        // 내 정보 모달을 닫을 때 다른 모달이 화면에 없는 경우에는 이전 menu 가 examType 일 것이므로, sessionStorage 에 저장된 examType 값을 menu 로 할당한다. 
        if(!this.$store.state.changePrivateInfoView && !this.$store.state.modalViewModal) this.$store.state.menu = sessionStorage.getItem('examType');
        // 판독소견서 다운로드 모달이 떠 있는 경우에는, menu 를 'print'로 할당한다.
        else if(this.$store.state.modalViewModal) {
          this.$store.state.menu = 'print';
        }
      }
    },
  },
  created(){
    document.addEventListener('click',this.offSidebar);
  },
  destroyed(){
    document.removeEventListener('click',this.offSidebar);
  }
}
</script>

<style scoped>
  a{
    cursor: pointer;
  }
  .show{
    display: block !important;
  }
  .hide{
    display: none !important;
  }
  .select_lang{
    display: inline-block;
    width: 100px;
    height: 28px;
  }
</style>