export default {
  "LOGIN_TITLE": "Dental AI Diagnosis", 
  "LOGIN_TXT_ENTER_ID": "example@example.com",
  "LOGIN_TXT_ENTER_PASSWD": "password", 
  "LOGIN_BTN_LOGIN": "LOGIN",
  "LOGIN_BTN_SIGNUP": "Apply for membership",
  "LOGIN_BTN_RESET_PSW": "Reset your password",
  "LOGIN_ALERT_TXT_FAIL": "It is an unregistered ID or wrong password. ",
  "LOGIN_ALERT_TXT_TEMP_PWS_SENDED": "A temporary password has been issued.",
  "LOGIN_ALERT_TXT_NOT_EXIST_EMAIL": "There is no member information you entered.", 
  "LOGIN_ALERT_TXT_PLEASE_WRITE_EMAIL": "Please enter your email.",
  "LOGIN_ALERT_TXT_PLEASE_WRITE_VALID_EMAIL": "Please enter your email in the correct format.",
  "LOGIN_ALERT_TXT_EMAIL_FORM": "ID is in email format",
  "LOGIN_ALERT_TXT_PASSWD_FORM": "At least 8 characters required, En upper·lowercase letters/numbers/special characters available.",
  "LOGIN_LINK_PRIVACY": "privacy policy",
  "LOGIN_LINK_CONTACT": "contact us  dhkim@invisionlab.kr",
  "LOGIN_TXT_COPYRIGHT": "© InVisionLab. All Rights Reserved.",

  "MENU_PANORAMA": "PANORAMA",
  "MENU_CBCT": "CBCT",
  "MENU_REQUEST_MULTIPLE": "Multiple Requests",
  "MENU_PRINT_MULTIPLE": "Diagnosis Report",
  "MENU_MANUAL": "User Manual",
  "MENU_HISTORY": "Diagnosis Statistics",
  "MENU_MYINFO": "My Information",
  "MENU_LOGOUT": "Logout",
  "MENU_ALARM_HISTORY": "Notification List",
  "MENU_SET_LANG": "Change Language",

  "MODAL_TITLE_PRINT_MULTIPLE": "Download Diagnosis Report",
  "MODAL_TITLE_REQUEST_MULTIPLE": "Multiple Diagnosis Requests",
  "MODAL_TXT_MENU_TITLE": "Management",
  "MODAL_TXT_GUIDE1": "Only images that have not been requested are listed.",
  "MODAL_TXT_GUIDE2": "Only images that have been diagnosed are listed.",
  "MODAL_TXT_GUIDE3": "·The report is printed without C.C. You can modify the report.",
  "MODAL_TXT_GUIDE4": "·If you register Doctor's Signature file, it is printed in the report.",
  "MODAL_TXT_GUIDE5": "Please register the signature file in the \"My Info → Edit Information\" menu.",
  "MODAL_ALERT_TXT_FAIL_UPLOAD_SIGN": "Signature registration failed. \nPlease try again.", 
  "MODAL_ALERT_TXT_FAIL_REMOVE_SIGN": "Signature deletion failed.\nPlease try again.", 
  "MODAL_TXT_DOWNLOAD_PDF": "Download will start soon.",
  "MODAL_TXT_FAIL_DOWNLOAD_PDF": "Failed to save pdf.\nPlease try again.",
  "MODAL_BTN_UPLOAD": "Upload Signature",
  "MODAL_BTN_CLOSE": "Close",
  "MODAL_BTN_SAVE_PDF": "Download PDF",
  "MODAL_BTN_PRINT": "Print",
  "MODAL_BTN_REQUEST": "Request",
  "MODAL_THEAD_TYPE": "image type",
  "MODAL_THEAD_CHART_ID": "chart number",
  "MODAL_THEAD_NAME": "patient name",
  "MODAL_THEAD_DATE": "exam date",

  "FILTER_TXT_TODAY": "today",
  "FILTER_TXT_WEEK": "a week",
  "FILTER_TXT_MONTH": "a month",
  "FILTER_TXT_ALLTIME": "all",
  "FILTER_TXT_ENTER_KEYWORD": "enter the patient name or chart number.",
  "FILTER_TXT_TYPE": "type",
  "FILTER_TXT_ALLTYPE": "all",
  "FILTER_TXT_STATUS": "DX status",
  "FILTER_TXT_ALLSTATUS": "all",
  "FILTER_TXT_REQUESTED": "Requested",
  "FILTER_TXT_COMPLETED": "Completed",
  "FILTER_TXT_UNREADABLE": "Unreadable",
  "FILTER_TXT_CHAT": "with chatting",
  "FILTER_TXT_SPECIFIC": "with findings",
  "FILTER_BTN_SEARCH": "SEARCH",
  "FILTER_BTN_CLEAR": "reset",
  "FILTER_BTN_ALLTIME": "all",

  "LIST_TXT_REQUESTED": "REQ",
  "LIST_TXT_COMPLETED": "DONE",
  "LIST_TXT_UNREADABLE":"UNRDBL",
  "LIST_TXT_NO_EXAM_FOUNDED":"No results",
  "LIST_BTN_SCAN": "I can't see the image I want",
  "LIST_BTN_UPLOAD_PX_FILE": "upload PANORAMA",
  "LIST_ALERT_TXT_FAIL_RECV_EXAMS": "Failed to list the exams.\nPlease try again.",
  "LIST_ALERT_TXT_FAIL_RECV_EXAM": "Failed to show the exam.\nPlease try again.",
  "LIST_ALERT_TXT_FAIL_RECV_NEW_CHAT_EXAMS": "Failed to expose new chatting list to the top of the list.",

  "MAIN_TXT_DISEASE_FILTER_TITLE": "Parameter",
  "MAIN_TXT_PARAMETER_POPUP_TITLE": "According to the parameter, the range of diseases displayed by AI is different.",
  "MAIN_TXT_PARAMETER_POPUP_SENSITIVE_DES": "All possible diseases are detected.",
  "MAIN_TXT_PARAMETER_POPUP_STANDARD_DES": "Clinical level diseases are detected.",
  "MAIN_TXT_PARAMETER_POPUP_PRECISE_DES": "Only certain diseases are detected.",
  "MAIN_TXT_DISEASE_FILTER_SENSITIVE": "Sensitive",
  "MAIN_TXT_DISEASE_FILTER_STANDARD": "Standard",
  "MAIN_TXT_DISEASE_FILTER_PRECISE": "Precise",
  "MAIN_TXT_SHOW_DISEASE": "disease",
  "MAIN_TXT_SHOW_IMPLANT": "implant",
  "MAIN_ALERT_TXT_CONFIRM_MIC_ACCESS":"Please allow browser microphone access to hear chatting audio.",
  "MAIN_TXT_EMPTY": "<strong>Select an image<br>from the list on the left</strong>",
  "MAIN_TXT_DRAG_PX_EXAM": "Drag and drop new PANORAMA files",
  "MAIN_TXT_DRAGGABLE_PX_EXAM_FORM": "DICOM, PNG, JPG, BMP",
  "MAIN_BTN_REQUEST": "Diagnosis Request",
  "MAIN_BTN_CANCEL": "Cancel",
  "MAIN_BTN_REQUEST2": "Diagnosis Request",
  "MAIN_MODAL_TITLE": "Diagnosis Request",
  "MAIN_MODAL_GUIDE1": "· Selecting or entering C.C is optional.",
  "MAIN_MODAL_GUIDE2": "· Radiologist diagnose the images.",
  "MAIN_MODAL_GUIDE3": "· When diagnosis is completed, You can print the diagnosis report.",
  "MAIN_TXT_SELECT_CC": "Select C.C",
  "MAIN_TXT_CC_OPT1": "Wisdom tooth extraction",
  "MAIN_TXT_CC_OPT2": "supernumerary tooth · impacted tooth ",
  "MAIN_TXT_CC_OPT3": "TMJ",
  "MAIN_TXT_CC_OPT4": "root canal treatment failure",
  "MAIN_TXT_CC_OPT5": "lesions larger than 3cm",
  "MAIN_TXT_CC_OPT6": "Enter C.C",
  "MAIN_TXT_CC_OPT7": " ",
  "MAIN_TXT_ENTER_CC": "Enter C.C",
  "MAIN_TXT_REPORT": "Diagnosis Report",
  "MAIN_DISEASES_TABLE_TXT_CATEGORY": "Category",
  "MAIN_DISEASES_TABLE_TXT_NAME": "Name",
  "MAIN_DISEASES_TABLE_TXT_POSITION": "Position",
  "MAIN_DISEASES_TABLE_TXT_ADDITIONAL_EXAM": "Additional Exam",
  "MAIN_DISEASES_TABLE_TXT_DESCRIPTION": "Description",
  "MAIN_ALERT_TXT_FAIL_REQ": "Your request for diagnosis has failed.\nPlease try again.",
  "MAIN_ALERT_TXT_FAIL_EXAM_UPLOAD": "Please try again after AI diagnosis is complete.", 
  "MAIN_ALERT_TXT_EXAM_FILE_NOT_ALLOWED": "Only DICOM, PNG, JPG, BMP format files can be uploaded.",
  "MAIN_ALERT_TXT_EXAM_FILE_NOT_ALLOWED2": "is not Panorama Image",
  "MAIN_ALERT_TXT_FAIL_TO_LOGOUT": "Logout failed.Please try again.",
  "MAIN_ALERT_TXT_FAIL_TO_UPLOAD_EXAM": "File upload failed.Please try again.",
  "MAIN_ALERT_TXT_ENTER_BLANKS": "Please enter the following fields.",
  
  "CHATTING_TXT_HEADER": "PANVI",
  "CHATTING_TXT_PLACEHOLDER": "Enter the message.",
  "CHATTING_TXT_BUTTON": "send",
  "CHATTING_TXT_RECV": "Chat received",
  "CHATTING_TXT_FAIL_LOAD_CHATTING_LIST": "Failed to retrieve the chatting of the image.\nPlease try again.", 
  "CHATTING_TXT_AUTO_RESPONSE": "The consulting chat service is in preparation.\n General inquiries about the service will be handled within 24 hours.",

  "EXAM_INFO_MODAL_TEXT_TITLE":"Enter exam info",
  "EXAM_INFO_MODAL_TEXT_PATIENT_NAME":"Name",
  "EXAM_INFO_MODAL_TEXT_TAKEN_DATE":"Exam date ( YYYY MM DD )",
  "EXAM_INFO_MODAL_TEXT_TAKEN_TIME":"Exam time",
  "EXAM_INFO_MODAL_TEXT_SEX_FEMAIL":"FEMAIL",
  "EXAM_INFO_MODAL_TEXT_SEX_MAIL":"MALE",
  "EXAM_INFO_MODAL_TEXT_PLACEHOLDER_PATIENT_NAME":"please enter the patient's name",
  "EXAM_INFO_MODAL_TEXT_PLACEHOLDER_CHART_NUMBER":"please enter the chart number",
  "EXAM_INFO_MODAL_TEXT_PLACEHOLDER_PATIENT_SEX":"please select the sex",
  "EXAM_INFO_MODAL_TEXT_PLACEHOLDER_PATIENT_AGE":"please enter a number",
  "EXAM_INFO_MODAL_BUTTON_UPLOAD":"upload",

  "REPORT_TXT_TITLE": "Diagnosis Report",
  "REPORT_TXT_AUTO_SAVE_ALERT": "Any changes you make are automatically saved.",
  "REPORT_TXT_PATIENT": "Patient",
  "REPORT_TXT_PATIENT_NAME": "Name",
  "REPORT_TXT_PATIENT_AGE": "Age",
  "REPORT_TXT_PATIENT_GENDER": "Sex",
  "REPORT_TXT_CHART_NUMBER": "Chart number",
  "REPORT_TXT_EXAM_DATE": "Exam date",
  "REPORT_TXT_IMAGE_TYPE": "Image type",
  "REPORT_TXT_PLACEHOLDER": "Enter the text.",    
  "REPORT_TXT_RADIOLOGIST": "Radiologist",
  "REPORT_TXT_HOSPITAL_NAME": "Hospital Name",
  "REPORT_TXT_DIAGNOSIS_DATE": "Diagnosis date",
  "REPORT_TXT_LICENSE_NUMBER": "License number",
  "REPORT_TXT_RADIOLOGIST_NAME": "Name",
  "REPORT_TXT_SEAL": "(seal)",
  "REPORT_TXT_IMAGE_REQUIREMENT": "Image ratio 1:1, a resolution at least 100px recommended",
  "REPORT_TXT_BUTTON_UPLOAD_FILE": "upload signature ",
  "REPORT_TXT_BUTTON_CANCEL": "Cancel",
  "REPORT_TXT_BUTTON_SAVE": "Save Text",
  "REPORT_TXT_BUTTON_DOWNLOAD_PDF": "Download PDF",
  "REPORT_TXT_SIGN_REMOVE_ALERT": "Are you sure to delete the signature file?",

  "MY_INFO_TXT_DOCTOR": "Dr.",
  "MY_INFO_TXT_MY_INFO": "My Info",
  "MY_INFO_TXT_SUB_INFO": "membership",
  "MY_INFO_TXT_COMPLETE": "Diagnosis Complete",
  "MY_INFO_TXT_UNREADABLE": "Unreadable",
  "MY_INFO_TXT_NEW_MESSAGE": "New Message",
  "MY_INFO_TXT_MINUTES_AGO": "m age",
  "MY_INFO_TXT_HOURS_AGO": "h age",
  "MY_INFO_TXT_YESTERDAY": "yesterday",
  "MY_INFO_TXT_EDIT_PRIVATE_INFO": "Edit info",
  "MY_INFO_TXT_LOG_OUT": "log out",
  "MY_INFO_BUTTON_CLOSE": "Close",

  "REQUEST_HISTORY_TXT_TOTAL_BILL": "total bill($)",
  "REQUEST_HISTORY_TXT_WHETHER_TO_PAY": "payed",
  "REQUEST_HISTORY_TXT_MONTH_JAN": "JAN.",
  "REQUEST_HISTORY_TXT_MONTH_FEB": "FEB.",
  "REQUEST_HISTORY_TXT_MONTH_MAR": "Mar.",
  "REQUEST_HISTORY_TXT_MONTH_APR": "Apr.",
  "REQUEST_HISTORY_TXT_MONTH_MAY": "May.",
  "REQUEST_HISTORY_TXT_MONTH_JUN": "Jun.",
  "REQUEST_HISTORY_TXT_MONTH_JUL": "Jul.",
  "REQUEST_HISTORY_TXT_MONTH_AUG": "Aug.",
  "REQUEST_HISTORY_TXT_MONTH_SEP": "Sep.",
  "REQUEST_HISTORY_TXT_MONTH_OCT": "Oct.",
  "REQUEST_HISTORY_TXT_MONTH_NOV": "Nov.",
  "REQUEST_HISTORY_TXT_MONTH_DEC": "Dec.",
  "REQUEST_HISTORY_TXT_FOOTER_PRICE_INFO": "· &nbsp;<span>PX $1/case    CT $5/case.</span><span style='color:#9192F6; margin-left:10px; font-weight:900'>(30% discount for automatic request)</span>",
  "REQUEST_HISTORY_TXT_FOOTER_RECEIPT_INFO": "· &nbsp;<span>We will issue a tax invoice.</span>",
  "REQUEST_HISTORY_CLOSE": "Close",
  "REQUEST_HISTORY_ALERT_FAIL_LOAD_LIST": "Failed to retrieve list of diagnosis statistics.\nPlease try again.", 
  
  "EDIT_INFO_LIST_TITLE_MY_INFO": "My information",
  "EDIT_INFO_LIST_TITLE_MEMBERSHIP": "My membership",
  "EDIT_INFO_TAB_TXT_DOCTOR": "Dentist",
  "EDIT_INFO_TAB_TXT_HOSPITAL": "Hospital",
  "EDIT_INFO_TAB_TXT_PASSWORD": "Change password",
  "EDIT_INFO_TAB_TXT_MEMBERSHIP": "Membership",
  "EDIT_INFO_TAB_TXT_PAYMENT_INFO": "Payment",
  "EDIT_INFO_TAB_TXT_PAYMENT_DETAIL": "Payment history",
  "EDIT_INFO_DOCTOR_TITLE": "Dentist information",
  "EDIT_INFO_HOSPITAL_TITLE": "Hospital information",
  "EDIT_INFO_PASSWD_TITLE": "Change password",
  "EDIT_INFO_TAB_TXT_SET_REQUEST": "Set Request",
  "EDIT_INFO_DOCTOR_TAB_TOP_TXT": "Name and ID(e-mail) cannot be changed. Please contact the manager to change name or ID.<br/>the information below is entered in the report as radiologist's",
  "EDIT_INFO_HOSPITAL_TAB_TOP_TXT": "Hospital information is required for issuing a tax invoice, so please enter it correctly.<br/>If you do not enter an email below, the tax invoice will be sent to the e-mail for ID.",
  "EDIT_INFO_HOSPITAL_TAB_TOP_TXT_VER_EN": "We will send you an invoice based on the hospital information below.",
  "EDIT_INFO_PASSWD_TAB_TOP_TXT": "At least 8 characters required, upper·lowercase letters/numbers/special characters available",
  "EDIT_INFO_DOCTOR_NAME": "Name *",
  "EDIT_INFO_DOCTOR_EMAIL": "ID(E-mail) *",
  "EDIT_INFO_DOCTOR_PHONE": "Mobile Number<span style='font-weight:400;margin-left:10px;'>with country code *</span>",
  "EDIT_INFO_DOCTOR_LICENSE_NO": "License Number",
  "EDIT_INFO_DOCTOR_LICENSE_FILE": "Doctor's License",
  "EDIT_INFO_DOCTOR_SIGN": "Signature of radiologist",
  "EDIT_INFO_DOCTOR_SIGN_UPLOAD_TXT": "Please upload the signature image. You can print a report with the signature.",
  "EDIT_INFO_DOCTOR_SIGN_UPLOAD_REQUIREMENT": "Image ratio 1 to 1, a resolution at least 100px recommended.",
  "EDIT_INFO_HOSPITAL_NAME": "Hospital name *",
  "EDIT_INFO_HOSPITAL_NAME_PLACEHOLDER": "Hospital name",
  "EDIT_INFO_HOSPITAL_CEO": "Name of Representative",
  "EDIT_INFO_HOSPITAL_BUSINESS_NO": "VAT/GST ID",
  "EDIT_INFO_HOSPITAL_BUSINESS_NO_PLACEHOLDER": "000-00-00000",
  "EDIT_INFO_HOSPITAL_ADDRESS": "Address",
  "EDIT_INFO_HOSPITAL_ADDRESS_PLACEHOLDER": "Lô SQ4, Khu Ngoại Giao Đoàn, Xuân Tảo, Bắc Từ Liêm",
  "EDIT_INFO_HOSPITAL_CITY_ADDRESS": "Address(City, Country)",
  "EDIT_INFO_HOSPITAL_CONTACT": "Hospital phone number<span style='font-weight:400;margin-left:10px;'>with country code *</span>",
  "EDIT_INFO_HOSPITAL_EMAIL_FOR_TAX": "Billing email ",
  "EDIT_INFO_HOSPITAL_BUSINESS_FILE": "Certificate for Business Registration",
  "EDIT_INFO_HOSPITAL_CEO_TXT_PLACEHOLDER":"Eugene Peterson",
  "EDIT_INFO_HOSPITAL_ADDRESS_TXT_PLACEHOLDER":"Address",
  "EDIT_INFO_HOSPITAL_NAME_ALERT_TXT": "Please enter the name of hospital.",
  "EDIT_INFO_HOSPITAL_CEO_ALERT_TXT": "Please enter the name of representative.",
  "EDIT_INFO_HOSPITAL_BUSINESS_NO_ALERT_TXT": "Please enter the business registration number.",
  "EDIT_INFO_HOSPITAL_ADDRESS_ALERT_TXT": "Please enter the address.",
  "EDIT_INFO_HOSPITAL_NUMBER_ALERT_TXT": "Please enter a valid number.",
  "EDIT_INFO_PASSWORD_TXT_FORGET": "I forgot my password",
  "EDIT_INFO_PASSWORD_OLD_ALERT_TXT": "Password is incorrect",
  "EDIT_INFO_PASSWORD_RE_NEW_ALERT_TXT": "Password is incorrect",
  "EDIT_INFO_PASSWORD_FORGET_MODAL_TXT_TITLE": "Temporary password",
  "EDIT_INFO_PASSWORD_FORGET_MODAL_TXT_DESCRIPTION": "We've sent a temporary password<br> to your email for ID",
  "EDIT_INFO_PASSWORD_FAIL_TO_GET_TEMP_PASSWD": "Failed to issue temporary password.", 
  "EDIT_INFO_SUCCESS_CHANGE_PASSWD": "You have succeeded in changing your password.",
  "EDIT_INFO_FAIL_CHANGE_PASSWD": "Failed to change password.",
  "EDIT_INFO_SUCCESS_CHANGE_DOCTOR": "You have succeeded in changing the doctor information.",
  "EDIT_INFO_FAIL_CHANGE_DOCTOR": "Failed to change doctor information.",
  "EDIT_INFO_SUCCESS_CHANGE_HOSPITAL": "You have succeeded in changing the hospital information.",
  "EDIT_INFO_FAIL_CHANGE_HOSPITAL": "Failed to change hospital information.",
  "EDIT_INFO_PASSWORD_FORGET_MODAL_BUTTON_CONFIRM": "Confirm",
  "EDIT_INFO_PASSWORD_TXT_OLD": "Old password",
  "EDIT_INFO_PASSWORD_TXT_NEW": "New password",
  "EDIT_INFO_PASSWORD_TXT_CONFIRM": "Confirm new password",
  "EDIT_INFO_PASSWORD_ALERT_TXT": "At least 8 characters required, En upper·lowercase letters/numbers/special characters available",
  "EDIT_INFO_PASSWORD_TXT_PLACEHOLDER_OLD": "Enter your old password",
  "EDIT_INFO_PASSWORD_TXT_PLACEHOLDER_NEW": "Enter your new password",
  "EDIT_INFO_PASSWORD_TXT_PLACEHOLDER_AGAIN": "Enter your new password again",
  "EDIT_INFO_TXT_HOSPITAL_ADDRESS": "Please enter the hospital address.",
  "EDIT_INFO_TXT_HOSPITAL_CONTACT": "Please enter the hospital number.",
  "EDIT_INFO_TXT_EMAIL_FOR_TAX": "Please enter your email for tax invoice.",
  "EDIT_INFO_TXT_POLICY_AUTO_REQ_PX": "PX Automatic Request Policy",
  "EDIT_INFO_TXT_POLICY_AUTO_REQ_CT": "CBCT Automatic Request Policy",
  "EDIT_INFO_TXT_REQ_NEW_PATIENT":"New Patient",
  "EDIT_INFO_TXT_REQ_POLICY_HALF_YEAR":"New Patient within 6 months",
  "EDIT_INFO_TXT_REQ_POLICY_A_YEAR":"New Patient within 12 months",
  "EDIT_INFO_TXT_REQ_REJECT":"Automatic Request Off",
  "EDIT_BUTTON_UPLOAD_FILE": "Register",
  "EDIT_INFO_TXT_FREE_TRIAL_MODAL_TITLE": "Free Trial",
  "EDIT_INFO_TXT_FREE_TRIAL_MODAL_SUB_TITLE": "You are using the PANVI AI Free Trial.",
  "EDIT_INFO_TXT_FREE_TRIAL_MODAL_INFO":"If you send any suggestions or comments<br>about the service to the email below,<br>it will be of great help to improve the service.<br>dhkim@invisionlab.kr",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_PRICE_PER_MONTH": "$ 30",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_DESCRIPTION1": "AI for panorama diagnosis",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_DESCRIPTION2": "tooth/periodontal/calcification 24 diseases",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PX_DESCRIPTION3": "image auto transfer(free installationn)",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IN_USE": "in use",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IMPLANT_PRICE_PER_MONTH": "$ 30",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IMPLANT_DESCRIPTION1": "AI for implant information",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_IMPLANT_DESCRIPTION2": "accuracy provided  ",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_PAY_DESCRIPTION": "Membership fees are charged on the start date of your billing cycle, and if you change your membership, your billing cycle will restart on that date.",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_CHANGE_MEMBERSHIP_DESCRIPTION1": "If you change your membership, the monthly fee will be paid with your registered payment information.",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_TXT_CHANGE_MEMBERSHIP_DESCRIPTION2": "The amount used in the previous month will be refunded. (Card cancellation may take several days.)",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_BUTTON_START_FREE": "Get started for free",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_BUTTON_START_MEMBERSHIP": "started membership",
  "EDIT_INFO_MEMBERSHIP_MEMBERSHIP_BUTTON_CANCEL_MEMBERSHIP": "cancel membership",
  "EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_SERVICE": "service",
  "EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_PAYMENT_DATE": "payment date",
  "EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_PAYMENT_METHOD": "payment method",
  "EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_PAYMENT_PRICE": "payment",
  "EDIT_INFO_MEMBERSHIP_PAYMENT_DETAIL_TXT_TABLE_ROW_TITLE_RECEIPT": "receipt",
  "EDIT_INFO_BUTTON_CLOSE": "return to main page",
  "EDIT_INFO_BUTTON_SAVE": "Save",
  "EDIT_INFO_BUTTON_CHANGE_MEMBERSHIP": "Change",

  "SIGNUP_TITLE_SIGNUP": "SIGN UP",
  "SIGNUP_ALERT_TXT_CHECK_REQUIRED_TERMS": "*You must agree to the required terms and conditions to use the service.",
  "SIGNUP_TXT_ID": "ID(e-mail)",
  "SIGNUP_TXT_PASSWORD": "password",
  "SIGNUP_BTN_ID_DOUBLE_CHECK": "duplicate check",
  "SIGNUP_TXT_ID_DOUBLE_CHECK_COMPLETE":"ID available",
  "SIGNUP_TXT_ALERT_DUPLICATED_EMAIL":"This is a duplicate ID.",
  "SIGNUP_TXT_ID_DOUBLE_CHECK_NOTICE":"Please click the duplicate check button.",
  "SIGNUP_TXT_PASSWORD_PLACEHOLDER":"Please enter a password.",
  "SIGNUP_TXT_PASSWORD_NOTICE":"At least 8 characters required, En upper·lowercase letters/numbers/special characters available",
  "SIGNUP_TXT_PHONE_NOTICE": "Please enter a valid number.",
  "SIGNUP_TXT_HOSPITAL_CONTACT": "Hospital phone number<span style='font-weight:400;margin-left:10px;'>with country code *</span>",
  "SIGNUP_TXT_CONTACT_PLACEHOLDER":"020000000",
  "SIGNUP_TXT_PHONE_PLACEHOLDER":"+84-00-0000-0000",
  "SIGNUP_TXT_HOSPITAL_NAME_PLACEHOLDER":"Please enter hospital’s name",
  "SIGNUP_TXT_PHONE":"Mobile number",
  "SIGNUP_TXT_CERTIFICATE_OF_BUSINESS_REGISTRATION_AND_LICENSE_NECESSITY": "<div><strong style='text-decoration:underline;'>Certificate for business registration</strong> is required for <strong style='text-decoration:underline;'>issuing a tax invoice</strong> when requesting diagnosis.<br><strong style='text-decoration:underline;'>A doctor's license</strong>is required to enter the information <strong style='text-decoration:underline;'>when issuing diagnosis report.</strong> </div>",
  "SIGNUP_TXT_CERTIFICATE_OF_BUSINESS_REGISTRATION":"Certificate for Business Registration",
  "SIGNUP_TXT_FILE_UPLOAD_NOTICE":"Please upload files in pdf, jpg, png format.",
  "SIGNUP_TXT_FILE_UPLOAD":"upload",
  "SIGNUP_TXT_LICENSE": "Doctor's License",
  "SIGNUP_TXT_EMAIL_FOR_TAX":"Email for Tax Invoice",
  "SIGNUP_TXT_NOTICE_WITHOUT_EMAIL_FOR_TAX":"If not entered, the tax invoice is sent to the email for ID.",
  "SIGNUP_TXT_NOTICE_FOR_ACCURATE_WRITING_OF_BUSINESS_INFO":"If the certificate for business registration is not uploaded, please enter it.",
  "SIGNUP_TXT_NOTICE_FOR_ACCURATE_WRITING_OF_LICENSE_INFO":"If the doctor's license file is not uploaded, please enter it.",
  "SIGNUP_TXT_HOSPITAL_NAME": "Hospital name *",
  "SIGNUP_TXT_HOSPITAL_REPRESENTATIVE":"Name of Representative",
  "SIGNUP_TXT_HOSPITAL_BUSINESS_NUMBER": "Business Registration Number",
  "SIGNUP_TXT_HOSPITAL_ADDRESS": "Address",
  "SIGNUP_TXT_HOSPITAL_ADDRESS_PLACEHOLDER": "Lô SQ4, Khu Ngoại Giao Đoàn, Xuân Tảo, Bắc Từ Liêm",
  "SIGNUP_TXT_HOSPITAL_CITY_ADDRESS_PLACEHOLDER": "Hanoi, Vietnam",
  "SIGNUP_TXT_HOSPITAL_CITY_ADDRESS": "Address(City, Country)",
  "SIGNUP_TXT_DOCTOR_NAME": "Doctor’s name *",
  "SIGNUP_TXT_DOCTOR_NAME_PLACEHOLDER": "Please enter your name",
  "SIGNUP_TXT_LICENSE_NUMBER": "License Number",
  "SIGNUP_TXT_TERMS_ALL_AGREE":"I agree to all of the following.",
  "SIGNUP_TXT_TERMS_OF_SERVICE":"Terms of the Service",
  "SIGNUP_TXT_PRIVATE_INFO_COLLECTION":"Personal information collection and use",
  "SIGNUP_TXT_MARKETING_INFO_RECEIVING":"Receive marketing(optional)",
  "SIGNUP_TXT_ALREADY_UPLOAD_BUSINESS_FILE": "certificate for business registration registered",
  "SIGNUP_TXT_ALREADY_UPLOAD_LICENSE_FILE": "doctor's license registered",
  "SIGNUP_TXT_SEE_TERMS":"view",
  "SIGNUP_BTN_PREV":"previous",
  "SIGNUP_BTN_NEXT":"NEXT",
  "SIGNUP_BTN_NO_IMG_FILE":"No Image File",
  "SIGNUP_BTN_CANCEL":"sign up later",
  "SIGNUP_BTN_SIGNUP": "<span style='letter-spacing:1px'>START PANVI</span>",
  "SIGNUP_MSG_DONE": "You have completed the registration application:)",
  "SIGNUP_MSG_FAIL": "Your registration for membership has failed.\nPlease try again.",


  "PRIVACY_POLICY_TITLE": "Guide for private information collection and use",
  "PRIVACY_POLICY_CLOSE": "Confirm",
  "PRIVACY_POLICY": `
    <p>
    주식회사 인비전랩(이하 ‘회사’라 한다)은 사이트 운영에 있어서 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)과 개인정보 보호법 등 대한민국의 개인정보 관련 법령 및
    개인정보 보호지침 등 규정을 준수합니다.
    <br>
    이에 회사는 이용자의 개인정보를 보호하고 이와 관련된 고충을 원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 수립하여 공개합니다.
    <br>
    <br>
    제1조 처리하는 개인정보 항목과 수집 방법
    <br>
    회사는 다음의 개인정보 항목을 처리하고 있습니다.
    <br>
    1. 서비스 가입 및 이용을 위한 회원 정보
    <br>
    - 필수: 의원명, 대표의사 성명, 담당자 성명, 담당자 연락처, 담당자 이메일 주소, 보유하고 있는 파노라마 장비 브랜드 등
    <br>
    - 선택: 의원 전화번호, 의원 주소, 소속 의료인에 관한 정보 등
    <br>
    2. 서비스의 제공을 위한 파노라마 영상 (단, 파노라마 영상은 사이트에 업로드되는 즉시 개인을 특정할 수 없도록 비식별처리됩니다.)
    <br>
    3. 이용자 민원 관련 정보
    <br>
    - 필수: 이름, 전화번호, 이메일 등
    <br>
    - 선택: 건강정보(치료영역, 질의분야, 안전성 정보 내용 및 기간 등) 등
    <br>
    4. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
    <br>
    - IP 주소, 서비스 이용 기록, 기기정보, 위치정보
    <br>
    <br>
    회사는 다음과 같은 방법 또는 과정을 통하여 위의 개인정보를 수집합니다.
    <br>
    - 서비스 회원가입을 위하여 계정을 생성하는 과정에서 수집
    <br>
    - 서비스 제공을 위한 소프트웨어 설치 등 필수적인 과정에서 수집
    <br>
    - 서비스에 대한 피드백 또는 민원 제공 과정에서 수집
    <br>
    <br>
    <br>
    제2조 개인정보의 처리 목적
    <br>
    회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
    동의를 받는 등 필요한 조치를 이행할 예정입니다.
    <br>
    1. 이용자의 가입 의사 확인, 이용자에 대한 서비스 제공에 따른 본인 식별·인증, 서비스 부정이용 방지, 이메일 발송 등 각종 고지·통지, 이용자 탈퇴 의사 확인
    <br>
    <br>
    주식회사 인비전랩(이하 ‘회사’라 한다)은 사이트 운영에 있어서 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)과 개인정보 보호법 등 대한민국의 개인정보 관련 법령 및
    개인정보 보호지침 등 규정을 준수합니다.
    <br>
    이에 회사는 이용자의 개인정보를 보호하고 이와 관련된 고충을 원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 수립하여 공개합니다.
    <br>
    <br>
    제1조 처리하는 개인정보 항목과 수집 방법
    <br>
    회사는 다음의 개인정보 항목을 처리하고 있습니다.
    <br>
    1. 서비스 가입 및 이용을 위한 회원 정보
    <br>
    - 필수: 의원명, 대표의사 성명, 담당자 성명, 담당자 연락처, 담당자 이메일 주소, 보유하고 있는 파노라마 장비 브랜드 등
    <br>
    - 선택: 의원 전화번호, 의원 주소, 소속 의료인에 관한 정보 등
    <br>
    2. 서비스의 제공을 위한 파노라마 영상 (단, 파노라마 영상은 사이트에 업로드되는 즉시 개인을 특정할 수 없도록 비식별처리됩니다.)
    <br>
    3. 이용자 민원 관련 정보
    <br>
    - 필수: 이름, 전화번호, 이메일 등
    <br>
    - 선택: 건강정보(치료영역, 질의분야, 안전성 정보 내용 및 기간 등) 등
    <br>
    4. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
    <br>
    - IP 주소, 서비스 이용 기록, 기기정보, 위치정보
    <br>
    <br>
    회사는 다음과 같은 방법 또는 과정을 통하여 위의 개인정보를 수집합니다.
    <br>
    - 서비스 회원가입을 위하여 계정을 생성하는 과정에서 수집
    <br>
    - 서비스 제공을 위한 소프트웨어 설치 등 필수적인 과정에서 수집
    <br>
    - 서비스에 대한 피드백 또는 민원 제공 과정에서 수집
    <br>
    <br>
    <br>
    제2조 개인정보의 처리 목적
    <br>
    회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
    동의를 받는 등 필요한 조치를 이행할 예정입니다.
    <br>
    1. 이용자의 가입 의사 확인, 이용자에 대한 서비스 제공에 따른 본인 식별·인증, 서비스 부정이용 방지, 이메일 발송 등 각종 고지·통지, 이용자 탈퇴 의사 확인
    <br>
  </p>
  `
}