<template>
  <div class="modal_bg_black file_info_modal" v-if="$store.state.fileInfoModal" id="examUploadModal">
    <div>
      <form class="form">
        <h2>{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_TITLE}}<!--영상정보 입력--></h2>
        <h3>{{$store.state.fileArr[0].content.filename}}</h3>
        <div>
          <section>
            <p>{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_PATIENT_NAME}}<!--환자명--></p>
            <div class="input_form">
              <input type="text" id="patient_name_input" v-model="info.patient_name">
              <label v-if="!info.patient_name.length" for="patient_name_input">{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_PLACEHOLDER_PATIENT_NAME}}<!--환자명을 입력하세요.--></label>
            </div>
          </section>
          
          <section>
            <p>{{lang[$store.state.lang].REPORT_TXT_CHART_NUMBER}}<!--차트번호--></p>
            <div class="input_form">
              <input type="text" id="chart_id_input" v-model="info.chart_id">
              <label v-if="!info.chart_id.length" for="chart_id_input">{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_PLACEHOLDER_CHART_NUMBER}}<!--차트번호를 입력하세요.--></label>
            </div>
          </section>

          <div class="form_row" >
            <section>
              <p>{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_TAKEN_DATE}}<!--촬영날짜--></p>
              <div class="input_form" style="display:flex">
                <input type="number" min="1900" max="2099" step="1" v-model="date.year" />
                <select v-model="date.month">
                  <option :value="month"  v-for="month in 12" :key="'month-'+month">{{month}}</option>
                </select>
                <select v-model="date.date">
                  <option :value="date"  v-for="date in new Date(today.getFullYear(),today.getMonth()+1,0).getDate()" :key="'date-'+date">{{date}}</option>
                </select>
              </div>
            </section>
            <section>
              <p>{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_TAKEN_TIME}}<!--촬영시간--></p>
              <div class="input_form" style="display:flex">
                <select v-model="time.hour">
                  <option :value="hour-1"  v-for="hour in 25" :key="'hour-'+hour">{{hour-1}}</option>
                </select>
                <select v-model="time.minute">
                  <option :value="minute-1"  v-for="minute in 60" :key="'minute-'+minute">{{minute-1}}</option>
                </select>
              </div>
            </section>
          </div>

          <div class="form_row">
            <section>
              <p>{{lang[$store.state.lang].REPORT_TXT_PATIENT_GENDER}}<!--성별--></p>
              <div class="input_form">
                <select v-model="info.sex">
                  <option value="default">{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_PLACEHOLDER_PATIENT_SEX}}<!--성별을 선택하세요--></option>
                  <option value="M">{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_SEX_MAIL}}<!--남성--></option>
                  <option value="F">{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_SEX_FEMAIL}}<!--여성--></option>
                </select>
              </div>
            </section>
            <section>
              <p>{{lang[$store.state.lang].REPORT_TXT_PATIENT_AGE}}<!--나이--></p>
              <div class="input_form">
                <input type="text" id="patient_age_input" v-model="info.age">
                <label v-if="!info.age.toString().length" for="patient_age_input">{{lang[$store.state.lang].EXAM_INFO_MODAL_TEXT_PLACEHOLDER_PATIENT_AGE}}<!--숫자로 입력하세요--></label>
              </div>
            </section>
          </div>

        </div>

        <div class="button_wrap">
          <button type="button" class="cancel" @click="_close">{{lang[$store.state.lang].REPORT_TXT_BUTTON_CANCEL}}<!--취소--></button>
          <button type="button" class="upload" @click="upload">{{lang[$store.state.lang].EXAM_INFO_MODAL_BUTTON_UPLOAD}}<!--입력하기--></button>
        </div>
        
      </form>
    </div>
      
  </div>
</template>

<script>
import lang from '../lang';
import wsMixin from '@/wsMixin';
import alert from '@/alert'
export default {
  name: 'ExamInfoForm',
  mixins : [wsMixin],
  data: function() {
    return{
      lang,
      info: {
        patient_name: '',
        chart_id: '',
        sex: 'default',
        age: '',
      },
      dateStr: '',
      today: new Date(),
      date: {year: new Date().getFullYear(), month: new Date().getMonth()+1, date: new Date().getDate()},
      time: {hour: new Date().getHours(), minute: new Date().getMinutes()}
    }
  },
  methods: {
    alert,
    upload(ev){
      ev.preventDefault();

      let {patient_name, chart_id, sex, age} = this.info;

      if( !patient_name.trim() || !chart_id.trim() || sex == 'default' || !age.trim() || isNaN(parseInt(age))) {
        return this.alert('error',this.lang[this.$store.state.lang].MAIN_ALERT_TXT_ENTER_BLANKS); // 모든 필드를 입력하세요.
      }
      // action 에 부여할 아이디
      const id = this._getRandomFileId(); 

      // date = YYYYMMDD 형식, time = HHMM 형식
      let date = this.date.year + this.convertNumberToStringAddZero(this.date.month) + this.convertNumberToStringAddZero(this.date.date);
      let time = this.convertNumberToStringAddZero(this.time.hour) + this.convertNumberToStringAddZero(this.time.minute);

      // 입력한 정보
      let writeInfo = {
        patient_name,
        chart_id,
        date,
        time,
        sex,
        age: parseInt(age),
      }

      this.$store.state.fileArr[0].content = {...this.$store.state.fileArr[0].content, ...writeInfo};

      let packet = this.$$build(64,id,this.$store.state.fileArr);
      this.$socket.send(packet);
      this.$store.state.fileInfoModal = false;
      this.$store.state.fileArr = [];
    },
    _getRandomFileId(){
      let randomId = 0;
      while('true'.length){
        randomId = Math.floor(Math.random() * 100  + 100);
        if(this.$store.state.examsUploaded.indexOf(randomId) == -1){
          this.$store.state.examsUploaded.push(randomId);
          break;
        }else continue;
      }
      return randomId;
    },
    convertNumberToStringAddZero(num){
      let str = num.toString();
      if(str.length == 1) return `0${str}`;
      else return str;
    },
    _close(ev){
      ev.preventDefault();
      this.$store.state.fileInfoModal = false
      this.$store.state.fileArr = [];
    },
  },
}
</script>

<style scoped>
  .modal_bg_black{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 3;
    overflow: auto;
  }
  .file_info_modal > div{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .form{
    width: 500px;
    height: 553px;
    padding: 48px 36px 48px 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #212025;
    color: white;
    border: 2px solid #5B5B5E;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .form h2{
    font-size: 28px;
    font-weight: 600;
  }
  .form input,
  .form select{
    width: 100%;
    padding: 10px 10px;
    background-color: #0E0E0E;
    color:#848488;
    border: 1px solid #2E2E33;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    box-sizing: border-box;
  }
  .input_form{
    position: relative;
  }
  .form label{
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #848488;
    font-size: 12px;
    opacity: 1;
    visibility: visible;
    cursor: text;
    transition: all .25s ease;
  }
  .form input:focus-within~label{
    opacity: 0;
    visibility: hidden;
  }
  .form_row,
  .form .button_wrap{
    display: flex;
    column-gap: 10px;
  }
  .form_row > section,
  .form .button_wrap > button{
    flex: 1;
  }
  .form p{
    padding-left: 15px;
    margin-bottom: 7px;
  }
  .form section{
    margin-bottom: 12px;
  }
  .form .button_wrap{
    height: 44px;
  }
  .form .button_wrap .cancel{
    height: 100%;
    color: white;
    border: 2px solid #848488;
    border-radius: 4px;
    font-size: 16px;
  }
  .form .button_wrap .upload{
    height: 100%;
    color: white;
    background-color: #A45CFE;
    border: 2px solid transparent;
    border-radius: 4px;
    font-size: 16px;
  }
  .form .button_wrap .upload:hover{
    border-color: white;
  }
</style>