<template>
  <div class="chatting">
    <div class="header">
      <div class="close_btn" @click="$emit('close')">
        <img src="@/assets/images/closeBK.png" alt="closeButton">
      </div>
      <div class="header_title">{{lang[$store.state.lang].CHATTING_TXT_HEADER}}<!--AI영상치의학과치과--></div>
    </div>
    <div class="main" ref="main">
      <div class="loading_bg" v-if="!msgsIsLoaded">
        <div class="spinner"></div>
      </div>
      <div class="chat_wrapper" v-for="(msg,idx) in msgs" :key="'msgs-'+idx+'-'+msg.created">
        <div class="date_info" v-if="idx==0 || (new Date(msgs[idx-1].created).getFullYear() != new Date(msg.created).getFullYear() || new Date(msgs[idx-1].created).getMonth() != new Date(msg.created).getMonth() || new Date(msgs[idx-1].created).getDate() != new Date(msg.created).getDate())">
          <p v-if="$store.state.lang == 'ko'">{{new Date(msg.created).getFullYear()}}년 {{new Date(msg.created).getMonth()+1}}월 {{new Date(msg.created).getDate()}}일</p>
          <p v-else>{{months[new Date(msg.created).getMonth()]}} {{(new Date(msg.created).getDate()).toString().length == 1 ? '0'+(new Date(msg.created).getDate()).toString():(new Date(msg.created).getDate())}}, {{new Date(msg.created).getFullYear()}}</p>
        </div>
        <div class="chat_column" v-if="msg.direction == 'RD TO LABELER'">
          <div class="chat chat_me" >
            <div class="chat_date">
              <div class="time">{{new Date(msg.created).getHours()}}:{{new Date(msg.created).getMinutes().toString().length>1?new Date(msg.created).getMinutes():`0${new Date(msg.created).getMinutes()}`}}</div>
            </div>
            <div class="chat_text">{{msg.msg}}</div>
          </div>
        </div>
        <div class="chat_column" v-if="msg.direction == 'LABELER TO RD'">
          <!-- <div class="name">{{msg.written_by.name}}</div> -->
          <div class="chat" >
            <div class="chat_text">{{msg.msg}}</div>
            <div class="chat_date">
              <div class="time">{{new Date(msg.created).getHours()}}:{{new Date(msg.created).getMinutes().toString().length>1?new Date(msg.created).getMinutes():`0${new Date(msg.created).getMinutes()}`}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="chat_area" ref="chatBox">
        <textarea ref="input" id="chatInput" rows="1" @keydown="_keydownOnTextarea" v-model="msg" class="chat_input" type="text"/>
        <label v-if="msg.length == 0" for="chatInput">{{lang[$store.state.lang].CHATTING_TXT_PLACEHOLDER}}<!--메시지를 입력해주세요.--></label>
        <button @click="sendMessage" class="send_msg">{{lang[$store.state.lang].CHATTING_TXT_BUTTON}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import wsMixin from '@/wsMixin';
export default {
  name : 'ChattingView',
  mixins: [wsMixin],
  props:['seq','msgs','msgsIsLoaded','shouldOpenChat','hasNewChat'],
  data(){
    return{
      lang,
      msg : '',
      isAddedNewChat: false,
      months: ["January","February","March","April","May","Jun","July","August","September","October","November","December"]
    }
  },
  methods : {
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      /*
      ** 102번 액션 (= 특정 영상정보에 대한 채팅메시지 보내기) 에 대한 응답이 수신되었을 때의 처리
      ** 보낸 메시지를 상위 컴포넌트로 전달한 후, 새로운 메시지 작성을 위해 메시지값에 빈 문자열을 대입한다.  
      */
      if(data.action == 102){
        if(data.body[0].content.success){
          this.$emit('add',this.msg);
          this.msg = '';
          this.$refs.input.value = '';
        }
      }
      // 103번 action(= 채팅메시지 수신)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 103) {
        let packet = this.$$build(105,[{
          contentType: 1,
          content: {exam:this.seq}
        }])
        this.$socket.send(packet);
      }
    },
    sendMessage(){
      // 메시지를 작성하지 않고 채팅을 발신하는 경우 액션을 보내지 않고, 함수 실행을 종료한다.
      if(this.msg.length == 0)return;
      let packet = this.$$build(102,[{
        contentType: 1,
        content: {exam : this.seq, msg:this.msg}
      }])
      this.$socket.send(packet);
    },
    _keydownOnTextarea(ev){
      //  textarea에서 keydown 이벤트가 발생했을 때, keydown된 키의 코드가 27 (= esc) 인 경우 채팅창을 닫고 함수를 종료한다.
      if(ev.keyCode == 27) return this.$emit('close');
      // textarea에서 keydown 이벤트가 발생했을 때, keydown된 키의 코드가 13 (= 엔터) 인 경우 줄바꿈 하지 않고, 채팅발신 함수를 호출한다.
      if(ev.keyCode == 13){
        ev.preventDefault();
        this.sendMessage();
      }
    },
  },
  mounted(){
    let main = this.$refs.main;
    main.scrollTop = main.scrollHeight;
    this.$refs.input.focus();    
    if(!this.hasNewChat || !this.msgsIsLoaded) return;
    let packet = this.$$build(105,[{
      contentType: 1,
      content: {exam:this.seq}
    }]);
    this.$socket.send(packet);
  },
  watch:{
    msgs(){
      this.$nextTick(()=>{
        let main = this.$refs.main;
        main.scrollTop = main.scrollHeight;
      })
    },
    msg(newVal,prevVal){
      // 채팅 메시지의 길이가 길어지는 중에 채팅 메시지를 작성하는 textarea 의 부모박스의 높이가 100px 이상이 된다면 함수를 종료한다.
      if(this.$refs.chatBox.clientHeight >= 100 && newVal.length > prevVal.length) return;
      this.$refs.chatBox.style.height = 'auto';
      // textarea 부모박스의 clientHeight 보다 textarea의 scrollHeight가 더 큰 경우, 부모박스의 높이를  textarea의 scrollHeight로 변경한다.
      if(this.$refs.chatBox.clientHeight < this.$refs.input.scrollHeight) {
        this.$refs.chatBox.style.height = this.$refs.input.scrollHeight + 'px';
      }
      let main = this.$refs.main;
      main.scrollTop = main.scrollHeight;
    },
    msgsIsLoaded(newVal) {
      if(this.hasNewChat && newVal) {
        this.$refs.input.focus();
        let packet = this.$$build(105,[{
          contentType: 1,
          content: {exam:this.seq}
        }]);
        this.$socket.send(packet);
      }
    }
  },
}
</script>

<style scoped>
  .chatting{
    display: grid;
    grid-template-rows: 60px 1fr auto;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 370px;
    height: 70%;
    background: white;
    border-radius: 8px;
    z-index: 3;
  }
  .header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EFE7FF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .header_title{
    font-size: 17px;
    font-weight: 600;
  }
  .close_btn{
    position: absolute;
    right: 20px;
    cursor: pointer;
  }
  .main{
    overflow: auto;
    position: relative;
  }
  .loading_bg{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading_bg>.spinner{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: var(--color-selected);
    border-bottom-color: var(--color-selected);
    animation: spinner .8s ease infinite;
    z-index: 3;
  }
  .date_info{
    text-align: center;
    padding-top: 10px;
  }
  .date_info p{
    padding: 5px 10px;
    border-radius: 20px;
    display: inline-block;
    font-size: 8px;
    background: rgba(0, 0, 0, 0.02);
  }
  .main::-webkit-scrollbar{
    background: rgba(0, 0, 0, 0.2);
  }
  .main::-webkit-scrollbar{
    width: 5px;
    height: 5px;
  }
  .main::-webkit-scrollbar-thumb {
    width: 4px;
    height: 4px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  .chat_column{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin: 30px 10px;
  }
  .name{
    padding-left: 3px;
  }
  .chat{
    display: flex;
    column-gap: 5px;
    color: rgba(0, 0, 0, 0.4);;
  }
  .chat_me{
    justify-content: flex-end;
  }
  .chat_me .chat_text{
    background-color: rgb(248, 248, 143);
  }
  .chat_text{
    padding: 8px 10px;
    min-height: 25px;
    max-width: 280px;
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.05);
    color: black;
    box-sizing: border-box;
    font-size: 13px;
    word-break:break-all;
  }
  .chat_date{
    display: flex;
    align-items: flex-end;
    font-size: 10px;
  }
  .footer{
    min-height: 70px;
    padding: 10px 8px;
    box-sizing: border-box;
  }
  .footer>.chat_area{
    position: relative;
    margin: 0 auto;
    min-height: 50px;
    max-height: 100px;
    box-sizing: border-box;
  }
  .chat_input{
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: 60px;
    padding-left: 16px;
    background: none;
    background:  rgba(239, 239, 240, 0.8);
    border: none;
    outline: none;
    border-radius: 8px;
    box-sizing: border-box;
    resize: none;
    font-size: 16px;
  }
  .chat_input::-webkit-scrollbar{
    display: none;
  }
  .send_msg{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    padding: 0;
    font-size: 13px;
    font-weight: 600;
  }
  .chat_input:focus-within+label{
    opacity: 0;
    visibility: hidden;
  }
  .chat_input+label{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    transition: all 0.5s;
    opacity: 1;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    cursor: text;
  }
</style>