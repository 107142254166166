<template>
  <div class="register_en">
    <div class="register_box" :style="{height: page == 0 ? '646px' : '720px'}">
      <div class="title">
        <h3>{{lang[$store.state.lang].SIGNUP_TITLE_SIGNUP}}<!--회원가입--></h3>
      </div>
      <div v-if="page==0" class="terms">
        <div class="list_title agree_title" @click="checkAll">
          <div class="check">
            <input v-model="all" type="checkbox" id="all_agree">
            <label for="all_agree" class="pointer"></label>
          </div>
          <p class="pointer">{{lang[$store.state.lang].SIGNUP_TXT_TERMS_ALL_AGREE}}<!--모두동의--></p>
        </div>
        <ul class="check_box">
          <li class="agree_list">
            <div class="agree_list_left">
              <div class="check">
                <input v-model="serviceUse" type="checkbox" id="service_use">
                <label for="service_use" class="pointer"></label>
              </div>
              <p>{{lang[$store.state.lang].SIGNUP_TXT_TERMS_OF_SERVICE}}<!--판비서비스 이용약관 동의--></p>
            </div>
            <div><a href="https://invisionlab.kr/policy" target="blank">{{lang[$store.state.lang].SIGNUP_TXT_SEE_TERMS}}<!--보기--></a></div>
          </li>
          <li class="agree_list">
            <div class="agree_list_left">
              <div class="check">
                <input v-model="privateInfo" type="checkbox" id="private_info">
                <label for="private_info" class="pointer"></label>
              </div>
              <p>{{lang[$store.state.lang].SIGNUP_TXT_PRIVATE_INFO_COLLECTION}}<!--개인정보 수집 및 이용 동의--></p>
            </div>
            <div><a href="https://invisionlab.kr/policy" target="blank">{{lang[$store.state.lang].SIGNUP_TXT_SEE_TERMS}}<!--보기--></a></div>
          </li>
        </ul>
        <p class="terms_alert" v-if="!agreed">{{lang[$store.state.lang].SIGNUP_ALERT_TXT_CHECK_REQUIRED_TERMS}}<!--*필수 약관에 동의해 주셔야 서비스 이용이 가능합니다.--></p>
      </div>

      <div v-if="page == 1" class="first_form form">
        <ul>
          <li>
            <div class="list_title">{{lang[$store.state.lang].SIGNUP_TXT_ID}}<!--아이디(이메일)--></div>
            <div class="input_box flex_space_between">
              <div class="input_wrap flex_1 person_img">
                <input ref="registerId" v-model="id" type="text" placeholder="aaa@naver.com">
              </div>
              <button v-show="!idIsChecked" @click="idCheck" :disabled="id.length<1 || !(id.includes('@'))" class="input_btn">{{lang[$store.state.lang].SIGNUP_BTN_ID_DOUBLE_CHECK}}<!--중복확인--></button>
              <span v-show="idIsChecked" class="id_check_txt">{{lang[$store.state.lang].SIGNUP_TXT_ID_DOUBLE_CHECK_COMPLETE}}<!--중복확인 완료--></span>
            </div>
            <p class="color_red under_info_position">{{!alertText.idAlert?'':lang[$store.state.lang].SIGNUP_TXT_ID_DOUBLE_CHECK_NOTICE}}<!--중복 확인을 클릭해 주세요--></p> 
          </li>
          <li>
            <div class="list_title">{{lang[$store.state.lang].SIGNUP_TXT_PASSWORD}}<!--비밀번호--></div>
            <div class="input_box">
              <div class="input_wrap passwd_img">
                <input maxlength="20" v-model="passwd" type="password" :placeholder="lang[$store.state.lang].SIGNUP_TXT_PASSWORD_PLACEHOLDER">
              </div>
            </div>
            <p class="color_red under_info_position">{{!alertText.pwAlert?'':lang[$store.state.lang].SIGNUP_TXT_PASSWORD_NOTICE}}<!--10~20자 이내 영문 대소문자, 숫자 또는 특수문자 조합--></p> 
          </li>
        </ul>
      </div>

      <div v-else-if="page==2" class="second_form form">
        <ul>
          <li>
            <div class="list_title">{{lang[$store.state.lang].SIGNUP_TXT_DOCTOR_NAME}}<!--Doctor’s name *--></div>
            <div class="input_box flex_space_between">
              <div class="input_wrap flex_1 person_img">
                <input ref="doctorName" v-model="doctorInfo.name" type="text" :placeholder="lang[$store.state.lang].SIGNUP_TXT_DOCTOR_NAME_PLACEHOLDER"> <!--Please enter your name-->
              </div>
            </div>
            <p class="color_red under_info_position">{{!alertText.doctorNameAlert?'': 'Please enter your name'}}</p> 
          </li>
          <li>
            <div class="list_title" v-html="lang[$store.state.lang].EDIT_INFO_DOCTOR_PHONE"></div> <!--Mobile number with country code *-->
            <div class="input_box">
              <div class="input_wrap phone_img">
                <input v-model="doctorInfo.phone" type="text" :placeholder="lang[$store.state.lang].SIGNUP_TXT_PHONE_PLACEHOLDER"> <!--+84-00-0000-0000-->
              </div>
            </div>
            <p class="color_red under_info_position">{{!alertText.doctorPhoneAlert?'':lang[$store.state.lang].SIGNUP_TXT_PHONE_NOTICE}}<!--유효한 번호를 입력하세요--></p> 
          </li>
          <li>
            <div class="list_title">{{lang[$store.state.lang].SIGNUP_TXT_LICENSE}}<!--의사면허증--></div>
            <div class="input_box flex_space_between">
              <div class="file_name_txt" :style="{color:licenseFileName&&'white'}" @mouseenter="removeFileBtn = true" @mouseleave="removeFileBtn = false">
                <span style="position:relative">
                  <div class="remove_file_btn" v-show="removeFileBtn && licenseFileName" @click="removeFile"><img src="@/assets/images/dise_pu_close.png" alt="remove_file_btn" width="10"></div>
                  {{licenseFileName?licenseFileName:lang[$store.state.lang].SIGNUP_TXT_FILE_UPLOAD_NOTICE}}<!--'pdf,jpg,png 형식의 이미지 파일을 올려주세요.'-->
                </span>
              </div>
              <label class="input_btn upload_file_label" for="license_no">{{lang[$store.state.lang].SIGNUP_TXT_FILE_UPLOAD}}<!--파일등록--></label>
              <input ref="licenseRef" @change="_getLicenseFileName" accept="image/png, image/jpeg, .pdf" id="license_no" type="file" style="display:none">
            </div>
          </li>
        </ul>
      </div>

      <div v-else-if="page==3" class="third_form form">
        <ul>
          <li>
            <div class="list_title">{{lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_NAME}}<!--병원이름--></div>
            <div class="input_box">
              <div class="input_wrap address_img">
                <input v-model="hospitalInfo.name" type="text" :placeholder="lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_NAME_PLACEHOLDER">
              </div>
            </div>
            <p class="color_red under_info_position">{{!alertText.hospitalNameAlert?'': lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_NAME_PLACEHOLDER}}</p> 
          </li>
          <li>
            <div class="list_title" v-html="lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_CONTACT"></div> <!--Hospital phone number with country code *-->
            <div class="input_box">
              <div class="input_wrap phone_img">
                <input v-model="hospitalInfo.phone" type="text" :placeholder="lang[$store.state.lang].SIGNUP_TXT_PHONE_PLACEHOLDER">
              </div>
            </div>
            <p class="color_red under_info_position">{{!alertText.hospitalPhoneAlert?'':lang[$store.state.lang].SIGNUP_TXT_PHONE_NOTICE}}<!--유효한 번호를 입력하세요--></p> 
          </li>
          <li>
            <div class="list_title">{{lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_ADDRESS}}<!--병원주소--></div>
            <div class="input_box">
              <div class="input_wrap address_img">
                <input v-model="hospitalInfo.address" type="text" :placeholder="lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_ADDRESS_PLACEHOLDER"> <!--Lô SQ4, Khu Ngoại Giao Đoàn, Xuân Tảo, Bắc Từ Liêm-->
              </div>
            </div>
          </li>
          <li>
            <div class="list_title">{{lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_CITY_ADDRESS}}<!--Address(City, Country)--></div>
            <div class="input_box">
              <div class="input_wrap address_img">
                <input v-model="hospitalInfo.city" type="text" :placeholder="lang[$store.state.lang].SIGNUP_TXT_HOSPITAL_CITY_ADDRESS_PLACEHOLDER"> <!--Hanoi, Vietnam-->
              </div>
            </div>
          </li>
        </ul>
      </div>


      <div class="btns">
        <button @click="prevPage" class="btn_basic bg_transparent">{{page != 0 ? lang[$store.state.lang].SIGNUP_BTN_PREV : lang[$store.state.lang].SIGNUP_BTN_CANCEL}}<!--이전단계, 나중에 가입하기--></button>
        <button 
          @click="nextPage"
          :class="[
            'btn_basic', 
            ( page == 0 && serviceUse&& privateInfo ) || 
            ( page == 1 && idIsChecked && passwdIsChecked ) ||
            ( page == 2 && doctorInfo.name.length != 0 && doctorInfo.phone.length >= 14 ) || 
            ( page == 3 && hospitalInfo.name.length != 0 && hospitalInfo.phone.length != 0)
            ?'btn_active'
            : 'disabled'
          ]"
          v-html="page == 3 ? lang[$store.state.lang].SIGNUP_BTN_SIGNUP : lang[$store.state.lang].SIGNUP_BTN_NEXT"
        >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import lang from '@/lang';
import wsMixin from '@/wsMixin';
import { AsYouType } from 'libphonenumber-js'
import alert from '@/alert';

export default {
  name: 'RegisterEn',
  mixins: [wsMixin],
  data: function(){
    return{
      lang,
      alert,
      page: 0,
      agreed: true,
      all: false,
      serviceUse: false,
      privateInfo: false,
      marketing: false,
      idIsChecked: false,
      id: '',
      passwd: '',
      doctorInfo:{
        phone: '',
        name: '',
      },
      hospitalInfo:{
        phone: '',
        address:'',
        city: '',
        name: '',
      },
      alertText: {
        idAlert: false,
        pwAlert: false,
        doctorPhoneAlert: false,
        doctorNameAlert: false,
        hospitalNameAlert: false,
        hospitalPhoneAlert: false,
      },
      passwdIsChecked: false,
      licenseFileName: '',
      licenseUrl: '',
      showRemoveBtn: false,
      removeFileBtn: false,
      businessFileName: ''
    }
  },
  methods: {
    onRecv(data){
      // 85번 action(= 아이디 중복확인)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 85){
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success) {
          this.idIsChecked = true;
          this.alertText.idAlert = false;
        }
        // 응답이 실패인 경우(= 아이디 중복)에 대한 처리
        else {
          this.alert('error',this.lang[this.$store.state.lang].SIGNUP_TXT_ALERT_DUPLICATED_EMAIL); // '중복되는 아이디 입니다.'
          this.id = "";
          this.$refs.registerId.focus();
        }
      }
      // 19번 action(= 가입신청)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 19){
        // 응답이 성공한 경우 11번 action(= 로그인) 을 보낸다.
        if(data.body[0].content.success){
          this.alert('success',lang[this.$store.state.lang].SIGNUP_MSG_DONE); // You have completed the registration application:)
          let userInfo = this.$$build(11,1,[{
            contentType: 1,
            content: {type:'RD',email:this.id,passwd:this.passwd}
          }])
          this.$socket.send(userInfo);
        }
        // 응답이 실패인 경우에 대한 처리
        else{
          this.alert('error',lang[this.$store.state.lang].SIGNUP_MSG_FAIL); // '가입신청에 실패하셨습니다.\n다시 신청해주세요'
        }
      }
      /*
      ** 11번 action(= 로그인)에 대한 응답이 수신된 경우에 대한 처리
      ** id = 1 회원가입 이후 바로 로그인 action을 보내는 경우
      */
      if(data.action == 11 && data.id == 1){
        // 응답이 성공인 경우에 대한 처리로, 회원정보와 병원정보 등을 저장한 후 Main 으로 이동한다.
        if(data.body[0].content.success ){
          sessionStorage.setItem('id',this.id);
          sessionStorage.setItem('passwd',this.passwd);
          this.$emit('close');
          this.$store.state.socket.isLoggedIn = true;
        }
        // 응답이 실패인 경우에 대한 처리
        else this.$emit('close');
      }
    },
    checkAll(ev){
      ev.preventDefault();
      if(ev.target.tagName == 'P' || ev.target.tagName == 'INPUT' || ev.target.tagName == 'LABEL' ){
        // 모든 약관이 선택되어 있는 상황에서 모두동의 버튼을 클릭한 경우로, 모든 약관의 선택을 해제한다.
        if(this.all){
          this.serviceUse = false;
          this.privateInfo = false;
          this.marketing = false;
        }
        // 모든 약관이 선택되어 있지 않은 상황에서 모두동의 버튼을 클릭한 경우로, 모든 약관을 선택한다.
        else{
          this.serviceUse = true;
          this.privateInfo = true;
          this.marketing = true;
        }
      }
    },
    nextPage(){
      /*
      ** click 이벤트가 발생했을 때, 사용자가 회원가입 창의 0 번째 페이지를 보고있으면서 약관을 모두 체크하지 않은 경우에 대한 처리
      ** 약관을 모두 체크해야 다음 페이지로 넘어갈 수 있으며 모두 체크하지 않은 경우에는 alert를 띄워 사용자에게 알리고,  함수를 종료한다.
      */
      if( this.page == 0 ){
        if(!(this.serviceUse && this.privateInfo)) return this.agreed = false;
        else this.agreed = true;
      }
      // click 이벤트가 발생했을 때, 사용자가 회원가입 창의 1 번째 페이지를 보고있는 경우에 대한 처리
      else if(this.page==1){
        // 아이디 중복확인, 비밀번호 조건이 충족되지 않는 경우에 대한 처리
        if(!this.idIsChecked || !this.passwdIsChecked) {
          if( !this.idIsChecked ) this.alertText.idAlert = true;
          if( !this.passwdIsChecked ) this.alertText.pwAlert = true;
          return;
        }
      } 
      /*
      ** click 이벤트가 발생했을 때, 사용자가 회원가입 창의 2 번째 페이지를 보고있으면서 의사 면서증 이미지 파일, 사업자등록증 이미지 파일을 모두 등록한 경우에 대한 처리
      ** 이미지 파일을 등록하여 회원가입을 진행한다.
      */
      else if(this.page == 2 ) {
        if(this.doctorInfo.phone.length == 0 || this.doctorInfo.name.length <= 0 ) {
          if(this.doctorInfo.phone.length == 0 ) this.alertText.doctorPhoneAlert = true;
          if(this.doctorInfo.name.length == 0 ) this.alertText.doctorNameAlert = true;
          return;
        }
      }
      /*
      ** click 이벤트가 발생했을 때, 사용자가 회원가입 창의 3 번째 페이지를 보고있는 경우에 대한 처리
      ** 이미지 파일을 등록하지 않고, 직접 정보를 입력하여 회원가입을 진행한다.
      */
      else if(this.page == 3){
        if(this.hospitalInfo.name.length == 0 || this.hospitalInfo.phone.length == 0 ) {
          if(this.hospitalInfo.name.length == 0) this.alertText.hospitalNameAlert = true;
          if(this.hospitalInfo.phone.length == 0) this.alertText.hospitalPhoneAlert = true;
          return;
        }
        let body = {
          contentType: 1,
          content: {
            hospital: {
              name: this.hospitalInfo.name,
              phone: this.hospitalInfo.phone,
            },
            doctor:{
              email: this.id,
              phone: this.doctorInfo.phone,
              name: this.doctorInfo.name,
              passwd: this.passwd
            },
            agree1: this.serviceUse,
            agree2: this.privateInfo
          }
        }

        if(this.licenseFileName) body.content.doctor.license = this.licenseUrl;
        if(this.hospitalInfo.address) body.content.hospital.addr1 = this.hospitalInfo.address;
        if(this.hospitalInfo.city) body.content.hospital.addr2 = this.hospitalInfo.city;
        let packet = this.$$build(19,[body]);
        this.$socket.send(packet);
      }
      // 회원가입의 마지막 페이지는 3 이므로, 페이지가 3 보다 작은 경우에는 page 에 1을 더하여 다음 페이지를 사용자가 볼 수 있도록 한다.
      if(this.page < 3) this.page += 1;
    },
    prevPage(){
      // 회원가입이 페이지가 0 인 경우에는 이벤트를 방출하여 컴포넌트를 없앤다.
      if(this.page == 0) {
        return this.$emit('close');
      }
      this.page -= 1;
    },
    idCheck(){
      // 아이디의 길이가 1보다 작거나, 아이디에 @가 포함되어있지 않은 경우에 함수를 종료한다.
      if(this.id.length<1 || !this.id.includes('@')) return;
      let packet = this.$$build(85,[{
        contentType : 1,
        content : {email:this.id}
      }])
      this.$socket.send(packet);
    },
    removeFile(){
      this.$refs.licenseRef.value = '';
      this.licenseFileName = '';
      this.licenseUrl = '';
      this.removeFileBtn = false;
    },
    _getLicenseFileName(ev){
      this.licenseFileName = ev.target.files[0].name;
      let licenseFr = new FileReader();
      licenseFr.readAsDataURL(this.$refs.licenseRef.files[0]);
      licenseFr.onload = (e) => { 
        this.licenseUrl= e.target.result;
      }
    },
  },
  watch: {
    serviceUse(){
      // 약관을 모두 선택한 경우에 대한 처리로 all에 true를 할당하여 모두동의 버튼이 체크되도록 한다.
      if(this.serviceUse&&this.privateInfo) this.all = true;
      // 약관을 모두 선택하지 않은 경우에 대한 처리로, 모두동의 버튼의 선택이 해제되도록 한다.
      else this.all = false;
    },
    privateInfo(){
      // 약관을 모두 선택한 경우에 대한 처리로 all에 true를 할당하여 모두동의 버튼이 체크되도록 한다.
      if(this.serviceUse&&this.privateInfo) this.all = true;
      // 약관을 모두 선택하지 않은 경우에 대한 처리로, 모두동의 버튼의 선택이 해제되도록 한다.
      else this.all = false;
    },
    passwd(){
      let condition =  /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      // 입력한 비밀번호에 한글이 포함되어있지 않고, 8자 이상인 경우에 대한 처리 
      if(!condition.test(this.passwd) && this.passwd.length>=8) {
        this.passwdIsChecked = true;
        this.alertText.pwAlert = false;
      }
      // 입력한 비밀번호에 한글이 포함되어있거나, 8자 미만인 경우
      else this.passwdIsChecked = false;
    },
    // 아이디 중복확인을 받은 후, 아이디를 변경하는 경우에 대한 처리
    id(){
      if(this.idIsChecked) this.idIsChecked = false;
    },
    "doctorInfo.phone"(val,prev){
      if(prev.length == 0) this.doctorInfo.phone = '+84-'+ val;
      if(val.length > 1) {
        let converted = new AsYouType('VN').input(val);
        this.doctorInfo.phone = converted.replaceAll(' ','-');
        if(this.alertText.doctorPhoneAlert) this.alertText.doctorPhoneAlert = false;
      } 
    },
    "hospitalInfo.phone"(val,prev){
      if(prev.length == 0) this.hospitalInfo.phone = '+84-'+ val;
      if(val.length > 1) {
        let converted = new AsYouType('VN').input(val);
        this.hospitalInfo.phone = converted.replaceAll(' ','-');
        if(this.alertText.hospitalPhoneAlert) this.alertText.hospitalPhoneAlert = false;
      }
    },
    "doctorInfo.name"(val){
      if(val.length > 0 && this.alertText.doctorNameAlert) this.alertText.doctorNameAlert = false;
    },
    "hospitalInfo.name"(val){
      if(val.length > 0 && this.alertText.hospitalNameAlert) this.alertText.hospitalNameAlert = false;
    },
  },
}
</script>

<style scoped>
  .register_en{
    display: grid;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: hsla(0,0%,5%,.85);
    z-index: 5;
  }
  .register_box{
    display: grid;
    grid-template-rows: 28px 1fr 44px;
    gap: 36px;
    padding: 48px 60px 48px 48px;
    width: 640px;
    box-sizing: border-box;
    background: #212125;
    color: white;
    border-radius: 8px;
  }

  input{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    color: white;
  }
  ::-webkit-input-placeholder{
    color: #848488;
    transition: color 0.3s;
  }
  input:focus::-webkit-input-placeholder {
    color: transparent;
  }
  .third_height{
    height: 860px;
  }
  .title{
    display: flex;
    align-items: center;
  }
  .title>h3{
    font-size: 32px;
    line-height: 42px;
    font-weight: 700;
    color: white;
  }
  .first_form{                
    justify-self: center;
    width: 100%;
  }
  .form li{
    margin-bottom: 27px;
  }
  .list_title{
    margin-left: 12px;
    margin-bottom: 6px;
    color: white;
    font-size: 16px;
    font-weight: 700;
  }
  .flex_space_between{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .input_box{
    position: relative;
    padding-left: 28px;
    width: 100%;
    height: 40px;
    background:  rgba(132, 132, 136,0.25);
    border: 1px solid rgba(255, 255, 255,0.1);
    box-sizing: border-box;
  }
  .input_bg{
    background:  rgba(132, 132, 136,0.25);
  }
  .bg_transparent{
    background: transparent;
  }
  .input_btn{
    width: 120px;
    height: 100%;
    background: #A45CFE;
    color: white;
    font-size: 14px;
    border: 1px solid white;
    cursor: pointer;
  }
  .input_btn:disabled{
    opacity: 0.5;
    cursor: default;
  }
  .id_check_txt{
    margin-right: 10px;
    color: white;
    font-size: 14px;
  }
  .flex_1{
    flex: 1;
  }
  .input_wrap{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 0px center;
  }
  .form input{
    padding-left: 42px;
    color: white;
    font-size: 14px;
    box-sizing: border-box;
  }
  .under_info_position{
    position: absolute;
    height: 17px;
    margin-left: 28px;
    font-size: 11px;
  }
  .color_red{
    color: #E6404D;
    opacity: 0.7;
  }
  .color_gray{
    color: #DFDEE0;
  }
  .btns{
    display: grid;
    grid-template-columns: 132px 164px;
    justify-content: space-between;
  }
  .btn_basic{
    border:1px solid #5B5B5E;
    color: #5B5B5E; 
    color: white;
    border-radius: 4px; 
    font-size: 16px;
    font-weight: 500;
    background: #A45CFE;
  }
  .bg_transparent{
    background-color: transparent;
  }
  .btn_active{
    background: #A45CFE;
    font-size: 16px;
    font-weight: 700;
  }
  .second_form{
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .form ul{
    width: 100%;
    justify-self: center;
  }
  .upload_file_label{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file_name_txt{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    color: #848488;
    font-weight: 500;
    font-size: 14px;
  }
  .remove_file_btn{
    position: absolute;
    top: 50%;
    right: -15px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .sub_title{
    margin-top: 34px;
    margin-bottom: 18px;
    color: #FFFFFF;
    font-size: 15px;
  }
  .sub_title_second{
    margin-top: 11px;
    margin-bottom: 22px;
  }
  .third_form{
    display: flex;
    flex-direction: column;
  }
  .third_form .list_title{
    font-size: 14px;
  }
  .agree_title{
    display: flex;
    align-items: center;
    column-gap: 13px;
    margin-top: 48px;
    margin-bottom: 20px;
    color: white;
  }
  .terms input{
    display: none;
    width: 100%;
    height: 100%;
  }
  .check{
    position: relative;
    width: 29px;
    height: 29px;
  }
  .check label{
    position: absolute;
    top: 0;
    left: 0;
    width: 29px;
    height: 29px;
    background-image: url('https://panvi.kr/images/dise_pu_chk-c01e43bd3e4ec973d845..png');
    background-size: cover;
  }
  input[type="checkbox"]:checked + label{
    background-image: url('https://panvi.kr/images/dise_pu_chk_on-9bcce7de261a2ea0bdaf..png');
  }
  .check_box{
    padding: 20px 25px 20px 25px;
    width: 100%;
    background: #403F45;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .agree_list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
    color: white;
    font-size: 16px;
  }
  .agree_list:last-child{
    margin-bottom: 0;
  }
  .agree_list_left{
    display: flex;
    align-items: center;
    column-gap: 13px;
    color: white;
    font-size: 16px;
  }
  .person_img{
    background-image: url('@/assets/images/id.png');
  }
  .passwd_img{
    background-image:url('@/assets/images/password.png')
  }
  .call_img{
    background-image: url('@/assets/images/call.png');
  }
  .phone_img{
    background-image: url('@/assets/images/phone.png');
  }
  .address_img{
    background-image: url('@/assets/images/address.png');
  }
  .disabled{
    cursor: default;
    opacity: 0.3;
    background: #A45CFE;
  }
  .pointer{
    cursor: pointer;
  }
  .terms_height{
    height: 544px;
  }
  .terms_alert{
    transform: translate(24px, 10px);
    color: #E5404D;
    font-size: 11px;
  }
</style>