const diseases = [
  {
    "key": "치관부우식증 또는 치질결손",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치관부우식증 또는 치질결손",
      "position": "치아의 치관부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Dental caries or hemorrhoidal defects",
      "position": "crown of teeth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    },
    "vietnam": {
      "category": "bệnh răng",
      "name": "Sâu răng hoặc dị tật trĩ",
      "position": "mão răng",
      "additional": "X quang quanh chóp, Vatality test",
      "recommend": "Cần có phương pháp điều trị thích hợp tùy theo tình trạng răng"
    },
  },
  {
    "key": "인접면우식증",
    "ko": {
      "category": "치아 관련 질환",
      "name": "인접면우식증",
      "position": "치아의 치관부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Proximal caries",
      "position": "crown of teeth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    }
  },
  {
    "key": "치경부우식증 또는 마모증",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치경부우식증 또는 마모증",
      "position": "치아의 치경부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Cervical caries or abrasion",
      "position": "cervical region of teeth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    }
  },
  {
    "key": "치근우식증",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치근우식증",
      "position": "치아의 치근부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Root caries",
      "position": "root of tooth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    }
  },
  {
    "key": "이차우식증",
    "ko": {
      "category": "치아 관련 질환",
      "name": "이차우식증",
      "position": "치아의 수복물 하방",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Secondary caries",
      "position": "Lower restoration of teeth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    }
  },
  {
    "key": "치내치",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치내치",
      "position": "치아의 치관부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치근단 염증이 있는 경우 즉각적인 치료가 필요하며 없는 경우에는 정기적인 구강검진으로 치근단 염증 발생 여부 확인 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Dens invaginatus",
      "position": "crown of teeth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "If there is apical inflammation, immediate treatment is required, and if there is no apical inflammation, regular oral examinations are required to check for apical inflammation."
    }
  },
  {
    "key": "치외치",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치외치",
      "position": "치아의 치관부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치근단 염증이 있는 경우 즉각적인 치료가 필요하며 이 부위의 치질이 약하기 때문에 정기적인 구강검진으로 변화 여부 확인 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Dens Evaginatus",
      "position": "crown of teeth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "If there is apical inflammation, immediate treatment is required, and since hemorrhoids in this area are weak, regular oral examinations are required to check for changes."
    }
  },
  {
    "key": "Fusion",
    "ko": {
      "category": "치아 관련 질환",
      "name": "Fusion",
      "position": "치아의 치관부",
      "additional": "치근단영상, 임상검사",
      "recommend": "변화 여부 체크를 위해 정기적인 구강검진 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Fusion",
      "position": "crown of teeth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Regular oral examination is required to check for changes"
    }
  },
  {
    "key": "선천적 치아결손",
    "ko": {
      "category": "치아 관련 질환",
      "name": "선천적 치아결손",
      "position": "치아",
      "additional": "",
      "recommend": "환자에게 치아의 선천적 결손에 대한 설명 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Congenital tooth loss",
      "position": "teeth",
      "additional": "",
      "recommend": "The patient needs to be explained about the birth defect of the teeth"
    }
  },
  {
    "key": "하악관 중첩",
    "ko": {
      "category": "치아 관련 질환",
      "name": "하악관 중첩",
      "position": "#38, 48 치아의 치근부",
      "additional": "치근단영상, CBCT",
      "recommend": "발치를 하기 전에 CBCT에서 사랑니와 하악관과의 관계에 대한 정확한 평가 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Nesting of the mandibular canal",
      "position": "Roots of teeth #38 and 48",
      "additional": "Periapical radiograph, CBCT",
      "recommend": "Accurate evaluation of the relationship between the wisdom tooth and the mandibular canal in CBCT before tooth extraction is necessary"
    }
  },
  {
    "key": "매복치",
    "ko": {
      "category": "치아 관련 질환",
      "name": "매복치",
      "position": "치아",
      "additional": "치근단영상",
      "recommend": "매복치 주위에 병적인 소견이 있는지 확인 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Embedded teeth",
      "position": "teeth",
      "additional": "Periapical radiograph",
      "recommend": "It is necessary to check whether there are any pathological findings around the impacted teeth."
    }
  },
  {
    "key": "치관주위염",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치관주위염",
      "position": "#38, 48 치아의 치관부",
      "additional": "치근단검사, 임상증상 확인",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Pericoronitis",
      "position": "The crown of teeth #38 and 48",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    }
  },
  {
    "key": "치근단방사선투과성",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치근단방사선투과성",
      "position": "치아의 치근부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치근단병소의 원인(치근단염증 또는 치주염)을 확인하고 상태에 따라 적절한 치료 필요 "
    },
    "en": {
      "category": "Teeth disease",
      "name": "Periapical radiolucency",
      "position": "root of tooth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Confirm the cause of the apical lesion (apical inflammation or periodontitis) and appropriate treatment is required according to the condition"
    }
  },
  {
    "key": "치근단골형성이상",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치근단골형성이상",
      "position": "치아의 치근부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": " 특별한 치료는 필요없으며 변화여부 확인을 위해 정기적인 구강검진이 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Periodontitis dysplasia",
      "position": "root of tooth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "No special treatment is required, and regular oral examinations are required to check for changes."
    }
  },
  {
    "key": "과백악질증",
    "ko": {
      "category": "치아 관련 질환",
      "name": "과백악질증",
      "position": "치아의 치근부",
      "additional": "치근단영상, 임상증상 확인",
      "recommend": "원인(과맹출, 과도한 교합력 등)이 있는지 확인하고 특별한 처치는 필요없으며 발치시 주의 요망"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Hypercelebrosis",
      "position": "root of tooth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Check if there is a cause (excessive eruption, excessive bite force, etc.)"
    }
  },
  {
    "key": "치근파절",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치근파절",
      "position": "치아의 치근부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Root fracture",
      "position": "root of tooth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    }
  },
  {
    "key": "잔존치근",
    "ko": {
      "category": "치아 관련 질환",
      "name": "잔존치근",
      "position": "치아의 치근부",
      "additional": "치근단영상",
      "recommend": "치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Residual root",
      "position": "root of tooth",
      "additional": "Periapical radiograph",
      "recommend": "Appropriate treatment is required according to the condition of the teeth"
    }
  },
  {
    "key": "치근외흡수",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치근외흡수",
      "position": "치아의 치근부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치근내흡수의 원인이 있는지 확인해야 하며 치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Extraradical resorption",
      "position": "root of tooth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "It is necessary to check whether there is a cause of intradental resorption, and appropriate treatment is required according to the condition of the tooth."
    }
  },
  {
    "key": "치근내흡수",
    "ko": {
      "category": "치아 관련 질환",
      "name": "치근내흡수",
      "position": "치아의 치근부",
      "additional": "치근단영상, 임상검사(vitality test)",
      "recommend": "치근내흡수의 원인이 있는지 확인해야 하며 치아 상태에 따라 적절한 치료 필요"
    },
    "en": {
      "category": "Teeth disease",
      "name": "Intradental resorption",
      "position": "root of tooth",
      "additional": "Periapical radiograph, Vatality test",
      "recommend": "It is necessary to check whether there is a cause of intradental resorption, and appropriate treatment is required according to the condition of the tooth."
    }
  },
  {
    "key": "과잉치",
    "ko": {
      "category": "치아 관련 질환",
      "name": "과잉치",
      "position": "",
      "additional": "치근단영상, CBCT",
      "recommend": ""
    },
    "en": {
      "category": "Teeth disease",
      "name": "Hyperdontia",
      "position": "",
      "additional": "Periapical radiograph, CBCT",
      "recommend": ""
    }
  },
  {
    "key": "타석",
    "ko": {
      "category": "연조직석회화",
      "name": "타석",
      "position": "타액선",
      "additional": "임상증상 확인",
      "recommend": "젊은 환자인 경우 제거를 해야하며 치료를 못할 경우 상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "Calcification",
      "name": "Sialoithiasis",
      "position": "salivary gland",
      "additional": "Vatality test",
      "recommend": "If the patient is young, it should be removed, and if treatment is not possible, refer to a higher level hospital."
    }
  },
  {
    "key": "림프절석회화",
    "ko": {
      "category": "연조직석회화",
      "name": "림프절석회화",
      "position": "악하공간",
      "additional": "",
      "recommend": "특별한 증상이 없으며 만성질환이 있을 가능성 있으며 메디컬 기왕력 체크 필요"
    },
    "en": {
      "category": "Calcification",
      "name": "Lymph node calcification",
      "position": "submaxillary space",
      "additional": "",
      "recommend": "There are no special symptoms, there is a possibility of chronic disease, and a medical history check is required"
    }
  },
  {
    "key": "편도석회화",
    "ko": {
      "category": "연조직석회화",
      "name": "편도석회화",
      "position": "구개편도",
      "additional": "",
      "recommend": "특별한 치료는 필요없으며 반복적인 상기도 감염(목감기)의 기왕력이 있을 가능성이 높음"
    },
    "en": {
      "category": "Calcification",
      "name": "Maxillary Sinus",
      "position": "tonsil",
      "additional": "",
      "recommend": "There is no need for special treatment, and there is a high possibility of having a history of recurrent upper respiratory infections (sore throat)"
    }
  },
  {
    "key": "경동맥석회화",
    "ko": {
      "category": "연조직석회화",
      "name": "경동맥석회화",
      "position": "인두주위공간",
      "additional": "",
      "recommend": "심혈관계 질환 여부 체크 필요"
    },
    "en": {
      "category": "Calcification",
      "name": "Carotid artery calcification",
      "position": "pharyngeal space",
      "additional": "",
      "recommend": "Need to check for cardiovascular disease"
    }
  },
  {
    "key": "정맥석",
    "ko": {
      "category": "연조직석회화",
      "name": "정맥석",
      "position": "",
      "additional": "",
      "recommend": "특별한 치료는 필요없으나 정확한 진단과 치료 여부 결정을 위해 상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "Calcification",
      "name": "Phlebolith",
      "position": "",
      "additional": "",
      "recommend": "No special treatment is required, but referral to a superior hospital for accurate diagnosis and treatment decision"
    }
  },
  {
    "key": "경상설골인대골화",
    "ko": {
      "category": "연조직석회화",
      "name": "경상설골인대골화",
      "position": "경상설골인대골화",
      "additional": "",
      "recommend": "목을 움직일 때 통증이 있는 경우 병소와의 상관성 평가 필요"
    },
    "en": {
      "category": "Calcification",
      "name": "Stylohyoid ligament calcification",
      "position": "Hyoid ligament ossification",
      "additional": "",
      "recommend": "If there is pain when moving the neck, it is necessary to evaluate the correlation with the lesion"
    }
  },
  {
    "key": "상악동석",
    "ko": {
      "category": "연조직석회화",
      "name": "상악동석",
      "position": "상악동",
      "additional": "",
      "recommend": "특별한 치료는 필요없으며 주기적인 정기검진 필요"
    },
    "en": {
      "category": "Calcification",
      "name": "Antrolith",
      "position": "maxillary sinus",
      "additional": "",
      "recommend": "There is no need for special treatment, and regular check-ups are required."
    }
  },
  {
    "key": "피부골종",
    "ko": {
      "category": "연조직석회화",
      "name": "피부골종",
      "position": "교합면 높이의 구치부 후방부",
      "additional": "",
      "recommend": " 특별한 치료는 필요없으며 변화여부 확인을 위해 정기적인 구강검진이 필요"
    },
    "en": {
      "category": "Calcification",
      "name": "Dermal osteoma",
      "position": "posterior part at occlusal level",
      "additional": "",
      "recommend": "No special treatment is required, and regular oral examinations are required to check for changes."
    }
  },
  {
    "key": "쇼그렌증후군",
    "ko": {
      "category": "연조직석회화",
      "name": "쇼그렌증후군",
      "position": "타액선",
      "additional": "임상증상(타액분비기능 저하, 구강건조증, 안구건조증 등) 확인",
      "recommend": "상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "Calcification",
      "name": "Sjogren's syndrome",
      "position": "salivary gland",
      "additional": "Vatality test",
      "recommend": "Referral to a higher level hospital"
    }
  },
  {
    "key": "저류가성낭",
    "ko": {
      "category": "상악동 소견",
      "name": "저류가성낭",
      "position": "상악동",
      "additional": "",
      "recommend": "자연 치유되는 경우가 대부분이므로 특별한 치료는 필요없으며 변화여부 확인을 위해 정기적인 구강검진이 필요"
    },
    "en": {
      "category": "Maxillary sinus",
      "name": "Retention pseudocyst",
      "position": "maxillary sinus",
      "additional": "",
      "recommend": "In most cases, it heals naturally, so no special treatment is required, and regular oral examinations are required to check for changes."
    }
  },
  {
    "key": "점막비후",
    "ko": {
      "category": "상악동 소견",
      "name": "점막비후",
      "position": "상악동",
      "additional": "임상증상 확인",
      "recommend": "특별한 치료는 필요없으나 상악동 부위에 임상증상이 있다면 정확한 진단이 필요"
    },
    "en": {
      "category": "Maxillary sinus",
      "name": "Mucosal thickening",
      "position": "maxillary sinus",
      "additional": "Vatality test",
      "recommend": "No special treatment is required, but accurate diagnosis is necessary if there are clinical symptoms in the maxillary sinus area."
    }
  },
  {
    "key": "상악동혼탁상",
    "ko": {
      "category": "상악동 소견",
      "name": "상악동혼탁상",
      "position": "상악동",
      "additional": "CBCT, 임상증상 확인",
      "recommend": "상악동염이나 다른 병적인 소견의 가능성이 있으므로 영상치의학 전문의와의 협진 필요"
    },
    "en": {
      "category": "Maxillary sinus",
      "name": "Maxillary sinus congestion",
      "position": "maxillary sinus",
      "additional": "CBCT, Vatality test",
      "recommend": "There is a possibility of maxillary sinusitis or other pathological findings, so consultation with a specialist in imaging dentistry is necessary."
    }
  },
  {
    "key": "활액연골종증",
    "ko": {
      "category": "TMJ 이상",
      "name": "활액연골종증",
      "position": "TMJ",
      "additional": "CBCT",
      "recommend": "상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "TMJ",
      "name": "Synovial chondromatosis",
      "position": "TMJ",
      "additional": "CBCT",
      "recommend": "Referral to a higher level hospital"
    }
  },
  {
    "key": "골연골종",
    "ko": {
      "category": "TMJ 이상",
      "name": "골연골종",
      "position": "TMJ",
      "additional": "CBCT, 임상증상확인",
      "recommend": "치료를 못 할 경우 상급병원으로의 진료 의뢰"
    },
    "en": {
      "category": "TMJ",
      "name": "Osteochondroma",
      "position": "TMJ",
      "additional": "CBCT, Vatality test",
      "recommend": "If treatment is not available, referral to a higher level hospital"
    }
  },
  {
    "key": "방사선투과상",
    "ko": {
      "category": "악골 이상 소견",
      "name": "방사선투과상",
      "position": "악골",
      "additional": "",
      "recommend": "정확한 진단을 위해 영상치의학 전문의와의 협진 필요"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Radiolucency",
      "position": "jaw bone",
      "additional": "",
      "recommend": "Collaboration with imaging dentistry specialist is required for accurate diagnosis"
    }
  },
  {
    "key": "방사선불투과성",
    "ko": {
      "category": "악골 이상 소견",
      "name": "방사선불투과성",
      "position": "악골",
      "additional": "",
      "recommend": "대부분 특발성골경화증 또는 골형성이상인 경우가 많으며 환자 증상이 있다면 영상치의학 전문의와의 협진 필요"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Radiopaque",
      "position": "jaw bone",
      "additional": "",
      "recommend": "In most cases, it is idiopathic osteosclerosis or bone dysplasia, and if the patient has symptoms, it is necessary to consult with a radiologist."
    },
    "vietnam": {
      "category": "xương hàm",
      "name": "độ phóng xạ",
      "position": "xương hàm",
      "additional": "",
      "recommend": "Cần phối hợp với bác sĩ chuyên khoa hình ảnh nha khoa để chẩn đoán chính xác"
    },
  },
  {
    "key": "방사선혼합상",
    "ko": {
      "category": "악골 이상 소견",
      "name": "방사선혼합상",
      "position": "악골",
      "additional": "",
      "recommend": "정확한 진단을 위해 영상치의학 전문의와의 협진 필요"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Radiation mixture",
      "position": "jaw bone",
      "additional": "",
      "recommend": "Collaboration with imaging dentistry specialist is required for accurate diagnosis"
    }
  },
  {
    "key": "하악골설측함요",
    "ko": {
      "category": "악골 이상 소견",
      "name": "하악골설측함요",
      "position": "하악골",
      "additional": "CBCT",
      "recommend": " 특별한 치료는 필요없으며 변화여부 확인을 위해 정기적인 구강검진이 필요"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Mandibular lingual concavity",
      "position": "mandible",
      "additional": "CBCT",
      "recommend": "No special treatment is required, and regular oral examinations are required to check for changes."
    }
  },
  {
    "key": "치아종",
    "ko": {
      "category": "악골 이상 소견",
      "name": "치아종",
      "position": "악골",
      "additional": "치근단영상",
      "recommend": "제거가 필요하며 치료를 못 할 경우 상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Odontoma",
      "position": "jaw bone",
      "additional": "Periapical radiograph",
      "recommend": "If removal is necessary and treatment is not possible, refer to a higher level hospital"
    }
  },
  {
    "key": "비구개관낭",
    "ko": {
      "category": "악골 이상 소견",
      "name": "비구개관낭",
      "position": "상악정중부",
      "additional": "치근단영상, CBCT",
      "recommend": "치료를 못 할 경우 상급병원으로의 진료 의뢰"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Nasolabial canal cyst",
      "position": "mid maxilla",
      "additional": "Periapical radiograph, CBCT",
      "recommend": "If treatment is not available, referral to a higher level hospital"
    }
  },
  {
    "key": "섬유형성이상",
    "ko": {
      "category": "악골 이상 소견",
      "name": "섬유형성이상",
      "position": "악골",
      "additional": "CBCT",
      "recommend": "특별한 치료는 필요없으며 변화 여부 확인을 위해 주기적인 정기검진이 필요하고 이 부위에 염증이 발생하거나 외과적 치료시 혈액공급이 원할하지 않아 창상 치유에 문제가 있을 수 있으므로 주의 요망 "
    },
    "en": {
      "category": "Jaw bone",
      "name": "Fibrodysplasia",
      "position": "jaw bone",
      "additional": "CBCT",
      "recommend": "No special treatment is required, and periodic checkups are required to check for changes. Caution is advised as there may be problems with wound healing due to inflammation in this area or poor blood supply during surgical treatment."
    }
  },
  {
    "key": "골종",
    "ko": {
      "category": "악골 이상 소견",
      "name": "골종",
      "position": "악골",
      "additional": "CBCT, 임상증상확인",
      "recommend": "치료를 못 할 경우 상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Osteoma",
      "position": "jaw bone",
      "additional": "CBCT, Vatality test",
      "recommend": "If treatment is not available, refer to a higher level hospital"
    }
  },
  {
    "key": "골절",
    "ko": {
      "category": "악골 이상 소견",
      "name": "골절",
      "position": "악골",
      "additional": "치근단영상, CBCT, 임상증상 확인",
      "recommend": "치료를 못 할 경우 상급병원으로의 진료 의뢰"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Fracture",
      "position": "jaw bone",
      "additional": "Periapical radiograph, CBCT",
      "recommend": "If treatment is not available, referral to a higher level hospital"
    }
  },
  {
    "key": "골화석증",
    "ko": {
      "category": "악골 이상 소견",
      "name": "골화석증",
      "position": "악골",
      "additional": "",
      "recommend": "전신적인 골질환으로 상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Osteopetrosis",
      "position": "jaw bone",
      "additional": "",
      "recommend": "Referral to a superior hospital for systemic bone disease"
    }
  },
  {
    "key": "악성종양",
    "ko": {
      "category": "악골 이상 소견",
      "name": "악성종양",
      "position": "악골",
      "additional": "임상증상 확인",
      "recommend": "상급병원으로 진료 의뢰"
    },
    "en": {
      "category": "Jaw bone",
      "name": "Malignant tumor",
      "position": "jaw bone",
      "additional": "Vatality test",
      "recommend": "Referral to a higher level hospital"
    }
  },
  {
    "key": "골다공증",
    "ko": {
      "category": "골다공증",
      "name": "골다공증",
      "position": "",
      "additional": "",
      "recommend": "골다공증 진단 및 약 복용 여부 확인하고 필요하면 골다공증 검사 권유"
    },
    "en": {
      "category": "Osteoporosis",
      "name": "Osteoporosis",
      "position": "",
      "additional": "",
      "recommend": "Diagnosis of osteoporosis, confirmation of drug intake, recommendation of osteoporosis test if necessary"
    }
  }
]

export default diseases;